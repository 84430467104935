import { useSession } from '../../../routes/SessionContext';
import { FeatureScope } from './types';

export const useUserInfo = () => {
	return useSession().userinfo;
};

export const useFeatureFlag = (featureFlag: string) => {
	return useUserInfo().flags.includes(featureFlag);
};

export const useSipgateDomain = () => {
	return useUserInfo().domain;
};

export const useFeatureScope = (featureScope: FeatureScope) =>
	useUserInfo().featureScope === featureScope;
