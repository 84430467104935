import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconEmpty = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M5.75 4.5c-.69 0-1.25.56-1.25 1.25v.75H3v-.75A2.75 2.75 0 0 1 5.75 3h.75v1.5zm7.25 0h2.5V3H13zm4.5 0h.75c.69 0 1.25.56 1.25 1.25v.75H21v-.75A2.75 2.75 0 0 0 18.25 3h-.75zm-13 11V13H3v2.5zm0 1.5v1.25c0 .69.56 1.25 1.25 1.25h.75V21h-.75A2.75 2.75 0 0 1 3 18.25V17zm15 .5v.75c0 .69-.56 1.25-1.25 1.25h-.75V21h.75A2.75 2.75 0 0 0 21 18.25v-.75zM11 4.5H8.5V3H11zM4.5 11V8.5H3V11zM21 13v2.5h-1.5V13zm0-2V8.5h-1.5V11zm-5.5 10H13v-1.5h2.5zm-7 0H11v-1.5H8.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconEmpty;
