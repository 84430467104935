import { Store } from '@reduxjs/toolkit';
import moment from 'moment';
import { Moment } from 'moment-timezone';

import api, { ApiError } from '../../../../../api/index';
import { Selections } from '../../../selections/parsers';
import { State } from '../../state';
import {
	FetchHistoryErrorAction,
	FetchHistoryPendingAction,
	FetchHistorySuccessAction,
	FetchHistoryTooManyEventsAction,
} from './types';

export const getTypeFilterArgument = (selections: Selections) => {
	const types: string[] = [];

	selections.type.forEach(value => {
		types.push(value.toUpperCase());
	});

	return types;
};

export const fetchHistory =
	(store: Store<State>) =>
	async (selections: Selections, isUserChange = false) => {
		const state = store.getState();
		const abortController = new AbortController();

		store.dispatch<FetchHistoryPendingAction>({
			type: 'FETCH_HISTORY_PENDING',
			abortController,
			isUserChange,
		});

		try {
			if (state.entries.abortController) {
				state.entries.abortController.abort();
			}

			let dates: { from?: Moment | null; to?: Moment | null };

			if (!selections.from && state.entries.tooManyEvents) {
				dates = { from: moment().subtract(3, 'month'), to: moment() };
			} else {
				dates = { from: selections.from, to: selections.to };
			}

			const apiResult = await api.cancellable(abortController.signal).getHistory({
				types: getTypeFilterArgument(selections),
				directions: selections.direction.map(d => d.toUpperCase()) as ('INCOMING' | 'OUTGOING')[],
				status: selections.status.map(s => s.toUpperCase()) as ('SUCCESS' | 'FAILURE')[],
				limit: state.pageSize,
				directory: [selections.directory.toUpperCase()],
				offset: selections.offset,
				starred: selections.starred.map(s => s.toUpperCase()) as ('STARRED' | 'UNSTARRED')[],
				read: selections.read.map(s => s.toUpperCase()) as ('READ' | 'UNREAD')[],
				from: dates.from,
				to: dates.to,
				labelIds: selections.labelIds,
				connectionIds: selections.connectionIds,
				phonenumber: selections.phonenumber ? selections.phonenumber : undefined,
			});

			store.dispatch<FetchHistorySuccessAction>({
				type: 'FETCH_HISTORY_SUCCESS',
				events: apiResult.items,
				totalCount: apiResult.totalCount,
			});

			return apiResult;
		} catch (e) {
			if (e instanceof Error && e.name === 'AbortError') {
				return null;
			}

			if (e instanceof ApiError && e.status === 503 && !state.entries.tooManyEvents) {
				store.dispatch<FetchHistoryTooManyEventsAction>({
					type: 'FETCH_HISTORY_TOO_MANY_EVENTS',
				});

				fetchHistory(store)(selections, isUserChange);
				return null;
			}

			store.dispatch<FetchHistoryErrorAction>({
				type: 'FETCH_HISTORY_ERROR',
			});

			return null;
		}
	};
