export type ApiFaxline = {
	id: string;
	ownerId: string;
	alias: string;
	tagline: string;
	callerId: string;
	canReceive: boolean;
};

export type ApiFaxLineNeo = {
	id: string;
	ownerIds: string[];
	alias: string;
};

export const isApiFaxLineNeo = (object: ApiFaxline | ApiFaxLineNeo): object is ApiFaxLineNeo => {
	return Object.hasOwnProperty.call(object, 'ownerIds');
};
