import React from 'react';
import { DeprecatedDialogContent } from '../../DeprecatedDialogContent';
import classes from './VideoIdentFailedDialog.module.scss';
import { useTranslate } from '../../../../redux/slices/translations';
import IdentityVerificationAbortIcon from '../../../../media/icons/icon-verification-abort-dark-line-default-64.svg';

const VideoIdentFailedDialog = () => {
	const translate = useTranslate();

	return (
		<DeprecatedDialogContent
			containerClassName={classes.container}
			icon={<img src={IdentityVerificationAbortIcon} alt="" />}
			headline={translate('IDENTITY_VERIFICATION_VIDEO_IDENT_FAILED_DIALOG_HEADLINE')}
		>
			<div className={classes.contentContainer}>
				{translate('IDENTITY_VERIFICATION_VIDEO_IDENT_FAILED_DIALOG_TEXT')}
			</div>
		</DeprecatedDialogContent>
	);
};

export default VideoIdentFailedDialog;
