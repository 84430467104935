import React, { useContext } from 'react';
import { NeoIcon } from '@web-apps/neo-icons';
import { NavLink, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import classes from './SideNavigationLink.module.scss';
import { SideNavigationMenuLink } from '../SideNavigation.types';
import { AreColorsInvertedContext, IsSideNavigationOpenContext } from '../SideNavigation.utils';
import { useTranslate } from '../../../redux/slices/translations';
import { Pill } from '../../../components/pill/Pill';
import { useFeatureScope } from '../../../redux/slices/userinfo';

type Props = {
	item: SideNavigationMenuLink;
	inGroup?: boolean;
};

export const SideNavigationLink = ({ item, inGroup }: Props) => {
	const translate = useTranslate();
	const isSideNavigationOpen = useContext(IsSideNavigationOpenContext);
	const areColorsInverted = useContext(AreColorsInvertedContext);
	const history = useHistory();
	const isNeoPBX = useFeatureScope('NEO');
	const { theme } = useTheme(isNeoPBX);

	const isActive = item.isActive
		? item.isActive(history.location.pathname)
		: history.location.pathname === item.path ||
			history.location.pathname.startsWith(`${item.path}/`);

	return (
		<li>
			<NavLink
				to={item.external ? { pathname: item.path } : item.path}
				target={item.external ? '_blank' : undefined}
				className={classnames(classes.item, {
					[classes.invertColors]: areColorsInverted,
					[classes.slim]: !isSideNavigationOpen,
					[classes.active]: isActive,
					[classes.inGroup]: inGroup,
				})}
				activeClassName={classes.active}
			>
				<>
					{item.icon && (
						<NeoIcon name={item.icon} variant={isActive ? 'solid' : 'line'} size={24} />
					)}
					<span className={classes.text}>{translate(item.title)}</span>
					{item.pill && (
						<div className={classnames(theme, classes.pillContainer)}>
							<Pill type={item.pill} />
						</div>
					)}
				</>
			</NavLink>
		</li>
	);
};
