import {
	SideNavigationItem,
	SideNavigationMenu,
	SideNavigationMenuGroup,
	SideNavigationMenuLink,
} from '../../navigation/SideNavigation/SideNavigation.types';
import * as paths from '../../routes/paths';
import { ApiLinks } from '../../api/types/links';
import { hasRestriction, RestrictionState } from '../../redux/modules/restrictions';
import { AppProperties } from '../../redux/slices/appProperties';

const getSettingsGroup = (links: ApiLinks): SideNavigationMenuGroup => {
	const groupLinks: SideNavigationMenuLink[] = [];

	if (links.notificationsUrl) {
		groupLinks.push({
			kind: 'LINK',
			path: paths.userNotificationsPath.build(),
			title: 'NOTIFICATIONS',
		});
	}

	groupLinks.push({
		kind: 'LINK',
		path: paths.blocklistPath.build(),
		title: 'BLACKLIST',
	});

	if (links.personalSettingsUrl) {
		groupLinks.push({
			kind: 'LINK',
			path: paths.personalSettingsPath.build(),
			title: 'PERSONAL_SETTINGS',
		});
	}

	return {
		kind: 'GROUP',
		icon: 'UserSettings',
		title: 'SETTINGS',
		links: groupLinks,
	};
};

export const getUserMenuLinks = (
	links: ApiLinks,
	restrictions: RestrictionState,
	appProperties: AppProperties,
	isNeoPBXCustomer: boolean
): SideNavigationMenu => {
	const items: SideNavigationItem[] = [];

	if (!isNeoPBXCustomer && hasRestriction(restrictions.items, 'CAN_SEE_CHANNEL_DASHBOARD')) {
		items.push({
			kind: 'LINK',
			path: paths.acdTeamLeadOverviewPath.build(),
			title: 'CHANNEL_TEAM_LEAD_OVERVIEW',
			icon: 'Multi_user_channel',
		});
	}

	if (isNeoPBXCustomer) {
		items.push({
			kind: 'LINK',
			path: paths.myChannelsPath.build(),
			title: 'CHANNEL',
			icon: 'Multi_user_channel',
		});
		items.push({
			kind: 'LINK',
			path: paths.userAreaDevicesPath.build(),
			title: 'ADMINS_APPS_AND_DEVICES_VIEW_HEADING',
			icon: 'Device_pool',
		});
	} else {
		items.push({
			kind: 'LINK',
			path: paths.eventlistPath.build(),
			title: 'EVENTLISTVIEW_TITLE',
			icon: 'EventList',
		});
	}

	if (
		!isNeoPBXCustomer &&
		links.presenceContainerUrl &&
		hasRestriction(restrictions.items, 'CAN_USE_WEB_BLF')
	) {
		items.push({
			kind: 'LINK',
			path: links.presenceContainerUrl,
			title: 'PRESENCE',
			icon: 'Presence',
		});
	}

	items.push({
		kind: 'LINK',
		path: paths.contactsPath.build(),
		title: 'CONTACTS',
		icon: 'Contacts',
	});

	if (!isNeoPBXCustomer) {
		items.push({
			kind: 'LINK',
			path: paths.routingPath.build(),
			title: 'APP_NAVIGATION_ROUTING',
			icon: 'Routing',
		});
	}

	if (appProperties.showSimRelevantOptions) {
		items.push({
			kind: 'LINK',
			path: paths.devicePoolPath.build(),
			title: 'DEVICE_POOL',
			icon: 'MobileTelephony',
		});
	}

	if (isNeoPBXCustomer) {
		items.push({
			kind: 'LINK',
			path: paths.faxAndSmsHistoryPath.build(),
			title: 'SMS_AND_FAX_VIEW_HEADING',
			icon: 'Fax',
		});
	}

	items.push(getSettingsGroup(links));

	return { items };
};
