import { createAsyncThunk } from '../../utils/wrapper';
import {
	ApiIvr,
	ApiIvrForwardingFallback,
	ApiIvrForwardingKey,
	ApiIvrForwardingMain,
	ApiIvrKeyForwadingTargetType,
} from './api/types';
import { ivrRestApiClient as api } from './api';
import { IvrState } from './types';

type UpdateIvrForwardingKeyActionParameters =
	| {
			ivrId: string;
			forwardingKey: ApiIvrForwardingKey | ApiIvrForwardingMain;
			value: string;
			type: ApiIvrKeyForwadingTargetType;
	  }
	| {
			ivrId: string;
			forwardingKey: ApiIvrForwardingFallback;
			value: string;
			type: ApiIvrKeyForwadingTargetType;
			loopCount: number;
	  };
const shouldFetchIvrs = (state: IvrState) => !state.fetched && !state.fetching;

export const fetchIvrs = createAsyncThunk<
	ApiIvr[],
	{
		force?: boolean;
	}
>(
	'ivrs/fetch',
	async () => {
		return api.getIvrs();
	},
	{
		condition: ({ force = false }, { getState }) => force || shouldFetchIvrs(getState().ivrs),
	}
);

export const createIvr = createAsyncThunk<
	ApiIvr,
	{
		alias: string;
	}
>('ivrs/create', async ({ alias }) => {
	return api.createIvr(alias);
});

export const renameIVR = createAsyncThunk<
	void,
	{
		ivrId: string;
		alias: string;
	}
>('ivrs/rename', async ({ ivrId, alias }) => {
	return api.renameIvr(ivrId, alias);
});

export const deleteIVR = createAsyncThunk<void, string>('ivrs/delete', async ivrId => {
	return api.deleteIvr(ivrId);
});

export const assignPhoneNumbersToIvr = createAsyncThunk<
	void,
	{
		ivrId: string;
		phoneNumbers: string[];
	}
>('ivrs/assignPhoneNumbers', async ({ ivrId, phoneNumbers }) => {
	return api.assignPhoneNumbersToIvr(ivrId, phoneNumbers);
});

export const assignAudioFileToIvr = createAsyncThunk<
	void,
	{
		ivrId: string;
		audioFileId: string;
	}
>('ivrs/assignAudioFile', async ({ ivrId, audioFileId }) => {
	return api.assignAudioFileToIvr(ivrId, audioFileId);
});

export const setIvrKeyMapping = createAsyncThunk<void, UpdateIvrForwardingKeyActionParameters>(
	'ivrs/setIvrKeyMapping',
	async props => {
		const { ivrId, forwardingKey, value, type } = props;
		if (forwardingKey === 'FALLBACK') {
			const loopCount = props.loopCount;
			return api.updateIvrKeyForwardingMapping(ivrId, forwardingKey, value, type, loopCount);
		}
		return api.updateIvrKeyForwardingMapping(ivrId, forwardingKey, value, type);
	}
);

export const setIvrFallbackMapping = createAsyncThunk<
	void,
	{ ivrId: string; value: string; type: ApiIvrKeyForwadingTargetType; loopCount: number }
>('ivrs/setIvrFallbackMapping', async ({ ivrId, value, type, loopCount }) => {
	return api.updateIvrFallbackMapping(ivrId, value, type, loopCount);
});

export const deleteIvrKeyMapping = createAsyncThunk<
	void,
	{ ivrId: string; forwardingKey: ApiIvrForwardingKey | ApiIvrForwardingFallback }
>('ivrs/deleteIvrKeyMapping', async ({ ivrId, forwardingKey }) => {
	return api.deleteIvrKeyForwardingMapping(ivrId, forwardingKey);
});
