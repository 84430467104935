import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconOriginal_size = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M19.25 4H4.75A2.755 2.755 0 0 0 2 6.75v10.5A2.755 2.755 0 0 0 4.75 20h14.5A2.755 2.755 0 0 0 22 17.25V6.75A2.755 2.755 0 0 0 19.25 4m1.25 13.25c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25V6.75c0-.69.56-1.25 1.25-1.25h14.5c.69 0 1.25.56 1.25 1.25zm-3-9.75H16V9h-1.5v1.5H16V15h-1.5v1.5H19V15h-1.5zm-4.5 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0M12 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2M7 7.5h1.5V15H10v1.5H5.5V15H7v-4.5H5.5V9H7z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconOriginal_size;
