import { useEffect, useState } from 'react';

export function useTouchDevice() {
	const [isTouchDevice, setIsTouchDevice] = useState(false);

	useEffect(() => {
		setIsTouchDevice(window.matchMedia('(pointer: coarse)').matches);
	}, []);

	return isTouchDevice;
}
