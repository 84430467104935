import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconUpload = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M11.25 16.5V5.31L7.53 9.03 6.47 7.97l4.645-4.645a1.26 1.26 0 0 1 1.77 0L17.53 7.97l-1.06 1.06-3.72-3.72V16.5z"
			/>
			<path
				fill="currentColor"
				d="M19.5 18.25V15.5H21v2.75A2.755 2.755 0 0 1 18.25 21H5.75A2.755 2.755 0 0 1 3 18.25V15.5h1.5v2.75c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconUpload;
