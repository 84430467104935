import React, { forwardRef } from 'react';
import classnames from 'classnames';

import classes from './Button.module.scss';

interface Props {
	disabled?: boolean;
	children: React.ReactNode;
	className?: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	ariaLabel?: string;
}

interface PropsWithRef extends Props {
	forwardedRef?: React.Ref<HTMLButtonElement>;
}

class UnwrappedButton extends React.PureComponent<PropsWithRef> {
	public render() {
		return (
			<button
				ref={this.props.forwardedRef}
				onClick={this.props.onClick}
				className={classnames(classes.button, this.props.className)}
				type="button"
				disabled={this.props.disabled}
				aria-label={this.props.ariaLabel}
			>
				{this.props.children}
			</button>
		);
	}
}

/** @deprecated Please use Panda Buttons */
export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => (
	<UnwrappedButton
		disabled={props.disabled}
		className={props.className}
		onClick={props.onClick}
		forwardedRef={ref}
		ariaLabel={props.ariaLabel}
	>
		{props.children}
	</UnwrappedButton>
));
