import { createEntityAdapter } from '@reduxjs/toolkit';
import { PersonalAccessTokenItem, PersonalAccessTokenState } from './types';
import { createStatusItemEntityAdapter } from '../../utils/statusEntityAdapter';

export const personalAccessTokenEntityAdapter = createEntityAdapter<PersonalAccessTokenItem>();

const fetchItemSelector = (state: PersonalAccessTokenState) => state.fetch;
export const fetchItemEntityAdapter = createStatusItemEntityAdapter(fetchItemSelector);

const createItemSelector = (state: PersonalAccessTokenState) => state.create;
export const createItemSelectorAdapter = createStatusItemEntityAdapter(createItemSelector);
