import { createAsyncThunk } from '../../utils/wrapper';
import {
	CouldNotAddFaxLineMembers,
	CouldNotCreateFaxLine,
	CouldNotFetchFaxLines,
	CouldNotRemoveFaxLineMembers,
	CouldNotSetFaxLineAlias,
	CouldNotSetFaxLineCallerId,
	CouldNotSetFaxLineTagLine,
	FaxLineError,
} from './types';
import { isNeoPBXCustomer } from '../userinfo';
import { ApiFaxline, ApiFaxLineNeo, faxLineRestApiClient as api } from './api';
import { isFetched } from './selectors';

export const fetchFaxLines = createAsyncThunk<
	ApiFaxline[],
	boolean | undefined,
	{ rejectValue: FaxLineError }
>(
	'faxLines/fetch',
	async (_forceFetch, { rejectWithValue }) => {
		try {
			return (await api.getFaxLines()).items;
		} catch (error) {
			return rejectWithValue(CouldNotFetchFaxLines());
		}
	},
	{
		condition: (forceFetch = false, { getState }) => {
			const fetched = isFetched(getState().faxlines, '*');
			return !fetched || forceFetch;
		},
	}
);

export type SetFaxLineAliasParameter = {
	faxLineId: string;
	alias: string;
};

export const setFaxLineAlias = createAsyncThunk<
	void,
	SetFaxLineAliasParameter,
	{ rejectValue: FaxLineError }
>(
	'faxLines/setAlias',
	async ({ faxLineId, alias }: SetFaxLineAliasParameter, { rejectWithValue }) => {
		try {
			await api.setFaxLineAlias(faxLineId, alias);
		} catch (error) {
			return rejectWithValue(CouldNotSetFaxLineAlias(faxLineId));
		}
	}
);

export type SetFaxLineTagLineParameter = {
	faxLineId: string;
	tagline: string;
};

export const setFaxLineTagLine = createAsyncThunk<
	void,
	SetFaxLineTagLineParameter,
	{ rejectValue: FaxLineError }
>(
	'faxLines/setTagLine',
	async ({ faxLineId, tagline }: SetFaxLineTagLineParameter, { rejectWithValue }) => {
		try {
			await api.setFaxLineTagline(faxLineId, tagline);
		} catch (error) {
			return rejectWithValue(CouldNotSetFaxLineTagLine(faxLineId));
		}
	}
);

export type CreateFaxLineParameters = {
	ownerIds: string[];
	alias: string;
};

export const createFaxLine = createAsyncThunk<
	ApiFaxline | ApiFaxLineNeo,
	CreateFaxLineParameters,
	{
		rejectValue: FaxLineError;
	}
>(
	'faxLines/create',
	async ({ ownerIds, alias }: CreateFaxLineParameters, { getState, rejectWithValue }) => {
		try {
			if (isNeoPBXCustomer(getState().userinfo.data)) {
				return (await api.createFaxLineNeo(ownerIds, alias)) as ApiFaxLineNeo;
			}

			if (ownerIds.length === 0) {
				throw new Error('classic pbx faxes needs a owner');
			}

			return (await api.createFaxLine(ownerIds[0], alias)) as ApiFaxline;
		} catch (error) {
			return rejectWithValue(CouldNotCreateFaxLine());
		}
	}
);

export type DeleteFaxLineParameters = {
	faxLineId: string;
};

export const deleteFaxLine = createAsyncThunk<void, DeleteFaxLineParameters>(
	'faxLines/delete',
	async ({ faxLineId }: DeleteFaxLineParameters) => {
		return api.deleteFaxLine(faxLineId);
	},
	{
		condition: ({ faxLineId }: DeleteFaxLineParameters, { getState }) => {
			return getState()
				.restrictions.items.filter(
					r => r.restriction === 'CAN_DELETE_FAX_EXTENSION' && r.target === faxLineId
				)
				.every(r => r.value);
		},
	}
);

export type SetFaxLineCallerIdParameters = {
	faxLineId: string;
	callerId: string;
	anonymous: boolean;
};

export const setFaxLineCallerId = createAsyncThunk<
	void,
	SetFaxLineCallerIdParameters,
	{ rejectValue: FaxLineError }
>(
	'faxLines/setFaxLineCallerId',
	async ({ faxLineId, callerId, anonymous }: SetFaxLineCallerIdParameters, { rejectWithValue }) => {
		try {
			return await api.setFaxLineCallerId(faxLineId, callerId, anonymous);
		} catch (error) {
			return rejectWithValue(CouldNotSetFaxLineCallerId(faxLineId));
		}
	}
);

export type AddFaxLineOwnersParameter = {
	faxLineId: string;
	ownerIds: string[];
};

export const addFaxLineOwners = createAsyncThunk<
	void,
	AddFaxLineOwnersParameter,
	{
		rejectValue: FaxLineError;
	}
>(
	'faxLines/addOwners',
	async ({ faxLineId, ownerIds }: AddFaxLineOwnersParameter, { rejectWithValue }) => {
		try {
			await api.addFaxLineOwners(faxLineId, ownerIds);
		} catch (error) {
			return rejectWithValue(CouldNotAddFaxLineMembers(faxLineId));
		}
	},
	{
		condition: ({ ownerIds }) => {
			return ownerIds.length > 0;
		},
	}
);

export type RemoveFaxLineMembersActionParameter = AddFaxLineOwnersParameter;

export const removeFaxLineOwners = createAsyncThunk<
	void,
	RemoveFaxLineMembersActionParameter,
	{
		rejectValue: FaxLineError;
	}
>(
	'faxLines/removeOwners',
	async ({ faxLineId, ownerIds }: RemoveFaxLineMembersActionParameter, { rejectWithValue }) => {
		try {
			await api.removeFaxLineOwners(faxLineId, ownerIds);
		} catch (error) {
			return rejectWithValue(CouldNotRemoveFaxLineMembers(faxLineId));
		}
	},
	{
		condition: ({ ownerIds }) => {
			return ownerIds.length > 0;
		},
	}
);
