import { useSelector } from '../../utils/wrapper';
import { useAction } from '../../index';
import { fetchTranslations, setDebug } from './actions';
import { createSelections } from './selectors';

export const useTranslate = () => createSelections(useSelector(state => state.translations));

export const useTranslationActions = () => ({
	fetchTranslations: useAction(fetchTranslations),
	setDebug: useAction(setDebug),
});
