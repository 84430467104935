import { ApiTac } from '../../../api/types/tacs';
import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';
import { ReduxState } from '../../types';

const shouldFetchProductTacs = (state: ReduxState, productId: string) =>
	!state.productsTacs.fetchedForProducts.includes(productId) &&
	!state.productsTacs.fetchingForProducts.includes(productId);

export const fetchProductTacs = createAsyncThunk<ApiTac[], string>(
	'productsTacs/fetch',
	async (productId: string) => (await api.getTacsForProduct(productId)).items,
	{
		condition: (productId: string, { getState }) => shouldFetchProductTacs(getState(), productId),
	}
);
