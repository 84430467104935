import { createSlice } from '@reduxjs/toolkit';
import { ReferringChannelsState } from './types';
import { fetchReferringChannels } from './actions';

const initialState: ReferringChannelsState = {
	fetching: {},
	fetched: {},
	items: {},
};

const slice = createSlice({
	name: 'referringChannels',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchReferringChannels.fulfilled, (state, action) => ({
				...state,
				fetched: { ...state.fetched, [action.meta.arg.target]: true },
				fetching: { ...state.fetching, [action.meta.arg.target]: false },
				items: { [action.meta.arg.target]: action.payload },
			}))
			.addCase(fetchReferringChannels.rejected, (state, action) => ({
				...state,
				fetched: { ...state.fetched, [action.meta.arg.target]: false },
				fetching: { ...state.fetching, [action.meta.arg.target]: false },
			}))
			.addCase(fetchReferringChannels.pending, (state, action) => ({
				...state,
				fetched: { ...state.fetched, [action.meta.arg.target]: false },
				fetching: { ...state.fetching, [action.meta.arg.target]: true },
			}));
	},
});

export const reducer = slice.reducer;
