import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconMulti_user_channel = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M17.25 6.5c0-.69-.56-1.25-1.25-1.25h-1.63A2.49 2.49 0 0 1 12 7c-1.115 0-2.05-.74-2.37-1.75H8c-.69 0-1.25.56-1.25 1.25v3h-1.5v-3A2.755 2.755 0 0 1 8 3.75h1.63A2.49 2.49 0 0 1 12 2c1.115 0 2.05.74 2.37 1.75H16a2.755 2.755 0 0 1 2.75 2.75v3h-1.5zM9 14a3 3 0 1 1-6 0 3 3 0 0 1 6 0m9 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6m4 3.745A2.75 2.75 0 0 0 19.25 18h-2.495a2.75 2.75 0 0 0-2.75 2.745V22h8v-1.255zM7.25 18H4.755a2.75 2.75 0 0 0-2.75 2.745V22h8v-1.255A2.754 2.754 0 0 0 7.255 18z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconMulti_user_channel;
