import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconRinging_order_prarallel = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10 3.25c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v2.5C21 6.44 20.44 7 19.75 7h-8.5C10.56 7 10 6.44 10 5.75v-.5h-.75C8.56 5.25 8 5.81 8 6.5v4.75h2v-.5c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v2.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25v-.5H8v4.75c0 .69.56 1.25 1.25 1.25H10v-.5c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v2.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25v-.5h-.75A2.75 2.75 0 0 1 6.5 17.5v-4.75H3v-1.5h3.5V6.5a2.75 2.75 0 0 1 2.75-2.75H10zm1.5.25v2h8v-2zm0 7.5v2h8v-2zm0 9.5v-2h8v2z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconRinging_order_prarallel;
