import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconFile = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M13.56 2H4v20h16V8.44zM14 4.56 17.44 8H14zM5.5 20.5v-17h7v4.75c0 .69.56 1.25 1.25 1.25h4.75v11z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconFile;
