import { createAction } from '@reduxjs/toolkit';
import { createId } from './helpers';

export const createPseudo = createAction('pseudo/create', (value: string) => {
	return {
		payload: {
			key: createId(),
			value,
		},
	};
});

export const deletePseudo = createAction('pseudo/delete', (key: string) => {
	return {
		payload: {
			key,
		},
	};
});
