import React, { SVGProps } from 'react';
import classnames from 'classnames';

export const SortableArrowsIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		fill="currentColor"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		className={classnames('min-w-16', 'min-h-16', 'w-16', 'h-16', props.className)}
	>
		<path
			fillRule="evenodd"
			d="m3.201 2.419-2.01 2.847c-.475.672-.009 1.615.798 1.615h1.024v6.102c0 .562.442 1.017.987 1.017.545 0 .987-.455.987-1.017V6.881h1.024c.807 0 1.273-.943.798-1.615L4.8 2.42a.97.97 0 0 0-1.598 0ZM12.8 13.58l2.01-2.847c.475-.672.009-1.615-.798-1.615h-1.023V3.017C12.988 2.455 12.545 2 12 2c-.545 0-.988.455-.988 1.017v6.102H9.99c-.807 0-1.273.943-.798 1.615l2.01 2.847a.97.97 0 0 0 1.598 0Z"
			clipRule="evenodd"
		/>
	</svg>
);
