import { DataAutomationState } from './types';
import { dataAutomationAdapter, fetchItemEntityAdapter } from './adapter';

const selectItems = (state: DataAutomationState) => state.items;
const dataAutomationSelectors = dataAutomationAdapter.getSelectors(selectItems);
export const { selectAll, selectById } = dataAutomationSelectors;

const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();
export const {
	isSucceeded: isFetched,
	isPending: isFetching,
	hasFailed,
	selectById: selectFetchItemById,
} = fetchItemEntitySelectors;
