/* eslint-disable react/jsx-props-no-spreading */

import classnames from 'classnames';
import React, { forwardRef, memo } from 'react';
import {
	DisabledContext,
	isDisabled as isContextDisabled,
} from '../../../contexts/disabledContext';
import { DashIcon } from './DashIcon';
import { CheckIcon } from './CheckIcon';

type Props = Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	'type' | 'value' | 'className' | 'onChange' | 'checked'
> & {
	// eslint-disable-next-line react/no-unused-prop-types
	onChange?: (checked: boolean) => void;
	// eslint-disable-next-line react/no-unused-prop-types
	checked: boolean | 'indeterminate';
};

const styles = {
	div: (checked: boolean | 'indeterminate', isDisabled: boolean) =>
		classnames(
			'group',
			'relative',
			'z-0',
			'h-40',
			'pointer-fine:w-16',
			'pointer-fine:py-12',
			'pointer-coarse:w-40',
			'pointer-coarse:p-12',
			'pointer-coarse:-mx-12',
			'leading-none',
			isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
		),
	check: (checked: boolean | 'indeterminate', isDisabled: boolean) =>
		classnames(
			'absolute',
			'transition',
			'ease-in-out',
			'duration-100',
			'rounded',
			'z-10',
			'pointer-events-none',
			isDisabled && checked && 'text-neo-color-global-content-neutral-disabled',
			!isDisabled && [
				'group-hover:pointer-fine:text-neo-color-global-content-primary-moderate',
				'group-active:pointer-fine:text-neo-color-global-content-primary-intense',
			],
			checked &&
				!isDisabled && [
					'text-neo-color-global-content-primary-on-intense',
					'group-hover:pointer-fine:text-neo-color-global-content-primary-on-intense',
					'group-active:pointer-fine:text-neo-color-global-content-primary-on-intense',
				],
			!checked && ['text-neo-color-global-background-static-transparent']
		),
	dash: (isDisabled: boolean) =>
		classnames(
			'absolute',
			'transition-colors',
			'ease-in-out',
			'duration-100',
			'rounded',
			'z-10',
			'pointer-events-none',
			!isDisabled && ['text-neo-color-global-content-primary-intense'],
			isDisabled && ['text-neo-color-global-content-neutral-disabled']
		),
	input: (checked: boolean | 'indeterminate', isDisabled: boolean) =>
		classnames(
			'w-16',
			'h-16',
			'm-0',
			'relative',
			/* An outline-none creates a glitch on click in the App-Web */
			'appearance-none',
			'transition-background',
			'ease-in-out',
			'duration-100',
			'rounded',
			'border-2',
			'z-0',
			'focus-visible:ring-focus',
			isDisabled
				? [
						'cursor-not-allowed',
						'border-none',
						'bg-neo-color-global-background-neutral-intense-disabled',
					]
				: 'cursor-pointer',
			!checked &&
				!isDisabled && [
					'border-neo-color-global-border-neutral-intense-default',
					'bg-neo-color-global-background-neutral-soft-default',
					'group-hover:border-neo-color-global-border-primary-intense-hover',
					'group-active:border-neo-color-global-border-primary-intense-active',
				],
			checked === 'indeterminate' &&
				!isDisabled && [
					'border-neo-color-global-border-primary-intense-default',
					'bg-neo-color-global-background-neutral-soft-default',
					'group-hover:bg-neo-color-global-background-primary-moderate-hover',
					'group-active:bg-neo-color-global-background-primary-moderate-active',
				],
			checked === true &&
				!isDisabled && [
					'border-neo-color-global-border-static-transparent',
					'bg-neo-color-global-background-primary-intense-default',
					'group-hover:bg-neo-color-global-background-primary-intense-hover',
					'group-active:bg-neo-color-global-background-primary-intense-active',
				]
		),
};

export const VisualCheckbox = memo(
	forwardRef<HTMLInputElement, Props>(
		({ checked = false, disabled, onChange, ...inputProps }: Props, ref): JSX.Element => {
			const disabledContextValue = React.useContext(DisabledContext);
			const isDisabled = isContextDisabled(disabled, disabledContextValue);
			const getMark = () => {
				if (checked === 'indeterminate') {
					return <DashIcon className={styles.dash(isDisabled)} />;
				}

				return <CheckIcon className={styles.check(checked, isDisabled)} />;
			};

			/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
			return (
				<div
					className={styles.div(checked, isDisabled)}
					onClick={e => {
						if (!isDisabled && onChange && e.target === e.currentTarget) {
							onChange(!checked);
						}
					}}
				>
					{getMark()}
					<input
						ref={ref}
						className={styles.input(checked, isDisabled)}
						type="checkbox"
						disabled={isDisabled}
						checked={checked === 'indeterminate' ? false : checked}
						aria-checked={checked === 'indeterminate' ? 'mixed' : checked}
						onChange={() => {
							if (onChange) {
								onChange(!checked);
							}
						}}
						{...inputProps}
					/>
				</div>
			);
		}
	)
);
