import { createAsyncThunk } from '../../utils/wrapper';
import {
	CouldNotFindDataAutomation,
	DataAutomationError,
	DataAutomationState,
	GeneralDataAutomationError,
} from './types';
import { isApiError } from '../../../api/ApiError';
import { isFetched, isFetching } from './selectors';
import { dataAutomationRestApiClient as api } from './api';
import { DataAutomationResponse } from './api/types';

type FetchDataAutomationActionParameters = {
	containerId: string;
	force?: boolean;
};

const shouldFetchDataAutomation = (state: DataAutomationState, containerId: string) =>
	!isFetched(state, containerId) && !isFetching(state, containerId);

export const fetchDataAutomation = createAsyncThunk<
	DataAutomationResponse,
	FetchDataAutomationActionParameters,
	{
		rejectValue: DataAutomationError;
	}
>(
	'dataAutomation/fetch',
	async ({ containerId }: FetchDataAutomationActionParameters, { rejectWithValue }) => {
		try {
			return await api.getDataAutomation(containerId);
		} catch (error) {
			if (isApiError(error) && error.status === 404) {
				return rejectWithValue(CouldNotFindDataAutomation(containerId));
			}

			return rejectWithValue(GeneralDataAutomationError());
		}
	},
	{
		condition: ({ containerId, force = false }, { getState }) =>
			force || shouldFetchDataAutomation(getState().dataAutomations, containerId),
	}
);

export const deleteDataAutomation = createAsyncThunk(
	'dataAutomation/delete',
	async (containerId: string) => api.deleteDataAutomation(containerId)
);

type SetDataAutomationActionParameters = {
	containerId: string;
	productId: number;
	limit?: number;
};

export const setDataAutomation = createAsyncThunk<void, SetDataAutomationActionParameters>(
	'dataAutomation/set',
	async ({ containerId, productId, limit }) => api.setDataAutomation(containerId, productId, limit)
);
