import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconSmart_watch = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="m16.345 5.565-.955-3.57H8.615l-.955 3.57A2.75 2.75 0 0 0 5.505 8.25v7.5c0 1.31.925 2.41 2.155 2.685L8.615 22h6.775l.955-3.565A2.75 2.75 0 0 0 18.5 15.75v-7.5a2.76 2.76 0 0 0-2.155-2.685m-6.58-2.07h4.475l.535 2H9.23zm0 17-.535-2h5.545l-.535 2zM17 15.745c0 .685-.55 1.235-1.23 1.25H8.23A1.25 1.25 0 0 1 7 15.745v-7.5c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconSmart_watch;
