import {
	isAnonymous,
	isDirectDialNumber,
	isValidPhonenumber,
	localizeNumber,
} from '@web-apps/phonenumbers-utils';

import { NormalizedEndpoint, normalizeEndpoint } from './endpoint';
import { BlocklistEntry } from '../../../redux/slices/blocklist';
import { Contact } from '../../../redux/modules/contacts';
import { normalizeConnection, NormalizedConnection } from './connection';
import { HistoryEntry } from '../../../api/types/events';
import {
	createEndpointIdFromExtension,
	ExtensionState,
	resolveEndpoint,
} from '../../../utils/endpoints';
import { SipgateDomain } from '../../../redux/slices/userinfo';
import { flatMapRoutablePhonenumbers, Phonenumber } from '../../../redux/slices/phonenumbers';

interface ParticipantWithContact {
	name: string;
	number: string;
	contact: Contact;
	rawNumber: string;
	blacklistEntry?: BlocklistEntry;
}

interface ParticipantWithoutContact {
	name?: string;
	number?: string;
	rawNumber?: string;
	blacklistEntry?: BlocklistEntry;
}

export interface NormalizedEvent<Entry extends HistoryEntry = HistoryEntry> {
	originalEvent: Entry;
	selected: boolean;

	incoming: boolean;
	date: Date;
	scheduledDate?: Date;

	endpoints: NormalizedEndpoint[];
	connections: NormalizedConnection[];

	us: { number?: string; name?: string };
	them: ParticipantWithContact | ParticipantWithoutContact;

	source: ParticipantWithContact | ParticipantWithoutContact;
	target: ParticipantWithContact | ParticipantWithoutContact;
}

const findBlacklistEntry = (blacklist: BlocklistEntry[], number?: string) => {
	if (!number) {
		return undefined;
	}

	for (const entry of blacklist) {
		if (entry.isBlock && number.startsWith(entry.phoneNumber)) {
			return entry;
		}

		if (number === entry.phoneNumber) {
			return entry;
		}
	}

	return undefined;
};

const getNameFromNumber = (
	extensions: ExtensionState,
	domain: SipgateDomain,
	numbers: Phonenumber[],
	number: string | undefined
) => {
	const extensionId = flatMapRoutablePhonenumbers(numbers).find(
		n => localizeNumber(n.e164Number, domain) === number
	)?.routing?.targetId;

	if (extensionId) {
		const endpointId = createEndpointIdFromExtension(extensionId);

		if (endpointId) {
			const endpoint = resolveEndpoint(extensions, endpointId);
			if (typeof endpoint === 'object') {
				if ('alias' in endpoint) {
					return endpoint.alias;
				}

				if ('name' in endpoint) {
					return endpoint.name;
				}

				return `${endpoint.firstname} ${endpoint.lastname}`;
			}
		}
	}

	return undefined;
};

const normalizeEvent = (
	originalEvent: HistoryEntry,
	contactsByNumber: Map<string, Contact>,
	extensions: ExtensionState,
	blacklist: BlocklistEntry[],
	numbers: Phonenumber[],
	selected: boolean,
	domain: SipgateDomain
): NormalizedEvent<HistoryEntry> => {
	const date = new Date(originalEvent.dateCreated);

	let scheduledDate;
	if ('scheduled' in originalEvent) {
		scheduledDate = originalEvent.scheduled ? new Date(originalEvent.scheduled) : undefined;
	}

	const endpoints = originalEvent.endpoints.map(endpoint =>
		normalizeEndpoint(endpoint, extensions, domain)
	);

	const connections = originalEvent.connections.map(connection =>
		normalizeConnection(connection, extensions, domain)
	);

	let target: ParticipantWithContact | ParticipantWithoutContact;
	let source: ParticipantWithContact | ParticipantWithoutContact;

	let us: { number?: string; name?: string };
	let them: ParticipantWithContact | ParticipantWithoutContact;

	const targetAnonymous = isAnonymous(originalEvent.target);

	const isValidTargetNumber =
		!targetAnonymous &&
		(isValidPhonenumber(originalEvent.target, domain) || isDirectDialNumber(originalEvent.target));
	const targetNumber = isValidTargetNumber ? originalEvent.target : undefined;

	const isTargetName = !targetAnonymous && !isValidPhonenumber(originalEvent.target, domain);
	let targetNameFromNumber = isTargetName ? originalEvent.target : undefined;
	const rawTargetNumber = targetAnonymous ? undefined : originalEvent.target;

	const sourceAnonymous = isAnonymous(originalEvent.source);

	const isValidSourceNumber =
		!sourceAnonymous &&
		(isValidPhonenumber(originalEvent.source, domain) || isDirectDialNumber(originalEvent.source));
	const sourceNumber = isValidSourceNumber ? originalEvent.source : undefined;

	const isSourceName = !sourceAnonymous && !isValidPhonenumber(originalEvent.source, domain);
	let sourceNameFromNumber = isSourceName ? originalEvent.source : undefined;
	const rawSourceNumber = sourceAnonymous ? undefined : originalEvent.source;

	if (originalEvent.direction === 'INCOMING') {
		if (!sourceNameFromNumber) {
			sourceNameFromNumber = getNameFromNumber(extensions, domain, numbers, sourceNumber);
		}

		const sourceContact = sourceNumber ? contactsByNumber.get(sourceNumber) : undefined;
		const sourceName = sourceContact ? sourceContact.name : sourceNameFromNumber;

		target = {
			number: targetNumber,
			name: targetNameFromNumber,
			rawNumber: rawTargetNumber,
			blacklistEntry: findBlacklistEntry(blacklist, rawTargetNumber),
		};

		if (sourceNumber && sourceContact && sourceName) {
			source = {
				name: sourceName,
				number: sourceNumber,
				contact: sourceContact,
				rawNumber: rawSourceNumber,
				blacklistEntry: findBlacklistEntry(blacklist, rawSourceNumber),
			};
		} else {
			source = {
				name: sourceName,
				number: sourceNumber,
				rawNumber: rawSourceNumber,
				blacklistEntry: findBlacklistEntry(blacklist, rawSourceNumber),
			};
		}

		us = {
			number: targetNumber,
			name: targetNameFromNumber,
		};

		them = source;
	} else {
		if (!targetNameFromNumber) {
			targetNameFromNumber = getNameFromNumber(extensions, domain, numbers, targetNumber);
		}

		const targetContact = targetNumber ? contactsByNumber.get(targetNumber) : undefined;
		const targetName = targetContact ? targetContact.name : targetNameFromNumber;

		if (targetContact && targetName) {
			target = {
				name: targetName,
				number: targetNumber,
				contact: targetContact,
				rawNumber: rawTargetNumber,
				blacklistEntry: findBlacklistEntry(blacklist, rawTargetNumber),
			};
		} else {
			target = {
				name: targetName,
				number: targetNumber,
				rawNumber: rawTargetNumber,
				blacklistEntry: findBlacklistEntry(blacklist, rawTargetNumber),
			};
		}

		source = {
			number: sourceNumber,
			name: sourceNameFromNumber,
			rawNumber: rawSourceNumber,
			blacklistEntry: findBlacklistEntry(blacklist, rawSourceNumber),
		};

		us = {
			number: sourceNumber,
			name: sourceNameFromNumber,
		};

		them = target;
	}

	return {
		originalEvent,
		date,
		scheduledDate,
		endpoints,
		incoming: originalEvent.direction === 'INCOMING',
		selected,
		us,
		them,
		source,
		target,
		connections,
	};
};

export const normalizeEvents = (
	events: {
		originalEvent: HistoryEntry;
		selected: boolean;
	}[],
	contacts: Contact[],
	extensions: ExtensionState,
	blacklist: BlocklistEntry[],
	numbers: Phonenumber[],
	domain: SipgateDomain
) => {
	const sortedContacts = [...contacts].sort((contact1, contact2) =>
		contact2.name.localeCompare(contact1.name)
	);
	const contactsByNumber = new Map();

	for (const contact of sortedContacts) {
		for (const number of contact.numbers) {
			contactsByNumber.set(number.number, contact);
		}
	}

	return events.map(({ originalEvent, selected }) =>
		normalizeEvent(
			originalEvent,
			contactsByNumber,
			extensions,
			blacklist,
			numbers,
			selected,
			domain
		)
	);
};
