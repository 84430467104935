import React, { useState } from 'react';
import { Button, DownloadLink, Headline } from '@panda/ui';
import classnames from 'classnames';
import { ReactNode } from 'react-markdown';
import { useEvent, useEventsAction } from '../../../redux/modules/events/hooks/useEvents';
import { useTranslate } from '../../../redux/slices/translations';
import { useCurrentUser } from '../../../redux/modules/users';
import classes from './WelcomeView.module.scss';
import features from './images/interface-bg-fade.png';
import download from './images/started-dl-fade.png';
import logo from '../../../media/logo/sipgate_wort-bild-marke_schwarz.svg';
import { mixpanel } from '../../../third-party/mixpanel';
import { useAppDownloadUrl } from '../../users/user/app/hooks/useAppDownloadUrl';

const WelcomeView = () => {
	const translate = useTranslate();
	const currentUser = useCurrentUser();
	const { fetched: eventFetched, data: welcomeCommand } = useEvent('WELCOMECOMMAND');
	const { discardEvent } = useEventsAction();
	const [downloadStarted, setDownloadStarted] = useState<boolean>(false);
	const { getDownloadUrl, getOperatingSystemName } = useAppDownloadUrl();

	if (!currentUser || !welcomeCommand || !eventFetched) {
		return null;
	}

	const onClick = async () => {
		await discardEvent(welcomeCommand).payload.promise;
	};

	const trackDownload = () => {
		setDownloadStarted(true);

		mixpanel.track('CTA clicked', {
			CTA_ID: 'APP_DOWNLOAD_BUTTON',
		});
	};

	const renderStep = (
		index: number,
		title: string,
		description: string | ReactNode,
		cta: string | null
	) => {
		return (
			<li key={index} className={classnames(classes.step, classes[`step-${index}`])}>
				<div className={classes.title}>{title}</div>
				<div className={classes.description}>{description}</div>
				{cta && (
					<div className={classes.cta}>
						<DownloadLink
							button
							url={getDownloadUrl()}
							variant="loud"
							size="large"
							tracking={trackDownload}
						>
							{cta}
						</DownloadLink>
						<div className={classes.oshint}>{getOperatingSystemName()}</div>
					</div>
				)}
			</li>
		);
	};

	const steps = [
		{
			title: translate('WELCOME_PAGE_TITLE_STEP_1'),
			description: translate.markdown.inline(
				'WELCOME_PAGE_DESCRIPTION_STEP_1',
				translate('WELCOME_PAGE_DESCRIPTION_URL_STEP_1')
			),
			cta: translate('WELCOME_PAGE_CTA_TITLE_STEP_1'),
		},
		{
			title: translate('WELCOME_PAGE_TITLE_STEP_2'),
			description: translate('WELCOME_PAGE_DESCRIPTION_STEP_2'),
			cta: null,
		},
		{
			title: translate('WELCOME_PAGE_TITLE_STEP_3'),
			description: translate('WELCOME_PAGE_DESCRIPTION_STEP_3'),
			cta: null,
		},
	];

	return (
		<>
			<div className={classes.container}>
				<div className={classes.left}>
					<img className={classes.logo} src={logo} alt="" />
					<div className={classes.wrapper}>
						<Headline className={classes.greeting}>
							<span>
								{translate.markdown.inline(
									'WELCOME_PAGE_GREETING',
									`${currentUser?.firstname} ${currentUser.lastname}`
								)}
							</span>
						</Headline>
						<p className={classes.description}>{translate('WELCOME_PAGE_DESCRIPTION')}</p>
						<ul
							className={classnames(classes.steps, {
								[classes.started]: downloadStarted,
							})}
						>
							{steps.map((step, index) =>
								renderStep(index, step.title, step.description, step.cta)
							)}
						</ul>
						<p className={classes.hint}>{translate('WELCOME_PAGE_HINT')}</p>
						<Button onClick={onClick} size="large">
							{translate('WELCOME_PAGE_SKIP_BUTTON_TEXT')}
						</Button>
					</div>
				</div>
				<div
					className={classnames(classes.right, {
						[classes.started]: downloadStarted,
					})}
				>
					<img src={features} alt="" />
					<img src={download} alt="" />
				</div>
			</div>
		</>
	);
};

export default WelcomeView;
