import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconApi_clients = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M19.5 7.25v8.34h-.005c1.43.34 2.5 1.625 2.5 3.16 0 1.79-1.46 3.25-3.25 3.25s-3.25-1.46-3.25-3.25c0-1.535 1.07-2.82 2.5-3.16V7.25c0-.69-.56-1.25-1.25-1.25H13.56l1.715 1.72-1.06 1.06-2.635-2.645a1.25 1.25 0 0 1 0-1.765l2.64-2.65 1.06 1.06-1.715 1.72h3.185a2.755 2.755 0 0 1 2.75 2.75M17 18.75c0 .965.785 1.75 1.75 1.75s1.75-.785 1.75-1.75S19.715 17 18.75 17 17 17.785 17 18.75m-8.28-2.47 1.06-1.06h.005l2.635 2.645c.485.49.485 1.28 0 1.765l-2.64 2.65-1.06-1.06 1.715-1.72H7.25a2.755 2.755 0 0 1-2.75-2.75V8.41A3.254 3.254 0 0 1 2 5.25C2 3.46 3.46 2 5.25 2S8.5 3.46 8.5 5.25c0 1.535-1.07 2.82-2.5 3.16v8.34c0 .69.56 1.25 1.25 1.25h3.185zM5.25 3.5c-.965 0-1.75.785-1.75 1.75S4.285 7 5.25 7 7 6.215 7 5.25 6.215 3.5 5.25 3.5"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconApi_clients;
