import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconTimer = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m20.284 6.28-1.77 1.77h.005a8.46 8.46 0 0 1 1.985 5.45c0 4.685-3.815 8.5-8.5 8.5s-8.5-3.815-8.5-8.5c0-4.43 3.415-8.08 7.75-8.46V3.5h-2.25V2h6v1.5h-2.25v1.54a8.46 8.46 0 0 1 4.7 1.95l1.77-1.77zM4.999 13.5c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7m6.25-5h1.5v6h-1.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconTimer;
