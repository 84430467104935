import { createEntityAdapter, createSelector, EntityId } from '@reduxjs/toolkit';
import { DateTime, DurationUnit } from 'luxon';
import { hasError, OperationState, StatusItem, StatusState } from './types';

export const createStatusItemEntityAdapter = <T>(selectState: (state: T) => StatusState) => {
	const entityAdapter = createEntityAdapter<StatusItem>();

	const selectors = entityAdapter.getSelectors(selectState);
	const createTypedSelector = createSelector.withTypes<T>();

	const selectByIds = createTypedSelector(
		[selectors.selectAll, (_, ids: EntityId[]) => ids],
		(items, ids) => items.filter(i => ids.includes(i.id))
	);

	const selectByStatus = createTypedSelector(
		[selectors.selectAll, (_, status: OperationState) => status],
		(items, status) => items.filter(i => i.status === status)
	);

	const isStale = createTypedSelector(
		[
			selectors.selectById,
			(state: T, id: EntityId) => id,
			(state: T, id: EntityId, ttl?: number) => ttl,
			(state: T, id: EntityId, ttl?: number, durationUnit?: DurationUnit) => durationUnit,
		],
		(item, _, ttl = 1, durationUnit = 'hour') => {
			if (item !== undefined && item.timestamp !== undefined) {
				return DateTime.fromISO(item.timestamp).diffNow(durationUnit).get(durationUnit) < -ttl;
			}

			return false;
		}
	);

	const makeOperationStateSelectorById = (status: OperationState) => {
		return createTypedSelector(
			[selectors.selectById],
			item => item !== undefined && item.status === status
		);
	};

	const isPending = makeOperationStateSelectorById('pending');
	const isSucceeded = makeOperationStateSelectorById('succeeded');
	const hasFailed = makeOperationStateSelectorById('failed');

	const makeOperationStateSelectorByIds = (status: OperationState) => {
		return createTypedSelector([selectByIds], items => items.every(i => i.status === status));
	};

	const arePending = makeOperationStateSelectorByIds('pending');
	const areSucceeded = makeOperationStateSelectorByIds('succeeded');
	const areFailed = makeOperationStateSelectorByIds('failed');

	const selectError = createTypedSelector([selectors.selectById], item => {
		if (hasError(item)) {
			return item.error;
		}
	});

	return {
		...entityAdapter,
		getSelectors() {
			return {
				...selectors,
				selectByIds,
				selectByStatus,
				selectError,
				isStale,
				isPending,
				hasFailed,
				isSucceeded,
				arePending,
				areSucceeded,
				areFailed,
			};
		},
	};
};
