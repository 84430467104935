import { isFetched, isFetching, isUpdating } from './selectors';
import { createAsyncThunk } from '../../utils/wrapper';
import {
	PhonelineBlockAnonymous,
	phoneLineBlockAnonymousRestApiClient as api,
	PhonelineBlockAnonymousTargetType,
} from './api';

type FetchBlockAnonymousActionParameter = {
	userId: string;
	phoneLineId: string;
};

export const fetchBlockAnonymous = createAsyncThunk<
	PhonelineBlockAnonymous,
	FetchBlockAnonymousActionParameter
>(
	'phoneLineBlockAnonymous/fetch',
	async ({ userId, phoneLineId }) => api.getPhonelineBlockAnonymous(userId, phoneLineId),
	{
		condition: ({ phoneLineId }, { getState }) => {
			const state = getState().phonelineBlockAnonymous;
			return (
				!isFetching(state, phoneLineId) &&
				!isFetched(state, phoneLineId) &&
				!isUpdating(state, phoneLineId)
			);
		},
	}
);

type UpdateBlockAnonymousActionParameter = {
	userId: string;
	phoneLineId: string;
	enabled: boolean;
	target: PhonelineBlockAnonymousTargetType;
};

export const updateBlockAnonymous = createAsyncThunk<void, UpdateBlockAnonymousActionParameter>(
	'phoneLineBlockAnonymous/update',
	async ({ userId, phoneLineId, enabled, target }) =>
		api.setPhonelineBlockAnonymous(userId, phoneLineId, enabled, target)
);
