import { createSelector } from '@reduxjs/toolkit';
import { dataUsageSettingsAdapter, fetchItemAdapter } from './adapters';
import { DataUsageSettingsState } from './types';

const selectDataUsageSettingItems = (state: DataUsageSettingsState) => state.items;
const dataUsageSettingsSelectors = dataUsageSettingsAdapter.getSelectors(
	selectDataUsageSettingItems
);
const fetchItemEntitySelectors = fetchItemAdapter.getSelectors();

const createDataUsageSettingsSelector = createSelector.withTypes<DataUsageSettingsState>();

export const selectById = createDataUsageSettingsSelector(
	[dataUsageSettingsSelectors.selectById],
	dataUsageSettingsItem => {
		if (dataUsageSettingsItem) {
			return dataUsageSettingsItem.settings;
		}

		return undefined;
	}
);

export const {
	isSucceeded: isFetched,
	isPending: isFetching,
	hasFailed,
	selectById: selectFetchItemById,
} = fetchItemEntitySelectors;
