import React, { SVGProps } from 'react';
import classnames from 'classnames';

export const DashIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		fill="currentColor"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		className={classnames('min-w-16', 'min-h-16', 'w-16', 'h-16', props.className)}
	>
		<path
			fillRule="evenodd"
			d="M4 8a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z"
			clipRule="evenodd"
		/>
	</svg>
);
