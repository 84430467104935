import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchNotifications } from './actions';
import { SliceData } from '../../index';
import { Notifications } from './types';

export const useFaxReports = (userId: string): SliceData<Notifications> => {
	const dispatch = useDispatch();
	const notifications = useSelector(s => s.notifications);

	useEffect(() => {
		dispatch(fetchNotifications({ userId }));
	}, [userId, dispatch]);

	if (notifications.fetchedForUser.includes(userId)) {
		return { fetched: true, data: notifications.items };
	}

	return { fetched: false, data: null };
};
