import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconProducts = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				stroke="currentColor"
				strokeMiterlimit={10}
				strokeWidth={1.5}
				d="M17 17h-3.5m3.5 0v-3.5m0 3.5h3.5M17 17v3.5"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M3 13h8v8H4.25C3.56 21 3 20.44 3 19.75zM13 3h6.75c.69 0 1.25.56 1.25 1.25V11h-8zM3 4.25C3 3.56 3.56 3 4.25 3H11v8H3z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconProducts;
