import React from 'react';
import { useTranslate } from '../../redux/slices/translations';
import { useFeatureScope } from '../../redux/slices/userinfo';

export const AccountTariff = (props: { className: string }) => {
	const translate = useTranslate();
	const isNeoPBX = useFeatureScope('NEO');

	if (!isNeoPBX) {
		return null;
	}

	return <span className={props.className}>{translate('NEO_ACCOUNT_TARIFF_LABEL')}</span>;
};
