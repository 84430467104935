import { createEntityAdapter } from '@reduxjs/toolkit';
import { DeviceLocalPrefixState, LocalPrefix } from './types';
import { createStatusItemEntityAdapter } from '../../utils/statusEntityAdapter';

export const deviceLocalPrefixAdapter = createEntityAdapter<LocalPrefix>();

const fetchItemSelector = (state: DeviceLocalPrefixState) => state.fetch;
export const fetchItemEntityAdapter = createStatusItemEntityAdapter(fetchItemSelector);

const updateItemSelector = (state: DeviceLocalPrefixState) => state.update;
export const updateItemEntityAdapter = createStatusItemEntityAdapter(updateItemSelector);
