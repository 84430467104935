import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { NotificationState } from './types';
import { createFaxReportNotification, deleteNotification, fetchNotifications } from './actions';
import { setFaxLineAlias } from '../faxlines';

export const initialState: NotificationState = {
	fetchedForUser: [],
	fetchingForUser: [],

	items: {
		fax: [],
		call: [],
		sms: [],
		voicemail: [],
	},
};

const slice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchNotifications.pending, (state, { meta }) => ({
			...state,
			fetchingForUser: [...state.fetchingForUser, meta.arg.userId],
		}));

		builder.addCase(fetchNotifications.fulfilled, (state, { payload: notifications, meta }) => ({
			fetchingForUser: state.fetchingForUser.filter(user => user !== meta.arg.userId),
			fetchedForUser: [...state.fetchedForUser, meta.arg.userId],
			items: notifications,
		}));

		builder.addCase(setFaxLineAlias.pending, (state, { meta }) => ({
			...state,
			items: {
				...state.items,
				fax: state.items.fax.map(fax => {
					if (fax.endpointId !== meta.arg.faxLineId) {
						return fax;
					}

					return { ...fax, endpointAlias: meta.arg.alias };
				}),
			},
		}));

		builder.addMatcher(
			isAnyOf(createFaxReportNotification.fulfilled, deleteNotification.fulfilled),
			(state, { payload: notifications }) => ({
				...state,
				items: notifications,
			})
		);
	},
});

export const reducer = slice.reducer;
