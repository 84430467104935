import { createSelector } from '@reduxjs/toolkit';
import { TranslationsState } from './types';
import { buildStringOnlyTranslate, buildTranslate } from './helper';

const createTypedSelector = createSelector.withTypes<TranslationsState>();

export const createSelections = createTypedSelector(
	[state => state.items, state => state.debug, state => state.fake],
	(translations, debug, fake) => {
		if (fake) {
			return buildTranslate(
				(lk: string, ...args: unknown[]) =>
					`${lk
						.split('_')
						.map(part => part.slice(0, 2))
						.join('_')
						.substring(0, 10)}${args
						.map(a => (typeof a === 'object' ? ` ${JSON.stringify(a)}` : ` ${a}`))
						.join('')}`
			);
		}
		return buildTranslate(buildStringOnlyTranslate(translations, debug));
	}
);
