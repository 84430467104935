import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { saveLocale } from '@web-apps/phonenumbers-utils';
import { SliceSelectors } from '@reduxjs/toolkit/src/createSlice';
import { UserInfo, UserInfoState } from './types';
import { fetchUserInfo } from './actions';
import auth from '../../../utils/authenticate/auth';

export const initialState: UserInfoState = {
	data: null,

	fetched: false,
	fetching: false,
};

/**
 * We have to be here clear with our types because otherwise redux
 * doesn't know what to do with the UserInfoState union type.
 */
type CaseReducers = SliceCaseReducers<UserInfoState>;
type Selectors = SliceSelectors<UserInfoState>;

const slice = createSlice<UserInfoState, CaseReducers, 'userInfo', Selectors>({
	name: 'userInfo',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchUserInfo.pending, state => ({ ...state, fetching: true }));
		builder.addCase(fetchUserInfo.fulfilled, (_state, { payload }) => {
			moment.locale(payload.locale);

			/*
			 * This is a hack, packages/app-web/src/utils/phonenumbers/index.ts uses
			 * locale as default from localstorage instead from the store.
			 * Big appreciated if you can fix it.
			 */
			saveLocale(payload.locale);

			return {
				fetching: false,
				fetched: true as const,
				data: {
					...payload,
					featureScope: auth.isNeoPBXCustomer() ? 'NEO' : 'CLASSIC',
				} as UserInfo,
			} as UserInfoState;
		});
	},
});

export const reducer = slice.reducer;
