import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconPartner_program = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 10.93a.75.75 0 0 1-.53-.22L7.88 7.12A2.96 2.96 0 0 1 7 5c0-.8.315-1.555.88-2.12C8.445 2.31 9.2 2 10 2c.745 0 1.45.27 2 .765A2.97 2.97 0 0 1 14 2c.8 0 1.555.315 2.12.88.57.565.88 1.32.88 2.12s-.315 1.555-.88 2.12l-3.59 3.59a.75.75 0 0 1-.53.22m-2-7.435c-.4 0-.775.155-1.06.44s-.44.66-.44 1.06.155.775.44 1.06L12 9.115l3.06-3.06c.285-.285.44-.66.44-1.06s-.155-.775-.44-1.06c-.565-.565-1.555-.565-2.12 0q-.15.15-.27.39a.75.75 0 0 1-1.34 0c-.08-.165-.17-.29-.27-.39a1.49 1.49 0 0 0-1.06-.44m10.105 9.945a1.82 1.82 0 0 1 2.275.655h.005c.48.725.385 1.695-.23 2.31L17.83 20.73a3.78 3.78 0 0 1-3.15 1.07L8.7 21H5.5v1H2v-1.5h2V13H2v-1.5h3.5v1h3.37l1.5.5h3.88c1.175 0 2.135.915 2.23 2.07zm-3.34 6.23 4.325-4.325v.005a.34.34 0 0 0 .04-.42.34.34 0 0 0-.415-.12l-5.53 2.48c-.29.13-.605.21-.94.21h-3.69l-.78.78-1.06-1.06L9.935 16h4.31a.749.749 0 1 0 0-1.5h-4.12l-1.5-.5h-3.13v5.505h3.35l6.03.805c.69.09 1.395-.145 1.89-.64"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconPartner_program;
