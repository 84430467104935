import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconUp = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M18.97 16.03 12 9.06l-6.97 6.97-1.06-1.06 7.145-7.145a1.26 1.26 0 0 1 1.77 0l7.145 7.145z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconUp;
