import classNames from 'classnames';
import React from 'react';
import { NeoIcon } from '@web-apps/neo-icons';
import { useDisabled } from '../../contexts/disabledContext';

type Size = 'medium' | 'large' | 'xlarge';

export type Props<Option> = {
	id?: string;
	title: string;
	size: Size;
	disabled?: boolean;
	value: Option;
	options: readonly Option[];
	mapOptionToKey: (option: Option) => string;
	mapOptionToLabel: (option: Option) => string;
	onChange: (option: Option) => void;
};

const styles = {
	container: classNames('relative', 'group'),
	label: (size: Size, disabled: boolean) =>
		classNames(
			'relative',
			'font-brand',
			'font-bold',
			'transition-colors',
			'duration-150',
			'ease-in-out',
			'rounded',
			'peer-focus-visible:ring-focus-inset',
			'flex',
			'items-center',
			'whitespace-nowrap',
			'select-none',

			size === 'medium' && ['gap-8', 'pr-8', 'pl-16', 'py-4', 'text-sm/16'],
			size === 'large' && ['gap-10', 'pr-12', 'pl-20', 'py-8', 'text-base/24'],
			size === 'xlarge' && ['gap-8', 'pr-12', 'pl-24', 'py-12', 'text-base/24'],

			'pointer-coarse:gap-8',
			'pointer-coarse:pr-12',
			'pointer-coarse:pl-24',
			'pointer-coarse:py-8',
			'pointer-coarse:text-base/24',

			disabled && [
				'bg-neo-color-global-background-neutral-soft-disabled',
				'text-neo-color-global-content-neutral-disabled',
			],
			!disabled && [
				'bg-neo-color-global-background-neutral-soft-default',
				'text-neo-color-global-content-neutral-intense',
				'group-hover:bg-neo-color-global-background-neutral-soft-hover',
				'peer-active:bg-neo-color-global-background-neutral-soft-active',
			]
		),
	select: classNames(
		'peer',
		'z-10',
		'absolute',
		'w-full',
		'top-0',
		'bottom-0',
		'opacity-0',
		'cursor-pointer',
		'disabled:cursor-not-allowed'
	),
};

export const Dropdown = <Option,>({
	id,
	title,
	disabled: disabledProp,
	onChange,
	options,
	value,
	size = 'medium',
	mapOptionToKey,
	mapOptionToLabel,
}: Props<Option>) => {
	const disabled = useDisabled(disabledProp);
	return (
		<div className={styles.container}>
			<select
				id={id}
				value={mapOptionToKey(value)}
				aria-label={title}
				disabled={disabled}
				onChange={e => {
					const option = options.find(item => mapOptionToKey(item) === e.target.value);

					if (!option) {
						throw new Error(`Could not find item for key ${e.target.value}`);
					}

					onChange(option);
				}}
				className={styles.select}
			>
				{options.map(option => (
					<option key={mapOptionToKey(option)} value={mapOptionToKey(option)}>
						{mapOptionToLabel(option)}
					</option>
				))}
			</select>
			<div className={styles.label(size, disabled)}>
				{mapOptionToLabel(value)}

				<NeoIcon name="Expand" variant="solid" />
			</div>
		</div>
	);
};
