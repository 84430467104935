import serviceUrls from '@web-apps/service-urls';
import { ApiPhoneNumber } from './types';
import { ListResponse } from '../../../../api/SipgateApi';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';

interface NumbersRestApi {
	getAllNumbers(): Promise<ListResponse<ApiPhoneNumber>>;
}

class NumbersRestApiClient extends RestApiClient implements NumbersRestApi {
	public getAllNumbers(): Promise<ListResponse<ApiPhoneNumber>> {
		return this.get('/v2', `/numbers`);
	}
}

export const numbersRestApiClient = new NumbersRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
