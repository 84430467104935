import serviceUrls from '@web-apps/service-urls';
import { HttpClient } from '../../../../api/client/HttpClient';
import { RestApiClient } from '../../../../api/client/RestApiClient';

import { ListResponse } from '../../../../api/SipgateApi';
import { ApiFaxline } from './types';

interface FaxLineRestApi {
	getFaxLines(): Promise<ListResponse<ApiFaxline>>;

	setFaxLineAlias(faxLineId: string, alias: string): Promise<void>;

	setFaxLineTagline(faxLineId: string, tagline: string): Promise<void>;

	createFaxLine(ownerId: string, alias: string): Promise<ApiFaxline>;

	deleteFaxLine(faxLineId: string): Promise<void>;

	setFaxLineCallerId(faxLineId: string, callerId: string, anonymous: boolean): Promise<void>;

	addFaxLineOwners(faxLineId: string, ownerIds: string[]): Promise<void>;

	removeFaxLineOwners(faxLineId: string, ownerIds: string[]): Promise<void>;

	createFaxLineNeo(ownerIds: string[], alias: string): Promise<unknown>;
}

class FaxLineRestApiClient extends RestApiClient implements FaxLineRestApi {
	public getFaxLines = () => this.get('/v3', '/fax');

	public setFaxLineAlias = (faxlineId: string, alias: string) =>
		this.put('/v3', `/fax/${faxlineId}/alias`, { alias });

	public setFaxLineTagline = (faxlineId: string, tagline: string) =>
		this.put('/v3', `/fax/${faxlineId}/tagline`, {
			tagline,
		});

	public createFaxLine = (ownerId: string, alias: string) =>
		this.post('/v3', '/fax', { ownerId, alias });

	public deleteFaxLine = (faxlineId: string) => this.delete('/v3', `/fax/${faxlineId}`);

	public createFaxLineNeo = (ownerIds: string[], alias: string) => {
		return this.post('/v3', '/faxlines', { ownerIds, alias });
	};

	public setFaxLineCallerId = (faxlineId: string, callerId: string, anonymous: boolean) =>
		this.put('/v3', `/fax/${faxlineId}/callerId`, {
			callerId,
			anonymous,
		});

	public addFaxLineOwners = (faxLineId: string, ownerIds: string[]) => {
		return this.put('/v3', `/faxlines/${faxLineId}/members`, { memberIds: ownerIds });
	};

	public removeFaxLineOwners = (faxLineId: string, ownerIds: string[]) => {
		return this.delete('/v3', `/faxlines/${faxLineId}/members`, { memberIds: ownerIds });
	};
}

export const faxLineRestApiClient = new FaxLineRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
