import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconFax = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M19.25 7.5H19V3H5v4.5h-.25A2.755 2.755 0 0 0 2 10.25v6c0 .69.56 1.25 1.25 1.25H5V21h14v-3.5h1.75c.69 0 1.25-.56 1.25-1.25v-6a2.755 2.755 0 0 0-2.75-2.75M6.5 4.5h11v3h-11zm11 15h-11V15h11zM19 12h-1.5v-1.5H19z"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconFax;
