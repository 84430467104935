import { createAsyncThunk } from '../../utils/wrapper';
import { voicemailNotificationRestApiClient as api } from './api';
import { VoicemailNotificationState } from './types';
import { mixpanel } from '../../../third-party/mixpanel';

const shouldFetchVoicemailNotification = (state: VoicemailNotificationState, channelId: string) =>
	!state.fetched.includes(channelId) && !state.fetching.includes(channelId);

export const fetchVoicemailNotification = createAsyncThunk<
	{
		isActive: boolean;
	},
	{
		force?: boolean;
		channel: string;
	}
>(
	'voicemail-notifications/fetch',
	async ({ channel }) => {
		return api.getVoicemailNotification(channel);
	},
	{
		condition: ({ force = false, channel }, { getState }) =>
			force || shouldFetchVoicemailNotification(getState().voicemailNotifications, channel),
	}
);

export const enableVoicemailNotification = createAsyncThunk<
	{
		isActive: boolean;
	},
	{
		channel: string;
	}
>('voicemail-notifications/enable', async ({ channel }) => {
	mixpanel.track('Voicemail notification toggled', {
		enabled: true,
	});
	return api.enableVoicemailNotification(channel);
});

export const disableVoicemailNotification = createAsyncThunk<
	{
		isActive: boolean;
	},
	{
		channel: string;
	}
>('voicemail-notifications/disable', async ({ channel }) => {
	mixpanel.track('Voicemail notification toggled', {
		enabled: false,
	});
	return api.disableVoicemailNotification(channel);
});
