import React from 'react';
import classnames from 'classnames';
import { NeoIcon } from '@web-apps/neo-icons';
import { DisabledContext, isDisabled as isContextDisabled } from '../../contexts/disabledContext';
import { useAriaId } from '../../hooks/useAriaId';
import { VisualCheckbox } from '../visualComponents/visualCheckbox/VisualCheckbox';
import { ErrorDescription } from '../visualComponents/ErrorDescription';

type Props = {
	/**
	 * Die Checkbox kann drei Zustände annehmen. Im Normalfall wird
	 * über einen boolschen Wert der Zustand auf `checked` bzw. `unchecked`
	 * gesetzt. Wird die Checkbox zur Mehrfachauswahl verwendet, kann
	 * diese auch den Zustand `indeterminate` annehmen.
	 * [Multiselect Beispiel](../?path=/story/components-checkbox-stories--with-multi-selection)
	 */
	checked?: boolean | 'indeterminate';
	/**
	 * `onChange` wird bei jeder Änderung der Checkbox ausgeführt und bekommt
	 * den Zustand, den sie annehmen wird, als Parameter übergeben.
	 */
	onChange: (checked: boolean) => void;
	error?: string;
} & AdditionalProps;

type ManagedProps = {
	managedField: {
		name?: string;
		value: boolean;
		setValue: (checked: boolean) => void;
		onBlur: React.FocusEventHandler<HTMLInputElement>;
	} & ({ valid: true; error: null } | { valid: false; error: string });
} & AdditionalProps;

type AdditionalProps = {
	ariaLabel?: string;
	children?: string;
	description?: string;
	disabled?: boolean;
	/**
	 * Setzt das HTML-Attribut `name`
	 */
	name?: string;
} & (WithAriaLabelProps | WithInternalLabelProps);

type WithAriaLabelProps = {
	/**
	 * Ein Screenreader-kompatibles Label.
	 *
	 * Wenn du kein `children` übergibst, musst du diese Prop setzen.
	 *
	 */
	ariaLabel: string;

	/**
	 * Ein sichtbares Label, welches neben der Checkbox angezeigt
	 * wird.
	 */
	children?: string;
};

type WithInternalLabelProps = {
	/**
	 * Wenn du `children` übergibst, ist das ariaLabel optional und
	 * kann für zusätzliche Informationen für seheingeschränkte
	 * Nutzer:innen genutzt werden. Screenreader zeigen dieses
	 * ariaLabel anstelle von `children` an.
	 */
	ariaLabel?: string;

	/**
	 *
	 *
	 *
	 * Falls ein sichtbares Label unter keinen Umständen passt,
	 * musst du ein ariaLabel übergeben.
	 */
	children: string;
};

const styles = {
	span: classnames('group', 'flex', 'flex-col', 'items-start'),
	container: classnames('flex', 'flex-start'),
	label: (isDisabled: boolean) =>
		classnames(
			'grow',
			'py-10',
			'pr-12',
			'pl-8',
			'font-brand',
			'font-normal',
			'text-base/20',
			'select-none',
			isDisabled
				? 'text-neo-color-global-content-neutral-disabled'
				: 'text-neo-color-global-content-neutral-intense',
			isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
		),
	description: (isDisabled: boolean) =>
		classnames(
			'block',
			'pt-4',
			'font-brand',
			'font-normal',
			'text-xs/14',
			isDisabled
				? 'text-neo-color-global-content-neutral-disabled'
				: 'text-neo-color-global-content-neutral-moderate'
		),
	warningContainer: classnames('flex', 'items-center', 'gap-8'),
	icon: classnames('flex-shrink-0', 'text-neo-color-global-content-critical-moderate'),
};

const Checkbox = ({
	ariaLabel,
	checked = false,
	children,
	description,
	disabled,
	onChange,
	error,
	name,
}: Props): JSX.Element => {
	const inputElement = React.useRef<HTMLInputElement>(null);
	const id = useAriaId('aria-checkbox');
	const disabledContextValue = React.useContext(DisabledContext);
	const isDisabled = isContextDisabled(disabled, disabledContextValue);

	const focusInput = () => {
		inputElement.current?.focus?.();
	};

	if (typeof children !== 'string') {
		return (
			<VisualCheckbox
				id={id}
				name={name}
				checked={checked}
				disabled={isDisabled}
				onChange={onChange}
				aria-label={ariaLabel}
			/>
		);
	}

	return (
		<span className={styles.span}>
			<div className={styles.container}>
				<VisualCheckbox
					id={id}
					name={name}
					checked={checked}
					disabled={isDisabled}
					onChange={onChange}
					aria-label={ariaLabel}
					ref={inputElement}
				/>
				<label
					className={styles.label(isDisabled)}
					htmlFor={id}
					aria-hidden={typeof ariaLabel === 'string'}
				>
					{children}
					{description && <small className={styles.description(isDisabled)}>{description}</small>}
				</label>
			</div>
			{error ? (
				<span className={styles.warningContainer}>
					<NeoIcon name="Form_error" variant="solid" className={styles.icon} />
					<ErrorDescription onClick={focusInput} withoutMargin>
						{error}
					</ErrorDescription>
				</span>
			) : null}
		</span>
	);
};

const ManagedCheckbox = ({
	managedField: { error, value, setValue },
	...otherProps
}: ManagedProps) => (
	<Checkbox
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...otherProps}
		checked={value}
		onChange={setValue}
		error={error === null ? undefined : error}
	/>
);

export { Checkbox, ManagedCheckbox };
