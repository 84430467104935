import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { ApiMobileInfo } from '../../../api/types/mobileInfo';

type ForceFetch = {
	forceFetch?: true;
};

export const fetchMobileInfo = createAsyncThunk<ApiMobileInfo[], ForceFetch>(
	'mobile/fetch',
	async (_arg: ForceFetch) => {
		return api.getMobileInfo();
	}
);
