import { createAction } from '@reduxjs/toolkit';
import api from '../../../api';
import { SipgateLocale } from '../userinfo';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchTranslations = createAsyncThunk(
	'translations/fetchTranslations',
	async ({ locale }: { locale: SipgateLocale; forceFetch?: boolean; debug?: boolean }) =>
		api.getTranslations(locale),
	{
		condition: ({ locale, forceFetch }, { getState }) => {
			const state = getState();
			if (state.translations.locale !== locale) {
				return true;
			}
			return forceFetch === true || !state.translations.fetched;
		},
	}
);

export const setDebug = createAction('translations/setDebug', (debug: boolean) => ({
	payload: debug,
}));
