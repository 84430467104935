import { useEffect } from 'react';

import { useAction } from '../..';
import { useInit } from '../../../utils/initialize';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { deleteAudioFile, fetchAudioFiles, renameAudioFile, uploadAudioFile } from './actions';

export const useGenericAudioFiles = () => {
	const dispatch = useDispatch();

	const nextPage = useSelector(state => state.files.audiofiles.pages_fetched.length);
	const totalPages = useSelector(state => state.files.audiofiles.total_pages);
	const fetchedPages = useSelector(state => state.files.audiofiles.pages_fetched);

	useEffect(() => {
		if (nextPage > 0 && nextPage <= totalPages) {
			dispatch(fetchAudioFiles({ page: nextPage }));
		}
	}, [dispatch, nextPage, totalPages]);

	useInit(() => {
		if (!fetchedPages.includes(0)) {
			dispatch(fetchAudioFiles({ page: 0, forceFetch: true }));
		}
	});

	return {
		files: useSelector(state => state.files.audiofiles.content),
		fetching: useSelector(state => state.files.audiofiles.fetching),
		totalPages: useSelector(state => state.files.audiofiles.total_pages),
		upload: useAction(uploadAudioFile),
		delete: useAction(deleteAudioFile),
		rename: useAction(renameAudioFile),
	};
};
