import { localizeNumber } from '@web-apps/phonenumbers-utils';
import { SipgateDomain } from '../../../redux/slices/userinfo';
import {
	EndpointId,
	EndpointType,
	ExtensionState,
	resolveEndpoint,
} from '../../../utils/endpoints';

export interface NormalizedConnection {
	id: string;
	type: EndpointType;
	name?: string;
}

export const normalizeConnection = (
	endpoint: EndpointId,
	extensions: ExtensionState,
	domain: SipgateDomain
): NormalizedConnection => {
	const resolvedEndpoint = resolveEndpoint(extensions, endpoint);

	if (!resolvedEndpoint) {
		return {
			id: endpoint.extension,
			type: endpoint.type,
		};
	}

	if (typeof resolvedEndpoint === 'string') {
		return {
			id: endpoint.extension,
			type: endpoint.type,
			name: localizeNumber(resolvedEndpoint, domain),
		};
	}

	if ('alias' in resolvedEndpoint) {
		return {
			id: endpoint.extension,
			type: endpoint.type,
			name: resolvedEndpoint.alias,
		};
	}

	if ('name' in resolvedEndpoint) {
		return {
			id: endpoint.extension,
			type: endpoint.type,
			name: resolvedEndpoint.name,
		};
	}

	return {
		id: endpoint.extension,
		type: endpoint.type,
		name: `${resolvedEndpoint.firstname} ${resolvedEndpoint.lastname}`,
	};
};
