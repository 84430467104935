import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchTwoFactorAuthenticationSetting } from './actions';
import { useAction } from '../..';

export const useTwoFactorAuthentication = (webuserId: string) => {
	const dispatch = useDispatch();
	const { fetching, items } = useSelector(state => state.twoFactorAuthenticationSettings);

	useEffect(() => {
		dispatch(fetchTwoFactorAuthenticationSetting(webuserId));
	}, [dispatch, webuserId]);

	return {
		fetching,
		items,
		fetchTwoFactorAuthenticationSetting: useAction(fetchTwoFactorAuthenticationSetting),
	};
};
