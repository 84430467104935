import { flattenChildren, stringifyElementType } from '@web-apps/react-utils';
import pathToRegexp from 'path-to-regexp';
import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch, matchPath, useLocation } from 'react-router';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { instanaLog } from '../third-party/instana';
import { useBreadcrumbTrigger } from './breadcrumb';
import { useViewStack, View, withViewStack } from './view-stack';

import classes from './view.module.scss';
import PageNotFoundRedirect from '../redirects/PageNotFoundRedirect';
import { useDialogRoot } from '../dialog/Dialogs';

/**
 * Returns information about the current view.
 *
 * Most importantly, it returns the title passed to the current view.
 */
export const useView = () => {
	const stack = useViewStack();
	const view = stack.viewStack[stack.viewStack.length - 1];

	if (!view) {
		throw new Error(
			'Called useView() from within a component which is not wrapped in a withView() call!'
		);
	}

	return view;
};

export const ViewConsumer = (props: { children: (view: View) => React.ReactElement }) => {
	return props.children(useView());
};

/**
 * A way to override the externally passed title of a view.
 *
 * Use this only if your view wants to set a dynamic title (like e.g. the
 * name of a user).
 */
export const TitleOverride = (props: { name: string }) => {
	const { setTitle } = useViewStack();

	useEffect(() => {
		setTitle(props.name);
	}, [props.name, setTitle]);

	return null;
};

/**
 * A component to switch between views with a consistent transition.
 *
 * Use this if you are building a component like the PandaTwoColumn Layout which needs
 * to layout multiple different views.
 *
 * Simply render whatever view is currently active and pass a `current` prop which is unique
 * for that active view.
 */
export const ViewTransition = (props: {
	children: React.ReactElement;
	/** An identifier of the currently active view. For the transition to work, this needs to change whenever the rendered view changes */
	current: string;
}) => {
	return (
		<SwitchTransition>
			<CSSTransition
				key={props.current}
				timeout={75}
				classNames={{
					enter: classes.enter,
					enterActive: classes.enterActive,
					exit: classes.exit,
					exitActive: classes.exitActive,
				}}
			>
				{props.children}
			</CSSTransition>
		</SwitchTransition>
	);
};

/**
 * A wrapper around react-routers `Switch` which, in addition to
 * `Route`s and `Redirect`s also correctly switches our views
 * (anything wrapped in `withView()`).
 *
 * Also transitions anything it switches using `ViewTransition`.
 */
export const ViewSwitch = (props: { children: React.ReactNode }) => {
	const location = useLocation();

	const views = flattenChildren(props.children).filter(React.isValidElement);

	const routes = views
		.map((view, i) => {
			if (React.isValidElement(view) && (view.type === Redirect || view.type === Route)) {
				return view;
			}

			// This should probably be simplified by explicitly only allowing things wrapped in `withView`.
			// This would mean attaching a unique symbol to those views as they are different components
			// which we cant instanceof check.
			//
			// Would also allow avoiding rendering the same route twice (once here and once inside the view)
			// because the view and switch could cooperate.
			//
			// But alas, I do not have the time to build and test that approach right now. So this
			// horrible code it is.
			if (
				!React.isValidElement(view) ||
				view.props === null ||
				typeof view.props !== 'object' ||
				!('path' in view.props) ||
				typeof view.props.path !== 'string'
			) {
				if (i === views.length - 1) {
					return view;
				}

				instanaLog.error(
					`Passed view without "path" prop to ViewSwitch. This is not supported, so skipping it.\nPassed Component: ${stringifyElementType(
						view.type
					)}`
				);

				return null;
			}

			return <Route key={i} path={view.props.path as `/${string}`} render={() => view} />;
		})
		.filter((route): route is React.ReactElement => !!route);

	const current = routes
		.filter(el => typeof el.props.path === 'string')
		.map(el => el.props.path as `/${string}`)
		.find(path => matchPath(location.pathname, path));

	if (routes.length === 0) {
		return (
			<ViewTransition current="none">
				<Switch location={location} />
			</ViewTransition>
		);
	}

	return (
		<ViewTransition current={current || 'none'}>
			<Switch location={location}>{routes}</Switch>
		</ViewTransition>
	);
};

/**
 * Mark a component as a view.
 *
 * This integrates with routing, by making the component take a `path`
 * prop on which it will render. We do some magic to automatically
 * fill props which are contained in the passed path parameters.
 *
 * In addition, the view can be passed a user-readable `name` from the outside.
 * You should pass a name from outside, because it makes it way easier
 * to use a view in multiple different places, it makes synchronisation
 * between navigation and views easier and it also allows us to automatically
 * populate breadcrumbs.
 *
 * * If you want to access the name from inside the view, use `useView()`.
 * * If you want to override the name from within the view, use `TitleOverride`.
 * * If you need to switch between multiple views use a `ViewSwitch`.
 *
 * **Example:**
 * ```
 * const UserView = withView((props: {userId: string}) => {
 *   const view = useView();
 *
 *   return (<>
 *     <span>{view.title}</span>
 *     <p>
 *       Got {props.userId}
 *     </p>
 *   </>);
 * })
 *
 * // Then, in e.g. AuthenticatedLayout:
 * <UserView path="/user/:userId" name={"User"} />
 * ```
 */
export function withView<Props>(Component: React.ComponentType<Props>) {
	return withViewStack<Props>(
		props => {
			useBreadcrumbTrigger();

			/* eslint-disable-next-line react/jsx-props-no-spreading */
			return <Component {...(props as Props & JSX.IntrinsicAttributes)} />;
		},
		{ transparent: false }
	);
}

/**
 * Mark a component as a "full" view.
 *
 * What this means is, that it is only rendered when the
 * passed path matches exactly and if a subpath is opened,
 * its children render.
 *
 * **Example:**
 *
 * ```
 * <UserOverview path="/users">
 *   <UserView path="/users/:userId" />
 * </UserOverview>
 * ```
 *
 * Here, `UserOverview` is rendered if `/users` is opened.
 *
 * If a path like `/users/w12` is opened however, the
 * UserOverview is not rendered at all and replaced with
 * the UserView.
 */
export function withFullView<Props>(Component: React.ComponentType<Props>) {
	return withViewStack<Props & { children?: React.ReactNode }>(
		props => {
			useBreadcrumbTrigger();
			const match = useRouteMatch();
			const location = useLocation();
			const dialogRoot = useDialogRoot();

			const dialogMatcher = useMemo(
				() => pathToRegexp(`${match.path}(${dialogRoot}/.*)?`),
				[match.path, dialogRoot]
			);

			return match.isExact || dialogMatcher.test(location.pathname) ? (
				/* */
				/* eslint-disable-next-line react/jsx-props-no-spreading */
				<Component {...props} />
			) : (
				<ViewSwitch>
					{props.children}
					<PageNotFoundRedirect />
				</ViewSwitch>
			);
		},
		{ transparent: false }
	);
}
