import { createSlice } from '@reduxjs/toolkit';
import { IvrState } from './types';
import {
	assignAudioFileToIvr,
	assignPhoneNumbersToIvr,
	createIvr,
	deleteIVR,
	deleteIvrKeyMapping,
	fetchIvrs,
	renameIVR,
	setIvrFallbackMapping,
	setIvrKeyMapping,
} from './actions';
import { deleteAudioFile } from '../files';
import { ApiIvrForwarding } from './api/types';
import { deleteAcd } from '../acds';

const initialState: IvrState = {
	fetched: false,
	fetching: false,
	items: [],
};

export const reducer = createSlice({
	name: 'ivrs',
	initialState,
	reducers: {},
	extraReducers: builder => {
		// fetch
		builder.addCase(fetchIvrs.pending, state => {
			return {
				...state,
				fetching: true,
			};
		});
		builder.addCase(fetchIvrs.fulfilled, (state, { payload }) => {
			return {
				...state,
				fetching: false,
				fetched: true,
				items: payload,
			};
		});
		builder.addCase(fetchIvrs.rejected, state => {
			return {
				...state,
				fetching: false,
			};
		});

		// create
		builder.addCase(createIvr.fulfilled, (state, { payload }) => {
			return {
				...state,
				items: state.items.concat(payload),
			};
		});

		// rename
		builder.addCase(renameIVR.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr =>
					ivr.id === meta.arg.ivrId ? { ...ivr, alias: meta.arg.alias } : ivr
				),
			};
		});

		// delete
		builder.addCase(deleteIVR.fulfilled, (state, { meta }) => {
			return {
				...state,
				items: state.items
					.filter(ivr => ivr.id !== meta.arg)
					.map(ivr => {
						const newConfig = { ...ivr.configuration };
						Object.entries(newConfig).forEach(([key, value]) => {
							if (value?.type === 'NEO_PBX_IVR' && value.value === meta.arg) {
								newConfig[key as ApiIvrForwarding] = undefined;
							}
						});
						return {
							...ivr,
							configuration: newConfig,
						};
					}),
			};
		});

		// assign phone numbers
		builder.addCase(assignPhoneNumbersToIvr.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr =>
					ivr.id === meta.arg.ivrId
						? {
								...ivr,
								phoneNumbers: meta.arg.phoneNumbers,
							}
						: ivr
				),
			};
		});

		// assign audio file
		builder.addCase(assignAudioFileToIvr.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr =>
					ivr.id === meta.arg.ivrId
						? {
								...ivr,
								configuration: {
									...ivr.configuration,
									MAIN: { type: 'ANNOUNCEMENT', value: meta.arg.audioFileId },
								},
							}
						: ivr
				),
			};
		});

		// update key mapping
		builder.addCase(setIvrKeyMapping.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr =>
					ivr.id === meta.arg.ivrId
						? {
								...ivr,
								configuration: {
									...ivr.configuration,
									[meta.arg.forwardingKey]: {
										type: meta.arg.type,
										value: meta.arg.value,
										loopCount:
											meta.arg.forwardingKey === 'FALLBACK' ? meta.arg.loopCount : undefined,
									},
								},
							}
						: ivr
				),
			};
		});

		// update fallback mapping
		builder.addCase(setIvrFallbackMapping.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr =>
					ivr.id === meta.arg.ivrId
						? {
								...ivr,
								configuration: {
									...ivr.configuration,
									FALLBACK: {
										type: meta.arg.type,
										value: meta.arg.value,
										loopCount: meta.arg.loopCount,
									},
								},
							}
						: ivr
				),
			};
		});

		// We need to know if a file is deleted so we remove it from the IVR configuration
		builder.addCase(deleteAudioFile.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr => {
					const newConfig = { ...ivr.configuration };
					Object.entries(newConfig).forEach(([key, value]) => {
						if (value?.type === 'ANNOUNCEMENT' && value.value === meta.arg.id) {
							newConfig[key as ApiIvrForwarding] = undefined;
						}
					});
					return {
						...ivr,
						configuration: newConfig,
					};
				}),
			};
		});

		// delete mapping
		builder.addCase(deleteIvrKeyMapping.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr =>
					ivr.id === meta.arg.ivrId
						? {
								...ivr,
								configuration: {
									...ivr.configuration,
									[meta.arg.forwardingKey]: undefined,
								},
							}
						: ivr
				),
			};
		});

		builder.addCase(deleteAcd.pending, (state, { meta }) => {
			return {
				...state,
				items: state.items.map(ivr => {
					const newConfig = { ...ivr.configuration };
					Object.entries(newConfig).forEach(([key, value]) => {
						if (value?.type === 'NEO_PBX_CHANNEL' && value.value === meta.arg.acdId) {
							newConfig[key as ApiIvrForwarding] = undefined;
						}
					});
					return {
						...ivr,
						configuration: newConfig,
					};
				}),
			};
		});
	},
}).reducer;
