import { handleActions } from '../..';
import { DeviceState, MobileDevice } from './types';
import { compareDevices } from './selectors';
import * as actions from './actions';
import { setGroupMembers } from '../groups/actions';
import { createPhoneline } from '../phonelines';
import { deleteAddress } from '../addresses';

const initialState: DeviceState = {
	items: [],
	fetchingForUser: [],
	fetchedForUser: [],
	updatingForDevice: [],
};

export default handleActions<
	DeviceState,
	PossibleActions<
		typeof actions | typeof createPhoneline | typeof setGroupMembers | typeof deleteAddress
	>
>(
	{
		DEVICES_FETCH_PENDING: (state, { data }) => ({
			...state,
			fetchingForUser: [...state.fetchingForUser.filter(item => item !== data.userId), data.userId],
		}),

		DEVICES_FETCH_SUCCESS: (state, { payload, data }) => {
			const devicesOfOtherUsers = state.items
				.filter(
					device => Object.prototype.hasOwnProperty.call(device, 'owner') && device.owner !== ''
				)
				.filter(device => device.owner !== data.userId);

			return {
				...state,
				fetchingForUser: [...state.fetchingForUser.filter(item => item !== data.userId)],
				fetchedForUser: [...state.fetchedForUser.filter(item => item !== data.userId), data.userId],
				items: [...devicesOfOtherUsers, ...payload.items].sort(compareDevices),
			};
		},

		DEVICE_SETTINGS_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (data.deviceId !== device.id) {
					return device;
				}

				const newDevice = {
					...device,
					dnd: data.dnd,
				};

				if (data.emergencyAddressId && newDevice.type === 'REGISTER') {
					newDevice.emergencyAddressId = data.emergencyAddressId;
				}

				return newDevice;
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_SETTINGS_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_ALIAS_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				const newDevice = { ...device };
				if (newDevice.id === data.deviceId) {
					newDevice.alias = data.alias;
				}

				return newDevice;
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_ALIAS_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_SMS_SIM_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				const newDevice = { ...device } as MobileDevice;
				if (newDevice.id === data.containerId) {
					newDevice.smsReceivingSim = data.mobileId;
				}

				return newDevice;
			}),
			updatingForDevice: [...state.updatingForDevice, data.containerId],
		}),

		DEVICE_SMS_SIM_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.containerId),
		}),

		SIM_ALIAS_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.type !== 'MOBILE' || device.id !== data.containerId) {
					return device;
				}

				return {
					...device,
					sims: device.sims.map(sim => {
						if (sim.id !== data.mobileId) {
							return sim;
						}

						return { ...sim, alias: data.alias };
					}),
				};
			}),
			updatingForDevice: [...state.updatingForDevice, data.containerId],
		}),

		SIM_ALIAS_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.containerId),
		}),

		DEVICE_PASSWORD_RESET_PENDING: (state, { data }) => ({
			...state,
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_PASSWORD_RESET_SUCCESS: (state, { payload, data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId || device.type !== 'REGISTER') {
					return device;
				}

				return {
					...device,
					sipCredentials: { ...device.sipCredentials, password: payload.password },
				};
			}),
		}),

		DEVICE_SIM_ACTIVATE_PENDING: (state, { data }) => ({
			...state,
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_SIM_ACTIVATE_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_SET_ROUTING_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId) {
					return device;
				}

				return {
					...device,
					activePhonelines: device.activePhonelines.concat(data.phonelineId),
				};
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_SET_ROUTING_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_DELETE_ROUTING_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId) {
					return device;
				}

				return {
					...device,
					activePhonelines: device.activePhonelines.filter(
						activePhoneline => activePhoneline !== data.phonelineId
					),
				};
			}),
		}),

		DEVICE_DELETE_ROUTING_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_CREATE_SUCCESS: (state, { payload, data }) => {
			const devicesOfOtherUsers = state.items
				.filter(
					device => Object.prototype.hasOwnProperty.call(device, 'owner') && device.owner !== ''
				)
				.filter(device => device.owner !== data.userId);

			return {
				...state,
				items: [...devicesOfOtherUsers, ...payload.allDevices.items].sort(compareDevices),
			};
		},

		DEVICE_DELETE_PENDING: (state, { data }) => ({
			...state,
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_DELETE_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.filter(item => item.id !== data.deviceId),
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_ADDITIONAL_SIM_DEACTIVATE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.containerId || device.type !== 'MOBILE') {
					return device;
				}

				return { ...device, sims: device.sims.filter(s => s.id !== data.mobileId) };
			}),
			updatingForDevice: [...state.updatingForDevice, data.containerId],
		}),

		DEVICE_ADDITIONAL_SIM_DEACTIVATE_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.containerId),
		}),

		DEVICE_SET_GROUP_ROUTING_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId) {
					return device;
				}

				return {
					...device,
					activeGroups: device.activeGroups ? [...device.activeGroups, data.groupId] : undefined,
				};
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_SET_GROUP_ROUTING_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_DELETE_GROUP_ROUTING_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId) {
					return device;
				}

				return {
					...device,
					activeGroups: device.activeGroups
						? device.activeGroups.filter(activeGroup => activeGroup !== data.groupId)
						: undefined,
				};
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_DELETE_GROUP_ROUTING_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		EXTERNAL_DEVICE_SET_TARGET_NUMBER_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId || device.type !== 'EXTERNAL') {
					return device;
				}

				return { ...device, number: data.number };
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		EXTERNAL_DEVICE_SET_TARGET_NUMBER_SUCCESS: (state, { data }) => ({
			...state,

			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		EXTERNAL_DEVICE_CREATE_SUCCESS: (state, { payload, data }) => {
			const devicesOfOtherUsers = state.items.filter(device => device.owner !== data.userId);

			return {
				...state,
				items: [...devicesOfOtherUsers, ...payload.allDevices.items].sort(compareDevices),
			};
		},

		EXTERNAL_DEVICE_SET_INCOMING_CALL_DISPLAY_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId || device.type !== 'EXTERNAL') {
					return device;
				}

				return { ...device, incomingCallDisplayState: data.incomingCallDisplay };
			}),
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		EXTERNAL_DEVICE_SET_INCOMING_CALL_DISPLAY_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		PHONELINE_CREATE_SUCCESS: (state, { payload, data }) => {
			const userOwnedDevices = state.items
				.filter(
					device =>
						device.owner && device.owner === data.userId && device.activePhonelines !== undefined
				)
				.map(device => ({
					...device,
					activePhonelines: [...device.activePhonelines, payload.id],
				}));

			const allOtherDevices = state.items.filter(
				device => !device.owner || device.owner !== data.userId
			);

			return {
				...state,
				items: [...userOwnedDevices, ...allOtherDevices].sort(compareDevices),
			};
		},

		DEVICE_SET_PENDING_SIM_PENDING: (state, { data }) => ({
			...state,
			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_SET_PENDING_SIM_SUCCESS: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId || device.type !== 'MOBILE') {
					return device;
				}

				return { ...device, pendingSim: { iccid: data.iccid } };
			}),
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_SIM_SET_INTENDED_USE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId || device.type !== 'MOBILE') {
					return device;
				}

				return {
					...device,
					sims: device.sims.map(sim => {
						if (sim.id !== data.simExtensionId) {
							return sim;
						}

						return { ...sim, intendedUse: data.intendedUse };
					}),
				};
			}),
		}),

		DEVICE_CALLERID_SET_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.id !== data.deviceId) {
					return device;
				}

				return {
					...device,
					callerId: data.callerId,
				};
			}),
		}),

		DEVICE_CALLRECORDING_SET_PENDING: (state, { data }) => ({
			...state,

			items: state.items.map(device => {
				if (device.id !== data.deviceId) {
					return device;
				}

				return {
					...device,
					callRecording: data.callRecording,
				};
			}),

			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_CALLRECORDING_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_TARIFF_ANNOUNCEMENT_SET_PENDING: (state, { data }) => ({
			...state,

			items: state.items.map(item => {
				if (item.id !== data.deviceId) {
					return item;
				}

				return {
					...item,
					tariffAnnouncement: data.enabled,
				};
			}),

			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_TARIFF_ANNOUNCEMENT_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		DEVICE_SINGLEROWDISPLAY_SET_PENDING: (state, { data }) => ({
			...state,

			items: state.items.map(item => {
				if (item.id !== data.deviceId) {
					return item;
				}

				return {
					...item,
					singleRowDisplay: data.singleRowDisplay,
				};
			}),

			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_SINGLEROWDISPLAY_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),

		GROUP_MEMBERS_SET_PENDING: (state, { data }) => {
			const addedUsers = data.memberIds.filter(memberId => !data.oldMemberIds.includes(memberId));
			const removedUsers = data.oldMemberIds.filter(
				oldMemberId => !data.memberIds.includes(oldMemberId)
			);

			return {
				...state,
				items: state.items.map(device => {
					if (device.owner === undefined) {
						return device;
					}

					if (!addedUsers.includes(device.owner) && !removedUsers.includes(device.owner)) {
						return device;
					}

					const newDevice = {
						...device,
						activeGroups: device.activeGroups ? [...device.activeGroups] : undefined,
					};

					if (newDevice.activeGroups) {
						if (removedUsers.includes(device.owner)) {
							newDevice.activeGroups = newDevice.activeGroups.filter(
								groupId => groupId !== data.groupId
							);
						}

						if (addedUsers.includes(device.owner)) {
							newDevice.activeGroups.push(data.groupId);
						}
					}

					return newDevice;
				}),
			};
		},

		NEW_ADDRESS_DELETE_PENDING: (state, { data }) => ({
			...state,
			items: state.items.map(device => {
				if (device.type !== 'REGISTER') {
					return device;
				}

				if (device.emergencyAddressId !== data.addressId.toString()) {
					return device;
				}

				return {
					...device,
					emergencyAddressId: data.emergencyAddressId ? data.emergencyAddressId.toString() : '',
				};
			}),
		}),
	},

	initialState
);
