import React from 'react';
import classnames from 'classnames';
import { FocusTrap } from '@web-apps/focus-trap';

import classes from './FilterDropdown.module.scss';
import { DropdownButton } from '../../../components/buttons/DropdownButton';
import { DropdownWithOpener } from '../../../components/dropdowns/DropdownWithOpener';

interface Props {
	open: boolean;
	children: React.ReactNode;
	onOpenClose: (open: boolean) => void;
	buttonLabel: string;
	active: boolean;
	className?: string;
	testSelector?: string;
	openingPosition: 'Left' | 'Right';
	disableFocustrap?: boolean;
}

export class FilterDropdown extends React.PureComponent<Props> {
	public static defaultProps = {
		openingPosition: 'Right',
	};

	private renderContent() {
		return (
			<div
				className={classnames(classes.dropdown, this.props.className)}
				data-test-selector={
					this.props.testSelector ? `${this.props.testSelector}-dropdown` : undefined
				}
			>
				{this.props.children}
			</div>
		);
	}

	public render() {
		return (
			<DropdownWithOpener
				horizontalOpeningPosition={this.props.openingPosition}
				open={this.props.open}
				onOpen={() => this.props.onOpenClose(true)}
				onClose={() => this.props.onOpenClose(false)}
				opener={
					<DropdownButton
						open={this.props.open}
						active={this.props.active}
						testSelector={this.props.testSelector}
					>
						{this.props.buttonLabel}
					</DropdownButton>
				}
			>
				{this.props.disableFocustrap ? (
					this.renderContent()
				) : (
					<FocusTrap allowOutsideClick>{this.renderContent()}</FocusTrap>
				)}
			</DropdownWithOpener>
		);
	}
}
