import React from 'react';
import classnames from 'classnames';
import { NeoIcon } from '@web-apps/neo-icons';
import classes from './RevealComponent.module.scss';
import { Translate } from '../../redux/slices/translations';

interface Props {
	secret: string;
	translate: Translate;
	className?: string;
}

export const RevealComponent = (props: Props) => {
	return (
		<div className={classnames(classes.container)}>
			<div className={classes.revealComponent}>
				<div className={classes.public}>
					<div className={classnames(classes.text, props.className)}>
						{'•'.repeat(props.secret.length)}
					</div>
					<button
						type="button"
						className={classes.revealButton}
						onClick={event => {
							event.currentTarget.parentElement!.parentElement!.classList.add(classes.revealed);
						}}
						data-testid="device-card-show-password"
					>
						<NeoIcon
							name="Show"
							variant="line"
							size={16}
							aria-label={props.translate('SHOW_PASSWORD')}
						/>
					</button>
				</div>
				<div className={classes.secret}>
					<div className={classnames(classes.text, props.className)}>{props.secret}</div>
					<button
						type="button"
						className={classes.revealButton}
						onClick={event => {
							event.currentTarget!.parentElement!.parentElement!.classList.remove(classes.revealed);
						}}
						data-testid="device-card-hide-password"
					>
						<NeoIcon
							name="Hide"
							variant="line"
							size={16}
							aria-label={props.translate('HIDE_PASSWORD')}
						/>
					</button>
				</div>
			</div>
		</div>
	);
};
