import { createAsyncThunk } from '../../utils/wrapper';
import {
	CouldNotFetchTwoFactorAuthenticationSettings,
	CouldNotFindTwoFactorAuthenticationSettings,
	TwoFactorAuthenticationSetting,
	TwoFactorAuthenticationSettingsError,
} from './types';
import api from '../../../api';
import { isApiError } from '../../../api/ApiError';

export const fetchTwoFactorAuthenticationSetting = createAsyncThunk<
	TwoFactorAuthenticationSetting,
	string,
	{ rejectValue: TwoFactorAuthenticationSettingsError }
>(
	'twoFactorAuthenticationSettings/fetch',
	async (webuserId: string, { rejectWithValue }) => {
		try {
			return await api.getTwoFactorAuthenticationSetting(webuserId);
		} catch (error) {
			if (isApiError(error) && error.status === 404) {
				return rejectWithValue(CouldNotFindTwoFactorAuthenticationSettings(webuserId));
			}

			return rejectWithValue(CouldNotFetchTwoFactorAuthenticationSettings(webuserId));
		}
	},
	{
		condition: (webuserId: string, { getState }) =>
			!getState().twoFactorAuthenticationSettings.fetching.includes(webuserId),
	}
);
