import { Avatar } from '@panda/ui';
import { Size } from '@panda/ui/src/components/avatar/VisualAvatar';
import React from 'react';

import { ContactScope, UpdateApiContact } from '../../api/types/contacts';
import UserAvatar from '../../components/UserAvatar';
import {
	Base64EncodedImageString,
	Contact,
	ContactAddressType,
	ContactEmailType,
	ContactFaxNumberType,
	ContactNumberType,
	ContactWebsiteType,
	ImageType,
} from '../../redux/modules/contacts';
import { UserInfo } from '../../redux/slices/userinfo';
import { User } from '../../redux/modules/users';
import { getUserByEmail } from '../../redux/modules/users/selectors';
import { Translate } from '../../redux/slices/translations';

export const getOwnerForContact = (scope: ContactScope, userInfo: UserInfo) => {
	if (scope === 'PRIVATE') {
		return userInfo.masterSipId + userInfo.sub;
	}
	return userInfo.masterSipId;
};

export const getImageTypeFromBase64EncodedImage = (base64: Base64EncodedImageString): ImageType => {
	return base64.split(';')[0].split(':')[1] as ImageType;
};

export const getBase64ContentFromBase64EncodedImage = (base64: Base64EncodedImageString) => {
	return base64.split(',')[1];
};

export const guessImageType = (base64: string): ImageType => {
	const first = base64.charCodeAt(0);
	const second = base64.charCodeAt(1);
	const third = base64.charCodeAt(2);
	if (first === 0xff && second === 0xd8) {
		return 'image/jpeg';
	}
	if (first === 0x89 && second === 0x50 && third === 0x4e) {
		return 'image/png';
	}

	return 'image/jpeg';
};

export const getContactAvatar = (contact: Contact, size: Size, allUsers: User[]) => {
	if (contact.scope === 'INTERNAL' && allUsers.length > 0) {
		const user = getUserByEmail(allUsers, contact.emails[0].address);
		if (user) {
			return <UserAvatar user={user} size={size} />;
		}
	}

	const imgUrl = contact.avatar
		? `data:${contact.avatar.type};base64,${contact.avatar.content}`
		: '';

	const splitName = contact.name.split(' ');
	if (splitName.length > 1) {
		return (
			<Avatar firstname={splitName[0]} lastname={splitName[1]} imageUrl={imgUrl} size={size} />
		);
	}
	return <Avatar firstname={contact.name} lastname="" imageUrl={imgUrl} size={size} />;
};

export const mapContactToUpdateApiContact = (contact: Contact): UpdateApiContact => {
	const picture = contact.avatar ? contact.avatar.content : null;
	return {
		id: contact.id!,
		name: contact.name,
		given: contact.givenname,
		family: contact.familyname,
		picture,
		emails: contact.emails.map(email => ({
			email: email.address,
			type: [email.type],
		})),
		numbers: contact.numbers.map(number => ({
			number: number.number,
			type: number.fax ? ['fax', number.type] : [number.type],
		})),
		addresses: contact.addresses.map(address => ({
			poBox: address.poBox,
			extendedAddress: address.extendedAddress,
			streetAddress: address.streetAddress,
			locality: address.locality,
			region: address.region,
			postalCode: address.postalCode,
			country: address.country,
			type: [address.type],
		})),
		websites: contact.websites.map(website => ({ url: website.url, type: [website.type] })),
		note: contact.note,
		organization: contact.organizations.map(org => [org.company, org.unit]),
		scope: contact.scope,
	};
};

export const getTranslatedContactScope = (scope: ContactScope, translate: Translate) => {
	switch (scope) {
		case 'INTERNAL':
			return translate('CONTACTS_OVERVIEW_TYPE_INTERNAL');
		case 'PRIVATE':
			return translate('CONTACTS_OVERVIEW_TYPE_PRIVATE');
		case 'SHARED':
			return translate('CONTACTS_OVERVIEW_TYPE_SHARED');
		default:
			return '';
	}
};

export const getTranslatedContactScopeForContactDetailView = (
	scope: ContactScope,
	translate: Translate
) => {
	switch (scope) {
		case 'INTERNAL':
			return translate('CONTACTS_DETAIL_VIEW_TYPE_INTERNAL');
		case 'PRIVATE':
			return translate('CONTACTS_DETAIL_VIEW_TYPE_PRIVATE');
		case 'SHARED':
			return translate('CONTACTS_DETAIL_VIEW_TYPE_SHARED');
		default:
			return '';
	}
};

export const getTranslatedNumberType = (type: ContactNumberType, translate: Translate) => {
	switch (type) {
		case 'home':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_HOME');
		case 'work':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_WORK');
		case 'cell':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_CELL');
		case 'pager':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_PAGER');
		case 'directdial':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_DIRECTDIAL');
		case 'other':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_OTHER');
		default:
			return '';
	}
};

export const getTranslatedFaxNumberType = (type: ContactFaxNumberType, translate: Translate) => {
	switch (type) {
		case 'home':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_HOME');
		case 'work':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_WORK');
		case 'other':
			return translate('CONTACTS_OVERVIEW_NUMBER_TYPE_OTHER');
		default:
			return '';
	}
};

export const getTranslatedAddressType = (type: ContactAddressType, translate: Translate) => {
	switch (type) {
		case 'home':
			return translate('CONTACTS_OVERVIEW_ADDRESS_TYPE_HOME');
		case 'work':
			return translate('CONTACTS_OVERVIEW_ADDRESS_TYPE_WORK');
		case 'other':
			return translate('CONTACTS_OVERVIEW_ADDRESS_TYPE_OTHER');
		default:
			return '';
	}
};

export const getTranslatedEmailType = (type: ContactEmailType, translate: Translate) => {
	switch (type) {
		case 'home':
			return translate('CONTACTS_OVERVIEW_EMAIL_TYPE_HOME');
		case 'work':
			return translate('CONTACTS_OVERVIEW_EMAIL_TYPE_WORK');
		case 'other':
			return translate('CONTACTS_OVERVIEW_EMAIL_TYPE_OTHER');
		case 'internet':
			return translate('CONTACTS_OVERVIEW_EMAIL_TYPE_INTERNET');
		default:
			return '';
	}
};

export const getTranslatedWebsiteType = (type: ContactWebsiteType, translate: Translate) => {
	switch (type) {
		case 'home':
			return translate('CONTACTS_OVERVIEW_WEBSITE_TYPE_HOME');
		case 'work':
			return translate('CONTACTS_OVERVIEW_WEBSITE_TYPE_WORK');
		case 'page':
			return translate('CONTACTS_OVERVIEW_WEBSITE_TYPE_PAGE');
		case 'ftp':
			return translate('CONTACTS_OVERVIEW_WEBSITE_TYPE_FTP');
		case 'blog':
			return translate('CONTACTS_OVERVIEW_WEBSITE_TYPE_BLOG');
		case 'profile':
			return translate('CONTACTS_OVERVIEW_WEBSITE_TYPE_PROFILE');
		case 'other':
			return translate('CONTACTS_OVERVIEW_WEBSITE_TYPE_OTHER');
		default:
			return '';
	}
};
