import React from 'react';
import { ManagedInput, Button } from '@panda/ui';
import { ManagedMultiFieldValidator } from '@web-apps/forms';
import classes from './EditLabelSlideInContent.module.scss';
import { Translate } from '../../../redux/slices/translations';
import { ApiLabel } from '../../../api/types/labels';
import { ManagedForm } from '../../../components/forms/ManagedForm';

interface Props {
	translate: Translate;
	onBack: () => void;

	labels: ApiLabel[];
	labelId: string;

	editLabel: (id: number, newName: string) => void;
	deleteLabel: (id: number) => void;
	validateLabel: (
		translate: Translate,
		currentValue?: string
	) => ManagedMultiFieldValidator<string, unknown, string>;
}

interface State {
	showDeleteWarning: boolean;
}

export class EditLabelSlideInContent extends React.PureComponent<Props, State> {
	public state = {
		showDeleteWarning: false,
	};

	private onDelete = () => {
		if (this.state.showDeleteWarning) {
			this.props.deleteLabel(parseInt(this.props.labelId, 10));
			this.props.onBack();
		} else {
			this.setState({
				showDeleteWarning: true,
			});
		}
	};

	private getInitialLabelName = () => {
		const foundLabel = this.props.labels.find(l => l.id === parseInt(this.props.labelId, 10));

		if (foundLabel) {
			return foundLabel.name;
		}

		return '';
	};

	public render() {
		const initialValue = this.getInitialLabelName();
		const uneditedValue = initialValue;

		return (
			<ManagedForm
				initialValues={{
					name: initialValue,
				}}
				onSubmit={(data: { name: string }) => {
					if (data.name !== uneditedValue) {
						this.props.editLabel(parseInt(this.props.labelId, 10), data.name);
					}

					this.props.onBack();
				}}
				validators={{
					name: this.props.validateLabel(this.props.translate, uneditedValue),
				}}
			>
				{({ form, fields }) => (
					<>
						<ManagedInput
							managedField={fields.name}
							label={this.props.translate('EDIT_LABEL_TITLE')}
							placeholder={this.props.translate('EDIT_LABEL_PLACEHOLDER')}
						/>
						{this.state.showDeleteWarning ? (
							<>
								<p className={classes.deleteWarning}>
									{this.props.translate('EDIT_LABEL_DELETE_WARNING')}
								</p>
								<div className={classes.buttonWrapper}>
									<Button action="confirm" icon="delete" critical onClick={this.onDelete}>
										{this.props.translate('EDIT_LABEL_DELETE_CONFIRM')}
									</Button>
								</div>
							</>
						) : (
							<div className={classes.buttonWrapper}>
								<Button critical icon="delete" onClick={this.onDelete} />

								<Button action="confirm" type="submit" disabled={!form.canBeSubmitted}>
									{this.props.translate('EDIT_LABEL_SAVE')}
								</Button>
							</div>
						)}
					</>
				)}
			</ManagedForm>
		);
	}
}
