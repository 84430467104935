import { EntityState } from '@reduxjs/toolkit';
import { ErrorBase, isErrorBase } from '../../utils/statusEntityAdapter/types';
import { StatusState } from '../../utils/statusEntityAdapter';

export type Faxline = {
	id: string;
	ownerIds: string[];
	alias: string;
	tagline: string;
	callerId: string;
	canReceive: boolean;
};

export interface FaxlineState {
	items: EntityState<Faxline, string>;
	fetch: StatusState;
	update: StatusState;
}

const errorNames = [
	'COULD_NOT_FETCH_FAX_LINES',
	'COULD_NOT_SET_FAX_LINE_ALIAS',
	'COULD_NOT_SET_FAX_LINE_TAGLINE',
	'COULD_NOT_ADD_FAX_LINE_MEMBERS',
	'COULD_NOT_REMOVE_FAX_LINE_MEMBERS',
	'COULD_NOT_SET_FAX_LINE_CALLER_ID',
	'COULD_NOT_CREATE_FAX_LINE',
];

type FaxLineErrorName = (typeof errorNames)[number];

export type FaxLineError = ErrorBase<FaxLineErrorName>;

export const CouldNotFetchFaxLines = (): FaxLineError => ({
	name: 'COULD_NOT_FETCH_FAX_LINES',
	message: 'Could not fetch fax lines',
});

export const CouldNotCreateFaxLine = (): FaxLineError => ({
	name: 'COULD_NOT_CREATE_FAX_LINE',
	message: 'Could not create fax lines',
});

export const CouldNotSetFaxLineAlias = (id: string): FaxLineError => ({
	name: 'COULD_NOT_SET_FAX_LINE_ALIAS',
	message: `Could not set fax line alias for ${id}`,
});

export const CouldNotSetFaxLineTagLine = (id: string): FaxLineError => ({
	name: 'COULD_NOT_SET_FAX_LINE_TAGLINE',
	message: `Could not set fax line tag line for ${id}`,
});

export const CouldNotAddFaxLineMembers = (id: string): FaxLineError => ({
	name: 'COULD_NOT_ADD_FAX_LINE_MEMBERS',
	message: `Could not add fax line members for ${id}`,
});

export const CouldNotRemoveFaxLineMembers = (id: string): FaxLineError => ({
	name: 'COULD_NOT_REMOVE_FAX_LINE_MEMBERS',
	message: `Could not remove fax line members for ${id}`,
});

export const CouldNotSetFaxLineCallerId = (id: string): FaxLineError => ({
	name: 'COULD_NOT_SET_FAX_LINE_CALLER_ID',
	message: `Could not set fax line caller id for ${id}`,
});

export const isFaxLineError = (error: unknown): error is FaxLineError => {
	return isErrorBase(error, errorNames);
};
