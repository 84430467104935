import React, { useRef, useState } from 'react';
import { NeoIcon } from '@web-apps/neo-icons';
import classes from './HelpButton.module.scss';
import DropOut from '../../DropOut';
import useKeyboardEvents from '../../hooks/useKeyboardEvents';
import useOutsideClick from '../../hooks/useOutsideClick';
import useLinks from '../../../redux/slices/links/hooks';
import { useTranslate } from '../../../redux/slices/translations';

const HelpButton = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const { links } = useLinks();
	const translate = useTranslate();
	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);

	return (
		<div id="helpbutton" className={classes.help} ref={ref}>
			<button
				type="button"
				onClick={() => setOpen(!open)}
				aria-controls="helpbutton-menu"
				aria-expanded={open}
				aria-haspopup="true"
				id="helpbutton-button"
			>
				<NeoIcon name="Help_circle" variant="line" aria-label={translate('HELP_BUTTON_LABEL')} />
			</button>
			<DropOut id="helpbutton-menu" open={open} ariaLabelledBy="helpbutton-button">
				<ul>
					<li>
						<a href={links.whatsNewUrl} target="_blank" rel="noreferrer">
							{translate('WHATS_NEW_BUTTON')}
						</a>
					</li>
					<li>
						<a href={links.helpdeskUrl} target="_blank" rel="noreferrer">
							{translate('HELPDESK_LINK')}
						</a>
					</li>
					<li>
						<a href={links.testProgrammeUrl} target="_blank" rel="noreferrer">
							{translate('JOIN_TEST_PROGRAMME_BUTTON')}
						</a>
					</li>
				</ul>
			</DropOut>
		</div>
	);
};
export default HelpButton;
