import React from 'react';
import { connect, ReduxProps } from '../../redux';
import { createVoicemail, fetchVoicemails } from '../../redux/modules/voicemails';
import { ReduxState } from '../../redux/types';
import { User } from '../../redux/modules/users';
import { isClassicPBXCustomer } from '../../redux/slices/userinfo';

const mapStateToProps = (state: ReduxState) => ({
	voicemails: state.voicemails,
	userInfo: state.userinfo,
});

const mapDispatchToProps = {
	fetchVoicemails,
	createVoicemail,
};

interface State {
	status: null | 'CREATING' | 'CREATED';
}

export const ensureVoicemail = <
	Props extends { webuserId: string; user?: User } | { user: User; webuserId?: string },
>(
	Component: React.ComponentType<Props>
) => {
	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(
		class EnsureVoicemail extends React.Component<
			Props & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>,
			State
		> {
			public state: State = { status: null };

			public componentDidMount() {
				if (isClassicPBXCustomer(this.props.userInfo.data)) {
					this.props.fetchVoicemails(this.getUserId());
				}
			}

			public componentDidUpdate(prevProps: Props) {
				if (
					isClassicPBXCustomer(this.props.userInfo.data) &&
					(prevProps.user?.id !== this.props.user?.id ||
						prevProps.webuserId !== this.props.webuserId)
				) {
					this.props.fetchVoicemails(this.getUserId());
				}
			}

			private getUserId() {
				return (this.props.user?.id || this.props.webuserId)!;
			}

			public render() {
				if (isClassicPBXCustomer(this.props.userInfo.data)) {
					if (
						!this.props.voicemails.fetchedForUser.includes(this.getUserId()) ||
						this.state.status === 'CREATING'
					) {
						return null;
					}

					const hasVoicemail = this.props.voicemails.items.some(
						v => v.parentExtension.extension === this.getUserId()
					);

					if (!hasVoicemail && this.state.status !== 'CREATED') {
						this.setState({ status: 'CREATING' });
						this.props
							.createVoicemail(this.getUserId())
							.payload.promise.finally(() => this.setState({ status: 'CREATED' }));
						return null;
					}
				}
				// Allowed for HOC
				//
				// eslint-disable-next-line react/jsx-props-no-spreading
				return <Component {...this.props} />;
			}
		}
	);
};
