import { createSlice, EntityId, EntityState } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { ChannelHistory } from './types';
import { fetchHistory } from './actions';
import { randomString } from './utils';
import { StatusItem, StatusState } from '../../utils/statusEntityAdapter';
import { channelHistoryEntityAdapter, fetchItemEntityAdapter } from './adapter';

export type ChannelHistoryState = {
	items: EntityState<ChannelHistory, EntityId>;
	fetch: StatusState;
	revision: string;
};

export const getInitialState = (
	channelHistoriesItems?: ChannelHistory[],
	fetchItems?: StatusItem[]
) => ({
	items: channelHistoryEntityAdapter.getInitialState({}, channelHistoriesItems),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetchItems),
	revision: randomString(12),
});

export const reducer = createSlice({
	name: 'channelHistory',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchHistory.pending, (state, { meta }) => {
			fetchItemEntityAdapter.addOne(state.fetch, {
				id: meta.arg.channelId,
				status: 'pending',
				timestamp: DateTime.now().toISO(),
			});

			// eslint-disable-next-line no-param-reassign
			state.revision = randomString(12);
		});

		builder.addCase(fetchHistory.fulfilled, (state, { payload: history, meta }) => {
			channelHistoryEntityAdapter.addOne(state.items, { id: meta.arg.channelId, history });

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.channelId,
				status: 'succeeded',
				timestamp: DateTime.now().toISO(),
			});

			// eslint-disable-next-line no-param-reassign
			state.revision = randomString(12);
		});

		builder.addCase(fetchHistory.rejected, (state, { payload: error, meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.channelId,
				status: 'failed',
				timestamp: DateTime.now().toISO(),
				error,
			});

			// eslint-disable-next-line no-param-reassign
			state.revision = randomString(12);
		});
	},
}).reducer;
