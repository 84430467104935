import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconPresence = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M9.5 13c-2.755 0-5-2.245-5-5s2.245-5 5-5 5 2.245 5 5-2.245 5-5 5m0-8.5C7.57 4.5 6 6.07 6 8s1.57 3.5 3.5 3.5S13 9.93 13 8s-1.57-3.5-3.5-3.5m5.5 7V13c2.756 0 5-2.245 5-5s-2.244-5-5-5v1.5c1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5m5.815 9.694v.001h-.005zm0 0-.945-3.524A2.26 2.26 0 0 0 17.695 16v-1.5a3.75 3.75 0 0 1 3.62 2.78l.945 3.525zm-5-.002v.003h-.01zm-.944-3.522.944 3.522 1.44-.387-.944-3.525a3.75 3.75 0 0 0-3.62-2.78H6.295a3.76 3.76 0 0 0-3.62 2.78l-.945 3.525 1.45.39.945-3.525A2.26 2.26 0 0 1 6.3 16h6.395c1.02 0 1.91.69 2.175 1.67"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconPresence;
