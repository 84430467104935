import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconLocation = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M19.5 3.25V20.5H22V22H2v-1.5h2.5V3.25C4.5 2.56 5.06 2 5.75 2h12.5c.69 0 1.25.56 1.25 1.25M9.5 20.5h5v-2h-5zM16 17v3.5h2v-17H6v17h2V17zM8 5h1.5v2.5H8zm3.25 0h1.5v2.5h-1.5zM9.5 9H8v2.5h1.5zm1.75 0h1.5v2.5h-1.5zM9.5 13H8v2.5h1.5zm1.75 0h1.5v2.5h-1.5zM16 5h-1.5v2.5H16zm-1.5 4H16v2.5h-1.5zm1.5 4h-1.5v2.5H16z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconLocation;
