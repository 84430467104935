import { useEffect, useMemo } from 'react';
import { SliceData } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchDataAutomationHistory } from './actions';
import { DataAutomationHistoryItem } from './types';

export const useDataAutomationHistory = (
	deviceId: string
): SliceData<DataAutomationHistoryItem | null> => {
	const state = useSelector(s => s.dataAutomationHistory);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDataAutomationHistory({ containerId: deviceId }));
	}, [dispatch, deviceId]);

	return useMemo(() => {
		if (!state.fetchedForContainer.includes(deviceId)) {
			return {
				fetched: false,
				data: null,
			};
		}

		return {
			fetched: true,
			data: state.items.find(item => item.containerId === deviceId) || null,
		};
	}, [state, deviceId]);
};
