import { createAsyncThunk } from '../../utils/wrapper';
import { channelInboxRestApiClient as api } from './api';
import { ChannelInbox, ChannelInboxState } from './types';
import { isFetched, isFetching } from './selectors';
import { ApiChannelInboxMap } from './api/types';

type FetchChannelInboxParameters = {
	webuserId: string;
	force?: boolean;
};

export type SetChannelInboxParameters = {
	webuserId: string;
} & ChannelInbox;

const shouldFetchChannelInbox = (state: ChannelInboxState, webuserId: string) =>
	!isFetched(state, webuserId) && !isFetching(state, webuserId);

export const fetchChannelInbox = createAsyncThunk<ApiChannelInboxMap, FetchChannelInboxParameters>(
	'channelInbox/fetch',
	async ({ webuserId }: FetchChannelInboxParameters, { rejectWithValue }) => {
		try {
			return await api.getChannelInbox(webuserId);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
	{
		condition: ({ webuserId, force = false }, { getState }) =>
			force || shouldFetchChannelInbox(getState().channelInbox, webuserId),
	}
);

export const setChannelInbox = createAsyncThunk(
	'channelInbox/set',
	async ({ deviceId, channelId }: SetChannelInboxParameters, { rejectWithValue }) => {
		try {
			return await api.setChannelInbox(deviceId, channelId);
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
