import { DateTime, DurationUnit } from 'luxon';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { ChannelHistoryError, ChannelHistoryItem, CouldNotFetchHistory } from './types';
import { isFetched, isFetching, isStale } from './selectors';

export const fetchHistory = createAsyncThunk<
	ChannelHistoryItem[],
	{
		channelId: string;
		ttl?: number;
		durationUnit?: DurationUnit;
		forceFetch?: boolean;
	},
	{
		rejectValue: ChannelHistoryError;
	}
>(
	'channelHistory/fetch',
	async (
		{
			channelId,
		}: {
			channelId: string;
			ttl?: number;
			durationUnit?: DurationUnit;
			forceFetch?: boolean;
		},
		{ rejectWithValue }
	) => {
		try {
			return (await api.getChannelHistory(
				channelId,
				DateTime.now().minus({ days: 1 }),
				DateTime.now()
			)) as ChannelHistoryItem[];
		} catch (error) {
			return rejectWithValue(CouldNotFetchHistory(channelId));
		}
	},
	{
		condition: (
			{
				channelId,
				ttl = 10,
				durationUnit = 'hour',
				forceFetch = false,
			}: {
				channelId: string;
				ttl?: number;
				durationUnit?: DurationUnit;
				forceFetch?: boolean;
			},
			{ getState }
		) => {
			const state = getState().channelHistory;

			const staleCache = isStale(state, channelId, ttl, durationUnit);
			const isCurrentlyFetching = isFetching(state, channelId);
			const hasBeenFetched = isFetched(state, channelId);

			return (!hasBeenFetched && !isCurrentlyFetching) || staleCache || forceFetch;
		},
	}
);

export const fetchHistoryForChannels = createAsyncThunk(
	'channelHistory/fetchForChannels',
	async (
		{
			channelIds,
			ttl,
			durationUnit,
			forceFetch,
		}: {
			channelIds: string[];
			ttl?: number;
			durationUnit?: DurationUnit;
			forceFetch?: boolean;
		},
		{ dispatch }
	) => {
		channelIds.forEach(channelId => {
			dispatch(fetchHistory({ channelId, ttl, durationUnit, forceFetch }));
		});
	}
);
