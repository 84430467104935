import React from 'react';
import moment from 'moment-timezone';
import { localizeNumber } from '@web-apps/phonenumbers-utils';
import { NormalizedEvent } from './normalize/events';
import { EndpointType } from '../../utils/endpoints';
import { SipgateDomain } from '../../redux/slices/userinfo';
import { Translate } from '../../redux/slices/translations';

export const formatDate = (date: Date) => {
	const momentDate = moment(date);

	return `${momentDate.format('L')} ${momentDate.format('LT')}`;
};

export const formatDuration = (seconds: number) => {
	const minutes = Math.floor(seconds / 60);
	const remainingSeconds = seconds % 60;
	let result = `${minutes}:`;

	if (remainingSeconds < 10) {
		result += '0';
	}

	return `${result}${remainingSeconds} Min`;
};

export const formatPages = (pages: number, translate: Translate) => {
	if (pages === 1) {
		return `${pages} ${translate('EVENTLIST_FAX_PAGE')}`;
	}
	return `${pages} ${translate('EVENTLIST_FAX_PAGES')}`;
};

export const getLocalizedEventSource = (
	event: NormalizedEvent,
	domain: SipgateDomain,
	translate: Translate
) => {
	if (event.source.name) {
		return event.source.name;
	}

	if (!event.source.number) {
		return translate('ANONYMOUS');
	}

	return localizeNumber(event.source.number, domain);
};

export const getLocalizedEventTarget = (
	event: NormalizedEvent,
	domain: SipgateDomain,
	translate: Translate
) => {
	if (event.target.name) {
		return event.target.name;
	}

	if (!event.target.number) {
		return translate('ANONYMOUS');
	}

	return localizeNumber(event.target.number, domain);
};

export const formatExtension = (translate: Translate, type: EndpointType, name?: string) => {
	if (name) {
		return <strong>{name}</strong>;
	}

	if (type === 'GROUP') {
		return <strong>{translate('EVENTLIST_UNKNOWN_GROUP')}</strong>;
	}

	if (type === 'EXTERNAL' || type === 'MOBILE' || type === 'REGISTER') {
		return <strong>{translate('EVENTLIST_DELETED_DEVICE')}</strong>;
	}

	if (type === 'FAX') {
		return <strong>{translate('EVENTLIST_DELETED_FAX')}</strong>;
	}

	if (type === 'SMS') {
		return <strong>{translate('EVENTLIST_DELETED_SMS')}</strong>;
	}

	if (type === 'PHONELINE') {
		return <strong>{translate('EVENTLIST_DELETED_PHONELINE')}</strong>;
	}

	if (type === 'USER') {
		return <strong>{translate('EVENTLIST_DELETED_USER')}</strong>;
	}

	if (type === 'VOICEMAIL') {
		return <strong>{translate('EVENTLIST_DELETED_VOICEMAIL')}</strong>;
	}

	return <strong>{translate('EVENTLIST_UNKNOWN_TARGET')}</strong>;
};
