import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconRouting = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M6.68 10H4.25v-.005c-.69 0-1.25-.56-1.25-1.25V7.11c0-1.225.82-2.315 1.99-2.645a26 26 0 0 1 14.02 0A2.75 2.75 0 0 1 21 7.11v1.635c0 .69-.56 1.25-1.25 1.25h-2.43c-.485 0-.93-.28-1.135-.72L15.11 6.97a23 23 0 0 0-6.215-.005L7.815 9.28c-.205.435-.65.72-1.135.72m13.57 5.25v.88A2.49 2.49 0 0 1 22 18.5a2.5 2.5 0 0 1-5 0c0-1.115.74-2.05 1.75-2.37v-.88c0-.69-.56-1.25-1.25-1.25h-4.75v2.13a2.49 2.49 0 0 1 1.75 2.37 2.5 2.5 0 0 1-5 0c0-1.115.74-2.05 1.75-2.37V14H6.5c-.69 0-1.25.56-1.25 1.25v.88A2.49 2.49 0 0 1 7 18.5a2.5 2.5 0 0 1-5 0c0-1.115.74-2.05 1.75-2.37v-.88A2.755 2.755 0 0 1 6.5 12.5h4.75V9h1.5v3.5h4.75a2.755 2.755 0 0 1 2.75 2.75"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconRouting;
