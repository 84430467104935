import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconNext = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="m9.03 20.03-1.06-1.06L14.94 12 7.97 5.03l1.06-1.06 7.145 7.145c.485.485.485 1.28 0 1.77z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconNext;
