import { AudioFile } from '../../modules/audioFiles';
import { ApiIvr, ApiIvrForwarding } from './api/types';

export const selectUnusedAudioFilesInIvr = ({
	audioFiles,
	ivr,
}: {
	audioFiles: AudioFile[];
	ivr: ApiIvr;
}): AudioFile[] => {
	const usedAudioFileIds = Object.values(ivr.configuration)
		.filter(configuration => configuration && configuration.value)
		.map(({ value }) => value);
	return audioFiles.filter(({ id }) => !usedAudioFileIds.includes(id));
};

export const selectCurrentlySelectedAudioFileForIvrKey = (
	audioFiles: AudioFile[],
	ivr: ApiIvr,
	key: ApiIvrForwarding
): AudioFile[] => {
	if (ivr.configuration[key]?.type === 'EXTENSION') {
		return [];
	}
	return audioFiles.filter(({ id }) => ivr.configuration[key]?.value === id);
};

export const selectReferringIvrsByChannelId = (ivrs: ApiIvr[], channelId: string): ApiIvr[] => {
	return ivrs.filter(ivr => {
		return Object.values(ivr.configuration).some(
			configuration =>
				configuration?.type === 'NEO_PBX_CHANNEL' && configuration.value === channelId
		);
	});
};

export const selectReferringIvrsByExtension = (
	ivrs: ApiIvr[],
	extension: string | undefined
): ApiIvr[] => {
	if (extension === undefined) {
		return [];
	}

	return ivrs.filter(ivr => {
		return Object.values(ivr.configuration).some(
			configuration => configuration?.type === 'EXTENSION' && configuration.value === extension
		);
	});
};
