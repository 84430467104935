import { DateTime } from 'luxon';
import { AudioFile } from './types';

import defaultAudioFileGreetingDe from '../../../media/audioFiles/greeting/default.de.mp3';
import defaultAudioFileGreetingUk from '../../../media/audioFiles/greeting/default.uk.mp3';
import defaultAudioFileCallQueue from '../../../media/audioFiles/callQueue/default.mp3';
import defaultAudioFileVoicemailDe from '../../../media/audioFiles/voicemail/default.de.mp3';
import defaultAudioFileVoicemailUk from '../../../media/audioFiles/voicemail/default.uk.mp3';
import defaultAudioFileAutoRecordingDe from '../../../media/audioFiles/autoRecording/default.de.mp3';
import defaultAudioFileAutoRecordingUk from '../../../media/audioFiles/autoRecording/default.uk.mp3';
import { ForwardingSet, isVoicemailStep, selectAllForwardingSteps } from '../timeBasedForwardings';
import { Voicemail } from '../voicemails';
import { SipgateDomain } from '../../slices/userinfo';

export function getDefaultAudioFile(
	domain: SipgateDomain,
	ownerType: 'voicemail' | 'greeting' | 'callQueue' | 'autoRecording'
) {
	switch (ownerType) {
		case 'greeting':
			return domain === 'sipgate.de' ? defaultAudioFileGreetingDe : defaultAudioFileGreetingUk;
		case 'voicemail':
			return domain === 'sipgate.de' ? defaultAudioFileVoicemailDe : defaultAudioFileVoicemailUk;
		case 'autoRecording':
			return domain === 'sipgate.de'
				? defaultAudioFileAutoRecordingDe
				: defaultAudioFileAutoRecordingUk;
		case 'callQueue':
			return defaultAudioFileCallQueue;
	}
}

export function getAudioFilesForOwner(audioFiles: AudioFile[], ownerId: string) {
	return audioFiles.filter(audioFile => audioFile.ownerId === ownerId);
}

export function getAudioFile(audioFiles: AudioFile[], ownerId: string, audioFileId: string) {
	// We need to check ownerId because ALL default audioFiles have id 0...
	return audioFiles.find(
		audioFile => audioFile.id === audioFileId && audioFile.ownerId === ownerId
	);
}

export function shouldForceFetchOnError(
	audioFile: AudioFile,
	timestamp: DateTime | undefined
): boolean {
	if (timestamp) {
		return audioFile.url.state === 'ERROR' && timestamp > audioFile.url.timestamp;
	}
	return false;
}

export const selectAudioFile = (audioFiles: AudioFile[], audioFileId: string) =>
	audioFiles.find(audioFile => audioFile.id === audioFileId);

export const isAudioFileUsed = (
	audioFile: AudioFile,
	forwardings: ForwardingSet[],
	voicemails: Voicemail[]
) => {
	return (
		forwardings
			.flatMap(selectAllForwardingSteps)
			.filter(isVoicemailStep)
			.some(step => step.destination.announcementId === audioFile.id) ||
		voicemails.some(voicemail => voicemail.activeAudioFileId === audioFile.id)
	);
};
