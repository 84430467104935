import React from 'react';
import classnames from 'classnames';
import { HeadingBoundary, Headline } from '@panda/ui';
import CenterContainer from '../../helpers/CenterContainer';
import classes from './DeprecatedDialogContent.module.scss';
import { ariaId } from '../../utils/a11y/aria-id';

export interface Props {
	showHeaderContainer?: boolean;
	headline?: React.ReactNode;
	hintText?: React.ReactNode;
	containerClassName?: string;
	contentContainerClassName?: string;
	children: React.ReactNode;
	button?: React.ReactNode;
	icon?: React.ReactNode;
	illustration?: React.ReactNode;
	fullWidth?: boolean;
	dataTestSelector?: string;
}

/** @deprecated Use src/dialog/DialogContent instead */
export class DeprecatedDialogContent extends React.Component<Props> {
	private dialogRef = React.createRef<HTMLDivElement>();

	private ariaIdHeadline = ariaId();

	private getButton = () => {
		if (this.props.button) {
			return (
				<CenterContainer horizontal className={classes.centerContainer}>
					{this.props.button}
				</CenterContainer>
			);
		}

		return null;
	};

	public componentDidMount() {
		/**
		 * We set the focus manually to ensure the opening of the dialog is communicated by screenreaders.
		 * Because of race conditions the focus needs to be set asynchronously.
		 */
		setTimeout(() => {
			if (this.dialogRef.current) {
				// Prevent autofocus elements from not-being-focussed
				if (!this.dialogRef.current.contains(document.activeElement)) {
					this.dialogRef.current?.focus();
				}
			}
		});
	}

	public render() {
		return (
			<section
				className={classnames(classes.container, this.props.containerClassName)}
				data-test-selector={this.props.dataTestSelector}
				ref={this.dialogRef}
				tabIndex={-1}
				aria-labelledby={this.ariaIdHeadline}
			>
				<div>
					{this.props.showHeaderContainer === false ? null : (
						<div className={classes.headerContainer}>
							{this.props.icon && <div className={classes.iconContainer}>{this.props.icon}</div>}
							{this.props.illustration && (
								<div className={classes.illustrationContainer}>{this.props.illustration}</div>
							)}
							<Headline className={classes.head} id={this.ariaIdHeadline}>
								{this.props.headline}
							</Headline>
						</div>
					)}
					{React.Children.count(this.props.children) > 0 ? (
						<HeadingBoundary>
							<div
								className={classnames(
									classes.contentContainer,
									{
										[classes.fullWidth]: this.props.fullWidth,
									},
									this.props.contentContainerClassName
								)}
							>
								{this.props.children}
							</div>
						</HeadingBoundary>
					) : null}

					{this.props.hintText ? <p className={classes.hintText}>{this.props.hintText}</p> : null}

					{this.getButton()}
				</div>
			</section>
		);
	}
}
