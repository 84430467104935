import classnames from 'classnames';
import React, { AriaAttributes, useId } from 'react';
import { NeoIcon } from '@web-apps/neo-icons';
import { DisabledContext, isDisabled as isContextDisabled } from '../../contexts/disabledContext';
import { usePandaContext } from '../../contexts/pandaContext';
import { Description } from '../visualComponents/Description';
import { ErrorDescription } from '../visualComponents/ErrorDescription';
import { Label } from '../visualComponents/Label';
import { VisualInput } from '../visualComponents/VisualInput';
import { getIconLabel } from '../../assets/icons/icon-set/SetIcon';

type Props = {
	name?: string;
	value: string;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	error?: string;
} & AdditionalProps &
	AriaAttributes;

type AdditionalProps = {
	label: string | undefined;
	placeholder: string;
	minLength: number | undefined;
	description: string | undefined;
	autoFocus: boolean | undefined;
	disabled: boolean | undefined;
	readOnly: boolean | undefined;
	onFocus: React.FocusEventHandler<HTMLInputElement> | undefined;
};

const styles = {
	div: classnames('group', 'w-full', 'flex', 'flex-wrap'),
	inputContainer: classnames('relative', 'w-full'),
	errorContainer: classnames(
		'absolute',
		'top-8',
		'right-8',
		'inset-y-0',
		'w-24',
		'h-24',
		'flex',
		'justify-center',
		'items-center'
	),
	icon: classnames('text-neo-color-global-content-critical-moderate'),
	showPasswordButton: (error: boolean) =>
		classnames(
			'h-24',
			'w-24',
			'p-0',
			'block',
			'absolute',
			'top-8',
			'bg-neo-color-global-background-static-transparent',
			'cursor-pointer',
			'text-neo-color-global-content-neutral-intense',
			'disabled:cursor-not-allowed',
			'disabled:text-neo-color-global-content-neutral-disabled',
			'hover:text-neo-color-global-content-primary-moderate',
			'active:text-neo-color-global-content-primary-intense',
			'focus-visible:ring-neo-color-global-border-primary-intense-active',
			'rounded-sm',
			error ? 'right-[2.25rem]' : 'right-8'
		),
};

const PasswordInput = ({
	label,
	name,
	error,
	value,
	onChange,
	onBlur,
	onFocus,
	autoFocus = false,
	description,
	placeholder,
	minLength = 12,
	disabled,
	readOnly = false,
	...ariaAttributes
}: Props) => {
	const [showPassword, setShowPassword] = React.useState(false);
	const id = useId();
	const inputElement = React.useRef<HTMLInputElement>(null);
	const disabledContextValue = React.useContext(DisabledContext);
	const isDisabled = isContextDisabled(disabled, disabledContextValue);

	const { languageKeys } = usePandaContext();

	React.useEffect(() => {
		if (inputElement.current && autoFocus) {
			inputElement.current.focus();
		}
	}, [autoFocus]);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange(event);
	};

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		if (onBlur) {
			onBlur(event);
		}
	};

	const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		if (onFocus) {
			onFocus(event);
		}
	};

	const focusInput = () => {
		inputElement.current?.focus?.();
	};

	const getCondition = () => {
		if (isDisabled || readOnly) return 'disabled';
		if (typeof error !== 'undefined') return 'error';
		return 'default';
	};

	return (
		<div className={styles.div}>
			{label ? <Label htmlFor={id}>{label}</Label> : null}
			{description ? (
				<Description id={`${id}-description`} onClick={focusInput}>
					{description}
					<span aria-live="polite" className="sr-only">
						{showPassword
							? languageKeys.PANDA_PASSWORD_INPUT_SHOWN
							: languageKeys.PANDA_PASSWORD_INPUT_HIDDEN}
					</span>
				</Description>
			) : null}
			<div className={styles.inputContainer}>
				<VisualInput
					passwordInput
					condition={getCondition()}
					ref={inputElement}
					id={id}
					disabled={isDisabled}
					readOnly={readOnly}
					minLength={minLength}
					maxLength={255}
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					onFocus={handleOnFocus}
					type={showPassword ? 'text' : 'password'}
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...ariaAttributes}
					aria-describedby={`${id}-description`}
					aria-invalid={Boolean(error)}
				/>
				<button
					type="button"
					role="switch"
					aria-checked={showPassword}
					aria-controls={id}
					onClick={() => setShowPassword(!showPassword)}
					disabled={isDisabled}
					className={styles.showPasswordButton(!!error)}
					title={
						showPassword
							? getIconLabel(languageKeys, 'hide-password')
							: getIconLabel(languageKeys, 'show-password')
					}
				>
					<NeoIcon name={showPassword ? 'Hide' : 'Show'} variant="line" />
				</button>
				{error ? (
					<div className={styles.errorContainer}>
						<NeoIcon name="Form_error" variant="solid" className={styles.icon} />
					</div>
				) : null}
				{error ? <ErrorDescription onClick={focusInput}>{error}</ErrorDescription> : null}
			</div>
		</div>
	);
};

export { PasswordInput };
