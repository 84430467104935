import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconAdministration = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M4 22V2h16v9.38l-7.06 7.06L11.755 22zm3.5-3.5h4V17h-4zm0-5.5h4v-1.5h-4zm0-3h9V8.5h-9zm0-3h9V5.5h-9zm14.78 8.22-6.03 6.03-.005-.005-2.05.685a.497.497 0 0 1-.63-.63l.685-2.05 6.03-6.03c.55-.55 1.45-.55 2 0s.55 1.45 0 2"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconAdministration;
