import { handleActions } from '../..';
import * as actions from './actions';
import { normalizeContact } from './normalize';
import { ContactsState } from './types';

const initialState: ContactsState = {
	abortController: null,
	fetched: false,
	importMapper: null,
	importing: false,
	items: [],
	totalCount: 0,
};

export const reducer = handleActions<ContactsState, PossibleActions<typeof actions>>(
	{
		CONTACT_FETCH_PENDING: (state, action) => ({
			...state,
			abortController: action.abortController,
		}),
		CONTACTS_FETCH_PENDING: (state, action) => ({
			...state,
			abortController: action.abortController,
		}),
		CONTACT_FETCH_SUCCESS: (state, action) => {
			const normalizedContact = normalizeContact(action.contact);
			return {
				...state,
				abortController: null,
				items: state.items
					.filter(contact => contact.id !== normalizedContact.id)
					.concat(normalizedContact),
			};
		},
		CONTACTS_FETCH_BATCH: (state, action) => {
			const newIds = new Set(action.contacts.map(contact => contact.id));

			return {
				...state,
				totalCount: action.totalCount,
				items: [
					...state.items.filter(contact => contact.id === null || !newIds.has(contact.id)),
					...action.contacts.map(normalizeContact),
				],
			};
		},
		CONTACTS_FETCH_ABORT: state => ({
			...state,
			abortController: null,
		}),
		CONTACTS_FETCH_SUCCESS: (state, action) => ({
			...state,
			abortController: null,
			fetched: true,
			items: state.items.filter(
				contact => typeof contact.id === 'string' && !action.removedIds.includes(contact.id)
			),
		}),

		CONTACTS_CREATE_PENDING: state => {
			return {
				...state,
				items: [...state.items],
			};
		},
		CONTACTS_CREATE_SUCCESS: (state, action) => {
			const normalizedContact = normalizeContact(action.payload);
			return {
				...state,
				items: [
					...state.items.filter(
						element =>
							JSON.stringify(element) !== JSON.stringify({ ...normalizedContact, id: null })
					),
					normalizedContact,
				],
			};
		},
		CONTACTS_UPDATE_PENDING: (state, action) => ({
			...state,
			items: state.items.map(contact =>
				contact.id === action.data.contact.id
					? normalizeContact({
							...action.data.contact,
							givenname: action.data.contact.given,
							familyname: action.data.contact.family,
						})
					: contact
			),
		}),
		CONTACTS_UPDATE_SUCCESS: (state, action) => ({
			...state,
			items: state.items.map(contact =>
				contact.id === action.data.contact.id
					? normalizeContact({
							...action.data.contact,
							givenname: action.data.contact.given,
							familyname: action.data.contact.family,
						})
					: contact
			),
		}),
		CONTACTS_DELETE_ALL_FAILURE: state => {
			return {
				...state,
				fetched: false,
				items: [],
			};
		},
		CONTACTS_PREPARE_CSV_IMPORT_SUCCESS: (state, action) => {
			return {
				...state,
				fetched: true,
				importMapper: action.payload,
			};
		},
		CONTACTS_UPDATE_COLUMN_MAPPING_PENDING: state => state,
		CONTACTS_UPDATE_COLUMN_MAPPING_SUCCESS: (state, action) => {
			if (!state.importMapper) {
				return { ...state };
			}

			const columns = [...state.importMapper.columns];
			columns[action.data.index] = action.data.mappingAction;

			return {
				...state,
				importMapper: { ...state.importMapper, columns },
			};
		},
		CONTACTS_IMPORT_CACHED_CSV_PENDING: state => {
			return {
				...state,
				importing: true,
			};
		},
		CONTACTS_IMPORT_CACHED_CSV_FAILURE: state => {
			return {
				...state,
				importing: false,
			};
		},
		CONTACTS_IMPORT_CACHED_CSV_SUCCESS: state => {
			return {
				...state,
				importMapper: null,
				importing: false,
				fetched: false,
			};
		},
		CONTACTS_DELETE_ALL_SUCCESS: (state, action) => {
			if (action.data.contactIds.length === 0) {
				return {
					...state,
					fetched: true,
					items: state.items.filter(c => !action.data.scopes.includes(c.scope)),
				};
			}
			return {
				...state,
				fetched: true,
				items: state.items.filter(
					c =>
						(c.id && !action.data.contactIds.includes(c.id)) ||
						!action.data.scopes.includes(c.scope)
				),
			};
		},
	},
	initialState
);
