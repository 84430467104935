import serviceUrls from '@web-apps/service-urls';
import { SipgateApi } from './SipgateApi';
import { SipgateApiClient } from './client/SipgateApiClient';
import { HttpClient } from './client/HttpClient';
import { PaymentApiClient } from './client/PaymentApiClient';
import { SipgatePaymentApi } from './SipgatePaymentApi';

export { ApiError } from './ApiError';

export const api: SipgateApi = new SipgateApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);

export const paymentApi: SipgatePaymentApi = new PaymentApiClient(
	new HttpClient({
		baseUrl: serviceUrls.paymentApi,
	})
);

export default api;
