import serviceUrls from '@web-apps/service-urls';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { ApiCreatedPersonalAccessToken, ApiPersonalAccessToken } from './types';
import { HttpClient } from '../../../../api/client/HttpClient';
import { ListResponse } from '../../../../api/SipgateApi';

interface PersonalAccessTokenApiClient {
	getPersonalAccessTokens(webuserId: string): Promise<ListResponse<ApiPersonalAccessToken>>;

	deletePersonalAccessToken(tokenId: string): Promise<void>;

	createPersonalAccessToken(
		webuserId: string,
		tokenName: string,
		scopes: string[]
	): Promise<ApiCreatedPersonalAccessToken>;
}

class PersonalAccessTokenRestApiClient
	extends RestApiClient
	implements PersonalAccessTokenApiClient
{
	public getPersonalAccessTokens(webuserId: string): Promise<{ items: ApiPersonalAccessToken[] }> {
		return this.get('/v3', `/authorization/personal-access-token/${webuserId}`);
	}

	public deletePersonalAccessToken(tokenId: string): Promise<void> {
		return this.delete('/v3', `/authorization/personal-access-token/${tokenId}`);
	}

	public createPersonalAccessToken(
		webuserId: string,
		tokenName: string,
		scopes: string[]
	): Promise<ApiCreatedPersonalAccessToken> {
		return this.post('/v3', `/authorization/personal-access-token/${webuserId}`, {
			tokenName,
			scopes,
		});
	}
}

export const personalAccessTokenRestApiClient = new PersonalAccessTokenRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
