import { useCallback, useEffect } from 'react';
import {
	createUserNotification as createUserNotificationAction,
	deleteUserNotification,
	fetchUserNotifications,
	replaceUserNotification,
} from './actions';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { CreateUserNotificationRequest, ReplaceUserNotificationRequest } from './api';
import { forceFetchLabels } from '../../modules/labels';
import { isFetched, selectAll } from './selectors';
import { SliceData } from '../../index';
import { UserNotification } from './types';

export const useUserNotifications = (): SliceData<UserNotification[]> => {
	const dispatch = useDispatch();
	const userNotifications = useSelector(state => state.userNotifications);

	useEffect(() => {
		dispatch(fetchUserNotifications());
	}, [dispatch]);

	if (!isFetched(userNotifications, '*')) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: selectAll(userNotifications),
		fetched: true,
	};
};

export const useCreateUserNotification = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (createUserNotificationRequest: CreateUserNotificationRequest) => {
			const result = await dispatch(createUserNotificationAction(createUserNotificationRequest));

			dispatch(fetchUserNotifications(true));
			dispatch(forceFetchLabels());

			return result;
		},
		[dispatch]
	);
};

export const useReplaceUserNotification = () => {
	const dispatch = useDispatch();

	return useCallback(
		async (replaceUserNotificationRequest: ReplaceUserNotificationRequest) => {
			const result = await dispatch(replaceUserNotification(replaceUserNotificationRequest));

			dispatch(fetchUserNotifications(true));
			dispatch(forceFetchLabels());

			return result;
		},
		[dispatch]
	);
};

export const useDeleteUserNotification = (notificationId: string) => {
	const dispatch = useDispatch();

	return useCallback(async () => {
		await dispatch(deleteUserNotification(notificationId));
	}, [dispatch, notificationId]);
};
