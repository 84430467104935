import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconNq_nupsi = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 22C6.485 22 2 17.515 2 12S6.485 2 12 2s10 4.485 10 10-4.485 10-10 10m0-18.5c-4.685 0-8.5 3.815-8.5 8.5s3.815 8.5 8.5 8.5 8.5-3.815 8.5-8.5-3.815-8.5-8.5-8.5M7.53 16.03l-1.06-1.06L9.44 12 6.47 9.03l1.06-1.06 3.145 3.145c.485.485.485 1.28 0 1.77zM11.5 15h5v1.5h-5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconNq_nupsi;
