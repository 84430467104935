import React, { useCallback } from 'react';
import { Button, Headline, ManagedCheckbox } from '@panda/ui';
import { validateChecked } from '@web-apps/forms';
import classes from './PricingUpdateView.module.scss';
import { ManagedForm } from '../../../components/forms/ManagedForm';
import { useTranslate } from '../../../redux/slices/translations';
import SipgateBlackLogo from './img/sipgate.svg';
import useLinks from '../../../redux/slices/links/hooks';
import api from '../../../api';
import { useCurrentUser } from '../../../redux/modules/users';
import { Legal } from '../../../components/dialogs/Legal';

export const PricingUpdateView = () => {
	const currentUser = useCurrentUser();
	const translate = useTranslate();
	const { links } = useLinks();
	const [posting, setPosting] = React.useState(false);

	const asyncConsentPriceAdjustmentCall = useCallback(
		async (asyncCall: () => Promise<void>) => {
			if (!posting) {
				setPosting(true);
				try {
					await asyncCall();
					window.location.href = links.startUpPageUrl;
				} catch (e) {
					window.location.reload();
				}
			}
		},
		[posting, links.startUpPageUrl]
	);

	const consentPriceAdjustment = async () => {
		await asyncConsentPriceAdjustmentCall(() => api.consentPriceAdjustment());
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.backdrop} />
			<img className={classes.logo} src={SipgateBlackLogo} alt={translate('SIPGATE_LOGO_ALT')} />
			<div className={classes.center}>
				<div className={classes.content}>
					<Headline className={classes.title}>
						{translate('PRICING_UPDATE_FORCE_COMMAND_DIALOG_HEADER')}
					</Headline>

					<p>{translate('PRICING_UPDATE_FORCE_COMMAND_DIALOG_BODY')}</p>
					{currentUser && currentUser?.admin ? (
						<ManagedForm
							initialValues={{
								accepted: false,
							}}
							onSubmit={consentPriceAdjustment}
							validators={{
								accepted: validateChecked(translate),
							}}
						>
							{({ fields, form }) => (
								<>
									<Legal>
										{translate.markdown.block(
											'PRICING_UPDATE_FORCE_COMMAND_LINK_LIST',
											links.newStarterPriceList_121124,
											links.generalTacUrl,
											links.serviceDescriptionUrl,
											links.privacyPolicyUrl,
											links.dataProcessingAgreementUrl
										)}
									</Legal>
									<ManagedCheckbox managedField={fields.accepted}>
										{translate(
											'PRICING_UPDATE_FORCE_COMMAND_OPT_IN',
											links.brandUrl,
											links.brandUrl,
											links.brandUrl
										)}
									</ManagedCheckbox>
									<Button
										type="submit"
										action="confirm"
										disabled={!form.canBeSubmitted}
										size="xlarge"
										width="max"
									>
										{translate('PRICING_UPDATE_FORCE_COMMAND_DIALOG_BUTTON')}
									</Button>
								</>
							)}
						</ManagedForm>
					) : (
						<p>
							<strong>{translate('PRICING_UPDATE_FORCE_COMMAND_DIALOG_NOT_ADMIN')}</strong>
						</p>
					)}
				</div>
			</div>
		</div>
	);
};
