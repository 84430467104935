import React, { useRef, useState } from 'react';
import { NeoIcon } from '@web-apps/neo-icons';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import UserAvatar from '../components/UserAvatar';
import classes from './UserDropdown.module.scss';
import auth from '../utils/authenticate/auth';
import { AccountNumber } from '../navigation/logout/AccountNumber';
import DropOut, { DropOutDivider } from './DropOut';
import useKeyboardEvents from './hooks/useKeyboardEvents';
import useOutsideClick from './hooks/useOutsideClick';
import useLinks from '../redux/slices/links/hooks';
import { useTranslate } from '../redux/slices/translations';
import { useCurrentUser } from '../redux/modules/users';
import { useAccount } from '../redux/slices/account';
import { isNeoPBXCustomer, useUserInfo } from '../redux/slices/userinfo';
import { AccountTariff } from '../navigation/logout/AccountTariff';

const UserDropdown = () => {
	const ref = useRef<HTMLDivElement>(null);
	const currentUser = useCurrentUser();
	const translate = useTranslate();
	const { links } = useLinks();
	const accountData = useAccount();
	const [open, setOpen] = useState(false);
	const userInfo = useUserInfo();
	const isNeoPBX = isNeoPBXCustomer(userInfo);
	const { theme } = useTheme(isNeoPBX);
	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);
	const hasCustomLogo = !!accountData?.logoUrl;

	if (!currentUser) {
		return null;
	}

	return (
		<div id="userdropdown" className={classes.userDropDown} ref={ref}>
			<button
				type="button"
				className={classnames(theme, classes.userDropDownButton, {
					[classes.withLogo]: hasCustomLogo,
				})}
				onClick={() => setOpen(!open)}
				aria-controls="user-menu"
				aria-expanded={open}
				aria-haspopup="true"
				id="user-dropdown-button"
			>
				{hasCustomLogo && (
					<img
						src={accountData?.logoUrl}
						alt=""
						data-testid="custom_logo"
						className={classes.logo}
					/>
				)}

				<UserAvatar
					user={currentUser}
					size={hasCustomLogo && window.innerWidth >= 768 ? 'medium' : 'large'}
				/>
			</button>

			<DropOut id="user-menu" open={open} ariaLabelledBy="user-dropdown-button">
				<ul>
					<li>
						<div className={classes.currentUser}>
							<UserAvatar user={currentUser} size="large" editable />
							<div className={classes.currentUserRightContainer}>
								<div className={classes.customerName}>
									{currentUser.firstname} {currentUser.lastname}
								</div>
								<AccountNumber className={classes.secondaryText} />
								<AccountTariff className={classes.secondaryText} />
							</div>
						</div>
					</li>
					<li>
						<DropOutDivider />
					</li>
					<li>
						<NavLink to="/personal-settings" onClick={() => setOpen(false)}>
							{translate('PERSONAL_SETTINGS')}
						</NavLink>
					</li>
					<li>
						<DropOutDivider />
					</li>
					<li>
						<div className={classes.logout}>
							<button type="button" onClick={() => auth.logoutAndRedirectToLogoutPage(links)}>
								<NeoIcon name="Sign_out" variant="line" />
								{translate('LOGOUT')}
							</button>
						</div>
					</li>
				</ul>
			</DropOut>
		</div>
	);
};

export default UserDropdown;
