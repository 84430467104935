import { createSelector } from '@reduxjs/toolkit';
import { PersonalAccessTokenItem, PersonalAccessTokenState } from './index';
import {
	createItemSelectorAdapter,
	fetchItemEntityAdapter,
	personalAccessTokenEntityAdapter,
} from './adapter';

const selectItems = (state: PersonalAccessTokenState) => state.items;
const personalAccessTokenSelectors = personalAccessTokenEntityAdapter.getSelectors(selectItems);
export const { selectAll, selectIds, selectById } = personalAccessTokenSelectors;

const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();
export const { isSucceeded: isFetched, isPending: isFetching } = fetchItemEntitySelectors;

const createItemEntitySelectors = createItemSelectorAdapter.getSelectors();
export const {
	isSucceeded: isCreated,
	isPending: isCreating,
	hasFailed,
} = createItemEntitySelectors;

const createPersonalAccessTokenSelector = createSelector.withTypes<PersonalAccessTokenState>();

export const selectByTokenId = createPersonalAccessTokenSelector(
	[selectAll, (state: PersonalAccessTokenState, tokenId: string) => tokenId],
	(items: PersonalAccessTokenItem[], tokenId: string) =>
		items
			.flatMap(item => item.tokens.filter(i => i.tokenId === tokenId))
			.find(i => i.tokenId === tokenId)
);
