import React from 'react';
import moment from 'moment-timezone';

import { Button, DownloadLink } from '@panda/ui';
import { isMobileNumber, localizeNumber } from '@web-apps/phonenumbers-utils';
import classes from './FaxEvent.module.scss';
import eventClasses from './Event.module.scss';
import { Event } from './Event';
import { NormalizedEvent } from '../normalize/events';

import { Translate } from '../../../redux/slices/translations';
import { SystemLabel } from '../../../components/SystemLabel';
import {
	formatExtension,
	formatPages,
	getLocalizedEventSource,
	getLocalizedEventTarget,
} from '../util';
import { FaxHistoryEntry } from '../../../api/types/events';
import { SipgateDomain } from '../../../redux/slices/userinfo';

interface Props {
	domain: SipgateDomain;
	event: NormalizedEvent<FaxHistoryEntry>;
	translate: Translate;
	onRead: (eventIds: string[]) => void;
	onResend: (eventId: string) => void;
	onAnswerWithSms?: (number: string) => void;
	onNewContact: (number: string) => void;
	onAddNumberToContact?: (number: string) => void;
	onNoteChange: (eventId: string, note: string) => void;
	onSelection: (eventId: string) => void;
	onStar: (eventId: string, starred: boolean) => void;
}

export class FaxEvent extends React.PureComponent<Props> {
	private onNewContact = () => {
		if (this.props.event.them.number) {
			this.props.onNewContact(this.props.event.them.number);
		}
	};

	private onAddNumberToContact = () => {
		if (this.props.onAddNumberToContact && this.props.event.them.number) {
			this.props.onAddNumberToContact(this.props.event.them.number);
		}
	};

	private onResend = () => {
		this.props.onResend(this.props.event.originalEvent.id);
	};

	private getFaxFileName() {
		return `${this.props.event.date.toLocaleString()} - ${
			this.props.event.them.number ? this.props.event.them.number : 'unknown'
		}.pdf`;
	}

	private getReportFileName() {
		return `${this.props.event.date.toLocaleString()} - ${
			this.props.event.them.number ? this.props.event.them.number : 'unknown'
		}.report.pdf`;
	}

	private renderSystemLabels() {
		const tags = [];

		switch (this.props.event.originalEvent.faxStatus) {
			case 'FAILED':
				tags.push(
					<SystemLabel className={eventClasses.systemLabel} key="failed" intentness="strong">
						{this.props.translate('FAX_STATUS_FAILED')}
					</SystemLabel>
				);
				break;
			case 'PENDING':
				if (this.props.event.scheduledDate) {
					break;
				}

				tags.push(
					<SystemLabel className={eventClasses.systemLabel} key="pending" intentness="weak">
						{this.props.translate('FAX_STATUS_PENDING')}
					</SystemLabel>
				);
				break;
			case 'SENDING':
				tags.push(
					<SystemLabel className={eventClasses.systemLabel} key="sending" intentness="weak">
						{this.props.translate('FAX_STATUS_SENDING')}
					</SystemLabel>
				);
				break;
			case 'SENT':
				break;
			default:
				break;
		}

		if (this.props.event.scheduledDate) {
			const label = `${this.props.translate('FAX_STATUS_SCHEDULED')} ${moment(
				this.props.event.scheduledDate
			).format('L')}`;

			tags.push(
				<SystemLabel className={eventClasses.systemLabel} key="scheduled" intentness="weak">
					{label}
				</SystemLabel>
			);
		}

		for (const connection of this.props.event.connections) {
			if (connection.type === 'GROUP' && connection.name) {
				tags.push(
					<SystemLabel className={eventClasses.systemLabel} key={`group-${connection.id}`}>
						{connection.name}
					</SystemLabel>
				);
			}
		}

		return tags;
	}

	private renderFaxPreview() {
		return (
			this.props.event.originalEvent.previewUrl && (
				<div className={classes.preview}>
					<a
						href={this.props.event.originalEvent.documentUrl}
						download={this.getFaxFileName()}
						target="_blank"
						rel="noreferrer"
					>
						<img
							src={this.props.event.originalEvent.previewUrl}
							alt={this.props.translate('ALT_FAX_PREVIEW')}
						/>
					</a>
				</div>
			)
		);
	}

	private renderSmsButton() {
		const number = this.props.event.them.number;
		if (
			!this.props.event.incoming ||
			!this.props.onAnswerWithSms ||
			!number ||
			!isMobileNumber(number, this.props.domain)
		) {
			return null;
		}

		return (
			<Button
				onClick={() => {
					if (this.props.onAnswerWithSms) {
						this.props.onAnswerWithSms(number);
					}
				}}
			>
				{this.props.translate('ANSWER_WITH_SMS_BUTTON')}
			</Button>
		);
	}

	private renderActionButtons() {
		return (
			<div className={classes.actionButtons}>
				{this.renderDownloadFaxButton()}
				{this.renderDownloadReportButton()}
				{this.renderResendButton()}
				{this.renderSmsButton()}
				{this.renderContactButton()}
			</div>
		);
	}

	private renderDownloadFaxButton() {
		return (
			<DownloadLink
				button
				fileName={this.getFaxFileName()}
				url={this.props.event.originalEvent.documentUrl}
			>
				{this.props.translate('FAX_EVENT_DOWNLOAD')}
			</DownloadLink>
		);
	}

	private renderDownloadReportButton() {
		if (this.props.event.originalEvent.reportUrl) {
			return (
				<DownloadLink
					button
					fileName={this.getReportFileName()}
					url={this.props.event.originalEvent.reportUrl}
				>
					{this.props.translate('FAX_EVENT_DOWNLOAD_REPORT')}
				</DownloadLink>
			);
		}

		return null;
	}

	private renderContactButton() {
		if (
			('contact' in this.props.event.them && this.props.event.them.contact) ||
			!this.props.event.them.number
		) {
			return null;
		}

		if (this.props.onAddNumberToContact) {
			return (
				<>
					<Button onClick={this.onNewContact}>
						{this.props.translate('SAVE_NUMBER_TO_CONTACT')}
					</Button>

					<Button onClick={this.onAddNumberToContact}>
						{this.props.translate('ADD_TO_CONTACT')}
					</Button>
				</>
			);
		}
	}

	private renderResendButton() {
		if (!this.props.event.incoming && this.props.event.originalEvent.faxStatus === 'FAILED') {
			return <Button onClick={this.onResend}>{this.props.translate('FAX_EVENT_RESEND')}</Button>;
		}

		return null;
	}

	private renderScheduledDetailHeadline() {
		const formattedScheduledDate = moment(this.props.event.scheduledDate).calendar();
		const lowercasedFormattedDate =
			formattedScheduledDate[0].toLocaleLowerCase() + formattedScheduledDate.slice(1);

		return (
			<p>
				<span className={classes.bold}>{this.props.translate('SCHEDULED_FAX')}</span>{' '}
				{this.props.translate('SCHEDULED_FOR')} {lowercasedFormattedDate}
			</p>
		);
	}

	private renderDetailHeadline() {
		const direction = this.props.event.incoming
			? this.props.translate('INCOMING_FAX')
			: this.props.translate('OUTGOING_FAX');

		const formattedDate = moment(this.props.event.originalEvent.dateCreated).calendar();
		const lowercasedFormattedDate = formattedDate[0].toLocaleLowerCase() + formattedDate.slice(1);

		if (this.props.event.originalEvent.pageCount > 0) {
			return (
				<p>
					<span className={classes.bold}>{direction}</span> {lowercasedFormattedDate},{' '}
					{formatPages(this.props.event.originalEvent.pageCount, this.props.translate)}
				</p>
			);
		}

		return (
			<p>
				<span className={classes.bold}>{direction}</span> {lowercasedFormattedDate}
			</p>
		);
	}

	private renderIncomingSourceLine() {
		if ('contact' in this.props.event.them) {
			return (
				<>
					{this.props.translate('FAX_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.them.contact.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		if (this.props.event.source.name && this.props.event.them.number) {
			return (
				<>
					{this.props.translate('FAX_FROM')}{' '}
					<span className={classes.bold}>{this.props.event.source.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		return (
			<>
				{this.props.translate('FAX_FROM')}{' '}
				<span className={classes.bold}>
					{getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}{' '}
				</span>
			</>
		);
	}

	private renderOutgoingTargetLine() {
		if ('contact' in this.props.event.them) {
			return (
				<>
					{this.props.translate('FAX_TO')}{' '}
					<span className={classes.bold}>{this.props.event.them.contact.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		if (this.props.event.target.name && this.props.event.them.number) {
			return (
				<>
					{this.props.translate('FAX_TO')}{' '}
					<span className={classes.bold}>{this.props.event.target.name}</span> (
					{localizeNumber(this.props.event.them.number, this.props.domain)})
				</>
			);
		}

		return (
			<>
				{this.props.translate('FAX_TO')}{' '}
				<span className={classes.bold}>
					{getLocalizedEventTarget(this.props.event, this.props.domain, this.props.translate)}{' '}
				</span>
			</>
		);
	}

	private renderIncomingDetail() {
		const endpoint = this.props.event.endpoints[0];
		const extension = formatExtension(this.props.translate, endpoint.type, endpoint.name);
		const number = this.props.event.target.number
			? `(${localizeNumber(this.props.event.target.number, this.props.domain)})`
			: null;

		return (
			<p>
				{this.renderIncomingSourceLine()}
				<br />
				{this.props.translate('FAX_ON')} <span className={classes.bold}>{extension}</span> {number}
			</p>
		);
	}

	private renderOutgoingDetail() {
		return (
			<p>
				{this.props.translate('FAX_FROM')}{' '}
				<span className={classes.bold}>{this.props.translate('EVENTLIST_ME')}</span> <br />
				{this.renderOutgoingTargetLine()}
			</p>
		);
	}

	private renderInlineText() {
		return this.props.event.originalEvent.note ? this.props.event.originalEvent.note : '';
	}

	public render() {
		return (
			<Event
				eventId={this.props.event.originalEvent.id}
				date={this.props.event.date}
				eventType="FAX"
				incoming={this.props.event.incoming}
				note={this.props.event.originalEvent.note || undefined}
				onNoteChange={this.props.onNoteChange}
				read={this.props.event.originalEvent.read === 'READ'}
				onRead={this.props.onRead}
				length={formatPages(this.props.event.originalEvent.pageCount, this.props.translate)}
				source={getLocalizedEventSource(this.props.event, this.props.domain, this.props.translate)}
				target={getLocalizedEventTarget(this.props.event, this.props.domain, this.props.translate)}
				systemLabels={this.renderSystemLabels()}
				translate={this.props.translate}
				selected={this.props.event.selected}
				onSelection={this.props.onSelection}
				onStar={this.props.onStar}
				starred={this.props.event.originalEvent.starred === 'STARRED'}
				labels={this.props.event.originalEvent.labels}
				inlineText={this.renderInlineText()}
			>
				{this.props.event.scheduledDate
					? this.renderScheduledDetailHeadline()
					: this.renderDetailHeadline()}
				{this.props.event.incoming ? this.renderIncomingDetail() : this.renderOutgoingDetail()}
				{this.renderFaxPreview()}
				{this.renderActionButtons()}
			</Event>
		);
	}
}
