import React from 'react';
import classnames from 'classnames';
import { usePandaContext } from '../../contexts/pandaContext';
import { useDisabled } from '../../contexts/disabledContext';
import { SortArrowIcon } from './SortArrowIcon';
import { SortableArrowsIcon } from './SortableArrowsIcon';

type Props<Columns extends string> = {
	columns: { id: Columns; label: string; sortable: boolean; hidden: boolean }[];
	sortBy: (column: Columns, direction: 'ascending' | 'descending') => void;
	sortedBy: { column: Columns; direction: 'ascending' | 'descending' };
};

const styles = {
	columnHeader: classnames(
		'h-24',
		'px-16',
		'py-0',
		'[&:nth-child(2)]:pl-8',
		'align-bottom',
		'text-left'
	),
	columnSorter: classnames(
		'appearance-none',
		'flex',
		'items-end',
		'group',
		'gap-x-4',
		'p-0',
		'm-0',
		'border-0',
		'bg-neo-color-global-background-static-transparent',
		'font-meta',
		'font-light',
		'text-base/16',
		'text-neo-color-global-content-neutral-moderate',
		'outline-none',
		'hover:text-neo-color-global-content-neutral-intense',
		'focus-visible:ring-focus',
		'active:text-neo-color-global-content-neutral-moderate',
		'cursor-pointer',
		'duration-150',
		'ease-in-out',
		'transition-colors',
		'whitespace-nowrap',
		'rounded-sm',
		'disabled:text-neo-color-global-content-neutral-disabled',
		'disabled:cursor-not-allowed'
	),
	columnLabel: classnames(
		'group',
		'p-0',
		'm-0',
		'font-meta',
		'font-light',
		'text-base/16',
		'text-neo-color-global-content-neutral-moderate',
		'cursor-default',
		'align-bottom',
		'whitespace-nowrap'
	),
	currentSortOrderIcon: (showIcon: boolean, sortDirection: 'ascending' | 'descending') =>
		classnames(
			'mr-8',
			'transform',
			'group-hover:text-neo-color-global-content-neutral-intense',
			'group-active:text-neo-color-global-content-neutral-moderate',
			'duration-150',
			'ease-in-out',
			'transition',
			'group-disabled:text-neo-color-global-content-neutral-disabled',
			showIcon ? 'text-neo-color-global-content-neutral-intense' : 'rotate-0 hidden',
			sortDirection === 'descending' ? 'rotate-180' : 'group-hover:rotate-0'
		),
	possibleSortOrderIcon: (showSortableIcon: boolean) =>
		classnames(
			'transform',
			'group-hover:text-neo-color-global-content-neutral-intense',
			'group-active:text-neo-color-global-content-neutral-moderate',
			'duration-150',
			'ease-in-out',
			'transition',
			'group-disabled:text-neo-color-global-content-neutral-disabled',
			showSortableIcon ? 'text-neo-color-global-content-neutral-moderate' : 'hidden'
		),
	endShadow: classnames(
		'p-0',
		'sticky',
		'right-0',
		'bg-gradient-to-r',
		'from-80%',
		'from-neo-color-global-background-static-transparent',
		// Stacking contexts be weird (otherwise sort arrows overlap fadeout)
		'z-10',
		'to-neo-color-web-app-surface-app',
		'min-w-[calc(6.5rem+0.125rem)]'
	),
	startShadow: classnames(
		'p-0',
		'sticky',
		'left-0',
		'bg-gradient-to-r',
		'from-neo-color-web-app-surface-app',
		// Stacking contexts be weird (otherwise sort arrows overlap fadeout)
		'z-10',
		'to-neo-color-global-background-static-transparent',
		'min-w-[calc(1rem+0.125rem)]'
	),
};

export const ListHeader = <Columns extends string>({
	columns,
	sortBy,
	sortedBy,
}: Props<Columns>) => {
	const disabled = useDisabled();
	const { languageKeys } = usePandaContext();

	const onSort = (key: Columns) => () => {
		if (key === sortedBy.column) {
			sortBy(key, sortedBy.direction === 'ascending' ? 'descending' : 'ascending');
		} else {
			sortBy(key, 'ascending');
		}
	};

	return (
		<thead>
			<tr>
				<th aria-hidden className={styles.startShadow} />

				{columns
					.filter(c => !c.hidden)
					.map(({ id, label, sortable }) => (
						<th
							scope="col"
							key={id}
							className={styles.columnHeader}
							role="columnheader"
							aria-sort={id === sortedBy.column ? sortedBy.direction : 'none'}
						>
							{sortable ? (
								<button
									title={`${languageKeys.PANDA_LISTNAV_SORT_BY} ${label}`}
									className={styles.columnSorter}
									onClick={onSort(id)}
									aria-label={`${languageKeys.PANDA_LISTNAV_SORT_BY} ${label}`}
									disabled={disabled}
									type="button"
								>
									<div>{label}</div>

									<SortArrowIcon
										className={styles.currentSortOrderIcon(
											sortedBy.column === id,
											sortedBy.direction || 'ascending'
										)}
									/>

									<SortableArrowsIcon
										className={styles.possibleSortOrderIcon(sortedBy.column !== id)}
									/>
								</button>
							) : (
								<div className={styles.columnLabel}>{label}</div>
							)}
						</th>
					))}
				<th aria-label={`${languageKeys.PANDA_LIST_VIEW_ACTIONS}`} className={styles.endShadow} />
			</tr>
		</thead>
	);
};
