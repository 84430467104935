import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	addFaxLineOwners,
	createFaxLine,
	deleteFaxLine,
	fetchFaxLines,
	removeFaxLineOwners,
	setFaxLineAlias,
} from './actions';
import { SliceData, useAction } from '../../index';
import { Faxline } from './types';
import { faxLinesFetched, selectLoadedFaxLines } from './selectors';

export const useFaxLines = (): SliceData<Faxline[]> => {
	const dispatch = useDispatch();

	const faxlines = useSelector(s => s.faxlines);

	useEffect(() => {
		dispatch(fetchFaxLines());
	}, [dispatch]);

	if (!faxLinesFetched(faxlines)) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: selectLoadedFaxLines(faxlines),
		fetched: true,
	};
};

export const useFaxLine = (faxLineId: string): SliceData<Faxline> => {
	const { data: faxLines, fetched } = useFaxLines();

	if (!fetched && !faxLines) {
		return {
			data: null,
			fetched: false,
		};
	}

	const faxLine = faxLines.find(item => item.id === faxLineId);

	if (!faxLine) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: faxLine,
		fetched: true,
	};
};

export const useFaxLinesActions = () => ({
	setFaxLineAlias: useAction(setFaxLineAlias),
	deleteFaxLine: useAction(deleteFaxLine),
	createFaxLine: useAction(createFaxLine),
	addFaxLineOwners: useAction(addFaxLineOwners),
	removeFaxLineOwners: useAction(removeFaxLineOwners),
});
