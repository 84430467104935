import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { ReduxState } from '../../types';
import { numbersRestApiClient as numbersApi } from './api';

const shouldFetchNumbers = (state: ReduxState) => {
	return !state.numbers.fetching && !state.numbers.fetched;
};

export const fetchAccessibleNumbers = createAsyncThunk(
	'numbers/fetch',
	async (_force?: boolean) =>
		Promise.all([numbersApi.getAllNumbers(), api.getAcds().catch(() => ({ items: [] }))]),
	{
		condition: (forceFetch = false, { getState }) => forceFetch || shouldFetchNumbers(getState()),
	}
);
