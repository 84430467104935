import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconRocket = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2 2h3.76c1.793 0 3.51.708 4.775 1.975L13.561 7h4.36c.73 0 1.43.29 1.945.805l1.805 1.805a1.25 1.25 0 0 1-.555 2.09l-4.669 1.274-.394-1.448 4.253-1.16-1.5-1.5a1.25 1.25 0 0 0-.884-.366h-4.983L9.475 5.035A5.25 5.25 0 0 0 5.76 3.5H3.5v2.265c0 1.391.55 2.726 1.536 3.715L8.5 12.944v4.983c0 .331.132.649.366.884l1.5 1.5 1.16-4.253 1.448.394-1.274 4.67a1.25 1.25 0 0 1-2.09.554l-1.805-1.805A2.75 2.75 0 0 1 7 17.927v-4.361L3.975 10.54h-.002A6.77 6.77 0 0 1 2 5.764zm15.72 16.78-6-6 1.06-1.06 6 6z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconRocket;
