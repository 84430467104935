import React from 'react';
import { Headline } from '@panda/ui';
import classes from './Filter.module.scss';

interface Props {
	title: string;
	children: React.ReactNode;
}

export class Filter extends React.Component<Props> {
	public render() {
		return (
			<>
				<Headline className={classes.heading}>{this.props.title}</Headline>
				<div className={classes.filter}>{this.props.children}</div>
			</>
		);
	}
}
