import { Acd } from './types';

export function selectAcd(acds: Acd[], acdId: string) {
	return acds.find(acd => acd.id === acdId);
}

export function selectAcdsByAgentId(acds: Acd[], agentId: string) {
	return acds.filter(acd => acd.agents.some(agent => agent.id === agentId));
}

export function selectPersonalChannel(channels: Acd[], userId: string) {
	return channels.find(channel => channel.owner === userId);
}

export function selectPersonalChannels(channels: Acd[] | null) {
	if (channels === null) {
		return [];
	}
	return channels.filter(channel => !!channel.owner);
}

export function selectMultiUserChannels(channels: Acd[] | null) {
	if (channels === null) {
		return [];
	}
	return channels.filter(channel => !channel.owner);
}

export function selectChannelsByMemberIdAndTeamLeadId(channels: Acd[], memberId: string) {
	return channels.filter(
		acd =>
			acd.teamLeadIds.some(teamlead => teamlead === memberId) ||
			acd.agents.some(agent => agent.id === memberId)
	);
}

export function selectAcdsByAgentIdAndTeamLeadId(acds: Acd[], agentId: string) {
	return acds.filter(
		acd =>
			acd.teamLeadIds.some(teamlead => teamlead === agentId) ||
			acd.agents.some(agent => agent.id === agentId)
	);
}
