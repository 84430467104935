import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconDevice_pool = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M3.5 5.75c0-.69.56-1.25 1.25-1.25h14.5c.69 0 1.25.56 1.25 1.25V9h-1v4h1v3.25c0 .69-.56 1.25-1.25 1.25h-.75V19h.75A2.75 2.75 0 0 0 22 16.25V5.75A2.75 2.75 0 0 0 19.25 3H4.75A2.75 2.75 0 0 0 2 5.75v.75h1.5zm0 3.75v10H8v-10zM2 9.25C2 8.56 2.56 8 3.25 8h5c.69 0 1.25.56 1.25 1.25v10.5c0 .69-.56 1.25-1.25 1.25h-5C2.56 21 2 20.44 2 19.75zm10.5 7.25v-3H16v3zm-.5 1.475c-.57-.116-1-.62-1-1.225v-3.5c0-.605.43-1.11 1-1.225V10.25c0-.69.56-1.25 1.25-1.25h2c.69 0 1.25.56 1.25 1.25v1.775c.57.116 1 .62 1 1.225v3.5c0 .605-.43 1.11-1 1.225v1.775c0 .69-.56 1.25-1.25 1.25h-2c-.69 0-1.25-.56-1.25-1.25zm1.5.025v1.5H15V18zm1.5-6h-1.5v-1.5H15z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconDevice_pool;
