import serviceUrls from '@web-apps/service-urls';
import { ApiDataUsageItem } from './types';
import { RestApiClient } from '../../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../../api/client/HttpClient';
import { ListResponse } from '../../../../../api/SipgateApi';

interface DataUsageRestApi {
	getDataUsage(
		containerId: string,
		dateStart: string,
		dateStop: string,
		intervalMinutes: string
	): Promise<ListResponse<ApiDataUsageItem>>;
}

class DataUsageRestApiClient extends RestApiClient implements DataUsageRestApi {
	public getDataUsage = (
		containerId: string,
		dateStart: string,
		dateStop: string,
		intervalMinutes: string
	): Promise<ListResponse<ApiDataUsageItem>> =>
		this.get(
			'/v3',
			`/devices/${containerId}/contingents/usage?dateStart=${dateStart}&dateStop=${dateStop}&intervalMinutes=${intervalMinutes}`
		);
}

export const dataUsageRestApiClient = new DataUsageRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
