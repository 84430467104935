import { createSlice } from '@reduxjs/toolkit';
import { VoicemailNotificationState } from './types';
import {
	disableVoicemailNotification,
	enableVoicemailNotification,
	fetchVoicemailNotification,
} from './actions';

const initialState: VoicemailNotificationState = {
	fetched: [],
	fetching: [],
	channelsWithEnabledNotifications: [],
};

export const reducer = createSlice({
	name: 'voicemail-notifications',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchVoicemailNotification.pending, (state, { meta }) => {
			state.fetching.push(meta.arg.channel);
		});
		builder.addCase(fetchVoicemailNotification.fulfilled, (state, { meta, payload }) => {
			const shouldAddNotification = payload.isActive;
			return {
				...state,
				fetched: [...state.fetched, meta.arg.channel],
				fetching: state.fetching.filter(channel => channel !== meta.arg.channel),
				channelsWithEnabledNotifications: shouldAddNotification
					? [...state.channelsWithEnabledNotifications, meta.arg.channel]
					: state.channelsWithEnabledNotifications,
			};
		});
		builder.addCase(enableVoicemailNotification.pending, (state, { meta }) => {
			state.fetching.push(meta.arg.channel);
			state.channelsWithEnabledNotifications.push(meta.arg.channel);
		});
		builder.addCase(enableVoicemailNotification.fulfilled, (state, { meta }) => {
			return {
				...state,
				fetching: state.fetching.filter(channel => channel !== meta.arg.channel),
				fetched: [...state.fetched, meta.arg.channel],
			};
		});
		builder.addCase(disableVoicemailNotification.pending, (state, { meta }) => {
			return {
				...state,
				fetching: [...state.fetching, meta.arg.channel],
				channelsWithEnabledNotifications: state.channelsWithEnabledNotifications.filter(
					channel => channel !== meta.arg.channel
				),
			};
		});
		builder.addCase(disableVoicemailNotification.fulfilled, (state, { meta }) => {
			return {
				...state,
				fetching: state.fetching.filter(channel => channel !== meta.arg.channel),
			};
		});
	},
}).reducer;
