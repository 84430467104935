import React from 'react';
import classnames from 'classnames';

import classes from './DropdownMenuItem.module.scss';
import { SmartLink } from '../SmartLink';

interface BaseProps {
	selected?: boolean;
	className?: string;
	separateAfter?: boolean;
	separateBefore?: boolean;
	children: React.ReactNode;
}

type Props = BaseProps &
	(
		| { type: 'button'; onClick: (e: React.MouseEvent) => void }
		| { type: 'link'; onClick?: (e: React.MouseEvent) => void; href: string; newTab?: boolean }
	);

export class DropdownMenuItem extends React.PureComponent<Props> {
	public onLinkKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
		if (e.key === ' ') {
			e.currentTarget.click();
		}
	};

	public render() {
		return (
			<li
				role="none"
				className={classnames(classes.menuItem, this.props.className, {
					[classes.selected]: this.props.selected,
					[classes.separateAfter]: this.props.separateAfter,
					[classes.separateBefore]: this.props.separateBefore,
				})}
			>
				{this.props.type === 'button' ? (
					<button
						role="menuitem"
						tabIndex={-1}
						onClick={this.props.onClick}
						className={classes.content}
						type="button"
					>
						{this.props.children}
					</button>
				) : (
					<SmartLink
						role="menuitem"
						tabIndex={-1}
						to={this.props.href}
						onClick={this.props.onClick}
						onKeyDown={this.onLinkKeyDown}
						target={this.props.newTab ? '_blank' : undefined}
						className={classes.content}
					>
						{this.props.children}
					</SmartLink>
				)}
			</li>
		);
	}
}
