import { LayoutContext } from '@panda/ui';
import classnames from 'classnames';
import React, { Component } from 'react';
import classes from './PandaOneColumnLayout.module.scss';

interface Props {
	fullWidth?: boolean;
	children: React.ReactNode;
}

export class PandaOneColumnLayout extends Component<Props> {
	public render() {
		return (
			<LayoutContext.Provider value={{ position: 'page' }}>
				<div
					className={classnames(classes.container, {
						[classes.fullWidth]: this.props.fullWidth,
					})}
				>
					{this.props.children}
				</div>
			</LayoutContext.Provider>
		);
	}
}
