import React from 'react';
import classes from './UserLabel.module.scss';

interface Props {
	children: string;
}

export class UserLabel extends React.PureComponent<Props> {
	public render() {
		return <span className={classes.userLabel}>{this.props.children}</span>;
	}
}
