import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconTimetable = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<g clipPath="url(#a)">
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="M6.5 4.5V6H8V4.5h8V6h1.5V4.5h.75c.69 0 1.25.56 1.25 1.25V7.5H21V5.75A2.75 2.75 0 0 0 18.25 3h-.75V2H16v1H8V2H6.5v1h-.75A2.75 2.75 0 0 0 3 5.75v12.5A2.75 2.75 0 0 0 5.75 21H9v-1.5H5.75c-.69 0-1.25-.56-1.25-1.25V9H11V7.5H4.5V5.75c0-.69.56-1.25 1.25-1.25zM11 15.25a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0m5.25-6.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5m.75 3v3.44l1.78 1.78-1.06 1.06-2.22-2.22V11.5z"
					clipRule="evenodd"
				/>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="white" d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>,
		elementProps
	);
};
export default LineNeoIconTimetable;
