import { createSlice, isAnyOf, UnknownAction } from '@reduxjs/toolkit';
import { Faxline, FaxlineState } from './types';
import {
	addFaxLineOwners,
	createFaxLine,
	deleteFaxLine,
	fetchFaxLines,
	removeFaxLineOwners,
	setFaxLineAlias,
	setFaxLineCallerId,
	setFaxLineTagLine,
} from './actions';
import { handleActions } from '../../utils/actions';
import { setGroupAlias } from '../../modules/groups';
import { isApiFaxLineNeo } from './api';
import { StatusItem } from '../../utils/statusEntityAdapter';
import { faxLineEntityAdapter, fetchItemEntityAdapter, updateItemEntityAdapter } from './adapter';

export const getInitialState = (
	faxLine?: Faxline[],
	fetchItems?: StatusItem[],
	updateItems?: StatusItem[]
) => ({
	items: faxLineEntityAdapter.getInitialState({}, faxLine),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetchItems),
	update: updateItemEntityAdapter.getInitialState({}, updateItems),
});

const slice = createSlice({
	name: 'faxlines',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchFaxLines.pending, state => {
			fetchItemEntityAdapter.addOne(state.fetch, {
				id: '*',
				status: 'pending',
			});
		});

		builder.addCase(fetchFaxLines.rejected, (state, { payload: error }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: '*',
				status: 'failed',
				error,
			});
		});

		builder.addCase(fetchFaxLines.fulfilled, (state, { payload: faxLines }) => {
			const items = faxLines.reduce((faxlines, faxLine) => {
				const faxlineIndex = faxlines.findIndex(f => f.id === faxLine.id);
				if (faxlineIndex > -1) {
					const faxLineItem = faxlines[faxlineIndex];

					return [
						...faxlines.slice(0, faxlineIndex),
						{
							...faxLineItem,
							ownerIds: [...faxLineItem.ownerIds, faxLine.ownerId],
						} as Faxline,
					];
				}

				const { ownerId, ...faxLineWithoutOwner } = faxLine;
				// don't add undefined or null to ownerIds
				if (ownerId !== undefined && ownerId !== null) {
					return [...faxlines, { ...faxLineWithoutOwner, ownerIds: [ownerId] } as Faxline];
				}

				return [...faxlines, { ...faxLineWithoutOwner, ownerIds: [] } as Faxline];
			}, [] as Faxline[]);

			faxLineEntityAdapter.upsertMany(state.items, items);

			items.forEach(({ id }) =>
				fetchItemEntityAdapter.upsertOne(state.fetch, {
					id,
					status: 'succeeded',
				})
			);

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: '*',
				status: 'succeeded',
			});
		});

		builder.addCase(setFaxLineAlias.pending, (state, { meta }) => {
			const oldFaxLine = faxLineEntityAdapter
				.getSelectors()
				.selectById(state.items, meta.arg.faxLineId);

			if (oldFaxLine) {
				faxLineEntityAdapter.upsertOne(state.items, { ...oldFaxLine, alias: meta.arg.alias });
				updateItemEntityAdapter.upsertOne(state.update, {
					id: meta.arg.faxLineId,
					status: 'pending',
				});
			}
		});

		builder.addCase(setFaxLineTagLine.pending, (state, { meta }) => {
			const oldFaxLine = faxLineEntityAdapter
				.getSelectors()
				.selectById(state.items, meta.arg.faxLineId);

			if (oldFaxLine) {
				faxLineEntityAdapter.upsertOne(state.items, { ...oldFaxLine, tagline: meta.arg.tagline });
				updateItemEntityAdapter.upsertOne(state.update, {
					id: meta.arg.faxLineId,
					status: 'pending',
				});
			}
		});

		builder.addCase(createFaxLine.fulfilled, (state, { payload: faxLine }) => {
			if (isApiFaxLineNeo(faxLine)) {
				faxLineEntityAdapter.upsertOne(state.items, {
					...faxLine,
					tagline: '',
					callerId: 'anonymous',
					canReceive: true,
				});
				fetchItemEntityAdapter.upsertOne(state.fetch, {
					id: faxLine.id,
					status: 'succeeded',
				});
				return;
			}

			const { ownerId, ...faxLineWithoutOwnerId } = faxLine;
			faxLineEntityAdapter.upsertOne(state.items, {
				...faxLineWithoutOwnerId,
				ownerIds: [ownerId],
				tagline: '',
				callerId: 'anonymous',
				canReceive: true,
			});
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: faxLine.id,
				status: 'succeeded',
			});
		});

		builder.addCase(deleteFaxLine.pending, (state, { meta }) => {
			faxLineEntityAdapter.removeOne(state.items, meta.arg.faxLineId);
			fetchItemEntityAdapter.removeOne(state.fetch, meta.arg.faxLineId);
			updateItemEntityAdapter.removeOne(state.update, meta.arg.faxLineId);
		});

		builder.addCase(setFaxLineCallerId.pending, (state, { meta }) => {
			const oldFaxLine = faxLineEntityAdapter
				.getSelectors()
				.selectById(state.items, meta.arg.faxLineId);

			if (oldFaxLine) {
				faxLineEntityAdapter.upsertOne(state.items, {
					...oldFaxLine,
					callerId: meta.arg.anonymous ? 'anonymous' : meta.arg.callerId,
				});
				updateItemEntityAdapter.upsertOne(state.update, {
					id: meta.arg.faxLineId,
					status: 'pending',
				});
			}
		});

		builder.addCase(addFaxLineOwners.pending, (state, { meta }) => {
			const oldFaxLine = faxLineEntityAdapter
				.getSelectors()
				.selectById(state.items, meta.arg.faxLineId);

			if (oldFaxLine) {
				faxLineEntityAdapter.upsertOne(state.items, {
					...oldFaxLine,
					ownerIds: Array.from(new Set([...oldFaxLine.ownerIds, ...meta.arg.ownerIds])),
				});
				updateItemEntityAdapter.upsertOne(state.update, {
					id: meta.arg.faxLineId,
					status: 'pending',
				});
			}
		});

		builder.addCase(removeFaxLineOwners.pending, (state, { meta }) => {
			const oldFaxLine = faxLineEntityAdapter
				.getSelectors()
				.selectById(state.items, meta.arg.faxLineId);

			if (oldFaxLine) {
				faxLineEntityAdapter.upsertOne(state.items, {
					...oldFaxLine,
					ownerIds: Array.from(
						new Set(oldFaxLine.ownerIds.filter(ownerId => !meta.arg.ownerIds.includes(ownerId)))
					),
				});
				updateItemEntityAdapter.upsertOne(state.update, {
					id: meta.arg.faxLineId,
					status: 'pending',
				});
			}
		});

		builder.addMatcher(
			isAnyOf(
				addFaxLineOwners.fulfilled,
				removeFaxLineOwners.fulfilled,
				setFaxLineAlias.fulfilled,
				setFaxLineCallerId.fulfilled
			),
			(state, { meta }) => {
				updateItemEntityAdapter.upsertOne(state.update, {
					id: meta.arg.faxLineId,
					status: 'succeeded',
				});
			}
		);

		builder.addMatcher(
			isAnyOf(
				setFaxLineAlias.rejected,
				addFaxLineOwners.rejected,
				removeFaxLineOwners.rejected,
				setFaxLineCallerId.rejected
			),
			(state, { payload: error, meta }) => {
				updateItemEntityAdapter.upsertOne(state.update, {
					id: meta.arg.faxLineId,
					status: 'failed',
					error,
				});
			}
		);
	},
});

const legacyReducer = handleActions<FaxlineState, PossibleActions<typeof setGroupAlias>>(
	{
		GROUP_ALIAS_SET_SUCCESS: (state, { data }) => {
			const entities = {} as Record<string, Faxline>;

			for (const faxline of Object.values(state.items.entities)) {
				if (faxline.ownerIds.includes(data.groupId)) {
					entities[faxline.id] = { ...faxline, alias: data.alias } as Faxline;
				} else {
					entities[faxline.id] = { ...faxline } as Faxline;
				}
			}

			return {
				...state,
				items: {
					...state.items,
					entities,
				},
			};
		},
	},
	getInitialState()
);

export const reducer = (state: FaxlineState, action: UnknownAction) => {
	return slice.reducer(legacyReducer(state, action), action);
};
