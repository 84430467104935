import serviceUrls from '@web-apps/service-urls';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';
import { ApiDeviceProperty } from './types';

interface DevicePropertiesRestApi {
	getDeviceProperties(deviceId: string): Promise<{ properties: ApiDeviceProperty[] }>;

	getDeviceProperty(deviceId: string, key: string): Promise<ApiDeviceProperty>;

	setDeviceProperty(property: ApiDeviceProperty): Promise<void>;
}

class DevicePropertiesRestApiClient extends RestApiClient implements DevicePropertiesRestApi {
	public getDeviceProperties = (deviceId: string): Promise<{ properties: ApiDeviceProperty[] }> =>
		this.get('/v3', `/devices/${deviceId}/properties`);

	public getDeviceProperty = (deviceId: string, key: string): Promise<ApiDeviceProperty> =>
		this.get('/v3', `/devices/${deviceId}/properties/${key}`);

	public setDeviceProperty = (property: ApiDeviceProperty): Promise<void> =>
		this.put('/v3', `/devices/${property.deviceId}/properties`, {
			key: property.key,
			value: property.value,
		});
}

export const devicePropertiesRestApiClient = new DevicePropertiesRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
