import { createSlice } from '@reduxjs/toolkit';
import { DataUsageSettingsItem } from './types';
import { dataUsageSettingsAdapter, fetchItemAdapter } from './adapters';
import { StatusItem } from '../../../utils/statusEntityAdapter';
import { fetchDataUsageSettings } from './actions';

export const getInitialState = (items?: DataUsageSettingsItem[], fetch?: StatusItem[]) => ({
	items: dataUsageSettingsAdapter.getInitialState({}, items),
	fetch: fetchItemAdapter.getInitialState({}, fetch),
});

export const slice = createSlice({
	name: 'dataUsageSettings',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDataUsageSettings.pending, (state, { meta }) => {
			fetchItemAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'pending',
			});
		});

		builder.addCase(
			fetchDataUsageSettings.fulfilled,
			(state, { meta, payload: dataUsageSettingItem }) => {
				dataUsageSettingsAdapter.upsertOne(state.items, dataUsageSettingItem);
				fetchItemAdapter.upsertOne(state.fetch, {
					id: meta.arg.containerId,
					status: 'succeeded',
				});
			}
		);
	},
});

export const reducer = slice.reducer;
