import React from 'react';
import { MarkdownProvider } from '../../helpers/Markdown';

import classes from './Legal.module.scss';

export const Legal = (props: { children: React.ReactNode }) => (
	<MarkdownProvider quietLinks>
		<div className={classes.legal}>{props.children}</div>
	</MarkdownProvider>
);
