import { createSlice } from '@reduxjs/toolkit';
import {
	isTwoFactorAuthenticationSettingsError,
	TwoFactorAuthenticationSettingsState,
} from './types';
import { fetchTwoFactorAuthenticationSetting } from './actions';

const initialState: TwoFactorAuthenticationSettingsState = {
	items: [],
	fetching: [],
};

const slice = createSlice({
	name: 'twoFactorAuthenticationSettings',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchTwoFactorAuthenticationSetting.pending, (state, { meta }) => ({
			...state,
			fetching: [...state.fetching, meta.arg],
		}));

		builder.addCase(fetchTwoFactorAuthenticationSetting.fulfilled, (state, { payload, meta }) => {
			const newFetching = state.fetching.filter(webuserId => !webuserId.includes(meta.arg));
			const newItems = state.items.filter(item => !item.webuserId.includes(meta.arg));
			return {
				...state,
				items: [...newItems, payload],
				fetching: newFetching,
			};
		});

		builder.addCase(fetchTwoFactorAuthenticationSetting.rejected, (state, { payload: error }) => {
			if (
				isTwoFactorAuthenticationSettingsError(error) &&
				error.name === 'COULD_NOT_FETCH_TWO_FACTOR_AUTHENTICATION_SETTINGS'
			) {
				return {
					...state,
				};
			}
			return {
				...state,
				items: [],
				fetching: [],
			};
		});
	},
});

export const reducer = slice.reducer;
