import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconArrowRight = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M20.675 11.115 14.53 4.97l-1.06 1.06 5.22 5.22H3v1.5h15.69l-5.22 5.22 1.06 1.06 6.145-6.145a1.26 1.26 0 0 0 0-1.77"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconArrowRight;
