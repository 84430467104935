import serviceUrls from '@web-apps/service-urls';
import {
	ApiUserNotification,
	CreateUserNotificationRequest,
	ReplaceUserNotificationRequest,
} from './types';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';

interface UserNotificationsRestApi {
	getUserNotifications(): Promise<ApiUserNotification[]>;

	createUserNotification(
		createUserNotificationRequest: CreateUserNotificationRequest
	): Promise<void>;

	replaceUserNotification(
		replaceUserNotificationRequest: ReplaceUserNotificationRequest
	): Promise<void>;

	deleteUserNotification(notificationId: string): Promise<void>;
}

class UserNotificationsRestApiClient extends RestApiClient implements UserNotificationsRestApi {
	public getUserNotifications = (): Promise<ApiUserNotification[]> =>
		this.get('/v3', '/user-notifications');

	public createUserNotification = (createUserNotificationRequest: CreateUserNotificationRequest) =>
		this.post('/v3', `/user-notifications`, createUserNotificationRequest);

	public replaceUserNotification = (
		replaceUserNotificationRequest: ReplaceUserNotificationRequest
	) => this.put('/v3', `/user-notifications`, replaceUserNotificationRequest);

	public deleteUserNotification = (notificationId: string) =>
		this.delete('/v3', `/user-notifications/${notificationId}`);
}

export const userNotificationsRestApiClient = new UserNotificationsRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
