import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconInternational_phone_number = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M12 2C6.485 2 2 6.485 2 12s4.485 10 10 10 10-4.485 10-10S17.515 2 12 2m0 18.245A11.8 11.8 0 0 1 9.76 17.2a12.566 12.566 0 0 1 4.48 0A11.8 11.8 0 0 1 12 20.245m0-4.745c-1.03 0-1.975.115-2.845.29-.32-.895-.55-1.915-.62-3.04h6.935a11.3 11.3 0 0 1-.62 3.04 14.3 14.3 0 0 0-2.845-.29zm-3.465-4.25c.075-1.125.3-2.145.62-3.04.87.175 1.815.29 2.845.29s1.975-.115 2.845-.29c.32.895.55 1.915.62 3.04H8.53zM12 7c-.805 0-1.545-.075-2.24-.2A11.8 11.8 0 0 1 12 3.755c.545.525 1.47 1.55 2.24 3.045-.695.12-1.435.2-2.24.2m2.045-3.24c1.365.34 2.6 1.01 3.62 1.92-.55.275-1.19.54-1.915.765a13 13 0 0 0-1.7-2.685zm-5.79 2.685c-.73-.225-1.365-.49-1.915-.765a8.5 8.5 0 0 1 3.62-1.92c-.56.68-1.175 1.58-1.7 2.685zM7.68 7.84a12.9 12.9 0 0 0-.645 3.41H3.54c.145-1.66.77-3.18 1.735-4.43.665.36 1.47.72 2.405 1.02m-.645 4.91c.07 1.26.305 2.405.645 3.41-.935.295-1.74.66-2.405 1.02a8.43 8.43 0 0 1-1.735-4.43zm1.22 4.805a13 13 0 0 0 1.7 2.685 8.54 8.54 0 0 1-3.62-1.92c.55-.275 1.19-.54 1.915-.765zm7.49 0c.73.225 1.365.49 1.915.765a8.5 8.5 0 0 1-3.62 1.92c.56-.68 1.175-1.58 1.7-2.685zm.575-1.395c.34-1.005.575-2.15.645-3.41h3.495a8.43 8.43 0 0 1-1.735 4.43 14 14 0 0 0-2.405-1.02m.645-4.91a12.9 12.9 0 0 0-.645-3.41 13.7 13.7 0 0 0 2.405-1.02 8.43 8.43 0 0 1 1.735 4.43z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconInternational_phone_number;
