import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconBack = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="m14.97 20.03-7.145-7.145a1.26 1.26 0 0 1 0-1.77L14.97 3.97l1.06 1.06L9.06 12l6.97 6.97z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconBack;
