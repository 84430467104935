import { EntityId, EntityState } from '@reduxjs/toolkit';
import { StatusState } from '../../utils/statusEntityAdapter';

export type DeviceProperty = {
	key: string;
	value: string | number | boolean;
	deviceId: string;
};

export type DevicePropertyEntity = {
	id: EntityId;
	properties: Omit<DeviceProperty, 'deviceId'>[];
};

export interface DevicePropertiesState {
	items: EntityState<DevicePropertyEntity, EntityId>;
	fetch: StatusState;
}

export const deviceIconTypes = [
	'TABLET',
	'MOBILE',
	'SMARTWATCH',
	'COMPUTER',
	'ESIM',
	'SIM',
	'OTHER',
] as const;

export type DeviceIconType = typeof deviceIconTypes extends readonly (infer T)[] ? T : never;
export const isDeviceIconType = (value: unknown): value is DeviceIconType =>
	value !== null &&
	typeof value === 'string' &&
	(deviceIconTypes as readonly string[]).includes(value);

export type DeviceIcon = {
	deviceId: string;
	icon: DeviceIconType;
};
