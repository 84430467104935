import React from 'react';
import { Checkbox } from '@panda/ui';

import classes from './ConnectionFilter.module.scss';
import { Translate } from '../../../redux/slices/translations';
import { ChangeUrlParameter } from '../selections/types';
import { FilterDropdown } from './FilterDropdown';
import { Filter } from './Filter';
import { Group } from '../../../redux/modules/groups';
import Spinner from '../../../components/spinner/Spinner';
import { Phoneline } from '../../../api/types/phonelines';
import { Acd } from '../../../redux/slices/acds';

interface Props {
	open?: boolean;
	translate: Translate;
	onOpenClose?: (open: boolean) => void;

	groups?: Group[];
	phonelines?: Phoneline[];
	acds?: Acd[];

	selection: string[];
	onChange: ChangeUrlParameter;
}

export class ConnectionFilter extends React.PureComponent<Props> {
	private onEntrySelect = (id: string) => {
		const entries = this.getEntries().map(entry => {
			if (entry.id !== id) {
				return entry;
			}

			return {
				...entry,
				selected: !entry.selected,
			};
		});

		this.props.onChange({
			connectionIds: entries.filter(entry => entry.selected).map(entry => entry.id),
			offset: 0,
		});
	};

	private getEntries() {
		if (!this.props.phonelines || !this.props.groups || !this.props.acds) {
			return [];
		}

		const phonelines = this.props.phonelines.map(phoneline => ({
			id: phoneline.id,
			name: phoneline.alias,
			selected: this.props.selection.includes(phoneline.id),
		}));

		const groups = this.props.groups.map(group => ({
			id: group.id,
			name: group.alias,
			selected: this.props.selection.includes(group.id),
		}));

		const acds = this.props.acds.map(acd => ({
			id: acd.id,
			name: acd.name,
			selected: this.props.selection.includes(acd.id),
		}));

		return [...phonelines, ...groups, ...acds];
	}

	private isActive() {
		return this.getEntries().filter(entry => entry.selected).length > 0;
	}

	private renderButtonLabel() {
		const selectedEntries = this.getEntries().filter(entry => entry.selected);

		if (selectedEntries.length > 1) {
			return `${this.props.translate('FILTER_CONNECTIONS')} (${selectedEntries.length})`;
		}

		if (selectedEntries.length === 1) {
			return selectedEntries[0].name;
		}

		return this.props.translate('FILTER_CONNECTIONS');
	}

	private renderContent() {
		if (!this.props.phonelines || !this.props.groups) {
			return (
				<div className={classes.spinnerWrapper}>
					<Spinner className={classes.spinner} />
				</div>
			);
		}

		return (
			<ul className={classes.filterlist}>
				{this.getEntries().map(entry => (
					<li key={entry.id}>
						<Checkbox checked={entry.selected} onChange={() => this.onEntrySelect(entry.id)}>
							{entry.name}
						</Checkbox>
					</li>
				))}
			</ul>
		);
	}

	private renderDropdown() {
		if (!this.props.onOpenClose) {
			return null;
		}

		return (
			<FilterDropdown
				buttonLabel={this.renderButtonLabel()}
				active={this.isActive()}
				onOpenClose={this.props.onOpenClose}
				open={!!this.props.open}
			>
				{this.renderContent()}
			</FilterDropdown>
		);
	}

	public render() {
		if (this.props.onOpenClose) {
			return this.renderDropdown();
		}

		return (
			<Filter title={this.props.translate('FILTER_CONNECTIONS')}>
				<div className={classes.twoColumns}>{this.renderContent()}</div>
			</Filter>
		);
	}
}
