import { createSlice, SliceCaseReducers, SliceSelectors } from '@reduxjs/toolkit';
import { MobileInfoState } from './types';
import { fetchMobileInfo } from './actions';

const initialState: MobileInfoState = {
	data: null,
	fetched: false,
	fetching: false,
};

/**
 * We have to be here clear with our types because otherwise redux
 * doesn't know what to do with the MobileState union type.
 */
type CaseReducers = SliceCaseReducers<MobileInfoState>;
type Selectors = SliceSelectors<MobileInfoState>;

const slice = createSlice<MobileInfoState, CaseReducers, 'mobile', Selectors>({
	name: 'mobile',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchMobileInfo.pending, state => ({
			...state,
			fetching: true,
		}));

		builder.addCase(fetchMobileInfo.fulfilled, (state, { payload }) => {
			return {
				...state,
				data: payload,
				fetched: true,
				fetching: false,
			};
		});
	},
});

export const reducer = slice.reducer;
