import React from 'react';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import { ActionElement, ButtonDefinition, LinkDefinition } from '../ActionElement';
import { Headline } from '../heading/Headline';
import NeoShapes from '../../assets/illustrations/neo_shapes.svg';
import { usePandaContext } from '../../contexts/pandaContext';

type Props = {
	/**
	 * Überschrift für die kurze Erläuterung. Sollte nicht das selbe wie die Überschrift der Seite oder des Abschnitts sein.
	 */
	heading: string;
	/**
	 * Ein kurzer Text zur Erläuterung, um was es an dieser Stelle eigentlich geht.
	 */
	text: React.ReactNode;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Hinzufügen oder Buchen.
	 *
	 * **LinkDefinition**:
	 *
	 * `{ type: 'link'; to: string; label: string; loud?: boolean; direction?: 'internal' | 'external' }`
	 *
	 * **ButtonDefinition**:
	 *
	 * `{ type: 'button'; onClick: () => void; label: string; loud?: boolean; disabled?: boolean }`
	 */
	actionElements: (LinkDefinition | ButtonDefinition)[];
	context: 'view' | 'section';
	/**
	 *
	 * Die Illustration die angezeigt werden soll.
	 */
	image: React.ReactNode;
	neoLightImage?: React.ReactNode;
	neoDarkImage?: React.ReactNode;
};

const styles = {
	containerwrapper: classnames('@container', 'flex-1'),
	container: classnames(
		'flex',
		'items-center',
		'justify-center',
		'max-w-full',
		'flex-col',
		'@[48rem]:flex-row',
		'gap-[4cqmax]'
	),
	imageContainer: (isClassic: boolean) =>
		classnames(
			'flex',
			'justify-around',
			'@[48rem]:justify-end',
			'xs:max-w-xs',
			'select-none',
			'pointer-events-none',
			isClassic ? 'max-w-full' : 'w-full'
		),
	fallbackImage: (context: 'view' | 'section') =>
		classnames(
			context === 'section' && 'max-w-[66%]',
			context === 'section' && 'max-h-[50vh]',
			context === 'view' && 'max-w-[50vw] sm:max-w-[25vw]',
			context === 'view' && 'max-h-[280px]'
		),
	contentContainer: classnames('max-w-full', 'sm:max-w-md'),
	headline: (isClassic: boolean, context: 'view' | 'section') =>
		classnames(
			'font-brand',
			'font-bold',
			'text-neo-color-global-content-neutral-intense',
			'text-lg/24',
			'lg:text-xl/24',
			'mt-0',
			isClassic && context === 'view' && 'lg:mt-64',
			'mb-8',
			'hyphens'
		),
	text: classnames(
		'font-brand',
		'font-normal',
		'text-neo-color-global-content-neutral-intense',
		'text-base/24',
		'mt-8',
		'mb-16',
		'lg:my-16'
	),
	actionElements: classnames('flex', 'gap-8', 'flex-row', 'flex-wrap'),
};

const EmptyState = (props: Props): JSX.Element => {
	const {
		heading,
		text,
		actionElements,
		image,
		neoLightImage,
		neoDarkImage,
		context = 'view',
	} = props;

	const { isNeoPBX } = usePandaContext();
	const { theme } = useTheme(isNeoPBX);

	const renderImage = () => {
		switch (theme) {
			case 'classic-light':
				return image;
			case 'neo-light':
				return (
					neoLightImage || <img alt="" src={NeoShapes} className={styles.fallbackImage(context)} />
				);
			case 'neo-dark':
				return (
					neoDarkImage || <img alt="" src={NeoShapes} className={styles.fallbackImage(context)} />
				);
		}
	};

	const renderActionElements = () => {
		return (
			<div className={styles.actionElements}>
				{actionElements.map((element, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<ActionElement key={i} element={element} />
				))}
			</div>
		);
	};

	return (
		<div className={styles.containerwrapper}>
			<div className={styles.container}>
				<div className={styles.imageContainer(theme === 'classic-light')}>{renderImage()}</div>
				<div className={styles.contentContainer}>
					<Headline className={styles.headline(theme === 'classic-light', context)}>
						{heading}
					</Headline>
					<p className={styles.text}>{text}</p>
					<div>{renderActionElements()}</div>
				</div>
			</div>
		</div>
	);
};

export { EmptyState };
