import React, { ComponentProps } from 'react';
import classnames from 'classnames';
import { ManagedForm as BaseManagedForm, ManagedMultiFieldValidator } from '@web-apps/forms';
import classes from './Form.module.scss';

interface FieldValues {
	[key: string]: unknown;
}

type FieldValidators<Fields extends FieldValues> = {
	[field in keyof Fields]?: ManagedMultiFieldValidator<Fields[field], Fields, unknown>;
};

export const ManagedForm = <
	Source extends FieldValues,
	Validators extends FieldValidators<Source> = {
		[field in keyof Source]?: ManagedMultiFieldValidator<Source[field], Source, Source[field]>;
	},
>(
	props: ComponentProps<typeof BaseManagedForm<Source, Validators>>
) => (
	<BaseManagedForm
		state={props.state}
		onSubmit={props.onSubmit}
		validators={props.validators}
		formValidator={props.formValidator}
		initialValues={props.initialValues}
		className={classnames(classes.form, props.className)}
	>
		{props.children}
	</BaseManagedForm>
);
