import api from '../../../api';
import { AcdAudioFileType } from '../acdAudioFiles';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchTTS = (state: ReduxState) => !state.tts.fetched && !state.tts.fetching;

export const fetchTTS = createAsyncThunk('tts/fetch', (acdId: string) => api.fetchTTS(acdId), {
	condition: (_, { getState }) => shouldFetchTTS(getState()),
});

export const createTTSAudioFile = createAsyncThunk(
	'tts/create',
	async ({
		acdId,
		title,
		content,
		type,
	}: {
		acdId: string;
		title: string;
		content: string;
		type: AcdAudioFileType;
	}) => api.createTTSAudioFile(acdId, title, content, type)
);
