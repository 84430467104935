import { EVENT_TYPE_DIALOG, EVENT_TYPE_STICKY } from './event-types';
import { EventState } from './types';

export function selectEvent(events: EventState, eventId: string) {
	return events.items.find(event => event.eventId === eventId);
}

export function selectEventByName(events: EventState, eventName: string) {
	return events.items.find(event => event.eventName === eventName);
}

export function selectEventsByType(events: EventState, eventType: string) {
	return events.items.filter(event => event.eventType === eventType);
}

export function selectForceEvents(events: EventState) {
	return selectEventsByType(events, 'FORCE');
}

export function isAccountActivationNeeded(events: EventState) {
	return events.fetched && selectEventByName(events, 'ACCOUNT_NEEDS_ACTIVATION');
}

export type VerificationFailure = {
	reason: string;
	addressId: string;
};

export function isBatchUserCreationProcessFailed(events: EventState) {
	return (
		events.fetched && selectEventByName(events, 'BATCH_USER_CREATION_PROCESS_FAILED') !== undefined
	);
}

export function isBatchUserCreationProcessSucceeded(events: EventState) {
	return (
		events.fetched &&
		selectEventByName(events, 'BATCH_USER_CREATION_PROCESS_SUCCEEDED') !== undefined
	);
}

export function isBusinessVerificationFailed(events: EventState): VerificationFailure | null {
	const failureEvent = [
		...selectEventsByType(events, EVENT_TYPE_STICKY),
		...selectEventsByType(events, EVENT_TYPE_DIALOG),
	].find(t => t.eventName.toUpperCase() === 'BUSINESSVERIFICATIONFAILED');

	if (!failureEvent) {
		return null;
	}

	return {
		// This is like... we just know these are here i guess?
		addressId: failureEvent.payload.addressId,
		reason: failureEvent.payload.reason,
	};
}

export function isBusinessVerificationSucceeded(events: EventState) {
	return [
		...selectEventsByType(events, EVENT_TYPE_STICKY),
		...selectEventsByType(events, EVENT_TYPE_DIALOG),
	].some(t => t.eventName.toUpperCase() === 'BUSINESSVERIFICATIONSUCCEEDED');
}

export function isSatelliteEnterpriseSupport(events: EventState) {
	return [...selectEventsByType(events, EVENT_TYPE_DIALOG)].some(
		t => t.eventName.toUpperCase() === 'SATELLITEENTERPRISESUPPORT'
	);
}
