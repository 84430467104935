import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconTrunk = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M18.75 11.25H5.25C4.56 11.25 4 10.69 4 10V3.25C4 2.56 4.56 2 5.25 2h13.5c.69 0 1.25.56 1.25 1.25V10c0 .69-.56 1.25-1.25 1.25M5.5 9.75h13V3.5h-13zM17 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0M5.25 22h13.5c.69 0 1.25-.56 1.25-1.25V14c0-.69-.56-1.25-1.25-1.25H5.25C4.56 12.75 4 13.31 4 14v6.75c0 .69.56 1.25 1.25 1.25m13.25-1.5h-13v-6.25h13zM11 5.75H7v1.5h4zm-4 11h4v1.5H7z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconTrunk;
