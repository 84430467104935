import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconIntegrations = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M19.25 16c-.64 0-1.23.225-1.7.595l-1.93-1.4a3.971 3.971 0 0 0 .075-3.22l1.755-1.15c.485.42 1.11.675 1.8.675A2.755 2.755 0 0 0 22 8.75 2.755 2.755 0 0 0 19.25 6a2.755 2.755 0 0 0-2.625 3.57l-1.755 1.15a4 4 0 0 0-2.12-1.15V7.39c1.15-.33 2-1.385 2-2.645A2.755 2.755 0 0 0 12 1.995a2.755 2.755 0 0 0-2.75 2.75c0 1.255.85 2.315 2 2.645v2.18c-.825.155-1.56.57-2.12 1.15L7.375 9.57A2.755 2.755 0 0 0 4.75 6 2.755 2.755 0 0 0 2 8.75a2.755 2.755 0 0 0 2.75 2.75c.69 0 1.315-.255 1.8-.675l1.755 1.15a3.97 3.97 0 0 0 .075 3.22l-1.93 1.4A2.74 2.74 0 0 0 4.75 16 2.755 2.755 0 0 0 2 18.75a2.755 2.755 0 0 0 2.75 2.75 2.755 2.755 0 0 0 2.75-2.75c0-.33-.06-.65-.17-.94l1.93-1.4a3.99 3.99 0 0 0 5.48 0l1.93 1.4c-.11.295-.17.61-.17.94a2.755 2.755 0 0 0 2.75 2.75A2.755 2.755 0 0 0 22 18.75 2.755 2.755 0 0 0 19.25 16m0-8.5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5M4.75 10a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5m6-5.25a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0M4.75 20a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5M12 16a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5m7.25 4a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconIntegrations;
