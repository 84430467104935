import { createAsyncThunk } from '../../utils/wrapper';
import { devicePropertiesRestApiClient as api } from './api';
import { DevicePropertiesState, DeviceProperty } from './types';
import { isFetched, isFetching } from './selectors';
import { ApiDeviceProperty } from './api/types';

const shouldFetchDeviceProperties = (state: DevicePropertiesState, deviceId: string) =>
	!isFetched(state, deviceId) && !isFetching(state, deviceId);

type FetchDevicePropertiesParameters = {
	deviceId: string;
	force?: boolean;
};

export const fetchDeviceProperties = createAsyncThunk<
	ApiDeviceProperty[],
	FetchDevicePropertiesParameters
>(
	'deviceProperties/fetch',
	async ({ deviceId }: FetchDevicePropertiesParameters, { rejectWithValue }) => {
		try {
			return (await api.getDeviceProperties(deviceId)).properties;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
	{
		condition: ({ deviceId, force = false }, { getState }) =>
			force || shouldFetchDeviceProperties(getState().deviceProperties, deviceId),
	}
);

export const setDeviceProperty = createAsyncThunk(
	'deviceProperties/set',
	async (property: DeviceProperty, { rejectWithValue }) => {
		try {
			return await api.setDeviceProperty(property);
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
