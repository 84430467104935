import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { fetchDeviceProperties } from './actions';
import { areFetched, selectDeviceIcon, selectDeviceIcons } from './selectors';

export const useDeviceProperties = (deviceIds: string[]) => {
	const devicePropertiesState = useSelector(state => state.deviceProperties);
	const dispatch = useDispatch();

	useEffect(() => {
		if (deviceIds.length > 0) {
			deviceIds.forEach(deviceId => {
				dispatch(fetchDeviceProperties({ deviceId }));
			});
		}
	}, [dispatch, deviceIds]);

	return {
		devicePropertiesFetchedForDeviceIds: areFetched,
		deviceProperties: devicePropertiesState.items,
	};
};

export const useDeviceIcon = (deviceId: string) =>
	selectDeviceIcon(
		useSelector(state => state.deviceProperties),
		deviceId
	);

export const useDeviceIcons = (deviceIds: string[]) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (deviceIds.length > 0) {
			deviceIds.forEach(deviceId => {
				dispatch(fetchDeviceProperties({ deviceId }));
			});
		}
	}, [dispatch, deviceIds]);

	return selectDeviceIcons(
		useSelector(state => state.deviceProperties),
		deviceIds
	);
};
