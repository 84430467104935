import React, { useRef, useState } from 'react';
import { Toggle, ExternalLink } from '@panda/ui';
import { NeoIcon } from '@web-apps/neo-icons';
import { useTheme } from '@web-apps/theming';
import classes from './NqNupsi.module.scss';
import { useNqNupsi } from '../../../redux/slices/nqNupsi';
import DropOut from '../../DropOut';
import { useTranslate, useTranslationActions } from '../../../redux/slices/translations';
import useKeyboardEvents from '../../hooks/useKeyboardEvents';
import useOutsideClick from '../../hooks/useOutsideClick';
import { isProductionEnvironment } from '../../../utils/environment';
import { useFeatureScope, useUserInfo } from '../../../redux/slices/userinfo';
import { useUserActions, useUserLanguage } from '../../../redux/modules/users';
import type { SipgateLocale } from '../../../redux/slices/userinfo';

const supportedLanguages = {
	de: 'de_DE',
	en: 'en_GB',
} as const;

const NqNupsi = () => {
	const { fetched: isNqNupsiFetched, data: NqNupsiState } = useNqNupsi();
	const { setDebug } = useTranslationActions();
	const translate = useTranslate();
	const ref = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const isNeoPBX = useFeatureScope('NEO');
	const { theme, changeTheme, resetTheme } = useTheme(isNeoPBX);
	const userinfo = useUserInfo();
	const currentLanguage = useUserLanguage(userinfo.sub);
	const [selectedLanguage, setSelectedLanguage] = useState<SipgateLocale | null>(currentLanguage);
	const { setUserLanguage } = useUserActions();
	useKeyboardEvents('Escape', () => setOpen(false), open);
	useOutsideClick(ref, () => setOpen(false), open);

	if (!isNqNupsiFetched || !NqNupsiState || !NqNupsiState.isNq) {
		return <></>;
	}

	return (
		<div id="nqnupsi" className={classes.nqnupsi} ref={ref}>
			<button
				className={classes.toggleButton}
				type="button"
				onClick={() => setOpen(!open)}
				aria-controls="nqnupsi-menu"
				aria-expanded={open}
				aria-haspopup="true"
				id="nqnupsi-button"
			>
				<NeoIcon name="Nq_nupsi" variant="line" aria-label={translate('NQNUPSI_BUTTON_LABEL')} />
			</button>
			<DropOut id="nqnupsi-menu" open={open} ariaLabelledBy="nqnupsi-button">
				<ul>
					<li className={classes.withToggle}>
						<Toggle
							ariaLabel={translate('NQNUPSI_SHOW_LKS')}
							size="large"
							onChange={() => setDebug(!NqNupsiState.showLks)}
							checked={NqNupsiState.showLks}
						>
							{translate('NQNUPSI_SHOW_LKS')}
						</Toggle>
					</li>
					<li className={classes.dropdown}>
						<label>
							{translate('USER_SETTINGS_PROFILE_LANGUAGE_LABEL')}:{' '}
							<select
								value={selectedLanguage || currentLanguage || ''}
								onChange={async event => {
									if (
										event.target.value === supportedLanguages.de ||
										event.target.value === supportedLanguages.en
									) {
										setSelectedLanguage(event.target.value);
										await setUserLanguage(userinfo.sub, event.target.value);
										window.location.reload();
									}
								}}
							>
								<option value={supportedLanguages.de}>
									{translate('USER_SETTINGS_PROFILE_LANGUAGE_DE_DE')}
								</option>
								<option value={supportedLanguages.en}>
									{translate('USER_SETTINGS_PROFILE_LANGUAGE_EN_GB')}
								</option>
							</select>
						</label>
					</li>
					<li className={classes.dropdown}>
						<label>
							Theme:{' '}
							<select
								value={theme}
								onChange={event => {
									if (event.target.value === 'auto') {
										resetTheme();
									} else {
										changeTheme(event.target.value as 'classic-light' | 'neo-dark' | 'neo-light');
									}
								}}
							>
								<option value="auto">Auto</option>
								<option value="classic-light">classic-light</option>
								<option value="neo-dark">neo-dark</option>
								<option value="neo-light">neo-light</option>
							</select>
						</label>
					</li>
					<li>
						<ExternalLink
							variant="quiet"
							to={
								isProductionEnvironment()
									? 'https://admin.live.sipgate.net'
									: 'https://admin.dev.sipgate.net'
							}
							target="blank"
						>
							{translate('NQNUPSI_HELPDESK_LINK')}
						</ExternalLink>
					</li>
				</ul>
			</DropOut>
		</div>
	);
};

export default NqNupsi;
