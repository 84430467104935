import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconCopy = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M7.25 18h12.5c.69 0 1.25-.56 1.25-1.25V4.25C21 3.56 20.44 3 19.75 3H7.25C6.56 3 6 3.56 6 4.25v12.5c0 .69.56 1.25 1.25 1.25m12.25-1.5h-12v-12h12z"
				clipRule="evenodd"
			/>
			<path
				fill="currentColor"
				d="M5.75 21H18v-1.5H5.75c-.69 0-1.25-.56-1.25-1.25V6H3v12.25A2.755 2.755 0 0 0 5.75 21"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconCopy;
