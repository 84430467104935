import { createSlice } from '@reduxjs/toolkit';
import { fetchChannelInbox, setChannelInbox } from './actions';
import { channelInboxEntityAdapter, fetchItemEntityAdapter } from './adapters';
import { StatusItem } from '../../utils/statusEntityAdapter';
import { ChannelInboxesOfWebuser, ChannelInboxState } from './types';
import { activateSim, createDevice } from '../../modules/devices';

export const getInitialState = (
	channelInboxesOfWebusers?: ChannelInboxesOfWebuser[],
	fetchItems?: StatusItem[]
): ChannelInboxState => ({
	items: channelInboxEntityAdapter.getInitialState({}, channelInboxesOfWebusers),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetchItems),
});

const slice = createSlice({
	name: 'channelInbox',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchChannelInbox.pending, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.webuserId,
				status: 'pending',
			});
		});

		builder.addCase(fetchChannelInbox.fulfilled, (state, { payload, meta }) => {
			channelInboxEntityAdapter.removeOne(state.items, meta.arg.webuserId);

			channelInboxEntityAdapter.upsertOne(state.items, {
				id: meta.arg.webuserId,
				channelInboxes: Array.from(Object.entries(payload)).map(([endpointId, apiChannelInbox]) => {
					return {
						channelId: apiChannelInbox.channelId,
						deviceId: endpointId,
					};
				}),
			});

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.webuserId,
				status: 'succeeded',
			});
		});

		builder.addCase<
			'DEVICE_CREATE_SUCCESS',
			ExtractByType<PossibleActions<typeof createDevice>, 'DEVICE_CREATE_SUCCESS'>
		>('DEVICE_CREATE_SUCCESS', (state, { payload, data }) => {
			if (!payload.channelInboxes) {
				return;
			}

			channelInboxEntityAdapter.removeOne(state.items, data.userId);

			channelInboxEntityAdapter.upsertOne(state.items, {
				id: data.userId,
				channelInboxes: Array.from(Object.entries(payload.channelInboxes)).map(
					([endpointId, apiChannelInbox]) => {
						return {
							channelId: apiChannelInbox.channelId,
							deviceId: endpointId,
						};
					}
				),
			});

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: data.userId,
				status: 'succeeded',
			});
		});

		builder.addCase<
			'DEVICE_SIM_ACTIVATE_SUCCESS',
			ExtractByType<PossibleActions<typeof activateSim>, 'DEVICE_SIM_ACTIVATE_SUCCESS'>
		>('DEVICE_SIM_ACTIVATE_SUCCESS', (state, { payload, data }) => {
			if (!payload.channelInboxes) {
				return;
			}

			channelInboxEntityAdapter.removeOne(state.items, data.userId);

			channelInboxEntityAdapter.upsertOne(state.items, {
				id: data.userId,
				channelInboxes: Array.from(Object.entries(payload.channelInboxes)).map(
					([endpointId, apiChannelInbox]) => {
						return {
							channelId: apiChannelInbox.channelId,
							deviceId: endpointId,
						};
					}
				),
			});

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: data.userId,
				status: 'succeeded',
			});
		});

		builder.addCase(setChannelInbox.pending, (state, { meta }) => {
			const updatedItem = state.items.entities[meta.arg.webuserId].channelInboxes.find(
				ci => ci.deviceId === meta.arg.deviceId
			);

			if (updatedItem) {
				updatedItem.channelId = meta.arg.channelId;
			}

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.webuserId,
				status: 'succeeded',
			});
		});

		builder.addCase(setChannelInbox.fulfilled, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.webuserId,
				status: 'succeeded',
			});
		});
	},
});

export const reducer = slice.reducer;
