import React from 'react';
import { connect, ReduxProps } from '../../redux';
import Snackbar from './Snackbar';
import classes from './ClickToDialSnackbar.module.scss';
import { closeClickToDialSnackbar } from '../../redux/slices/snackbar';
import { ReduxState } from '../../redux/types';
import api from '../../api';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	clickToDial: state.dialogs.clickToDialSession,
});

const mapDispatchToProps = {
	closeClickToDialSnackbar,
};

type Props = TranslateProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class ClickToDialSnackbar extends React.Component<Props> {
	private getAlias = () =>
		this.props.clickToDial && this.props.clickToDial.alias ? this.props.clickToDial.alias : '';

	private getCancelButton = () => {
		return (
			<button
				className={classes.cancelButton}
				onTouchEnd={this.handleCancelCall}
				onMouseUp={this.handleCancelCall}
				type="button"
			>
				{this.props.translate('CONNECTING_CALL_CANCEL')}
			</button>
		);
	};

	private handleCancelCall = (
		evt: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
	) => {
		evt.stopPropagation();

		if (this.props.clickToDial) {
			api.hangupCall(this.props.clickToDial.sessionId).catch(() => {
				/* Ignore error */
			});

			this.props.closeClickToDialSnackbar();
		}
	};

	public render() {
		return (
			<div>
				<Snackbar
					snackbarOpen={!!this.props.clickToDial}
					autoHideDuration={20000}
					closeSnackbar={this.props.closeClickToDialSnackbar}
					severity="success"
				>
					{this.props.translate.markdown.inline('CONNECTING_CALL_ON_DEVICE', this.getAlias())}
					{this.getCancelButton()}
				</Snackbar>
			</div>
		);
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(ClickToDialSnackbar));
