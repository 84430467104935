import { createSlice } from '@reduxjs/toolkit';
import { DataUsageItem } from './types';
import { StatusItem } from '../../../utils/statusEntityAdapter';
import { dataUsageAdapter, fetchItemEntityAdapter } from './adapters';
import { fetchDataUsage } from './actions';

export const getInitialState = (items?: DataUsageItem[], fetch?: StatusItem[]) => ({
	items: dataUsageAdapter.getInitialState({}, items),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetch),
});

const slice = createSlice({
	name: 'dataUsage',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDataUsage.pending, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'pending',
			});
		});

		builder.addCase(fetchDataUsage.fulfilled, (state, { meta, payload: dataUsageItem }) => {
			dataUsageAdapter.upsertOne(state.items, dataUsageItem);
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'succeeded',
			});
		});
	},
});

export const reducer = slice.reducer;
