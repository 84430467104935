import serviceUrls from '@web-apps/service-urls';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';
import { ApiChannelInboxList, ApiChannelInboxMap } from './types';

class ChannelInboxRestApiClient extends RestApiClient {
	public getChannelInbox = async (webuserId: string): Promise<ApiChannelInboxMap> => {
		const apiChannelInboxResponse: ApiChannelInboxList = await this.get(
			'/v3',
			`/devices/channelInbox?owner=${webuserId}`
		);
		return apiChannelInboxResponse.channelInboxList;
	};

	public setChannelInbox = (deviceId: string, channelId: string): Promise<void> => {
		return this.put('/v3', `/devices/${deviceId}/channelInbox`, { channelId });
	};
}

export const channelInboxRestApiClient = new ChannelInboxRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
