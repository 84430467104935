import serviceUrls from '@web-apps/service-urls';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';
import auth from '../../../../utils/authenticate/auth';
import { ApiIvr, ApiIvrForwarding, ApiIvrKeyForwadingTargetType } from './types';

interface IvrRestApi {
	getIvrs(): Promise<ApiIvr[]>;
	createIvr(alias: string): Promise<ApiIvr>;
	renameIvr(ivrId: string, alias: string): Promise<void>;
	deleteIvr(ivrId: string): Promise<void>;
	assignPhoneNumbersToIvr(ivrId: string, phoneNumbers: string[]): Promise<void>;
	assignAudioFileToIvr(ivrId: string, audioFileId: string): Promise<void>;
	updateIvrKeyForwardingMapping(
		ivrId: string,
		forwardingKey: ApiIvrForwarding,
		value: string,
		type: ApiIvrKeyForwadingTargetType,
		loopCount?: number
	): Promise<void>;
	updateIvrFallbackMapping(
		ivrId: string,
		value: string,
		type: ApiIvrKeyForwadingTargetType,
		loopCount: number
	): Promise<void>;
	deleteIvrKeyForwardingMapping(ivrId: string, forwardingKey: string): Promise<void>;
}

class IvrRestApiClient extends RestApiClient implements IvrRestApi {
	private readonly getIvrBasePath = () => (auth.isNeoPBXCustomer() ? '/neoPBX/ivr' : '/ivr');

	public getIvrs = (): Promise<ApiIvr[]> => this.get('/v3', this.getIvrBasePath());

	public createIvr(alias: string): Promise<ApiIvr> {
		return this.post('/v3', this.getIvrBasePath(), {
			alias,
		});
	}

	public assignPhoneNumbersToIvr(ivrId: string, phoneNumbers: string[]): Promise<void> {
		return this.post('/v3', `${this.getIvrBasePath()}/${ivrId}/routing`, { phoneNumbers });
	}

	public assignAudioFileToIvr(ivrId: string, audioFileId: string): Promise<void> {
		return this.post('/v3', `${this.getIvrBasePath()}/${ivrId}/announcement/${audioFileId}`, {});
	}

	public renameIvr = (ivrId: string, alias: string): Promise<void> =>
		this.put('/v3', `${this.getIvrBasePath()}/${ivrId}/alias`, { alias });

	public deleteIvr = (ivrId: string): Promise<void> =>
		this.delete('/v3', `${this.getIvrBasePath()}/${ivrId}`);

	public updateIvrKeyForwardingMapping = (
		ivrId: string,
		forwardingKey: ApiIvrForwarding,
		value: string,
		type: ApiIvrKeyForwadingTargetType,
		loopCount?: number
	) =>
		this.post('/v3', `${this.getIvrBasePath()}/${ivrId}/key-forwarding/${forwardingKey}`, {
			target: {
				type,
				value,
			},
			loopCount,
		});

	public updateIvrFallbackMapping = (
		ivrId: string,
		value: string,
		type: ApiIvrKeyForwadingTargetType,
		loopCount: number
	) =>
		this.put('/v3', `${this.getIvrBasePath()}/${ivrId}/fallback`, {
			target: {
				type,
				value,
			},
			loopCount,
		});

	public deleteIvrKeyForwardingMapping = (ivrId: string, forwardingKey: string) =>
		this.delete('/v3', `${this.getIvrBasePath()}/${ivrId}/key-forwarding/${forwardingKey}`);
}

export const ivrRestApiClient = new IvrRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
