import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

interface AudioPlayerContextProps {
	currentAudioId: string | null;
	setCurrentAudioId: (id: string | null) => void;
}

const AudioPlayerContext = createContext<AudioPlayerContextProps | undefined>(undefined);

export const AudioPlayerProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const [currentAudioId, setCurrentAudioId] = useState<string | null>(null);

	return (
		<AudioPlayerContext.Provider value={{ currentAudioId, setCurrentAudioId }}>
			{children}
		</AudioPlayerContext.Provider>
	);
};

export const useAudioPlayer = () => {
	const context = useContext(AudioPlayerContext);
	if (!context) {
		throw new Error('useAudioPlayer must be used within an AudioPlayerProvider');
	}
	return context;
};
