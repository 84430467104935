import { personalAccessTokenRestApiClient as api } from './api';
import { createAsyncThunk } from '../../utils/wrapper';
import { isFetched, isFetching } from './selectors';
import { CreatedPersonalAccessToken } from './types';

export const fetchPersonalAccessTokens = createAsyncThunk(
	'personalAccessToken/fetch',
	async (webuserId: string) => (await api.getPersonalAccessTokens(webuserId)).items,
	{
		condition: (webuserId: string, { getState }) =>
			!isFetched(getState().personalAccessTokens, webuserId) &&
			!isFetching(getState().personalAccessTokens, webuserId),
	}
);

export const deletePersonalAccessToken = createAsyncThunk(
	'personalAccessToken/delete',
	async (tokenId: string) => {
		return api.deletePersonalAccessToken(tokenId);
	}
);

type CreatePersonalAccessTokenActionParameter = {
	webuserId: string;
	tokenName: string;
	scopes: string[];
};
export const createPersonalAccessToken = createAsyncThunk(
	'personalAccessToken/create',
	async ({ webuserId, tokenName, scopes }: CreatePersonalAccessTokenActionParameter) =>
		(await api.createPersonalAccessToken(
			webuserId,
			tokenName,
			scopes
		)) as CreatedPersonalAccessToken
);
