import { ErrorBase, isErrorBase } from '../../utils/statusEntityAdapter/types';

interface TwoFactorAuthenticationSettingDisabled {
	otpEnabled: false;
	webuserId: string;
}

interface TwoFactorAuthenticationSettingEnabled {
	otpEnabled: true;
	deviceName: string;
	webuserId: string;
}

export type TwoFactorAuthenticationSetting =
	| TwoFactorAuthenticationSettingEnabled
	| TwoFactorAuthenticationSettingDisabled;

export interface TwoFactorAuthenticationSettingsState {
	items: TwoFactorAuthenticationSetting[];
	fetching: string[];
}

const errorNames = [
	'COULD_NOT_FETCH_TWO_FACTOR_AUTHENTICATION_SETTINGS',
	'COULD_NOT_FIND_TWO_FACTOR_AUTHENTICATION_SETTINGS',
];

type TwoFactorAuthenticationSettingsErrorNames = (typeof errorNames)[number];

export type TwoFactorAuthenticationSettingsError =
	ErrorBase<TwoFactorAuthenticationSettingsErrorNames>;

export const CouldNotFetchTwoFactorAuthenticationSettings = (
	webuserId: string
): TwoFactorAuthenticationSettingsError => ({
	name: 'COULD_NOT_FETCH_TWO_FACTOR_AUTHENTICATION_SETTINGS',
	message: `Could not fetch two factor authentication settings for ${webuserId}`,
});

export const CouldNotFindTwoFactorAuthenticationSettings = (
	webuserId: string
): TwoFactorAuthenticationSettingsError => ({
	name: 'COULD_NOT_FIND_TWO_FACTOR_AUTHENTICATION_SETTINGS',
	message: `Could not find two factor authentication settings for ${webuserId}`,
});

export const isTwoFactorAuthenticationSettingsError = (
	error: unknown
): error is TwoFactorAuthenticationSettingsError => {
	return isErrorBase(error, errorNames);
};
