import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconProducts = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M13 3h6.75c.69 0 1.25.56 1.25 1.25V11h-8V3m1.5 1.5v5h5v-5zM3.75 13H3v6.75c0 .69.56 1.25 1.25 1.25H11v-8H3.75m.75 6.5v-5h5v5zm11.75 1v-2.75H13.5v-1.5h2.75V13.5h1.5v2.75h2.75v1.5h-2.75v2.75zM4.5 9.5v-5h5v5zM4.25 3C3.56 3 3 3.56 3 4.25V11h8V3H4.25"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconProducts;
