import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { NeoIcon } from '@web-apps/neo-icons';
import { formatFromToUnitString } from './fileUpload.utils';
import { ErrorDescription } from '../visualComponents/ErrorDescription';
import { usePandaContext } from '../../contexts/pandaContext';
import { Button } from '../button/Button';

type FileType = {
	mimeTypes: string[];
	displayName: string;
};

type FileValidationStatus = 'FILE_UPLOAD_INVALID_MIMETYPE' | 'FILE_UPLOAD_INVALID_FILE_SIZE';

export type FileEntry = {
	name: string;
	size: number;
	validations: FileValidationStatus[];
	content: File;
};

type Props = {
	/**
	 * Die Prop `maxFileSize` gibt die maximal erlaubte Datei-Größe in Bytes an.
	 */
	maxFileSize: number;
	/**
	 * Die Prop `allowedFileTypes` enthält die erlaubten Datei-Typen.
	 */
	allowedFileTypes: FileType[];
	/**
	 * Die Prop `setFiles` wird aufgerufen sobald Dateien ausgewählt / entfernt wurden.
	 */
	setFiles: (files: FileEntry[]) => void;
	/**
	 * Der aktuelle `files` State.
	 */
	files: FileEntry[];
	/**
	 * Die Prop `maxFileAmount`gibt die maximale Anzahl an ausgewählten Dateien an.
	 */
	maxFileAmount: 1 | 2 | 3 | 4 | 5;
	/**
	 * Die Prop `locale` ist auf die Sprache des Nutzers eingestellt, damit Dateigrößen korrekt formatiert werden (Komma statt Punkt).
	 */
	locale: 'de' | 'en';
	/**
	 * Die Prop `error` zeigt einen Fehlerfall an, wenn zum Beispiel das Formular ohne hochgeladene Dateien abgeschickt wird.
	 */
	error?: string;
	/**
	 * Die Prop `listLabel` ist das Label für die hochgeladene Dateien Liste.
	 */
	listLabel: string;
	/**
	 * Die Language-Prop `maxFileAmountExceededErrorMessage` erwartet eine Übersetzung anhand der maximalen Anzahl von Dateien (wird übergeben)
	 */
	maxFileAmountExceededErrorMessage: (maxFileAmount: number) => string;
	/**
	 * Die Language-Prop `getErrorMessageForValidationStatus` erwartet eine Übersetzung für die Datei-Validierungs-Stati.
	 */
	getErrorMessageForValidationStatus: (status: FileValidationStatus) => string;
	/**
	 * Die Language-Prop `maxFileAmountLabel` wird aufgerufen, sobald die maximale Anzahl an Dateien ausgewählt ist.
	 */
	maxFileAmountLabel(maxFileAmount: number): string;
};

const styles = {
	hiddenIfDragging: (isDragging: boolean) =>
		classnames(
			'flex',
			'flex-col',
			'justify-center',
			'items-center',
			'gap-8',
			isDragging && ['pointer-events-none', 'invisible', 'hidden'],
			!isDragging && ['visible']
		),
	dropOffNow: (isDragging: boolean) =>
		classnames(
			'text-neo-color-global-content-neutral-intense',
			'font-brand',
			'font-bold',
			'text-sm/16',
			'pointer-events-none',
			isDragging && ['visible'],
			!isDragging && ['invisible', 'hidden']
		),
	legend: classnames(
		'block',
		'mb-4',
		'mx-2',
		'p-0',
		'font-brand',
		'font-normal',
		'text-xs/14',
		'text-neo-color-global-content-neutral-moderate',
		'grow'
	),
	upload: (
		emptyList: boolean,
		isDragging: boolean,
		hasError: boolean,
		exceedsMaxFileAmount: boolean
	) =>
		classnames(
			'w-full',
			'min-h-[8.25rem]',
			'px-16',
			'flex',
			'flex-col',
			'flex-1',
			'gap-8',
			'justify-center',
			'items-center',
			'border',
			'border-dashed',
			'font-brand',
			'rounded',
			'text-center',
			'text-neo-color-global-content-primary-intense',
			'text-sm/16',
			'select-none',
			'transition',
			'duration-300',
			'ease-in-out',
			!emptyList && ['py-16'],
			emptyList && ['py-40'],
			!isDragging && !hasError && ['bg-neo-color-global-background-primary-soft-default'],
			isDragging && ['bg-neo-color-global-background-primary-soft-hover'],
			!hasError && ['border-neo-color-global-border-primary-intense-default'],
			!isDragging &&
				hasError && [
					'bg-neo-color-global-background-critical-soft-default',
					'text-neo-color-global-content-critical-intense',
					'border-neo-color-global-border-critical-intense-default',
				],
			exceedsMaxFileAmount && ['hidden', 'invisible']
		),
	fileSizeHint: (exceedsMaxFileAmount: boolean) =>
		classnames(
			'text-center',
			'w-full',
			'font-brand',
			'block',
			'text-xs/16',
			'text-neo-color-global-content-neutral-moderate',
			!exceedsMaxFileAmount && ['mt-8']
		),
	listElement: classnames('flex', 'items-center', 'gap-x-8'),
	listElementWrapper: (firstItem: boolean) =>
		classnames(
			'flex',
			'gap-x-8',
			'grow',
			'items-center',
			'justify-center',
			!firstItem && ['border-t'],
			'border-neo-color-global-border-neutral-soft-default',
			'py-8',
			'pr-8'
		),
	listElementContent: classnames('flex-row', 'grow', 'grid'),
	metaInfo: classnames(
		'block',
		'font-mono',
		'slashed-zero',
		'text-xs/16',
		'text-neo-color-global-content-neutral-moderate',
		'font-brand',
		'truncate'
	),
	validationHint: classnames(
		'block',
		'text-xs/16',
		'text-neo-color-global-content-critical-moderate',
		'truncate',
		'font-brand',
		'font-light'
	),
	fieldset: classnames('my-16'),
	fileName: classnames(
		'text-base/24',
		'text-neo-color-global-content-neutral-intense',
		'font-brand',
		'font-normal',
		'truncate'
	),
	fileList: (emptyList: boolean) =>
		classnames(
			'border-solid',
			'border-neo-color-global-border-neutral-soft-default',
			'pl-8',
			!emptyList && ['border'],
			emptyList && ['mb-0'],
			'rounded',
			'mt-0'
		),
	fileIcon: classnames('shrink-0', 'text-neo-color-global-content-neutral-intense'),
	touchDeviceButtonArea: (exceedsMaxFileAmount: boolean) =>
		classnames(
			'flex',
			'flex-col',
			'items-center',
			'pointer-fine:invisible',
			'pointer-fine:hidden',
			exceedsMaxFileAmount && ['hidden', 'invisible']
		),
	errorMessage: classnames('w-full'),
	fileUploadContainer: classnames(
		'flex-col',
		'invisible',
		'hidden',
		'pointer-fine:visible',
		'pointer-fine:flex'
	),
	errorIcon: classnames(
		'flex-grow-0',
		'flex-shrink-0',
		'basis-200',
		'text-neo-color-global-content-critical-moderate'
	),
	desktopDeleteButton: classnames('min-w-min-content', 'pointer-coarse:hidden'),
	mobileDeleteButton: classnames('min-w-min-content', 'pointer-fine:hidden'),
	hiddenInput: classnames('invisible', 'hidden'),
};

export const FileUpload = (props: Props): React.ReactElement => {
	const inputEl = useRef<HTMLInputElement | null>(null);

	const [errorMessage, setErrorMessage] = useState<string>('');

	const [isDragging, setIsDragging] = useState<boolean>(false);

	const { languageKeys } = usePandaContext();

	useEffect(() => {
		if (props.error) {
			setErrorMessage(props.error);
		} else {
			setErrorMessage('');
		}
	}, [props.error]);

	const metaInfo = (file: FileEntry) => {
		return (
			<span className={styles.metaInfo}>
				{file.name.toUpperCase().match(/[^.]+$/i)}&nbsp;
				{formatFromToUnitString(file.size, 'BYTE', 'MEGABYTE', 2, props.locale)}
			</span>
		);
	};
	const validationHints = (file: FileEntry) => {
		return file.validations.map(value => (
			<span className={styles.validationHint} key={value}>
				{props.getErrorMessageForValidationStatus(value)}
			</span>
		));
	};
	const fileEntryElement = (file: FileEntry, index: number): React.ReactNode => {
		return (
			<li className={styles.listElement} key={`${file.name}_${index}`}>
				<NeoIcon name="File" variant="line" size={24} className={styles.fileIcon} />

				<div className={styles.listElementWrapper(index === 0)}>
					<div className={styles.listElementContent}>
						<b className={styles.fileName} title={file.name}>
							{file.name}
						</b>

						{file.validations.length === 0 ? metaInfo(file) : validationHints(file)}
					</div>

					{file.validations.length > 0 ? (
						<NeoIcon name="Form_error" variant="solid" className={styles.errorIcon} />
					) : null}
					<div className={styles.desktopDeleteButton}>
						<Button
							icon="delete"
							variant="quiet"
							iconOnly
							critical
							onClick={() => {
								props.setFiles(props.files.filter(f => f !== file));
							}}
							size="large"
						>{`${languageKeys.PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX} ${file.name}`}</Button>
					</div>
					<div className={styles.mobileDeleteButton}>
						<Button
							icon="delete"
							variant="quiet"
							iconOnly
							critical
							onClick={() => {
								props.setFiles(props.files.filter(f => f !== file));
							}}
							size="xlarge"
						>{`${languageKeys.PANDA_FILEUPLOAD_ARIA_DELETEFILE_PREFIX} ${file.name}`}</Button>
					</div>
				</div>
			</li>
		);
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		setIsDragging(false);
	};

	const saveFileListEntries = (filesToUplaod: FileList) => {
		const newFiles: FileEntry[] = [];

		if (filesToUplaod.length + props.files.length > props.maxFileAmount) {
			setErrorMessage(props.maxFileAmountExceededErrorMessage(props.maxFileAmount));
			return;
		}

		setErrorMessage('');

		for (const file of filesToUplaod) {
			const validations: FileValidationStatus[] = [];

			if (!props.allowedFileTypes.find(value => value.mimeTypes.includes(file.type))) {
				validations.push('FILE_UPLOAD_INVALID_MIMETYPE');
			}
			if (file.size > props.maxFileSize) {
				validations.push('FILE_UPLOAD_INVALID_FILE_SIZE');
			}

			if (validations.length > 0) {
				newFiles.push({ name: file.name, size: file.size, content: file, validations });
			} else {
				newFiles.push({
					name: file.name,
					size: file.size,
					content: file,
					validations: [],
				});
			}
		}

		props.setFiles([...props.files, ...newFiles]);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		setIsDragging(false);

		const dt = e.dataTransfer;

		if (dt.files.length > 0) {
			saveFileListEntries(dt.files);
		}
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
		setIsDragging(true);
	};

	const handleFileInputClick = () => {
		if (inputEl.current) {
			inputEl.current.click();
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const inputFiles = e.target.files;
			saveFileListEntries(inputFiles);
			e.target.value = '';
		}
	};

	return (
		<fieldset className={styles.fieldset}>
			<legend className={styles.legend}>{props.listLabel}</legend>
			<ul aria-live="polite" className={styles.fileList(props.files.length === 0)}>
				{props.files.map(fileEntryElement)}
			</ul>
			<div className={styles.fileUploadContainer}>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<div
					className={styles.upload(
						props.files.length === 0,
						isDragging,
						errorMessage !== '',
						props.files.length >= props.maxFileAmount
					)}
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragLeave}
				>
					{/* desktop */}
					<span className={styles.dropOffNow(isDragging)}>
						{languageKeys.PANDA_FILEUPLOAD_DROPOFFAREA_DROPOVER_TEXT}
					</span>
					<span className={styles.hiddenIfDragging(isDragging)}>
						{languageKeys.PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_PREFIX}
						<Button
							size="small"
							icon="add"
							variant="loud"
							aria-label={props.listLabel}
							onClick={handleFileInputClick}
						>
							{languageKeys.PANDA_FILEUPLOAD_DROPOFFAREA_BUTTON_TEXT}
						</Button>
					</span>
				</div>
				{errorMessage !== '' ? (
					<div className={styles.errorMessage}>
						<ErrorDescription>{errorMessage}</ErrorDescription>
					</div>
				) : undefined}
			</div>
			<div className={styles.touchDeviceButtonArea(props.files.length >= props.maxFileAmount)}>
				<Button
					icon="add"
					size="xlarge"
					width="max"
					aria-label={props.listLabel}
					onClick={handleFileInputClick}
				>
					{languageKeys.PANDA_FILEUPLOAD_TOUCHDEVICE_BUTTON_LABEL}
				</Button>
				{errorMessage !== '' ? (
					<div className={styles.errorMessage}>
						<ErrorDescription>{errorMessage}</ErrorDescription>
					</div>
				) : undefined}
			</div>
			<span className={styles.fileSizeHint(props.files.length >= props.maxFileAmount)}>
				{props.files.length >= props.maxFileAmount ? (
					props.maxFileAmountLabel(props.maxFileAmount)
				) : (
					<>
						{languageKeys.PANDA_FILEUPLOAD_ALLOWED_FILETYPES_LABEL}{' '}
						{props.allowedFileTypes.map(fileType => fileType.displayName).join(', ')}
						<br />
						{languageKeys.PANDA_FILEUPLOAD_MAX_FILESIZE_LABEL}{' '}
						{formatFromToUnitString(props.maxFileSize, 'BYTE', 'MEGABYTE', 2, props.locale)}
					</>
				)}
			</span>
			<input
				onChange={handleFileInputChange}
				accept={props.allowedFileTypes.flatMap(allowed => allowed.mimeTypes).join(',')}
				multiple={props.maxFileAmount > 1}
				type="file"
				ref={inputEl}
				className={styles.hiddenInput}
			/>
		</fieldset>
	);
};
