import { PhoneNumber } from './types';

export const sortPhoneNumbers = (numbers: PhoneNumber[]) => {
	const landline = numbers
		.filter(number => number.type === 'LANDLINE')
		.sort((a, b) => a.number.localeCompare(b.number));

	const mobile = numbers
		.filter(number => number.type === 'MOBILE')
		.sort((a, b) => a.number.localeCompare(b.number));

	const international = numbers
		.filter(number => number.type === 'INTERNATIONAL')
		.sort((a, b) => a.number.localeCompare(b.number));

	return [...landline, ...mobile, ...international];
};
