import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	cancelSipgateApp,
	createSipgateApps,
	fetchSipgateApp,
	fetchSipgateApps,
	revokeCancelledSipgateApp,
	updateSipgateApps,
} from './actions';
import { SliceData, useAction } from '../../index';
import { SipgateApp } from './types';
import { useUserInfo } from '../userinfo';
import { useUsers } from '../../modules/users';
import { useContracts } from '../../modules/contracts';

export const useSipgateApps = (): SliceData<SipgateApp[]> => {
	const sipgateApp = useSelector(state => state.sipgateApp);
	const dispatch = useDispatch();
	const userInfo = useUserInfo();
	const { areUsersFetched: usersFetched } = useUsers();
	const { fetched: contractsFetched } = useContracts();

	useEffect(() => {
		if (usersFetched && contractsFetched) {
			dispatch(fetchSipgateApps({}));
		}
	}, [dispatch, userInfo, usersFetched, contractsFetched]);

	if (userInfo.isAdmin) {
		if (!sipgateApp.fetched) {
			return {
				data: null,
				fetched: false,
			};
		}

		return {
			data: sipgateApp.items,
			fetched: true,
		};
	}

	if (!sipgateApp.fetchedForWebuser.includes(userInfo.sub)) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: sipgateApp.items,
		fetched: true,
	};
};

export const useSipgateApp = (webuserId: string): SliceData<SipgateApp> => {
	const dispatch = useDispatch();
	const activeSipgateApp = useSelector(state =>
		state.sipgateApp.items.find(app => app.userId === webuserId)
	);
	const fetched = useSelector(state => state.sipgateApp.fetchedForWebuser.includes(webuserId));

	useEffect(() => {
		dispatch(fetchSipgateApp({ webuserId }));
	}, [dispatch, webuserId]);

	if (!fetched || !activeSipgateApp) {
		return {
			data: null,
			fetched: false,
		};
	}

	return {
		data: activeSipgateApp,
		fetched: true,
	};
};

export const useSipgateAppsError = () => {
	return useSelector(state => state.sipgateApp.fetchingErrorsForWebuser);
};

export const useSipgateAppsActions = () => ({
	fetchSipgateApps: useAction(fetchSipgateApps),
	createSipgateApps: useAction(createSipgateApps),
	updateSipgateApps: useAction(updateSipgateApps),
	cancelSipgateApp: useAction(cancelSipgateApp),
	revokeCancellation: useAction(revokeCancelledSipgateApp),
});
