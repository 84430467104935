import { createSlice } from '@reduxjs/toolkit';
import { ScopeState } from './types';
import { fetchScopes } from './actions';

const initialState: ScopeState = {
	fetching: false,
	fetched: false,
	items: [],
};

const slice = createSlice({
	name: 'scopes',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchScopes.pending, state => ({
			...state,
			fetching: true,
			fetched: false,
		}));

		builder.addCase(fetchScopes.fulfilled, (_state, { payload: items }) => ({
			fetching: false,
			fetched: true,
			items,
		}));
	},
});

export const reducer = slice.reducer;
