import React from 'react';
import { connect, ReduxProps } from '../redux';
import HistoryDetailView from './history';
import { fetchUserInfo } from '../redux/slices/userinfo';
import PageNotFoundRedirect from '../redirects/PageNotFoundRedirect';
import { ReduxState } from '../redux/types';
import { withView } from '../foundation/view';
import { HistoryEntryType } from '../api/types/events';

const mapStateToProps = (state: ReduxState) => ({
	userInfo: state.userinfo,
});

const mapDispatchToProps = {
	fetchUserInfo,
};

interface ExternalProps {
	webuserId: string;
	eventListHeading: string;
	selectedTypes: HistoryEntryType[];
}

type Props = ExternalProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class HistoryOverview extends React.Component<Props> {
	public componentDidMount() {
		this.props.fetchUserInfo();
	}

	public render() {
		if (!this.props.userInfo.fetched) {
			return null;
		}

		if (this.props.userInfo.data.sub !== this.props.webuserId) {
			return <PageNotFoundRedirect />;
		}

		const loggedInUserHasRightsToEventlist =
			this.props.userInfo.fetched && this.props.userInfo.data.sub === this.props.webuserId;

		if (!loggedInUserHasRightsToEventlist) {
			return null;
		}

		return (
			<HistoryDetailView
				webuserId={this.props.webuserId}
				selectedTypes={this.props.selectedTypes}
				eventListHeading={this.props.eventListHeading}
			/>
		);
	}
}

export default withView(connect(mapStateToProps, mapDispatchToProps)(HistoryOverview));
