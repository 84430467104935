import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconFax = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M19 7.5h.25A2.755 2.755 0 0 1 22 10.25v6c0 .69-.56 1.25-1.25 1.25H19V21H5v-3.5H3.25c-.69 0-1.25-.56-1.25-1.25v-6A2.755 2.755 0 0 1 4.75 7.5H5V3h14zm-1.5-3h-11v3h11zm-11 15h11V15h-11zM19 16h1.5v-5.75c0-.69-.56-1.25-1.25-1.25H4.75c-.69 0-1.25.56-1.25 1.25V16H5v-2.5h14zm-1.5-5.5H19V12h-1.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconFax;
