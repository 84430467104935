import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconVoip_phone = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M3.5 7.69V4.5h4v3.19l-1 1v6.87l1 1v2.94h-4v-2.94l1-1V8.69zM3.25 3C2.56 3 2 3.56 2 4.25v3.543c0 .331.132.65.366.884L3 9.31v5.628l-.634.634a1.25 1.25 0 0 0-.366.884v3.293c0 .69.56 1.25 1.25 1.25h4.5c.605 0 1.11-.43 1.225-1H20.75c.69 0 1.25-.56 1.25-1.25V5.25C22 4.56 21.44 4 20.75 4H8.975c-.116-.57-.62-1-1.225-1zM9 5.5v2.293c0 .331-.132.65-.366.884L8 9.31v5.628l.634.634c.234.235.366.553.366.884V18.5h11.5v-13zm3.5 6H11V10h1.5zm1.5 0h1.5V10H14zm4.5 0H17V10h1.5zm-7.5-3h7.5V7H11zm1.5 5.75H11v-1.5h1.5zm1.5 0h1.5v-1.5H14zm4.5 0H17v-1.5h1.5zM11 17h1.5v-1.5H11zm4.5 0H14v-1.5h1.5zm1.5 0h1.5v-1.5H17z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconVoip_phone;
