import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import { tariffAnnouncementRestApiClient as api } from './api';

const shouldFetchTariffAnnouncement = (state: ReduxState, deviceId: string) =>
	!state.mobileDeviceTariffAnnouncements.fetchedForDevice.includes(deviceId) &&
	!state.mobileDeviceTariffAnnouncements.fetchingForDevice.includes(deviceId);

export const fetchMobileDeviceTariffAnnouncement = createAsyncThunk(
	'mobileDeviceTariffAnnouncement/fetch',
	async (deviceId: string) => api.getTariffAnnouncement(deviceId),
	{
		condition: (deviceId, { getState }) => shouldFetchTariffAnnouncement(getState(), deviceId),
	}
);
