import { Notifications } from './types';
import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';

type ForceFetch = {
	forceFetch?: boolean;
};

export type FetchNotificationsParameters = {
	userId: string;
} & ForceFetch;

export const fetchNotifications = createAsyncThunk<Notifications, FetchNotificationsParameters>(
	'notifications/fetch',
	async ({ userId }: FetchNotificationsParameters) => {
		return api.getNotifications(userId);
	},
	{
		condition: ({ userId }: FetchNotificationsParameters, { getState }) => {
			const state = getState();
			return (
				!state.notifications.fetchedForUser.includes(userId) &&
				!state.notifications.fetchedForUser.includes(userId)
			);
		},
	}
);

type CreateFaxReportNotification = {
	userId: string;
	faxlineId: string;
	email: string;
};

export const createFaxReportNotification = createAsyncThunk<
	Notifications,
	CreateFaxReportNotification
>(
	'notifications/createFaxReportNotification',
	async ({ userId, faxlineId, email }: CreateFaxReportNotification) => {
		await api.createFaxReportNotification(userId, faxlineId, email);
		return api.getNotifications(userId);
	}
);

type ChangeFaxReportNotification = {
	userId: string;
	notificationId: string;
	faxlineId: string;
	email: string;
};

export const changeFaxReportNotification = createAsyncThunk<
	Notifications,
	ChangeFaxReportNotification
>(
	'notifications/changeFaxReportNotification',
	async ({ userId, notificationId, faxlineId, email }: ChangeFaxReportNotification) => {
		await api.deleteNotification(userId, notificationId);
		await api.createFaxReportNotification(userId, faxlineId, email);
		return api.getNotifications(userId);
	}
);

type DeleteNotificationParameters = {
	userId: string;
	notificationId: string;
};

export const deleteNotification = createAsyncThunk<Notifications, DeleteNotificationParameters>(
	'notifications/deleteNotification',
	async ({ userId, notificationId }: DeleteNotificationParameters) => {
		await api.deleteNotification(userId, notificationId);
		return api.getNotifications(userId);
	}
);
