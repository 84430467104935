import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import classes from './CenterContainer.module.scss';

interface ExternalProps {
	horizontal?: boolean;
	vertical?: boolean;
	style?: CSSProperties;
	direction?: 'row' | 'column';
	className?: string;
	children: React.ReactNode;
}

export default class CenterContainer extends React.Component<ExternalProps> {
	public render() {
		const {
			direction,
			horizontal = true,
			vertical = true,
			children,
			className,
			style,
		} = this.props;

		return (
			<div
				style={style}
				className={classnames(classes.container, className, {
					[classes.column]: direction === 'column',
					[classes.vertical]: vertical,
					[classes.horizontal]: horizontal,
				})}
			>
				{children}
			</div>
		);
	}
}
