import React from 'react';
import ReactDOM from 'react-dom';
import { FocusTrap } from '@web-apps/focus-trap';

import classnames from 'classnames';
import classes from './Overlay.module.scss';

interface Props {
	children: React.ReactNode;
	onClose?: () => void;
	className?: string;
	focusTrap?: boolean;
}

export class Overlay extends React.Component<Props> {
	private modalRoot = document.getElementById('overlay-portal')!;

	private el = document.createElement('div');

	public componentDidMount() {
		this.modalRoot.appendChild(this.el);
		document.addEventListener('keyup', this.onKeyUp);
		document.body.style.overflow = 'hidden';
	}

	public componentWillUnmount() {
		this.modalRoot.removeChild(this.el);
		document.removeEventListener('keyup', this.onKeyUp);
		document.body.style.overflow = '';
	}

	private onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		if (e.target === e.currentTarget) {
			if (this.props.onClose) {
				this.props.onClose();
			}
		}
	};

	private onKeyUp = (e: KeyboardEvent) => {
		const ESC_KEYCODE = 'Escape';

		if (e.code === ESC_KEYCODE) {
			if (this.props.onClose) {
				this.props.onClose();
			}
		}
	};

	public render() {
		return ReactDOM.createPortal(
			this.props.focusTrap ? (
				<FocusTrap>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
					<div
						id="overlay"
						className={classnames(classes.overlay, this.props.className)}
						onMouseDown={this.onClick}
					>
						{this.props.children}
					</div>
				</FocusTrap>
			) : (
				/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
				<div
					id="overlay"
					className={classnames(classes.overlay, this.props.className)}
					onMouseDown={this.onClick}
				>
					{this.props.children}
				</div>
			),
			this.el
		);
	}
}
