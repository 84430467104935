import { Moment } from 'moment/moment';
import { DataUsageItem, DataUsageState } from './types';
import { createAsyncThunk } from '../../../utils/wrapper';
import { dataUsageRestApiClient as api } from './api';
import { isFetched, isFetching } from './selectors';

type WithForce = {
	force?: boolean;
};

type FetchDataUsageActionParameter = {
	containerId: string;
	dateStart: Moment;
	dateStop: Moment;
	intervalMinutes: number;
} & WithForce;

const shouldFetchDataUsage = (state: DataUsageState, deviceId: string) =>
	!isFetched(state, deviceId) || !isFetching(state, deviceId);

export const fetchDataUsage = createAsyncThunk<DataUsageItem, FetchDataUsageActionParameter>(
	'dataUsage/fetch',
	async ({ containerId, dateStart, dateStop, intervalMinutes }: FetchDataUsageActionParameter) => {
		const { items } = await api.getDataUsage(
			containerId,
			dateStart.toISOString(),
			dateStop.toISOString(),
			intervalMinutes.toString()
		);

		return {
			id: containerId,
			items,
		};
	},
	{
		condition: ({ force = false, containerId }: FetchDataUsageActionParameter, { getState }) =>
			force || shouldFetchDataUsage(getState().dataUsage.usage, containerId),
	}
);
