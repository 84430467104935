import { createSlice, UnknownAction } from '@reduxjs/toolkit';
import { MobileDeviceTariffAnnouncementState } from './types';
import { fetchMobileDeviceTariffAnnouncement } from './actions';
import { handleActions } from '../../utils/actions';
import { setTariffAnnouncement } from '../../modules/devices';

const initialState: MobileDeviceTariffAnnouncementState = {
	items: [],
	fetchedForDevice: [],
	fetchingForDevice: [],
	updatingForDevice: [],
};

const slice = createSlice({
	name: 'mobileDeviceTariffAnnouncement',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchMobileDeviceTariffAnnouncement.pending, (state, { meta }) => ({
			...state,
			fetchingForDevice: [...state.fetchingForDevice, meta.arg],
		}));

		builder.addCase(fetchMobileDeviceTariffAnnouncement.fulfilled, (state, { payload, meta }) => ({
			...state,

			items: [
				...state.items.filter(item => item.deviceId !== meta.arg),
				{ deviceId: meta.arg, enabled: payload.enabled },
			],

			fetchingForDevice: state.fetchingForDevice.filter(device => device !== meta.arg),

			fetchedForDevice: [...state.fetchedForDevice, meta.arg],
		}));
	},
});

const legacyReducer = handleActions<
	MobileDeviceTariffAnnouncementState,
	PossibleActions<typeof setTariffAnnouncement>
>(
	{
		DEVICE_TARIFF_ANNOUNCEMENT_SET_PENDING: (state, { data }) => ({
			...state,

			items: state.items.map(item => {
				if (item.deviceId !== data.deviceId) {
					return item;
				}

				return {
					...item,
					enabled: data.enabled,
				};
			}),

			updatingForDevice: [...state.updatingForDevice, data.deviceId],
		}),

		DEVICE_TARIFF_ANNOUNCEMENT_SET_SUCCESS: (state, { data }) => ({
			...state,
			updatingForDevice: state.updatingForDevice.filter(device => device !== data.deviceId),
		}),
	},

	initialState
);

export const reducer = (state: MobileDeviceTariffAnnouncementState, action: UnknownAction) => {
	return slice.reducer(legacyReducer(state, action), action);
};
