import React, { ComponentProps, useCallback } from 'react';
import classnames from 'classnames';
import { ActionElement, ButtonDefinition, LinkDefinition } from '../../components/ActionElement';
import { ViewHeader } from '../../viewComponents/ViewHeader';
import { Search } from '../../components/search/Search';
import { ListHeader } from '../../viewComponents/listHeader/ListHeader';
import { SearchNoResult } from '../../components/search/SearchNoResult';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { ListItem } from '../../viewComponents/ListItem';

type ColumnMeta<T> = {
	text: string;
	sort?: (a: T, b: T) => number;
	hidden?: boolean;
};

export type Props<T, Columns extends string> = {
	/** Überschrift der View. Erscheint oben in einer Zeile mit den `actionElements`. */
	heading: string;
	/**
	 * Bereich für Aktionselemente, PandaButtons oder PandaLinks, z. B. zum Hinzufügen oder Buchen.
	 *
	 * **LinkDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'link';
	 *  to: string;
	 *  label: string;
	 *  loud?: boolean;
	 *  direction?: 'internal' | 'external';
	 * }
	 * ```
	 * **ButtonDefinition**:
	 *
	 * ```
	 * {
	 *  type: 'button';
	 *  onClick: () => void;
	 *  label: string;
	 *  disabled?: boolean;
	 *  loud?: boolean;
	 * }
	 * ```
	 */
	actionElements: (LinkDefinition | ButtonDefinition)[];
	/**
	 * Angabe der `columns` mit einem frei wählbaren `key`.
	 *
	 * * `sort` muss eine Sortierfunktion sein, welche an [Array.sort](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
	 *   übergeben werden kann und aufsteigend sortiert.
	 *
	 * ```
	 * {
	 *  [key]: {
	 *   text: string;
	 *   sort?: (a: T, b: T) => number;
	 *  }
	 * }
	 * ```
	 */
	columns: Record<Columns, ColumnMeta<T>>;
	/**
	 * Übergib die Komponente die angezeigt werden soll. Falls du keine besonderen Wünsche hast, dann nutz unseren [EmptyState](../?path=/docs/components-emptystate-overview--docs)
	 * Der Safari kann nicht mit SVGs umgehen an denen nicht height="100%" steht, denkt also daran das an eure SVGs dran zu machen.
	 */
	emptyState: React.ReactNode;
	/**
	 * Array der Datensätze, die später in der ListNav dargestellt werden sollen.
	 */
	items: T[];
	/** Eine `.map`-Funktion zur Darstellung eines `items`. Benutze die
	 * `<ListViewColumn>`- und `<ListViewItem>`-Komponenten, um deine Daten für die Liste aufzuarbeiten.
	 */
	children: (item: T) => React.ReactElement;
	/**
	 * Eine Funktion, die die übergebenen Elemente anhand einer Nutzer:innen-Eingabe filtern kann.
	 */
	search: (term: string) => T[];
	/**
	 * Dieser Platzhalter ist sichtbar, wenn ein leeres Suchfeld angezeigt wird.
	 */
	searchPlaceholder: string;
	/**
	 * Übergib die Komponente die zwischen Headline und Suchfeld angezeigt werden soll.
	 */
	infoBox?: React.ReactNode;
};

const styles = {
	search: classnames('sm:max-w-[35rem]', 'mt-16', 'sm:mt-24'),
	scrollContainer: classnames(
		'max-w-full',
		'overflow-auto',
		'focus-visible:ring-focus',
		'mt-8',
		'rounded'
	),
	table: classnames('border-spacing-y-16'),
	searchEmptyState: classnames('p-24', 'max-w-[35rem]'),
};

export const ListView = <T, Columns extends string>({
	actionElements,
	children,
	columns: propColumns,
	emptyState,
	heading,
	items,
	infoBox,
	search,
	searchPlaceholder,
}: Props<T, Columns>): JSX.Element => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [sortedField, setSortedField] = React.useState(Object.keys(propColumns)[0] as Columns);
	const [sortDirection, setSortDirection] = React.useState<'ascending' | 'descending'>('ascending');

	const [isScrolling, setIsScrolling] = React.useState(false);
	const scrollContainer = useResizeObserver(
		useCallback(entry => {
			const containerWidth = Math.round(entry.contentRect.width);
			const contentWidth = entry.target.scrollWidth;
			setIsScrolling(contentWidth > containerWidth);
		}, [])
	);

	// I think making columns an object might have been a bad idea.
	const columns = Object.entries(propColumns) as [Columns, ColumnMeta<T>][];

	const visibleItems = (search ? [...search(searchTerm)] : [...items]).sort((a, b) => {
		const sort = propColumns[sortedField].sort;

		if (!sort) {
			return 0;
		}

		return sort(a, b) * (sortDirection === 'ascending' ? 1 : -1);
	});

	return (
		<>
			<ViewHeader
				heading={heading}
				itemCount={items.length}
				actionElements={actionElements.map((element, i) => (
					<ActionElement
						// eslint-disable-next-line react/no-array-index-key
						key={i}
						element={{ ...element, loud: i === actionElements.length - 1 }}
					/>
				))}
			/>
			{infoBox}
			{items.length === 0 ? (
				emptyState
			) : (
				<>
					<div className={styles.search}>
						<Search
							value={searchTerm}
							onChange={setSearchTerm}
							placeholder={searchPlaceholder}
							resultCount={visibleItems.length}
							landmark
						/>
					</div>

					<div className={styles.scrollContainer} ref={scrollContainer}>
						<table className={styles.table}>
							<ListHeader
								columns={columns.map(([id, col]) => ({
									id,
									label: col.text,
									sortable: !!col.sort,
									hidden: !!col.hidden,
								}))}
								sortedBy={{
									column: sortedField,
									direction: sortDirection,
								}}
								sortBy={(column, direction) => {
									setSortedField(column);
									setSortDirection(direction);
								}}
							/>

							<tbody>
								{visibleItems.map(item => {
									const child: React.ReactElement<ComponentProps<typeof ListItem>> = children(item);

									return React.cloneElement(child, {
										showShadow: isScrolling,
									});
								})}
							</tbody>
						</table>
					</div>

					{visibleItems.length === 0 ? (
						<div className={styles.searchEmptyState}>
							<SearchNoResult searchTerm={searchTerm} />
						</div>
					) : null}
				</>
			)}
		</>
	);
};
