import React from 'react';
import classnames from 'classnames';

import { Link } from 'react-router-dom';
import { NeoIcon } from '@web-apps/neo-icons';
import classes from './Breadcrumb.module.scss';

interface Props {
	children: React.ReactNode;
	link: string;
	current?: boolean;
	hasNext: boolean;
}

export const Breadcrumb = (props: Props) => (
	<li className={classnames(classes.container)}>
		<Link
			to={props.link}
			className={classes.breadcrumbLink}
			aria-current={props.current ? 'page' : undefined}
		>
			<div className={classes.shortLink}>•••</div>
			<span>{props.children}</span>
		</Link>
		{props.hasNext && <NeoIcon name="Next" variant="line" size={16} className={classes.icon} />}
	</li>
);
