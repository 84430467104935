import { Translate } from '../../../redux/slices/translations';
import { SipgateDomain } from '../../../redux/slices/userinfo';

export type Currency = 'EUR' | 'GBP';

export type PaymentInterval = 'monthly' | 'onetime' | 'per-call';

/**
 * An amount of money which may be displayed to the user using `formatMoney()`.
 */
export interface MoneyAmount {
	amount: number;
	/**
	 * The fraction of 1 Euro / 1 Pound which `amount` specifies.
	 *
	 * So if fraction is 100 and amount is 1, we are talking about 1 cent.
	 * So amount / fraction is your value in Euro or Pounds.
	 */
	fraction: number;
	currency: Currency;
}

/**
 * A cost of a product. In addition to an amount of money, this also specifies if
 * taxes are included and at which interval this cost occurs.
 */
export interface Cost {
	amount: MoneyAmount;
	/**
	 * Does the `amount` include taxes or not?
	 */
	isNetto: boolean;
	/**
	 * How often does this cost occur?
	 */
	interval: PaymentInterval;
}

// This should not be hardcoded and should be provided by the API in the future
const getVatFactor = (domain: SipgateDomain) => (domain === 'sipgate.de' ? 1.19 : 1.2);

const getNettoCost = (domain: SipgateDomain, price: Cost): Cost =>
	price.isNetto
		? price
		: {
				amount: { ...price.amount, amount: price.amount.amount / getVatFactor(domain) },
				isNetto: true,
				interval: price.interval,
			};

export const formatMoney = (money: MoneyAmount, locale: string) =>
	(money.amount / money.fraction).toLocaleString(locale.replace('_', '-'), {
		style: 'currency',
		currency: money.currency === 'EUR' ? 'EUR' : 'GBP',
	});

export const formatNettoCost = (domain: SipgateDomain, price: Cost, locale: string) =>
	formatMoney(getNettoCost(domain, price).amount, locale);

export const formatNettoUnitCost = (domain: SipgateDomain, price: Cost, locale: string) => {
	const money = getNettoCost(domain, price).amount;

	const penniesOrCents = ((money.amount * 100) / money.fraction).toLocaleString(
		locale.replace('_', '-'),
		{
			minimumFractionDigits: 1,
			maximumFractionDigits: 1,
		}
	);

	if (money.currency === 'GBP') {
		return `${penniesOrCents}p`;
	}

	return `${penniesOrCents} ct`;
};

export const translatePaymentInterval = (translate: Translate, interval: PaymentInterval) => {
	if (interval === 'onetime') {
		return translate('BOOKABLE_PRICE_ONETIME');
	}

	if (interval === 'monthly') {
		return translate('BOOKABLE_PRICE_MONTHLY');
	}

	if (interval === 'per-call') {
		return translate('BOOKABLE_PRICE_PER_CALL');
	}

	return translate('BOOKABLE_PRICE_UNKNOWN_PAYMENT_INTERVAL');
};

export const compareCosts = (domain: SipgateDomain) => (left: Cost, right: Cost) => {
	const leftNetto = getNettoCost(domain, left);
	const rightNetto = getNettoCost(domain, right);

	return (
		leftNetto.amount.amount * rightNetto.amount.fraction -
		rightNetto.amount.amount * leftNetto.amount.fraction
	);
};

export const multiplyCosts = (cost: Cost, factor: number) => ({
	...cost,
	amount: {
		...cost.amount,
		amount: factor * cost.amount.amount,
	},
});

export const sumCosts = (domain: SipgateDomain, costs: (Cost | null | undefined)[]): Cost => {
	const filtered = costs.filter((c): c is Cost => !!c);

	const first = filtered[0];
	if (!first) {
		throw new Error('Called sumCosts with empty array');
	}

	return filtered
		.map(c => getNettoCost(domain, c))
		.reduce(
			(sum, b) => {
				if (b.amount.currency !== sum.amount.currency) {
					throw new Error('Called sumCosts with costs of differing currencies');
				}

				if (b.amount.fraction !== sum.amount.fraction) {
					throw new Error('Called sumCosts with costs of differing fractions');
				}

				if (b.interval !== sum.interval) {
					throw new Error('Called sumCosts with costs of differing intervals');
				}

				return {
					...sum,
					amount: {
						...sum.amount,
						amount: sum.amount.amount + b.amount.amount,
					},
				};
			},
			{
				amount: {
					amount: 0,
					currency: first.amount.currency,
					fraction: first.amount.fraction,
				},
				isNetto: true,
				interval: first.interval,
			}
		);
};
