import { createSlice } from '@reduxjs/toolkit';
import { deleteUserNotification, fetchUserNotifications, replaceUserNotification } from './actions';
import { UserNotification } from './types';
import { StatusItem } from '../../utils/statusEntityAdapter';
import { fetchItemEntityAdapter, userNotificationEntityAdapter } from './adapters';

export const getInitialState = (items?: UserNotification[], fetchItems?: StatusItem[]) => ({
	items: userNotificationEntityAdapter.getInitialState({}, items),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetchItems),
});

const slice = createSlice({
	name: 'userNotifications',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchUserNotifications.pending, state => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: '*',
				status: 'pending',
			});
		});

		builder.addCase(fetchUserNotifications.fulfilled, (state, { payload }) => {
			userNotificationEntityAdapter.upsertMany(state.items, payload);
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: '*',
				status: 'succeeded',
			});
			fetchItemEntityAdapter.upsertMany(
				state.fetch,
				payload.map(i => ({
					id: i.id,
					status: 'succeeded',
				}))
			);
		});

		builder.addCase(fetchUserNotifications.rejected, state => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: '*',
				status: 'failed',
			});
		});

		builder.addCase(deleteUserNotification.pending, (state, { meta }) => {
			userNotificationEntityAdapter.removeOne(state.items, meta.arg);
		});

		builder.addCase(replaceUserNotification.pending, (state, { meta }) => {
			const { notificationId, ...item } = meta.arg;
			userNotificationEntityAdapter.upsertOne(state.items, { id: notificationId, ...item });
		});
	},
});

export const reducer = slice.reducer;
