import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconWarning = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2.86 21c-.45 0-.855-.235-1.08-.625a1.23 1.23 0 0 1 0-1.25L10.915 3.14a1.25 1.25 0 0 1 2.17 0l9.14 15.99c.225.39.22.86 0 1.25-.225.39-.63.625-1.08.625L21.14 21zm.435-1.5H20.71L12 4.26 3.29 19.5zm9.455-5V9h-1.5v5.5zM13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconWarning;
