import { useUserInfo } from '../../redux/slices/userinfo';
import { Authentication } from './Authentication';

export default new Authentication();

export const useIsAffiliateImpersonationAndTargetingSelf = (webuserId: string) => {
	const authenticationType = new Authentication().getAuthenticationType();
	const userinfo = useUserInfo();
	return authenticationType === 'AFFILIATE_IMPERSONATION' && userinfo.sub === webuserId;
};
