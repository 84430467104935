import { createSelector } from '@reduxjs/toolkit';
import { Faxline, FaxlineState } from './types';
import { faxLineEntityAdapter, fetchItemEntityAdapter, updateItemEntityAdapter } from './adapter';
import { StatusItem } from '../../utils/statusEntityAdapter';

const selectFaxLinesItems = (state: FaxlineState) => state.items;
const faxLineFetchItemsSelector = faxLineEntityAdapter.getSelectors(selectFaxLinesItems);
const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();
const updateItemEntitySelectors = updateItemEntityAdapter.getSelectors();

const createFaxLineSelector = createSelector.withTypes<FaxlineState>();

export const selectLoadedFaxLines = createFaxLineSelector(
	[faxLineFetchItemsSelector.selectAll, fetchItemEntitySelectors.selectAll],
	(faxLines: Faxline[], fetchItems: StatusItem[]) => {
		const fetchedIds = fetchItems.filter(f => f.status === 'succeeded').map(f => f.id);
		return faxLines.filter(f => fetchedIds.includes(f.id));
	}
);

export const faxLinesFetched = createFaxLineSelector(
	[fetchItemEntitySelectors.selectAll],
	(items: StatusItem[]) => items.some(i => i.status === 'succeeded' && i.id === '*')
);

export const selectFaxLine = createSelector.withTypes<FaxlineState>()(
	[faxLineFetchItemsSelector.selectById, (_, faxLineId: string) => faxLineId],
	(item, _) => item
);

export const selectFaxlinesForOwner = createFaxLineSelector(
	[faxLineFetchItemsSelector.selectAll, (_, ownerId: string) => ownerId],
	(faxlines: Faxline[], ownerId: string) =>
		faxlines.filter(fax => fax.ownerIds.some(id => id === ownerId))
);

export const selectFaxlinesForOwners = createFaxLineSelector(
	[faxLineFetchItemsSelector.selectAll, (_, ownerIds: string[]) => ownerIds],
	(faxlines: Faxline[], ownerIds: string[]) =>
		faxlines.filter(fax => fax.ownerIds.some(id => ownerIds.includes(id)))
);

export const isFetched = fetchItemEntitySelectors.isSucceeded;
export const isFetching = fetchItemEntitySelectors.isPending;
export const areFetched = fetchItemEntitySelectors.areSucceeded;
export const fetchingFailed = fetchItemEntitySelectors.hasFailed;
export const selectFetchError = fetchItemEntitySelectors.selectError;
export const selectAll = faxLineFetchItemsSelector.selectAll;
export const selectIds = faxLineFetchItemsSelector.selectIds;
export const selectById = faxLineFetchItemsSelector.selectById;

export const isUpdating = updateItemEntitySelectors.isPending;
export const isUpdated = updateItemEntitySelectors.isSucceeded;
export const updateFailed = updateItemEntitySelectors.hasFailed;
export const selectUpdateError = updateItemEntitySelectors.selectError;
