import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconHelp_circle = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M12.75 15h-.005v-.495c0-.655.33-.905 1.02-1.37l.003-.002c.785-.53 1.852-1.25 1.852-3.023a3.61 3.61 0 0 0-3.605-3.605 3.6 3.6 0 0 0-3.33 2.225l1.385.575c.325-.79 1.09-1.3 1.945-1.3 1.16 0 2.105.945 2.105 2.105 0 .975-.47 1.295-1.19 1.78-.71.475-1.68 1.13-1.68 2.61v.5h1.495v.005zM13 17.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 22C6.485 22 2 17.515 2 12S6.485 2 12 2s10 4.485 10 10-4.485 10-10 10m0-18.5c-4.685 0-8.5 3.815-8.5 8.5s3.815 8.5 8.5 8.5 8.5-3.815 8.5-8.5-3.815-8.5-8.5-8.5"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconHelp_circle;
