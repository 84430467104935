import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconRouting = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 3.5c-2.463 0-4.844.349-7.101.995C3.734 4.828 3 5.906 3 7.066V8.75C3 9.44 3.56 10 4.25 10h3.175a1.25 1.25 0 0 0 1.142-.743l.704-1.585a21.7 21.7 0 0 1 5.459.001l.703 1.584c.2.452.648.743 1.142.743h3.175c.69 0 1.25-.56 1.25-1.25V7.064c0-1.159-.732-2.236-1.895-2.57A25.7 25.7 0 0 0 12 3.5M5.311 5.937A24.3 24.3 0 0 1 12 5c2.322 0 4.566.326 6.691.936.471.135.809.587.809 1.128V8.5h-2.762l-.976-2.2-.412-.058A24 24 0 0 0 12 6q-1.71.002-3.354.243l-.409.06L7.262 8.5H4.5V7.066c0-.541.339-.994.811-1.13M3.5 18.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0m.25-2.386a2.501 2.501 0 1 0 1.5 0v-.864c0-.69.56-1.25 1.25-1.25h4.75v2.114a2.501 2.501 0 1 0 1.5 0V14h4.75c.69 0 1.25.56 1.25 1.25v.864a2.501 2.501 0 1 0 1.5 0v-.864a2.75 2.75 0 0 0-2.75-2.75h-4.75V9h-1.5v3.5H6.5a2.75 2.75 0 0 0-2.75 2.75zM12 17.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m6.5 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconRouting;
