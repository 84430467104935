import React from 'react';
import { Headline } from '@panda/ui';

import classes from './ErrorLabelSlideInContent.scss';
import emptyLabelIllustration from '../../../media/illustrations/error.svg';
import { DeprecatedMarkdown } from '../../../helpers/Markdown';
import { Translate } from '../../../redux/slices/translations';

interface Props {
	translate: Translate;
}

export class ErrorLabelSlideInContent extends React.PureComponent<Props> {
	public render() {
		return (
			<div className={classes.errorContent}>
				<div className={classes.errorState}>
					<figure className={classes.illustrationContainer}>
						<img src={emptyLabelIllustration} height="100%" alt="" />
					</figure>
					<Headline>{this.props.translate('EDIT_LABEL_ERROR_HEADLINE')}</Headline>
					<p>
						<DeprecatedMarkdown source={this.props.translate('EDIT_LABEL_ERROR_DESCRIPTION')} />
					</p>
				</div>
			</div>
		);
	}
}
