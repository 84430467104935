import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconAnonymous = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M16 4.345a1.25 1.25 0 0 0-1.498-.913l-1.872.44a2.75 2.75 0 0 1-1.26 0l-1.872-.44A1.25 1.25 0 0 0 8 4.345l-1.521 6.087-1.456-.364.16-.636a6 6 0 0 0-1.101.498c-.552.334-.581.55-.581.57 0 .014.013.18.408.457.375.264.966.532 1.764.769 1.587.472 3.824.774 6.328.774s4.74-.302 6.328-.774c.798-.237 1.389-.505 1.764-.769.395-.278.408-.444.408-.457 0-.02-.03-.236-.581-.57a6 6 0 0 0-1.1-.498l.159.636-1.456.364zm-9.456-.363-.938 3.751c-.91.245-1.7.55-2.302.914C2.648 9.044 2 9.65 2 10.5c0 .746.505 1.304 1.045 1.684.561.395 1.325.72 2.2.98C7.005 13.687 9.394 14 12 14s4.994-.313 6.755-.836c.875-.26 1.639-.585 2.2-.98.54-.38 1.045-.938 1.045-1.684 0-.85-.648-1.456-1.304-1.853-.601-.364-1.392-.67-2.302-.914l-.938-3.751a2.75 2.75 0 0 0-3.298-2.01l-1.872.44a1.25 1.25 0 0 1-.572 0l-1.872-.44a2.75 2.75 0 0 0-3.298 2.01M5.5 18.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0m2-3.5a3.5 3.5 0 1 0 3.42 4.25h2.16A3.501 3.501 0 0 0 20 18.5a3.5 3.5 0 0 0-6.92-.75h-2.16A3.5 3.5 0 0 0 7.5 15m9 1.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconAnonymous;
