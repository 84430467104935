import serviceUrls from '@web-apps/service-urls';
import { ApiDataUsageSettings } from './types';
import { RestApiClient } from '../../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../../api/client/HttpClient';

interface DataUsageSettingsRestApi {
	getDataUsageSettings(containerId: string): Promise<ApiDataUsageSettings>;

	setDataUsageSettings(containerId: string, persistenceLevel: string): Promise<void>;
}

class DataUsageSettingsRestApiClient extends RestApiClient implements DataUsageSettingsRestApi {
	public getDataUsageSettings = (containerId: string): Promise<ApiDataUsageSettings> =>
		this.get('/v3', `/devices/${containerId}/contingents/usage/settings`);

	public setDataUsageSettings = (containerId: string, persistenceLevel: string): Promise<void> =>
		this.put('/v3', `/devices/${containerId}/contingents/usage/settings`, {
			persistenceLevel,
		});
}

export const dataUsageSettingsRestApiClient = new DataUsageSettingsRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
