import { ManagedFieldValidator } from '../ManagedForm';
import { renderError } from './translate';
import { Translate } from './types';

/**
 * Ensure a string is not empty
 */
export const validateNonEmpty =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	input => {
		if (input.length > 0) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_EMPTY'),
		};
	};

/**
 * Ensure a string does not contain dots.
 */
export const validateNoDots =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	input => {
		if (input.includes('.')) {
			return {
				valid: false,
				error: renderError(translate, 'FORM_VALIDATION_GENERAL_NO_DOTS'),
			};
		}

		return {
			valid: true,
			value: input,
		};
	};

/**
 * Ensure a string is not empty.
 * Trims whitespace.
 */
export const validateTrimmedNonEmpty =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	input => {
		const trimmed = input.trim();

		if (trimmed.length > 0) {
			return {
				valid: true,
				value: trimmed,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_EMPTY'),
		};
	};

/**
 * Ensure a string matches a regular expression.
 */
export const validateRegex =
	(translate: Translate | string, regex: RegExp): ManagedFieldValidator<string, string> =>
	input => {
		// We create a new regex to reset the `lastIndex` flag of a regex, which is stateful.
		// Because javascript RegExp-es are annoying.
		if (new RegExp(regex).test(input)) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_REGEX'),
		};
	};

/**
 * Ensure a checkbox is checked
 */
export const validateChecked =
	(translate: Translate | string): ManagedFieldValidator<boolean, boolean> =>
	input => {
		if (input) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_NOT_CHECKED'),
		};
	};

/**
 * Ensure a string is empty
 */
export const validateEmpty =
	(translate: Translate | string): ManagedFieldValidator<string, null> =>
	input => {
		if (input.length === 0) {
			return {
				valid: true,
				value: null,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_NOT_EMPTY'),
		};
	};

/**
 * Ensure a string is not longer than the provided
 * character count (checks `String.length()`).
 *
 * ```
 * // Value can be at most 15 bytes long
 * validateMaxLength(translate, 15)
 * ```
 */
export const validateMaxLength =
	(translate: Translate | string, maxLength: number): ManagedFieldValidator<string, string> =>
	input => {
		if (input.length <= maxLength) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_TOO_LONG', maxLength.toString()),
		};
	};

/**
 * Ensure a string is not shorter than the provided
 * character count (checks `String.length()`).
 *
 */
export const validateMinLength =
	(translate: Translate | string, minLength: number): ManagedFieldValidator<string, string> =>
	input => {
		if (input.length >= minLength) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_TOO_SHORT', minLength.toString()),
		};
	};

/**
 * Ensure a string is exactly as long as the provided
 * character count (checks `String.length()`).
 *
 * ```
 * // Value must be 15 bytes long
 * validateExactLength(translate, 15)
 * ```
 */
export const validateExactLength =
	(translate: Translate | string, length: number): ManagedFieldValidator<string, string> =>
	input => {
		if (input.length === length) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_WRONG_LENGTH', length.toString()),
		};
	};

/**
 * Ensure that a value is one of several other values.
 * Most useful to narrow type `string`.
 *
 * ```
 * // Ensures value is of type `'CASE_1' | 'CASE_2'`
 * validateEnum(translate, 'CASE_1', 'CASE_2');
 * ```
 */
export const validateEnum =
	<T extends string>(
		translate: Translate | string,
		...members: T[]
	): ManagedFieldValidator<string, T> =>
	input => {
		for (const member of members) {
			if (input === member) {
				return {
					valid: true,
					value: member,
				};
			}
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_ENUM'),
		};
	};

/**
 * Ensure that a value is part of an array.
 * Most useful to get the original value from a string-only select.
 *
 * ```
 * // Ensures value is of type `'CASE_1' | 'CASE_2'`
 * validateOneOf(translate, 'CASE_1', 'CASE_2');
 * ```
 */
export const validateOneOf =
	<K, V>(
		translate: Translate | string,
		values: V[],
		matcher: (key: K, value: V) => boolean
	): ManagedFieldValidator<K, V> =>
	input => {
		const entry = values.find(v => matcher(input, v));

		if (entry) {
			return {
				valid: true,
				value: entry,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_UNSET'),
		};
	};

/**
 * Ensure a string has no emoji
 */
export const validateNoEmoji =
	(translate: Translate | string): ManagedFieldValidator<string, string> =>
	input => {
		const regex = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
		if (!input.match(regex)) {
			return {
				valid: true,
				value: input,
			};
		}

		return {
			valid: false,
			error: renderError(translate, 'FORM_VALIDATION_GENERAL_EMOJI'),
		};
	};
