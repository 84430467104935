import api from '../../../api';
import { ReduxState } from '../../types';
import { isFetched, isFetching } from './selectors';
import { createAsyncThunk } from '../../utils/wrapper';
import { LocalPrefix } from '../../../api/types/localprefix';

const shouldFetchLocalprefix = (state: ReduxState, deviceId: string) =>
	!isFetched(state.deviceLocalprefix, deviceId) && !isFetching(state.deviceLocalprefix, deviceId);

type FetchLocalPrefixActionArguments = {
	deviceId: string;
	force?: boolean;
};

export const fetchLocalprefix = createAsyncThunk<LocalPrefix, FetchLocalPrefixActionArguments>(
	'deviceLocalPrefix/fetch',
	async ({ deviceId }) => api.getLocalprefix(deviceId),
	{
		condition: ({ deviceId, force = false }, { getState }) =>
			force || (shouldFetchLocalprefix(getState(), deviceId) as boolean),
	}
);

type SetLocalPrefixActionArguments = {
	deviceId: string;
	localPrefix: string;
	active: boolean;
};

export const setLocalprefix = createAsyncThunk<void, SetLocalPrefixActionArguments>(
	'deviceLocalPrefix/set',
	async ({ deviceId, localPrefix, active }) => api.setLocalprefix(deviceId, localPrefix, active)
);
