import api from '../../../api';
import { forceFetchRestrictions } from '../../modules/restrictions';
import { createAsyncThunk } from '../../utils/wrapper';
import { SipgateIoOption, SipgateIoSettings } from './types';

export const fetchSipgateIo = createAsyncThunk('sipgateIo/fetch', () => api.getSipgateIo(), {
	condition: (_, { getState }) => !getState().sipgateIo.fetched,
});

export const updateSipgateIoSettings = createAsyncThunk(
	'sipgateIo/settings/update',
	(settings: SipgateIoSettings) =>
		api.setSipgateIoSettings({
			log: settings.log,
			incomingUrl: settings.incomingUrl || '',
			outgoingUrl: settings.outgoingUrl || '',
			whitelist: settings.enabledType === 'ALL' ? null : settings.enabledEndpoints,
		})
);

export const bookSipgateIo = createAsyncThunk(
	'sipgateIo/book',
	async (arg: { option: SipgateIoOption }, { dispatch }) => {
		const result = await api.bookSipgateIo(arg.option.id);

		await dispatch(forceFetchRestrictions(['CAN_BOOK_SIPGATE_IO', 'CAN_USE_SIPGATE_IO'])).payload
			.promise;

		return result;
	}
);

export const cancelSipgateIo = createAsyncThunk('sipgateIo/cancel', async (_, { dispatch }) => {
	const result = await api.cancelSipgateIo();

	await dispatch(forceFetchRestrictions(['CAN_BOOK_SIPGATE_IO', 'CAN_USE_SIPGATE_IO'])).payload
		.promise;

	return result;
});

export const revokeSipgateIoCancellation = createAsyncThunk(
	'sipgateIo/revokeCancellation',
	async (_, { dispatch }) => {
		const result = await api.revokeSipgateIoCancellation();

		await dispatch(forceFetchRestrictions(['CAN_BOOK_SIPGATE_IO', 'CAN_USE_SIPGATE_IO'])).payload
			.promise;

		return result;
	}
);
