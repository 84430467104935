import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconAdministration = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M20 2H4v20h7.5v-1.5h-6v-17h13V11H20zm2.28 11.22a2.17 2.17 0 0 0-3.06 0l-5.125 5.125-.742 2.218c-.32.96.59 1.917 1.583 1.577l2.214-.74 5.13-5.12a2.17 2.17 0 0 0 0-3.06m-2 1.06a.67.67 0 0 1 .94 0 .67.67 0 0 1 0 .94l-4.88 4.87-1.404.469.47-1.404zM16.5 7h-9V5.5h9zm-9 3h9V8.5h-9zm4 8.5h-4V17h4zm-4-5.5h4v-1.5h-4z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconAdministration;
