import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { mixpanel } from '../../../third-party/mixpanel';
import { SipgateDomain } from '../../slices/userinfo';

export const fetchTrunkContracts = createAction(
	'TRUNK_CONTRACTS_FETCH',
	(domain: SipgateDomain) => ({
		promise: () => api.getTrunkContracts(),
		shouldFetch: (state: ReduxState) =>
			!state.trunkContracts.fetching && !state.trunkContracts.fetched,
		data: {
			domain,
		},
	})
);

export const forceFetchTrunkContracts = createAction(
	'TRUNK_CONTRACTS_FETCH',
	(domain: SipgateDomain) => ({
		promise: api.getTrunkContracts(),
		data: {
			domain,
		},
	})
);

export const changeTrunkContract = createAction(
	'TRUNK_CONTRACTS_CHANGE',
	(
		productId: number,
		productName: string,
		replacesCustomerProductId: number,
		bookingType: 'Initial Booking' | 'Cancellation' | 'Downgrade' | 'Upgrade'
	) => ({
		promise: () =>
			api.changeTrunkContract(productId, replacesCustomerProductId).then(result => {
				mixpanel.track('Booked contract', {
					'Contract id': productId,
					'Contract name': productName,
					'Contract type': 'Trunking contract',
					'Contract booking type': bookingType,
				});
				return result;
			}),
		data: { productId, replacesCustomerProductId },
	})
);

export const createTrunkContract = createAction(
	'TRUNK_CONTRACTS_CREATE',
	(productId: number, productName: string, alias: string) => ({
		promise: api.createTrunkContract(productId, alias).then(result => {
			mixpanel.track('Booked contract', {
				'Contract id': productId,
				'Contract name': productName,
				'Contract type': 'Trunking contract',
				'Contract booking type': 'Initial Booking',
			});
			return result;
		}),
		data: { productId, alias },
	})
);

export const revokeTrunkContractCancellation = createAction(
	'TRUNK_CONTRACTS_REVOKE_CANCELLATION',
	(contractId: number) => ({
		promise: () => api.revokeTrunkContractCancellation(contractId),
		data: { contractId },
	})
);

export const cancelTrunkContract = createAction(
	'TRUNK_CONTRACTS_CANCELLATION',
	(contractId: number) => ({
		promise: () => api.cancelTrunkContract(contractId),
		data: { contractId },
	})
);
