import { createSlice } from '@reduxjs/toolkit';

import { FilesState } from './types';
import { deleteAudioFile, fetchAudioFiles, renameAudioFile, uploadAudioFile } from './actions';

export const initialState: FilesState = {
	audiofiles: {
		pages_fetched: [],
		total_pages: 0,
		fetching: false,
		content: [],
	},
};

export const slice = createSlice({
	name: 'files',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchAudioFiles.pending, state => ({
			...state,
			audiofiles: { ...state.audiofiles, fetching: true },
		}));
		builder.addCase(fetchAudioFiles.rejected, state => ({
			...state,
			audiofiles: { ...state.audiofiles, fetching: false },
		}));
		builder.addCase(fetchAudioFiles.fulfilled, (state, action) => ({
			...state,
			audiofiles: {
				fetching: false,
				pages_fetched: [
					...state.audiofiles.pages_fetched.filter(i => i !== action.payload.page.number),
					action.payload.page.number,
				],
				total_pages: action.payload.page.totalPages,
				content: [
					...state.audiofiles.content.filter(i => !action.payload.content.find(j => j.id === i.id)),
					...action.payload.content,
				],
			},
		}));
		builder.addCase(uploadAudioFile.pending, state => ({
			...state,
			audiofiles: { ...state.audiofiles, fetching: true },
		}));
		builder.addCase(uploadAudioFile.rejected, state => ({
			...state,
			audiofiles: { ...state.audiofiles, fetching: false },
		}));
		builder.addCase(uploadAudioFile.fulfilled, (state, action) => ({
			...state,
			audiofiles: {
				...state.audiofiles,
				fetching: false,
				content: [...state.audiofiles.content, action.payload],
			},
		}));
		builder.addCase(deleteAudioFile.pending, state => ({
			...state,
			audiofiles: { ...state.audiofiles },
		}));
		builder.addCase(deleteAudioFile.rejected, state => ({
			...state,
			audiofiles: { ...state.audiofiles },
		}));
		builder.addCase(deleteAudioFile.fulfilled, (state, action) => ({
			...state,
			audiofiles: {
				...state.audiofiles,
				content: state.audiofiles.content.filter(i => i.id !== action.meta.arg.id),
			},
		}));
		builder.addCase(renameAudioFile.pending, state => ({
			...state,
			audiofiles: { ...state.audiofiles },
		}));
		builder.addCase(renameAudioFile.rejected, state => ({
			...state,
			audiofiles: { ...state.audiofiles },
		}));
		builder.addCase(renameAudioFile.fulfilled, (state, action) => ({
			...state,
			audiofiles: {
				...state.audiofiles,
				content: state.audiofiles.content.map(file => {
					if (file.id === action.meta.arg.id) {
						return { ...file, alias: action.meta.arg.alias };
					}
					return file;
				}),
			},
		}));
	},
});

export const reducer = slice.reducer;
