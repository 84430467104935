import { EntityId, EntityState } from '@reduxjs/toolkit';
import { ErrorBase, isErrorBase, StatusState } from '../../utils/statusEntityAdapter/types';

export interface DataAutomation {
	id: EntityId;
	productId: number;
	limit?: number;
}

export interface DataAutomationState {
	items: EntityState<DataAutomation, EntityId>;
	fetch: StatusState;
}

const errorNames = ['GENERAL_DATA_AUTOMATION_ERROR', 'COULD_NOT_FIND_DATA_AUTOMATION'];

type DataAutomationErrorName = (typeof errorNames)[number];

export type DataAutomationError = ErrorBase<DataAutomationErrorName>;

export const GeneralDataAutomationError = (): DataAutomationError => ({
	name: 'GENERAL_DATA_AUTOMATION_ERROR',
	message: 'General data automation error',
});

export const CouldNotFindDataAutomation = (id: string) => ({
	name: 'COULD_NOT_FIND_DATA_AUTOMATION',
	message: `Could not find data automation ${id}`,
});

export const isDataAutomationError = (error: unknown): error is DataAutomationError => {
	return isErrorBase(error, errorNames);
};
