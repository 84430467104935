import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconWebphone = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M5.038 10h.712a2.75 2.75 0 0 1 2.75 2.75v1.5A2.75 2.75 0 0 1 6 16.989v.761c0 .69.56 1.25 1.25 1.25h2.378a2.25 2.25 0 0 1 2.122-1.5h.5a2.25 2.25 0 0 1 0 4.5h-.5a2.25 2.25 0 0 1-2.122-1.5H7.25a2.75 2.75 0 0 1-2.75-2.75V17h-1v-6.25A8.75 8.75 0 0 1 12.25 2 8.75 8.75 0 0 1 21 10.75V17h-2.25A2.75 2.75 0 0 1 16 14.25v-1.5A2.75 2.75 0 0 1 18.75 10h.712a7.25 7.25 0 0 0-7.212-6.5A7.25 7.25 0 0 0 5.038 10m.712 1.5H5v4h.75c.69 0 1.25-.56 1.25-1.25v-1.5c0-.69-.56-1.25-1.25-1.25m13 0c-.69 0-1.25.56-1.25 1.25v1.5c0 .69.56 1.25 1.25 1.25h.75v-4zM11 19.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconWebphone;
