import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconRinging_order_fixed = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M4.25 8.19V2h1.5v6.19l1.72-1.72 1.06 1.06-2.646 2.647a1.25 1.25 0 0 1-1.768 0L1.47 7.53l1.06-1.06zM10 3.25c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v2.5C21 6.44 20.44 7 19.75 7h-8.5C10.56 7 10 6.44 10 5.75zm1.5.25v2h8v-2zM10 10.75c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v2.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25zm1.5.25v2h8v-2zm-.25 6c-.69 0-1.25.56-1.25 1.25v2.5c0 .69.56 1.25 1.25 1.25h8.5c.69 0 1.25-.56 1.25-1.25v-2.5c0-.69-.56-1.25-1.25-1.25zm.25 3.5v-2h8v2zm-7.25-.81V13.5h1.5v6.19l1.72-1.72 1.06 1.06-2.646 2.647a1.25 1.25 0 0 1-1.768 0L1.47 19.03l1.06-1.06z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconRinging_order_fixed;
