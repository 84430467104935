import React from 'react';
import classnames from 'classnames';
import { LineIconNames, NeoIcon } from '@web-apps/neo-icons';
import redIconSmall from './icons/icon-state-red500-overlay-24.svg';
import orangeIconSmall from './icons/icon-state-orange500-overlay-24.svg';
import greenIconSmall from './icons/icon-state-green500-overlay-24.svg';
import redIconMedium from './icons/icon-state-red500-overlay-32.svg';
import orangeIconMedium from './icons/icon-state-orange500-overlay-32.svg';
import greenIconMedium from './icons/icon-state-green500-overlay-32.svg';
import sipgateAppIcon from './icons/sipgate_app_logo.svg';

type Color = 'green' | 'orange' | 'red';
type Size = 'xsmall' | 'small' | 'medium';

type Props = {
	/**
	 * Die Icons, die du hier übergibst, können nur in der Variante `line` verwendet werden.
	 */
	icon: LineIconNames | 'SIPGATE_APP_LOGO';

	/**
	 * Bestimmt die Farbe des angezeigten Status.
	 */
	color: Color;

	/**
	 * Die Icon-Größe kann `small (1,5rem)` und `medium (2rem)`, in Ausnahmefällen auch `xsmall (1rem)` sein.
	 */
	size: Size;

	/**
	 * Ein String, der den aktuellen Status des Objekts beschreibt, wie online oder offline.
	 */
	state: string;

	/**
	 * Ein String, der die Bedeutung des Icons für Screenreader Nutzer:innen beschreibt.
	 */
	alt: string;
};

const styles = {
	dotwrapper: (size: Size) =>
		classnames(
			'relative',
			size === 'xsmall' && 'h-16',
			size === 'small' && 'h-24',
			size === 'medium' && 'h-32',
			size === 'xsmall' && 'w-16',
			size === 'small' && 'w-24',
			size === 'medium' && 'w-32'
		),
	state: (size: Size) =>
		classnames(
			'absolute',
			'xs:mb-[1px]',
			'sm:mb-[2px]',
			'md:mb-[3px]',
			size === 'xsmall' && 'h-16',
			size === 'small' && 'h-24',
			size === 'medium' && 'h-32',
			size === 'xsmall' && 'w-16',
			size === 'small' && 'w-24',
			size === 'medium' && 'w-32'
		),
};

const renderDot = (type: Color, size: Size, state: string) => {
	switch (type) {
		case 'green':
			return (
				<img
					src={size === 'small' ? greenIconSmall : greenIconMedium}
					alt=""
					title={state}
					className={styles.state(size)}
				/>
			);

		case 'orange':
			return (
				<img
					src={size === 'small' ? orangeIconSmall : orangeIconMedium}
					alt=""
					title={state}
					className={styles.state(size)}
				/>
			);

		case 'red':
			return (
				<img
					src={size === 'small' ? redIconSmall : redIconMedium}
					alt=""
					title={state}
					className={styles.state(size)}
				/>
			);
	}
};

const getCalculationSize = (size: Size) => {
	switch (size) {
		case 'xsmall':
			return 16;
		case 'small':
			return 24;
		case 'medium':
			return 32;
		default:
			return 32;
	}
};

const getCutoutSize = (size: Size) => {
	switch (size) {
		case 'xsmall':
			return 8;
		case 'small':
			return 12;
		case 'medium':
			return 16;
		default:
			return 16;
	}
};

export const StateIcon = ({ icon, size, state, color: type, alt }: Props): JSX.Element => {
	const calculationSize = getCalculationSize(size);
	const desiredCutoutSize = getCutoutSize(size);

	// For a circle in a square container, we need to calculate based on the
	// distance from center to corner, which is sqrt(2) * (size/2)
	const distanceToCorner = Math.sqrt(2) * (calculationSize / 2); // ≈ 22.6 pixels for 32x32

	// Calculate what percentage of that distance our cutout radius should be
	// Note: we use radius (half of diameter) since gradient percentages are from center
	const cutoutRadius = desiredCutoutSize / 2;
	const cutoutSizePercent = (cutoutRadius / distanceToCorner) * 100;

	const position = (calculationSize - desiredCutoutSize) / 2;

	return (
		// @ts-expect-error we have to set alt-attr for div with role='img'
		<div role="img" alt={`${alt}`} aria-label={`${alt}`} className={classnames('relative')}>
			<div
				style={{
					width: calculationSize,
					height: calculationSize,
					WebkitMaskImage: `radial-gradient(circle at center, transparent ${cutoutSizePercent}%, black ${cutoutSizePercent}%)`,
					maskPosition: `${position}px  ${position}px`,
					position: 'absolute',
				}}
			>
				{icon !== 'SIPGATE_APP_LOGO' && (
					<NeoIcon
						name={icon}
						variant="line"
						size={calculationSize}
						className={classnames('absolute')}
					/>
				)}

				{icon === 'SIPGATE_APP_LOGO' && (
					<img
						src={sipgateAppIcon}
						height={getCalculationSize(size)}
						width={getCalculationSize(size)}
						alt={`${alt}`}
						className={classnames('absolute')}
					/>
				)}
			</div>

			<div className={styles.dotwrapper(size)}>{renderDot(type, size, state)}</div>
		</div>
	);
};
