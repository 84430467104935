import React, { Component } from 'react';
import classes from './BorderedContainer.module.scss';

interface Props {
	children: React.ReactNode;
}

class BorderedContainer extends Component<Props> {
	public render() {
		return <div className={classes.borderedContainer}>{this.props.children}</div>;
	}
}

export default BorderedContainer;
