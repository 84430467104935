import { createAction } from '../..';
import api from '../../../api';
import {
	ApiContact,
	ContactScope,
	CreateApiContact,
	SipgateCsvMappingSuggestions,
	UpdateApiContact,
} from '../../../api/types/contacts';
import { ReduxState } from '../../types';
import { ContactsCreateAction, ContactsFetchAction } from './types';
import { instanaLog } from '../../../third-party/instana';

const PAGE_SIZE = 1000;

async function fetchContactPage(
	lastId: string | undefined,
	signal: AbortSignal
): Promise<{ items: ApiContact[]; totalCount: number }> {
	const result = await api.cancellable(signal).getContacts({
		limit: PAGE_SIZE,
		lastId,
	});

	return result;
}

export const fetchContact =
	(contactId: string, force?: boolean) =>
	async (dispatch: Dispatch<ContactsFetchAction>, getState: () => ReduxState) => {
		const state = getState().contacts;

		if (state.abortController || (state.fetched && !force)) {
			return;
		}

		const abortController = new AbortController();

		dispatch({
			type: 'CONTACT_FETCH_PENDING',
			abortController,
		});

		const currentContact = await api.getContact(contactId);

		dispatch({
			abortController,
			type: 'CONTACT_FETCH_SUCCESS',
			contact: currentContact,
		});
	};

export const fetchContacts =
	(force?: boolean) =>
	async (dispatch: Dispatch<ContactsFetchAction>, getState: () => ReduxState) => {
		const state = getState().contacts;

		if (state.abortController || (state.fetched && !force)) {
			return;
		}

		const abortController = new AbortController();
		dispatch({
			type: 'CONTACTS_FETCH_PENDING',
			abortController,
		});

		try {
			const firstPage = await fetchContactPage(undefined, abortController.signal);
			dispatch({
				type: 'CONTACTS_FETCH_BATCH',
				contacts: firstPage.items,
				totalCount: firstPage.totalCount,
			});

			const newIds = new Set<string>(firstPage.items.map(contact => contact.id));
			const totalPages = Math.ceil(firstPage.totalCount / PAGE_SIZE);

			let lastId = firstPage.items[firstPage.items.length - 1].id;
			for (let currentPage = 1; currentPage < totalPages; currentPage += 1) {
				const page = await fetchContactPage(lastId, abortController.signal); // eslint-disable-line no-await-in-loop
				lastId = page.items[page.items.length - 1].id;

				for (const contact of page.items) {
					newIds.add(contact.id);
				}

				dispatch({
					type: 'CONTACTS_FETCH_BATCH',
					contacts: page.items,
					totalCount: page.totalCount,
				});
			}

			const removedIds = [];
			for (const contact of state.items) {
				if (typeof contact.id === 'string' && !newIds.has(contact.id)) {
					removedIds.push(contact.id);
				}
			}

			dispatch({
				type: 'CONTACTS_FETCH_SUCCESS',
				removedIds,
			});
		} catch (error) {
			instanaLog.error('contacts-fetch-error', error);
			if (!(error instanceof Error && error.name === 'AbortError')) {
				dispatch({
					type: 'CONTACTS_FETCH_ERROR',
					error,
				});
			}
		}
	};

export const forceFetchContacts =
	() => async (dispatch: Dispatch<ContactsFetchAction>, getState: () => ReduxState) => {
		const abortController = getState().contacts.abortController;

		if (abortController) {
			abortController.abort();
		}

		dispatch({
			type: 'CONTACTS_FETCH_ABORT',
		});

		await fetchContacts(true)(dispatch, getState);
	};

export const fetchContactsByPhonenumbers =
	(e164Numbers: string[]) =>
	async (
		dispatch: (action: ContactsFetchAction) => ContactsFetchAction,
		getState: () => ReduxState
	) => {
		if (getState().contacts.fetched || e164Numbers.length === 0) {
			return;
		}

		// api expects phonenumber without +
		const result = await api.getContactsByPhonenumbers(
			e164Numbers.map(number => number.replace('+', ''))
		);

		dispatch({
			type: 'CONTACTS_FETCH_BATCH',
			contacts: result.items,
			totalCount: result.totalCount,
		});
	};

export const createContact =
	(contact: CreateApiContact) =>
	(dispatch: Dispatch<ContactsFetchAction | ContactsCreateAction>) => {
		return dispatch({
			type: 'CONTACTS_CREATE',
			payload: {
				promise: () => api.createContact(contact),
				data: {
					contact,
				},
			},
		});
	};

export const deleteContacts = createAction(
	'CONTACTS_DELETE_ALL',
	(scopes: ContactScope[], contactIds: string[] = []) => ({
		promise: () => api.deleteContacts(scopes, contactIds),
		data: {
			scopes,
			contactIds,
		},
	})
);

export const updateContact = createAction('CONTACTS_UPDATE', (contact: UpdateApiContact) => ({
	promise: () => api.updateContact(contact),
	data: {
		contact,
	},
}));

export const prepareCSVImport = createAction(
	'CONTACTS_PREPARE_CSV_IMPORT',
	(withHeaderRow: boolean, base64Content: string) => ({
		promise: () => api.prepareCSVContactImport(withHeaderRow, base64Content),
	})
);

export const updateColumnMapping = createAction(
	'CONTACTS_UPDATE_COLUMN_MAPPING',
	(index: number, mappingAction: SipgateCsvMappingSuggestions) => {
		return {
			promise: () => Promise.resolve(),
			data: { index, mappingAction },
		};
	}
);

export const importFromCachedCSV = createAction(
	'CONTACTS_IMPORT_CACHED_CSV',
	(cacheKey: string, columns: string[], scope: string, withHeader: boolean) => ({
		promise: () => api.importFromCachedCSV(cacheKey, columns, scope, withHeader),
	})
);
