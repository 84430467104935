import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconIvr = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M16.25 2C15.56 2 15 2.56 15 3.25v.5h-.75A2.75 2.75 0 0 0 11.5 6.5v4.75H8v1.5h3.5v4.75a2.75 2.75 0 0 0 2.75 2.75H15v.5c0 .69.56 1.25 1.25 1.25h3.5c.69 0 1.25-.56 1.25-1.25v-2.5c0-.69-.56-1.25-1.25-1.25h-3.5c-.69 0-1.25.56-1.25 1.25v.5h-.75c-.69 0-1.25-.56-1.25-1.25v-4.75h2v.5c0 .69.56 1.25 1.25 1.25h3.5c.69 0 1.25-.56 1.25-1.25v-2.5c0-.69-.56-1.25-1.25-1.25h-3.5c-.69 0-1.25.56-1.25 1.25v.5h-2V6.5c0-.69.56-1.25 1.25-1.25H15v.5c0 .69.56 1.25 1.25 1.25h3.5C20.44 7 21 6.44 21 5.75v-2.5C21 2.56 20.44 2 19.75 2zm3.25 3.5h-3v-2h3zM6.064 3c-1.159 0-2.236.732-2.57 1.895A25.7 25.7 0 0 0 2.5 12c0 2.463.349 4.844.995 7.101C3.828 20.266 4.906 21 6.066 21H7.75C8.44 21 9 20.44 9 19.75v-3.175a1.25 1.25 0 0 0-.743-1.142l-1.585-.704a21.7 21.7 0 0 1 .001-5.459l1.584-.703c.452-.2.743-.648.743-1.142V4.25C9 3.56 8.44 3 7.75 3zM4.936 5.309c.135-.471.587-.809 1.128-.809H7.5v2.762l-2.2.976-.058.412A24 24 0 0 0 5 12q.002 1.71.243 3.354l.06.409 2.197.975V19.5H6.066c-.541 0-.994-.339-1.13-.811A24.3 24.3 0 0 1 4 12c0-2.322.326-4.566.936-6.691M16.5 13h3v-2h-3zm3 7.5h-3v-2h3z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconIvr;
