import React, { SVGProps } from 'react';
import classnames from 'classnames';

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		fill="currentColor"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		className={classnames('min-w-16', 'min-h-16', 'w-16', 'h-16', props.className)}
	>
		<path
			fillRule="evenodd"
			d="M12.64 4.232a1 1 0 0 1 .128 1.408l-5 6a1 1 0 0 1-1.475.067l-2.5-2.5a1 1 0 0 1 1.414-1.414l1.726 1.726 4.299-5.16a1 1 0 0 1 1.408-.127Z"
			clipRule="evenodd"
		/>
	</svg>
);
