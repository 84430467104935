import React, { ReactNode } from 'react';
import classes from './DialogPageWrapper.module.scss';

interface ExternalProps {
	currentPage: number;
	pages: ReactNode[];
}

type Props = ExternalProps;

/** @deprecated Please mount your pages onto separate routes and build manual page transitions */
const DialogPageWrapper = (props: Props) => {
	return (
		<div className={classes.dialogPageWrapper} key={`transitiondialog-${props.currentPage}`}>
			{props.pages[props.currentPage - 1]}
		</div>
	);
};

export default DialogPageWrapper;
