import React from 'react';
import { sprintf } from 'sprintf-js';
import playIcon from '../../media/icons/icon-play_button-blue500-line-default-32.svg';
import pauseIcon from '../../media/icons/icon-pause_button-blue500-line-default-32.svg';
import classes from './AudioPlayer.module.scss';
import { handleKeyUp } from '../../utils/a11y/click-events-have-key-events';
import { Translate } from '../../redux/slices/translations';

interface Props {
	url: string;
	paused: boolean;
	onPlayPause: (playing: boolean) => void;
	translate: Translate;
}

interface State {
	duration: number;
	currentTime: number;
	canPlayAudio: boolean;
}

export class AudioPlayer extends React.PureComponent<Props, State> {
	private audioPlayer: HTMLAudioElement | null = null;

	public state = {
		currentTime: 0,
		duration: Infinity,
		canPlayAudio: true,
	};

	public componentDidUpdate(prevProps: Readonly<Props>) {
		const { paused } = this.props;
		if (prevProps.paused !== paused) {
			if (paused) {
				this.pause();
			} else {
				this.play();
			}
		}
	}

	private onSeek = (e: React.MouseEvent<HTMLElement>) => {
		if (this.audioPlayer) {
			const progressDimensions = e.currentTarget
				.getElementsByClassName(classes.progressBackground)[0]
				.getClientRects()[0];
			const progressPixels = e.clientX - progressDimensions.left;

			const progressPercent = progressPixels / progressDimensions.width;
			const progressSeconds = progressPercent * this.audioPlayer.duration;

			this.audioPlayer.currentTime = progressSeconds;
		}
	};

	private onTimeUpdate = () => {
		if (this.audioPlayer) {
			this.setState({ currentTime: this.audioPlayer.currentTime });
		}
	};

	private onEnded = () => {
		this.pause();
		this.props.onPlayPause(false);
	};

	private onNewAudioPlayer = (ref: HTMLAudioElement | null) => {
		this.audioPlayer = ref;

		if (this.audioPlayer) {
			this.audioPlayer.onended = this.onEnded;
			this.audioPlayer.ontimeupdate = this.onTimeUpdate;

			this.audioPlayer.addEventListener(
				'loadedmetadata',
				() => {
					if (this.audioPlayer) {
						this.setState({
							duration: this.audioPlayer.duration,
							canPlayAudio: this.canPlayAudio(this.audioPlayer),
						});
					}
				},
				false
			);
		}

		if (this.props.paused) {
			this.pause();
		} else {
			this.play();
		}
	};

	private onPlayPause = () => {
		this.props.onPlayPause(this.props.paused);
	};

	private play() {
		if (this.audioPlayer) {
			this.audioPlayer.play();
		}
	}

	private pause() {
		if (this.audioPlayer) {
			this.audioPlayer.pause();
		}
	}

	private canPlayAudio(element: HTMLAudioElement) {
		return !!(element.canPlayType && element.canPlayType('audio/mpeg;'));
	}

	private convertSecondsToTime(inputSeconds: number) {
		const minutes = Math.floor(Math.floor(inputSeconds) / 60);
		const seconds = Math.floor(inputSeconds) % 60;

		return {
			minutes,
			seconds: sprintf('%02d', seconds),
		};
	}

	private renderProgress() {
		const percentage = this.state.currentTime / this.state.duration;

		return (
			<div
				role="presentation"
				className={classes.progress}
				onClick={this.onSeek}
				onKeyUp={handleKeyUp(() => this.onSeek)}
			>
				<div className={classes.progressBackground}>
					<div className={classes.progressContent} style={{ width: `${percentage * 100}%` }} />
				</div>
			</div>
		);
	}

	private renderDuration() {
		const duration =
			this.state.duration !== Infinity ? this.state.duration : this.state.currentTime;

		const { minutes, seconds } = this.convertSecondsToTime(
			this.state.currentTime === 0 ? duration : this.state.currentTime
		);

		return (
			<span className={classes.time}>
				{minutes}:{seconds}
			</span>
		);
	}

	private renderPlayIcon() {
		return <img alt={this.props.translate('ALT_TAG_PLAY')} src={playIcon} />;
	}

	private renderPauseIcon() {
		return <img alt={this.props.translate('ALT_TAG_PAUSE')} src={pauseIcon} />;
	}

	public render() {
		if (!this.state.canPlayAudio) {
			return null;
		}

		return (
			<div className={classes.player}>
				<button type="button" className={classes.button} onClick={this.onPlayPause}>
					{this.props.paused ? this.renderPlayIcon() : this.renderPauseIcon()}
				</button>
				{this.renderProgress()}
				{this.renderDuration()}
				<audio preload="metadata" ref={this.onNewAudioPlayer}>
					<source src={this.props.url} type="audio/mpeg" />
				</audio>
			</div>
		);
	}
}
