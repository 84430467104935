import React from 'react';
import { Button, ManagedInput } from '@panda/ui';
import { ManagedMultiFieldValidator } from '@web-apps/forms';
import classes from './CreateLabelSlideInContent.module.scss';
import { Translate } from '../../../redux/slices/translations';
import { ManagedForm } from '../../../components/forms/ManagedForm';

interface Props {
	translate: Translate;
	onBack: () => void;

	validateLabel: (translate: Translate) => ManagedMultiFieldValidator<string, unknown, string>;
	createLabel: (name: string) => void;
}

export class CreateLabelSlideInContent extends React.PureComponent<Props> {
	public render() {
		return (
			<ManagedForm
				initialValues={{
					name: '',
				}}
				onSubmit={(data: { name: string }) => {
					this.props.createLabel(data.name);
					this.props.onBack();
				}}
				validators={{
					name: this.props.validateLabel(this.props.translate),
				}}
			>
				{({ form, fields }) => (
					<>
						<ManagedInput
							managedField={fields.name}
							label={this.props.translate('CREATE_LABEL_TITLE')}
							placeholder={this.props.translate('CREATE_LABEL_PLACEHOLDER')}
						/>
						<div className={classes.createLabelButtonWrapper}>
							<Button type="submit" action="confirm" disabled={!form.canBeSubmitted}>
								{this.props.translate('CREATE_LABEL_SAVE')}
							</Button>
						</div>
					</>
				)}
			</ManagedForm>
		);
	}
}
