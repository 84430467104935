import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconHide_navigation = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M3 21V3h1.5v18zm6.31-9.75 4.97-4.97-1.06-1.06-5.897 5.896a1.25 1.25 0 0 0 0 1.768l5.897 5.896 1.06-1.06-4.97-4.97H21v-1.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconHide_navigation;
