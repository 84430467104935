import serviceUrls from '@web-apps/service-urls';

import { HttpClient } from '../../../../api/client/HttpClient';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { VoicemailNotificationResponse } from './types';

interface VoicemailNotificationRestApi {
	enableVoicemailNotification: (channelId: string) => Promise<VoicemailNotificationResponse>;
	disableVoicemailNotification: (channelId: string) => Promise<VoicemailNotificationResponse>;
	getVoicemailNotification: (channelId: string) => Promise<VoicemailNotificationResponse>;
}

class VoicemailNotificationRestClient
	extends RestApiClient
	implements VoicemailNotificationRestApi
{
	public enableVoicemailNotification = async (
		channelId: string
	): Promise<VoicemailNotificationResponse> => {
		return this.post('/v3', `/neoPBX/voicemail-notification/${channelId}`, {});
	};

	public disableVoicemailNotification = async (
		channelId: string
	): Promise<VoicemailNotificationResponse> => {
		return this.delete('/v3', `/neoPBX/voicemail-notification/${channelId}`);
	};

	public getVoicemailNotification = async (
		channelId: string
	): Promise<VoicemailNotificationResponse> => {
		return this.get('/v3', `/neoPBX/voicemail-notification/${channelId}`);
	};
}

export const voicemailNotificationRestApiClient = new VoicemailNotificationRestClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
