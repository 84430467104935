import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconUserSettings = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10.5 12c2.755 0 5-2.245 5-5s-2.245-5-5-5-5 2.245-5 5 2.245 5 5 5m1 5.5c0-1.54.585-2.94 1.535-4H7.96a4.725 4.725 0 0 0-4.545 3.36L1.845 22H13.54a5.97 5.97 0 0 1-2.04-4.5m10.5-.75v1.5h-1.34c-.08.34-.215.66-.395.95l.95.95-1.06 1.06-.95-.95c-.29.18-.61.315-.95.395v1.34h-1.5v-1.34c-.34-.08-.66-.215-.95-.395l-.95.95-1.06-1.06.95-.95c-.18-.29-.315-.61-.395-.95h-1.34v-1.5h1.34c.08-.34.215-.66.395-.95l-.95-.95 1.06-1.06.95.95c.29-.18.61-.315.95-.395v-1.34h1.5v1.34c.34.08.66.215.95.395l.95-.95 1.06 1.06-.95.95c.18.29.315.61.395.95zm-6.25.75c0 .965.785 1.75 1.75 1.75s1.75-.785 1.75-1.75-.785-1.75-1.75-1.75-1.75.785-1.75 1.75"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconUserSettings;
