import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconSso = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m12 1.725 8.075 2.16c.545.15.925.645.925 1.21v8.18a6.77 6.77 0 0 1-3.375 5.845L12 22.37l-5.625-3.25A6.77 6.77 0 0 1 3 13.275v-8.18c0-.565.38-1.065.925-1.21zm4.875 16.09A5.26 5.26 0 0 0 19.5 13.27V5.285L12 3.275l-7.5 2.01v7.985a5.26 5.26 0 0 0 2.625 4.545L12 20.63zM9 10.525V9.5c0-1.655 1.345-3 3-3s3 1.345 3 3v1.025c.57.115 1 .62 1 1.225v3c0 .69-.56 1.25-1.25 1.25h-5.5C8.56 16 8 15.44 8 14.75v-3c0-.605.43-1.11 1-1.225M9.5 12v2.5h5V12zm4-1.5v-1c0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5v1z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconSso;
