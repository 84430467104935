import { createAsyncThunk } from '../../../utils/wrapper';
import { dataUsageSettingsRestApiClient as api } from './api';
import { createAction } from '../../../utils/actions';
import { isFetched, isFetching } from './selectors';
import { DataUsageSettingsItem } from './types';

type WithForce = {
	force?: boolean;
};

type FetchDataUsageSettingsActionParameters = {
	containerId: string;
} & WithForce;

export const fetchDataUsageSettings = createAsyncThunk<
	DataUsageSettingsItem,
	FetchDataUsageSettingsActionParameters
>(
	'dataUsageSettings/fetch',
	async ({ containerId }: FetchDataUsageSettingsActionParameters) => {
		const settings = await api.getDataUsageSettings(containerId);

		return {
			id: containerId,
			settings,
		};
	},
	{
		condition: (
			{ force = false, containerId }: FetchDataUsageSettingsActionParameters,
			{ getState }
		) =>
			force ||
			(!isFetched(getState().dataUsage.settings, containerId) &&
				!isFetching(getState().dataUsage.settings, containerId)),
	}
);

export const setDataUsageSettings = createAction(
	'DATA_USAGE_SET_SETTINGS',
	(containerId: string, persistenceLevel: string) => ({
		promise: () => api.setDataUsageSettings(containerId, persistenceLevel),
		data: { containerId, persistenceLevel },
	})
);
