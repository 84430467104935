import React from 'react';
import classnames from 'classnames';
import classes from './SystemLabel.module.scss';
import { Tooltip } from './tooltips/Tooltip';

interface Props {
	intentness?: 'strong' | 'weak' | 'gentle';
	className?: string;
	children: string;
	tooltip?: string;
}

export class SystemLabel extends React.PureComponent<Props> {
	public render() {
		const label = (
			<span
				className={classnames(
					classes.systemLabel,
					{
						[classes.strong]: this.props.intentness === 'strong',
						[classes.weak]: this.props.intentness === 'weak',
						[classes.gentle]: this.props.intentness === 'gentle',
					},
					this.props.className
				)}
				data-testid="systemlabel"
			>
				{this.props.children}
			</span>
		);
		return this.props.tooltip ? <Tooltip overlay={this.props.tooltip}>{label}</Tooltip> : label;
	}
}
