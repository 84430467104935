import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';

import {
	cancelUserEmailChangeRequest,
	createUser as createUserAction,
	createUser,
	deleteUser,
	fetchUserEmailChangeRequest,
	fetchUserLanguage,
	fetchUsers,
	forceFetchUsers,
	resetUserPassword,
	setDefaultDevice,
	setUserBusyOnBusy,
	setUserEmail,
	setUserLanguage,
	setUserLocation,
	setUserName,
	setUserRole,
} from './actions';
import { useUserInfo } from '../../slices/userinfo';
import { useAction } from '../..';

export const useUsers = () => {
	const dispatch = useDispatch();
	const usersState = useSelector(state => state.users);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	return useMemo(
		() => ({
			areUsersFetched: usersState.fetched,
			areUsersFetching: usersState.fetching,
			allUsers: usersState.items,
			getUsersByIds: (ids: string[]) => usersState.items.filter(user => ids.includes(user.id)),
			getUserById: (id: string) => usersState.items.find(user => user.id === id),
			createUser: (
				firstName: string,
				lastName: string,
				email: string,
				isAdmin: boolean,
				locationId: number
			) => dispatch(createUserAction(firstName, lastName, email, isAdmin, locationId)),
			isUserCreated: (email: string) => usersState.createdUser.includes(email),
			hasUserCreationError: (email: string) => usersState.userCreationError.includes(email),
		}),
		[usersState, dispatch]
	);
};

export const useCurrentUser = () => {
	const users = useUsers();
	const userInfo = useUserInfo();

	return users.getUserById(userInfo.sub);
};

export const useUserById = (webuserId: string) => useUsers().getUserById(webuserId);

export const useUserLanguage = (webuserId: string) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchUserLanguage(webuserId));
	}, [webuserId, dispatch]);

	return useSelector(state =>
		state.users.fetchedLanguageForUser.includes(webuserId)
			? state.users.itemsLanguage[webuserId]
			: null
	);
};

export const useUserEmailChangeRequest = (webuserId: string) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchUserEmailChangeRequest(webuserId));
	}, [webuserId, dispatch]);

	return useSelector(state =>
		state.users.fetchedEmailChangeRequestForUser.includes(webuserId)
			? state.users.itemsEmailChangeRequests[webuserId]
			: null
	);
};

export const useUserActions = () => ({
	fetchUserLanguage: useAction(fetchUserLanguage),
	forceFetchUsers: useAction(forceFetchUsers),
	setUserRole: useAction(setUserRole),
	setUserLocation: useAction(setUserLocation),
	setUserBusyOnBusy: useAction(setUserBusyOnBusy),
	setUserName: useAction(setUserName),
	setUserEmail: useAction(setUserEmail),
	cancelUserEmailChangeRequest: useAction(cancelUserEmailChangeRequest),
	setUserLanguage: useAction(setUserLanguage),
	setDefaultDevice: useAction(setDefaultDevice),
	resetUserPassword: useAction(resetUserPassword),
	deleteUser: useAction(deleteUser),
	createUser: useAction(createUser),
});
