import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconSign_out = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M5.865 20.135c.24.235.55.365.885.365H14v1.505H6.75a2.73 2.73 0 0 1-1.945-.805A2.73 2.73 0 0 1 4 19.255v-14.5A2.756 2.756 0 0 1 6.75 2H14v1.505H6.75c-.69 0-1.25.56-1.25 1.25V19.25c0 .335.13.65.365.885M16.53 7.215l4.145 4.145c.485.49.485 1.285 0 1.77l-4.145 4.145-1.06-1.06L18.685 13H10v-1.5h8.695L15.47 8.275z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconSign_out;
