import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconPbx = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M5.25 11.25h13.5c.69 0 1.25-.56 1.25-1.25V3.25C20 2.56 19.44 2 18.75 2H5.25C4.56 2 4 2.56 4 3.25V10c0 .69.56 1.25 1.25 1.25m13.25-1.5h-13V3.5h13zM17 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0M10 22H5.25C4.56 22 4 21.44 4 20.75V14c0-.69.56-1.25 1.25-1.25H16v1.5H5.5v6.25H10zM7 5.75h4v1.5H7zm4 10.75H7V18h4zm6.84-3.37-.47 1.75a.52.52 0 0 0 .135.495l1.19 1.19a8.4 8.4 0 0 1-3.625 3.625L13.88 19a.51.51 0 0 0-.495-.135l-1.75.47a.51.51 0 0 0-.38.495v2.06c0 .65.56 1.18 1.205 1.105a10.315 10.315 0 0 0 9.04-9.04c.075-.645-.455-1.205-1.105-1.205h-2.06a.51.51 0 0 0-.495.38"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconPbx;
