import React from 'react';
import classnames from 'classnames';

import { localizeNumber } from '@web-apps/phonenumbers-utils';
import classes from './PhonenumberSuggestion.module.scss';
import { ContactSearchResult } from '../../../redux/modules/contacts';
import { SipgateDomain } from '../../../redux/slices/userinfo';
import { HighlightedText } from '../../../components/HighlightedText';

export interface Suggestion extends ContactSearchResult {
	id: number;
	numberMatchLocation: null | {
		start: number;
		length: number;
	};
	contactMatchLocation: null | {
		start: number;
		length: number;
	};
}

interface Props {
	selected: boolean;
	className?: string;
	suggestion: Suggestion;
	domain: SipgateDomain;

	onSelect: (id: number) => void;
	onSubmit: (id: number) => void;
}

export class PhonenumberSuggestion extends React.PureComponent<Props> {
	private onSelect = () => {
		this.props.onSelect(this.props.suggestion.id);
	};

	private onSubmit = () => {
		this.props.onSubmit(this.props.suggestion.id);
	};

	public render() {
		return (
			<li
				key={`${this.props.suggestion.contact.id}-${this.props.suggestion.number}`}
				className={classnames({ [classes.selected]: this.props.selected }, this.props.className)}
			>
				<button
					className={classes.phonenumberSuggestion}
					onClick={this.onSubmit}
					onMouseOver={this.onSelect}
					onFocus={this.onSelect}
					type="button"
				>
					<span className={classes.number}>
						<HighlightedText
							text={localizeNumber(this.props.suggestion.number, this.props.domain)}
							highlight={this.props.suggestion.numberMatchLocation}
						/>
					</span>
					&nbsp;
					<span className={classes.name}>
						<HighlightedText
							text={this.props.suggestion.contact.name}
							highlight={this.props.suggestion.contactMatchLocation}
						/>
					</span>
				</button>
			</li>
		);
	}
}
