import { ReduxState } from '../../types';
import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

const shouldFetchReferringChannel = (target: string, state: ReduxState) =>
	!state.referringChannels.fetching[target] && !state.referringChannels.fetched[target];

export const fetchReferringChannels = createAsyncThunk(
	'referringChannels/fetchReferringChannels',
	async ({ target }: { target: string; force?: boolean }) => {
		const response = await api.getReferringChannels(target);
		if (!response || response.referringChannels.length < 1) {
			return [];
		}

		return response.referringChannels;
	},
	{
		condition: ({ target, force }, { getState }) => {
			if (force === true) {
				return true;
			}
			return shouldFetchReferringChannel(target, getState());
		},
	}
);
