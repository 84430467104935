import { combineReducers } from '@reduxjs/toolkit';
import { reducer as usageReducer } from './usage';
import { reducer as aggregationReducer } from './aggregation';
import { reducer as settingsReducer } from './settings';

export const reducer = combineReducers({
	usage: usageReducer,
	aggregation: aggregationReducer,
	settings: settingsReducer,
});
