import moment, { Moment } from 'moment-timezone';
import { SelectionParsers } from '.';

export const TypeTypeValues = ['call', 'sms', 'fax', 'voicemail'];
export type TypeType = (typeof TypeTypeValues)[number];
export type StatusType = 'success' | 'failure';
export type DirectionType = 'incoming' | 'outgoing';
export type StarredType = 'starred' | 'unstarred';
export type DirectoryType = 'inbox' | 'archive';
export type ReadType = 'read' | 'unread';

export interface Selections {
	offset: number;
	directory: DirectoryType;
	type: TypeType[];
	status: StatusType[];
	direction: DirectionType[];
	starred: StarredType[];
	to: Moment | null;
	from: Moment | null;
	labelIds: number[];
	read: ReadType[];
	connectionIds: string[];
	phonenumber: string;
}

const dateFormat = moment.ISO_8601;

const offsetDefault = 0;
const directoryDefault = 'inbox';

export const selectionParsers: SelectionParsers<Selections> = {
	directory: {
		validate: value => ['archive', 'inbox'].includes(value),
		isDefault: value => value === directoryDefault,
		serialize: value => (value === directoryDefault ? [] : [value]),
		deserialize: value => (value.length > 0 ? (value[0] as DirectoryType) : directoryDefault),
	},
	offset: {
		validate: value => !Number.isNaN(parseInt(value, 10)),
		isDefault: value => value === offsetDefault,
		serialize: value => (value === offsetDefault ? [] : [value.toString(10)]),
		deserialize: value => (value.length > 0 ? parseInt(value[0], 10) : offsetDefault),
	},
	type: {
		validate: value => ['fax', 'sms', 'call', 'voicemail'].includes(value),
		serialize: value => value,
		isDefault: value => value.length === 0,
		deserialize: value => value as TypeType[],
		filterName: 'type',
	},
	status: {
		validate: value => ['success', 'failure'].includes(value),
		serialize: value => value,
		isDefault: value => value.length === 0,
		deserialize: value => value as StatusType[],
		filterName: 'status',
	},
	direction: {
		validate: value => ['incoming', 'outgoing'].includes(value),
		serialize: value => value,
		isDefault: value => value.length === 0,
		deserialize: value => value as DirectionType[],
		filterName: 'direction',
	},
	starred: {
		validate: value => ['starred', 'unstarred'].includes(value),
		serialize: value => value,
		isDefault: value => value.length === 0,
		deserialize: value => value as StarredType[],
		filterName: 'starred',
	},
	to: {
		validate: value => moment(value, dateFormat, true).isValid(),
		isDefault: value => value === null,
		serialize: value => (value === null ? [] : [value.format('YYYY-MM-DDTHH:mm:ssZ')]),
		deserialize: value => (value.length > 0 ? moment(value[0], dateFormat, true) : null),
		filterName: 'date',
	},
	from: {
		validate: value => moment(value, dateFormat, true).isValid(),
		isDefault: value => value === null,
		serialize: value => (value === null ? [] : [value.format('YYYY-MM-DDTHH:mm:ssZ')]),
		deserialize: value => (value.length > 0 ? moment(value[0], dateFormat, true) : null),
		filterName: 'date',
	},
	labelIds: {
		validate: value => Number.isInteger(parseInt(value, 10)),
		serialize: value => value.map(v => v.toString()),
		isDefault: value => value.length === 0,
		deserialize: value => value.map(v => parseInt(v, 10)),
		filterName: 'labels',
	},
	read: {
		validate: value => ['read', 'unread'].includes(value),
		serialize: value => value,
		isDefault: value => value.length === 0,
		deserialize: value => value as ReadType[],
		filterName: 'read',
	},
	connectionIds: {
		validate: value =>
			/^[pg][0-9]+$/.test(value) ||
			/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(value),
		serialize: value => value,
		isDefault: value => value.length === 0,
		deserialize: value => value,
		filterName: 'connections',
	},
	phonenumber: {
		validate: value => /^\+[1-9]\d*$/.test(value) || /^[1-9][0-9]{0,3}$/.test(value),
		serialize: value => (value === '' ? [] : [value]),
		isDefault: value => value === '',
		deserialize: value => (value.length > 0 ? value[0] : ''),
		filterName: 'phonenumber',
	},
};
