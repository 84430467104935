import { DeviceLocalPrefixState } from './types';
import {
	deviceLocalPrefixAdapter,
	fetchItemEntityAdapter,
	updateItemEntityAdapter,
} from './adapter';

const selectItems = (state: DeviceLocalPrefixState) => state.items;
const deviceLocalPrefixSelectors = deviceLocalPrefixAdapter.getSelectors(selectItems);
export const { selectAll, selectById } = deviceLocalPrefixSelectors;

export const selectLocalPrefix = selectById;

const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();
export const {
	isSucceeded: isFetched,
	isPending: isFetching,
	selectById: selectFetchItemById,
} = fetchItemEntitySelectors;

const updateItemEntitySelectors = updateItemEntityAdapter.getSelectors();
export const {
	isSucceeded: isUpdated,
	isPending: isUpdating,
	selectById: selectUpdateItemById,
} = updateItemEntitySelectors;
