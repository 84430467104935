export type AppIntegration = {
	name: string;
	displayName: string;
};

export type AppSubscriptionName = 'INCLUDED' | 'PRO' | 'PREMIUM';

export type AppBooking = {
	bookingId: string;
	bookedAt: string;
	terminatedFor?: string;
	subscriptionName: Omit<'INCLUDED', AppSubscriptionName>;
	trial?: {
		endsAt: string;
		isCancelled: boolean;
	};
};

export type IncludedAppBooking = {
	subscriptionName: 'INCLUDED';
};

export type SipgateAppWithSubscription = {
	userId: string;
	activeBooking: AppBooking;
	loggedIn: boolean;
	connectedIntegrations: AppIntegration[];
};

export type SipgateIncludedApp = {
	userId: string;
	activeBooking: IncludedAppBooking;
	loggedIn: boolean;
	connectedIntegrations: AppIntegration[];
};

export type SipgateApp = SipgateAppWithSubscription | SipgateIncludedApp;

export type SipgateAppState = {
	items: SipgateApp[];
	fetching: boolean;
	fetched: boolean;
	fetchingErrorsForWebuser: { webuserId: string; error: SipgateAppError }[];
	fetchingForWebuser: string[];
	fetchedForWebuser: string[];
	upgradingForWebuser: string[];
	upgradedForWebuser: string[];
	creatingForWebuser: string[];
	createdForWebuser: string[];
	cancelingForWebuser: string[];
	canceledForWebuser: string[];
};

export const isSipgateFreeApp = (app: SipgateApp): app is SipgateIncludedApp =>
	app.activeBooking.subscriptionName === 'INCLUDED' ||
	app.activeBooking.subscriptionName === 'FREE';

export const isSipgateAppWithSubscription = (app: SipgateApp): app is SipgateAppWithSubscription =>
	!isSipgateFreeApp(app);

export const isTrial = (
	app: SipgateApp
): app is SipgateAppWithSubscription & {
	activeBooking: {
		trial: {
			endsAt: string;
			isCancelled: boolean;
		};
	};
} => isSipgateAppWithSubscription(app) && app.activeBooking.trial !== undefined;

export const isCanceled = (app: SipgateApp) =>
	isSipgateAppWithSubscription(app) && app.activeBooking.terminatedFor !== undefined;

export const isCanceledTrial = (app: SipgateApp) =>
	isSipgateAppWithSubscription(app) && app.activeBooking.trial?.isCancelled;

export const hasActiveBookingByType = (app: SipgateApp, type: AppSubscriptionName) =>
	app.activeBooking.subscriptionName === type;

export const isTerminated = (
	app: SipgateApp
): app is SipgateAppWithSubscription & { activeBooking: { terminatedFor: string } } =>
	isSipgateAppWithSubscription(app) && app.activeBooking.terminatedFor !== undefined;

export const hasConnectedIntegrations = (app: SipgateApp) =>
	app.connectedIntegrations.filter(i => i.name !== 'SIPGATE').length > 0;

export const hasLoggedIn = (app: SipgateApp) => app.loggedIn;

// errors
interface ErrorBase<T extends string> {
	name: T;
	message: string;
	webuserId: string;
}

export type SipgateAppError = ErrorBase<'COULD_NOT_FETCH_APPS' | 'COULD_NOT_FETCH_SINGLE_APP'>;

export const CouldNotFetchApps = (): SipgateAppError => ({
	name: 'COULD_NOT_FETCH_APPS',
	message: 'Could not fetch apps',
	webuserId: '*',
});

export const CouldNotFetchApp = (webuserId: string): SipgateAppError => ({
	name: 'COULD_NOT_FETCH_SINGLE_APP',
	message: `Could not fetch app for user ${webuserId}`,
	webuserId,
});

// fake sipgateApps for testing and development
const sipgateApp: SipgateApp = {
	userId: 'w0',
	activeBooking: {
		subscriptionName: 'INCLUDED',
	},
	loggedIn: false,
	connectedIntegrations: [],
};

const sipgateAppWithProBooking: SipgateApp = {
	...sipgateApp,
	activeBooking: {
		bookingId: '123',
		bookedAt: '2022-10-21T05:00:00',
		subscriptionName: 'PRO',
	},
};

const sipgateAppWithPremiumBooking: SipgateApp = {
	...sipgateAppWithProBooking,
	activeBooking: {
		...sipgateAppWithProBooking.activeBooking,
		subscriptionName: 'PREMIUM',
	},
};

const sipgateAppWithActiveProTrial: SipgateApp = {
	...sipgateAppWithProBooking,
	activeBooking: {
		...sipgateAppWithProBooking.activeBooking,
		trial: {
			endsAt: '2022-10-21T05:00:00',
			isCancelled: false,
		},
	},
};

const sipgateAppWithActivePremiumTrial: SipgateApp = {
	...sipgateAppWithActiveProTrial,
	activeBooking: {
		...sipgateAppWithActiveProTrial.activeBooking,
		subscriptionName: 'PREMIUM',
	},
};

const sipgateAppWithCanceledProTrial: SipgateApp = {
	...sipgateAppWithProBooking,
	activeBooking: {
		...sipgateAppWithProBooking.activeBooking,
		trial: {
			endsAt: '2022-10-21T05:00:00',
			isCancelled: true,
		},
	},
};

const sipgateAppWithCanceledPremiumTrial: SipgateApp = {
	...sipgateAppWithCanceledProTrial,
	activeBooking: {
		...sipgateAppWithCanceledProTrial.activeBooking,
		subscriptionName: 'PREMIUM',
	},
};

const sipgateAppWithTerminatedProBooking: SipgateApp = {
	...sipgateAppWithProBooking,
	activeBooking: {
		...sipgateAppWithProBooking.activeBooking,
		terminatedFor: '2022-10-31T23:59:59',
	},
};

const sipgateAppWithTerminatedPremiumBooking: SipgateApp = {
	...sipgateAppWithTerminatedProBooking,
	activeBooking: {
		...sipgateAppWithTerminatedProBooking.activeBooking,
		subscriptionName: 'PREMIUM',
	},
};

export const fakeSipgateApps = {
	free: sipgateApp,
	withProBooking: sipgateAppWithProBooking,
	withActiveProTrial: sipgateAppWithActiveProTrial,
	withCanceledProTrial: sipgateAppWithCanceledProTrial,
	withTerminatedProBooking: sipgateAppWithTerminatedProBooking,
	withActivePremiumBooking: sipgateAppWithPremiumBooking,
	withActivePremiumTrial: sipgateAppWithActivePremiumTrial,
	withCanceledPremiumTrial: sipgateAppWithCanceledPremiumTrial,
	withTerminatedPremiumBooking: sipgateAppWithTerminatedPremiumBooking,
};
