import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconZoom_in = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m16.16 15.1 5.62 5.62-1.06 1.06-5.62-5.62A7.96 7.96 0 0 1 10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8a7.96 7.96 0 0 1-1.84 5.1M3.5 10c0 3.585 2.915 6.5 6.5 6.5s6.5-2.915 6.5-6.5-2.915-6.5-6.5-6.5A6.506 6.506 0 0 0 3.5 10m5.75-.75V6.5h1.5v2.75h2.75v1.5h-2.75v2.75h-1.5v-2.75H6.5v-1.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconZoom_in;
