import { useEffect } from 'react';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	fetchRestrictions,
	fetchRestrictionsForUser,
	forceFetchRestrictions,
	forceFetchRestrictionsForUser,
} from './actions';
import { Restriction } from './types';
import { hasRestriction } from './selectors';

export const useRestrictions = <R extends Restriction>(
	requestedRestrictions: R[],
	options: { forceFetchOnMount: boolean } = { forceFetchOnMount: false }
) => {
	const dispatch = useDispatch();
	const restrictionState = useSelector(state => state.restrictions);

	useEffect(() => {
		if (options.forceFetchOnMount) {
			dispatch(forceFetchRestrictions(requestedRestrictions));
		} else {
			dispatch(fetchRestrictions(requestedRestrictions));
		}

		// We check restrictions by joining them, to allow easier usage of this hook.
		// Otherwise every call-location would have to call useRef() or smth. on
		// the parameter.
		//
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, requestedRestrictions.join('|'), options.forceFetchOnMount]);

	const fetchedRestrictions = restrictionState.fetchedSingle.map(
		fetchedSingle => fetchedSingle.restriction
	);

	return {
		data: restrictionState.items,
		fetched:
			restrictionState.fetchedFull.some(fetchedFull => fetchedFull.target === null) ||
			requestedRestrictions.every(requestedRestriction =>
				fetchedRestrictions.includes(requestedRestriction)
			),
	};
};

export const useUserRestrictions = <R extends Restriction>(
	userId: string,
	restrictions: R[],
	options: { forceFetchOnMount: boolean } = { forceFetchOnMount: false }
) => {
	const dispatch = useDispatch();
	const restrictionState = useSelector(state => state.restrictions.items);

	useEffect(() => {
		if (options.forceFetchOnMount) {
			dispatch(forceFetchRestrictionsForUser(userId, restrictions));
		} else {
			dispatch(fetchRestrictionsForUser(userId, restrictions));
		}

		// We check restrictions by joining them, to allow easier usage of this hook.
		// Otherwise every call-location would have to call useRef() or smth. on
		// the parameter.
		//
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, restrictions.join('|'), userId, options.forceFetchOnMount]);

	return restrictionState;
};

export const useCanUseChannelsRestriction = (): boolean | 'fetching' => {
	const restrictions = useRestrictions(['CAN_USE_CHANNELS', 'CAN_USE_CHANNEL_CALLCENTER_FEATURES']);

	if (!restrictions.fetched) {
		return 'fetching';
	}

	return hasRestriction(restrictions.data, 'CAN_USE_CHANNELS');
};
