import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconMobileTelephony = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M14.56 2H6.75A2.755 2.755 0 0 0 4 4.75v14.5A2.755 2.755 0 0 0 6.75 22h10.5A2.755 2.755 0 0 0 20 19.25V7.44zm.69 6.5c.69 0 1.25.56 1.25 1.25V11h-3.75V8.5zm-6.5 0h2.5V11H7.5V9.75c0-.69.56-1.25 1.25-1.25M7.5 17.25V12.5h9V14H10v4.5H8.75c-.69 0-1.25-.56-1.25-1.25m7.75 1.25H11.5v-3h5v1.75c0 .69-.56 1.25-1.25 1.25"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconMobileTelephony;
