import { createSlice } from '@reduxjs/toolkit';
import { DevicePropertyEntity } from './types';
import { fetchDeviceProperties, setDeviceProperty } from './actions';
import { devicePropertyEntityAdapter, fetchItemEntityAdapter } from './adapters';
import { selectById } from './selectors';
import { StatusItem } from '../../utils/statusEntityAdapter';

export const getInitialState = (
	deviceProperties?: DevicePropertyEntity[],
	fetchItems?: StatusItem[]
) => ({
	items: devicePropertyEntityAdapter.getInitialState({}, deviceProperties),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetchItems),
});

const slice = createSlice({
	name: 'deviceProperties',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDeviceProperties.pending, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.deviceId,
				status: 'pending',
			});
		});

		builder.addCase(fetchDeviceProperties.fulfilled, (state, { payload, meta }) => {
			const item = selectById(state, meta.arg.deviceId) || {
				id: meta.arg.deviceId,
				properties: [],
			};

			devicePropertyEntityAdapter.upsertOne(state.items, {
				...item,
				properties: [...item.properties, ...payload],
			});

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.deviceId,
				status: 'succeeded',
			});
		});

		builder.addCase(setDeviceProperty.pending, (state, { meta }) => {
			const item = selectById(state, meta.arg.deviceId) || {
				id: meta.arg.deviceId,
				properties: [],
			};

			const { deviceId: _, ...property } = meta.arg;

			devicePropertyEntityAdapter.upsertOne(state.items, {
				...item,
				properties: [...item.properties, { ...property }],
			});

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.deviceId,
				status: 'succeeded',
			});
		});
	},
});

export const reducer = slice.reducer;
