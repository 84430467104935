import React from 'react';
import classnames from 'classnames';

import classes from './DropdownContent.module.scss';

export type HorizontalPosition = { left: number } | { right: number };
export type VerticalPosition = { top: number } | { bottom: number };

export type DropdownPosition = HorizontalPosition & VerticalPosition;
export type DropdownSize = {
	width: number;
	height: number;
};

interface Props {
	position: DropdownPosition;
	open: boolean;
	onClose: () => void;
	dialogCount: number;
	children: React.ReactNode;
	preventBackgroundInteraction?: boolean;
}

export class DropdownContent extends React.PureComponent<Props> {
	private dropdown = React.createRef<HTMLDivElement>();

	public componentDidMount() {
		window.addEventListener('resize', this.onResize);
		document.addEventListener('click', this.onClick);
		document.addEventListener('keydown', this.onKeyDown);
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
		document.removeEventListener('click', this.onClick);
		document.removeEventListener('keydown', this.onKeyDown);
	}

	public calculateSize(): DropdownSize | null {
		if (!this.dropdown.current) {
			return null;
		}

		const dropdownDimensions = this.dropdown.current.getBoundingClientRect();

		return {
			width: dropdownDimensions.width,
			height: dropdownDimensions.height,
		};
	}

	private onResize = () => {
		// We try to prevent misalignment by closing the dropdown on resize
		this.props.onClose();
	};

	private onClick = (e: MouseEvent) => {
		if (!this.props.open || !this.dropdown.current || this.props.dialogCount > 0) {
			return;
		}

		let currentTarget = e.target;
		while (currentTarget) {
			if (currentTarget === this.dropdown.current) {
				return;
			}

			currentTarget = (currentTarget as HTMLElement).parentElement;
		}

		this.props.onClose();
	};

	private onKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			if (this.props.open) {
				this.props.onClose();
			}
		}
	};

	public render() {
		const style: React.CSSProperties = {};

		if ('left' in this.props.position) {
			style.left = this.props.position.left;
		} else {
			style.right = window.innerWidth - this.props.position.right;
		}

		if ('top' in this.props.position) {
			style.top = this.props.position.top;
		} else {
			style.bottom = window.innerHeight - this.props.position.bottom;
		}

		return (
			<div
				className={classnames(classes.dropdownWrapper, {
					[classes.visible]: this.props.open,
					[classes.preventBackgroundInteraction]: this.props.preventBackgroundInteraction,
				})}
			>
				<div ref={this.dropdown} className={classes.dropdown} style={style}>
					{this.props.children}
				</div>
			</div>
		);
	}
}
