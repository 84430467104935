import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect, ReduxProps } from '../redux';
import {
	fetchFaxLines,
	selectFaxlinesForOwner,
	selectFaxlinesForOwners,
} from '../redux/slices/faxlines';
import { fetchSms, selectFirstSmsId, selectSmsForUser } from '../redux/slices/sms';
import PureCommunicationButtons from './PureCommunicationButtons';
import { fetchGroups, selectGroupsByUserId } from '../redux/modules/groups';
import { fetchUserInfo } from '../redux/slices/userinfo';
import { ReduxState } from '../redux/types';
import { WithDialogProps, withDialogs } from '../routes/paths/dialogs';
import { hasRestriction } from '../redux/modules/restrictions';
import { TranslateProps, withTranslate } from '../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	faxlines: state.faxlines,
	links: state.links,
	sms: state.sms.items,
	userinfo: state.userinfo,
	groups: state.groups,
	restrictions: state.restrictions,
});

const mapDispatchToProps = {
	fetchFaxLines,
	fetchSms,
	fetchUserInfo,
	fetchGroups,
};

interface ExternalProps {
	webuserId: string;
}

type Props = ExternalProps &
	WithDialogProps &
	TranslateProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps> &
	RouteComponentProps;

class ConnectedCommunicationButtons extends React.Component<Props> {
	public constructor(props: Props) {
		super(props);
		this.props.fetchFaxLines();
		this.props.fetchSms({ userId: this.props.webuserId });
		this.props.fetchUserInfo();
		this.props.fetchGroups();
	}

	private handleCallButton = () => {
		this.props.dialogs.clickToDial.open({
			webuserId: this.props.webuserId,
		});
	};

	private handleFaxButton = () => {
		this.props.dialogs.sendFax.open({
			webuserId: this.props.webuserId,
		});
	};

	private handleSmsButton = () => {
		this.props.dialogs.smsSend.open();
	};

	private getFirstFaxlineId = () => {
		const faxLineByOwner = selectFaxlinesForOwner(this.props.faxlines, this.props.webuserId);

		const faxlineId = faxLineByOwner.length > 0 ? faxLineByOwner[0] : null;

		if (faxlineId) {
			return faxlineId;
		}

		const userGroupIds = selectGroupsByUserId(this.props.groups.items, this.props.webuserId).map(
			group => group.id
		);

		const groupFaxlines = selectFaxlinesForOwners(this.props.faxlines, userGroupIds);

		return groupFaxlines.length > 0 ? groupFaxlines[0] : null;
	};

	public render() {
		if (this.props.userinfo.data && this.props.userinfo.data.sub === this.props.webuserId) {
			return (
				<PureCommunicationButtons
					onCallButtonClicked={this.handleCallButton}
					onFaxButtonClicked={
						this.getFirstFaxlineId() &&
						hasRestriction(this.props.restrictions.items, 'CAN_SEND_FAX', this.props.webuserId)
							? this.handleFaxButton
							: undefined
					}
					onSmsButtonClicked={
						selectFirstSmsId(selectSmsForUser(this.props.sms, this.props.webuserId))
							? this.handleSmsButton
							: undefined
					}
					translate={this.props.translate}
				/>
			);
		}

		return null;
	}
}

export default withDialogs(
	withRouter(
		withTranslate(connect(mapStateToProps, mapDispatchToProps)(ConnectedCommunicationButtons))
	)
);
