import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';
import { ScopeState } from './types';

const shouldFetchScopes = (state: ScopeState) => !state.fetching && !state.fetched;

export const fetchScopes = createAsyncThunk(
	'scopes/fetch',
	async () => (await api.getScopes()).items,
	{
		condition: (_args, { getState }) => shouldFetchScopes(getState().scopes),
	}
);
