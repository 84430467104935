import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconInput_device = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M17 11c0 2.755-2.245 5-5 5s-5-2.245-5-5V7c0-2.755 2.245-5 5-5s5 2.245 5 5zm-5-7.5c-1.93 0-3.5 1.57-3.5 3.5v4c0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5V7c0-1.93-1.57-3.5-3.5-3.5m6.01 9.985 1.385.575a7.99 7.99 0 0 1-6.645 4.9v1.54H17V22H7v-1.5h4.26v-1.54a7.98 7.98 0 0 1-6.645-4.9L6 13.485a6.49 6.49 0 0 0 6.005 4.01 6.49 6.49 0 0 0 6.005-4.01"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconInput_device;
