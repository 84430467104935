import { Checkbox, Search, Button } from '@panda/ui';
import { History } from 'history';
import React from 'react';
import { ManagedMultiFieldValidator } from '@web-apps/forms';
import { ApiLabel } from '../../../api/types/labels';
import { Translate } from '../../../redux/slices/translations';
import { NormalizedEvent } from '../normalize/events';
import classes from './ListLabelSlideInContent.module.scss';

interface Props {
	history: History;
	translate: Translate;
	onClose: () => void;

	labels: ApiLabel[];
	events: NormalizedEvent[];

	createLabel: (name: string) => void;
	onAttachLabel: (labelId: number) => void;
	onDetachLabel: (labelId: number) => void;

	buildEditLabelLink: (labelId: number) => string;
	createLabelPath: string;
	validateLabel: (translate: Translate) => ManagedMultiFieldValidator<string, unknown, string>;
}

interface State {
	filter: string;
}

export class ListLabelSlideInContent extends React.PureComponent<Props, State> {
	public state = {
		filter: '',
	};

	private onLabelClick = (labelId: number) => {
		const selectionState = this.calculateSelectionState(this.props.events);

		if (selectionState[labelId]) {
			this.props.onDetachLabel(labelId);
		} else {
			this.props.onAttachLabel(labelId);
		}
	};

	private onFilterChange = (value: string) => {
		if (value.length > 24) {
			return;
		}
		this.setState({ filter: value });
	};

	private onCreateLabel = () => {
		this.props.createLabel(this.state.filter);
		this.setState({ filter: '' });
	};

	private redirectToCreateLabelPage = () => {
		this.props.history.push(this.props.createLabelPath);
	};

	private redirectToEditLabelPage = (labelId: number) => {
		this.props.history.push(this.props.buildEditLabelLink(labelId));
	};

	private getCreateLabelLink = () => {
		const { valid } = this.props.validateLabel(this.props.translate)(this.state.filter, undefined);

		if (!valid) {
			return (
				<div className={classes.createLabelLinkWrapper}>
					<Button onClick={this.redirectToCreateLabelPage} icon="add" variant="loud" width="max">
						{this.props.translate('CREATE_LABEL_LINK')}
					</Button>
				</div>
			);
		}

		return (
			<div className={classes.createLabelLinkWrapper}>
				<Button onClick={this.onCreateLabel} icon="add" variant="loud" width="max">
					{this.props.translate('CREATE_NAMED_LABEL_LINK', this.state.filter)}
				</Button>
			</div>
		);
	};

	private calculateSelectionState(events: NormalizedEvent[]) {
		const selectionState: { [labelId: string]: boolean } = {};
		const selectedEvents = events.filter(event => event.selected);

		for (const event of selectedEvents) {
			for (const label of event.originalEvent.labels) {
				selectionState[label.id] = true;
			}
		}

		return selectionState;
	}

	public render() {
		const selectionState = this.calculateSelectionState(this.props.events);

		const filteredLabels = this.props.labels.filter(label =>
			label.name.toLowerCase().includes(this.state.filter.toLowerCase())
		);

		return (
			<>
				<Search
					value={this.state.filter}
					onChange={this.onFilterChange}
					placeholder={this.props.translate('LABEL_SEARCH_PLACEHOLDER')}
					ariaLabel={this.props.translate('LABEL_SEARCH_PLACEHOLDER')}
					resultCount={filteredLabels.length}
				/>
				{this.getCreateLabelLink()}
				<ul className={classes.list}>
					{filteredLabels.map(label => (
						<li key={label.id} className={classes.entry}>
							<Checkbox
								checked={!!selectionState[label.id]}
								onChange={() => {
									this.onLabelClick(label.id);
								}}
							>
								{label.name}
							</Checkbox>
							<Button
								icon="edit"
								variant="quiet"
								onClick={() => {
									this.redirectToEditLabelPage(label.id);
								}}
								iconOnly
							/>
						</li>
					))}
				</ul>

				<div className={classes.mobileCloseContainer}>
					<Button onClick={this.props.onClose} size="xlarge" width="max-on-touch-device">
						{this.props.translate('CLOSE_LABEL_SLIDE_IN')}
					</Button>
				</div>
			</>
		);
	}
}
