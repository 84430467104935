import {
	fetchItemEntityAdapter,
	phoneLineBlockAnonymousAdapter,
	updateItemEntityAdapter,
} from './adapter';
import { PhoneLineBlockAnonymousState } from './types';

const selectItems = (state: PhoneLineBlockAnonymousState) => state.items;
const phoneLineBlockAnonymousSelectors = phoneLineBlockAnonymousAdapter.getSelectors(selectItems);
export const { selectById } = phoneLineBlockAnonymousSelectors;

const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();
export const { isSucceeded: isFetched, isPending: isFetching } = fetchItemEntitySelectors;

const updateItemEntitySelectors = updateItemEntityAdapter.getSelectors();
export const { isPending: isUpdating } = updateItemEntitySelectors;
