import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconHouse = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M12.87 2.55a1.245 1.245 0 0 0-1.74 0L3 10.435v9.315c0 .69.56 1.25 1.25 1.25H9v-5c0-1.655 1.345-3 3-3s3 1.345 3 3v5h4.75c.69 0 1.25-.56 1.25-1.25v-9.315z"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconHouse;
