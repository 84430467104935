import React, { SVGProps } from 'react';
import classnames from 'classnames';

export const SortArrowIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 8 16"
		fill="currentColor"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
		className={classnames('min-w-8', 'min-h-16', 'w-8', 'h-16', props.className)}
	>
		<path d="M1.19 5.266 3.202 2.42a.97.97 0 0 1 1.598 0l2.01 2.847c.475.672.009 1.615-.798 1.615H4.987v6.102C4.987 13.545 4.545 14 4 14c-.545 0-.987-.455-.987-1.017V6.881H1.989c-.807 0-1.273-.943-.798-1.615Z" />
	</svg>
);
