import React from 'react';
import { configureStore } from '@reduxjs/toolkit';

import { Provider } from '../../../redux';
import { initialState, State } from './state';
import {
	FetchHistoryErrorAction,
	FetchHistoryPendingAction,
	FetchHistorySuccessAction,
	FetchHistoryTooManyEventsAction,
} from './history/fetchHistory/types';
import {
	fetchHistoryError,
	fetchHistoryPending,
	fetchHistorySuccess,
	fetchHistoryTooManyEvents,
} from './history/fetchHistory/reducer';
import { Contact } from '../../../redux/modules/contacts';
import { SaveNotePendingAction } from './history/saveNote/types';
import { saveNotePending } from './history/saveNote/reducer';
import { markReadPending } from './history/markRead/reducer';
import { MarkReadPendingAction } from './history/markRead/types';
import { ChangeActiveAudioPlayerAction } from './changeActiveAudioPlayer/types';
import { changeActiveAudioPlayerReducer } from './changeActiveAudioPlayer/reducer';
import { fetchHistory } from './history/fetchHistory/action';
import { changeActiveAudioPlayer } from './changeActiveAudioPlayer/action';
import { markRead } from './history/markRead/action';
import { saveNote } from './history/saveNote/action';
import { resendFax } from './resendFax/action';
import { ToggleEntrySelectionAction } from './history/toggleEntrySelection/types';
import { toggleEntrySelectionReducer } from './history/toggleEntrySelection/reducer';
import { toggleEntrySelection } from './history/toggleEntrySelection/action';
import { UpdateExtensionAction } from './updateExtensions/types';
import { updateExtensionsReducer } from './updateExtensions/reducer';
import { updateExtensions } from './updateExtensions/action';
import { UpdateContactsAction } from './updateContacts/types';
import { updateContactsReducer } from './updateContacts/reducer';
import { updateContacts } from './updateContacts/action';
import { SelectMultipleAction } from './history/selectMultiple/types';
import { selectSubsetReducer } from './history/selectMultiple/reducer';
import { selectMultiple } from './history/selectMultiple/action';
import {
	DeleteEntriesErrorAction,
	DeleteEntriesPendingAction,
	DeleteEntriesSuccessAction,
} from './history/deleteEntries/types';
import {
	deleteEntriesError,
	deleteEntriesPending,
	deleteEntriesSuccess,
} from './history/deleteEntries/reducer';
import { deleteEntries } from './history/deleteEntries/action';
import {
	ArchiveEntriesErrorAction,
	ArchiveEntriesPendingAction,
	ArchiveEntriesSuccessAction,
} from './history/archiveEntries/types';
import {
	DearchiveEntriesErrorAction,
	DearchiveEntriesPendingAction,
	DearchiveEntriesSuccessAction,
} from './history/dearchiveEntries/types';
import {
	archiveEntriesError,
	archiveEntriesPending,
	archiveEntriesSuccess,
} from './history/archiveEntries/reducer';
import {
	dearchiveEntriesError,
	dearchiveEntriesPending,
	dearchiveEntriesSuccess,
} from './history/dearchiveEntries/reducer';
import { archiveEntries } from './history/archiveEntries/action';
import { dearchiveEntries } from './history/dearchiveEntries/action';
import { ChangePageSizeAction } from './changePageSize/types';
import { changePageSizeReducer } from './changePageSize/reducer';
import { changePageSize } from './changePageSize/action';
import { UpdateBlacklistAction } from './updateBlacklist/types';
import { updateBlacklistReducer } from './updateBlacklist/reducer';
import { BlocklistEntry } from '../../../redux/slices/blocklist';
import { updateBlacklist } from './updateBlacklist/action';
import {
	ToggleStarErrorAction,
	ToggleStarPendingAction,
	ToggleStarSuccessAction,
} from './history/toggleStar/types';
import {
	toggleStarError,
	toggleStarPending,
	toggleStarSuccess,
} from './history/toggleStar/reducer';
import { toggleStar } from './history/toggleStar/action';
import { Selections } from '../selections/parsers';
import { UpdateLabelsAction } from './labels/updateLabels/types';
import { updateLabels as updateLabelsReducer } from './labels/updateLabels/reducer';
import { updateLabels } from './labels/updateLabels/action';
import { AttachLabelPendingAction } from './labels/attachLabel/types';
import { attachLabelPending } from './labels/attachLabel/reducer';
import { attachLabel } from './labels/attachLabel/action';
import { detachLabel } from './labels/detachLabel/action';
import { DetachLabelPendingAction } from './labels/detachLabel/types';
import { detachLabelPending } from './labels/detachLabel/reducer';
import { MarkUnreadPendingAction } from './history/markUnread/types';
import { markUnreadPending } from './history/markUnread/reducer';
import { markUnread } from './history/markUnread/action';
import { HistoryEntry, HistoryEntryStarred } from '../../../api/types/events';
import { ExtensionState } from '../../../utils/endpoints';
import { UpdateNumbersAction } from './updateNumbers/types';
import { updateNumbers } from './updateNumbers/action';
import { updateNumbersReducer } from './updateNumbers/reducer';
import { SipgateDomain } from '../../../redux/slices/userinfo';
import { Label } from '../../../redux/modules/labels';
import { Phonenumber } from '../../../redux/slices/phonenumbers';

type ActionType =
	| FetchHistoryPendingAction
	| FetchHistoryErrorAction
	| FetchHistoryTooManyEventsAction
	| FetchHistorySuccessAction
	| SaveNotePendingAction
	| MarkReadPendingAction
	| MarkUnreadPendingAction
	| ChangeActiveAudioPlayerAction
	| ToggleEntrySelectionAction
	| UpdateExtensionAction
	| UpdateContactsAction
	| SelectMultipleAction
	| DeleteEntriesPendingAction
	| DeleteEntriesSuccessAction
	| DeleteEntriesErrorAction
	| ArchiveEntriesPendingAction
	| ArchiveEntriesSuccessAction
	| ArchiveEntriesErrorAction
	| DearchiveEntriesPendingAction
	| DearchiveEntriesSuccessAction
	| DearchiveEntriesErrorAction
	| ChangePageSizeAction
	| UpdateBlacklistAction
	| ToggleStarPendingAction
	| ToggleStarSuccessAction
	| ToggleStarErrorAction
	| UpdateLabelsAction
	| AttachLabelPendingAction
	| DetachLabelPendingAction
	| UpdateNumbersAction;

const rootReducer = (domain: SipgateDomain) => (state: State | undefined, action: ActionType) => {
	if (state === undefined) {
		return { ...initialState, domain };
	}

	switch (action.type) {
		case 'FETCH_HISTORY_PENDING':
			return fetchHistoryPending(state, action);
		case 'FETCH_HISTORY_ERROR':
			return fetchHistoryError(state);
		case 'FETCH_HISTORY_TOO_MANY_EVENTS':
			return fetchHistoryTooManyEvents(state);
		case 'FETCH_HISTORY_SUCCESS':
			return fetchHistorySuccess(state, action);
		case 'SAVE_NOTE_PENDING':
			return saveNotePending(state, action);
		case 'MARK_READ_PENDING':
			return markReadPending(state, action);
		case 'MARK_UNREAD_PENDING':
			return markUnreadPending(state, action);
		case 'CHANGE_ACTIVE_AUDIOPLAYER':
			return changeActiveAudioPlayerReducer(state, action);
		case 'TOGGLE_ENTRY_SELECTION':
			return toggleEntrySelectionReducer(state, action);
		case 'UPDATE_EXTENSIONS':
			return updateExtensionsReducer(state, action);
		case 'UPDATE_CONTACTS':
			return updateContactsReducer(state, action);
		case 'SELECT_MULTIPLE':
			return selectSubsetReducer(state, action);
		case 'DELETE_ENTRIES_PENDING':
			return deleteEntriesPending(state, action);
		case 'DELETE_ENTRIES_SUCCESS':
			return deleteEntriesSuccess(state);
		case 'DELETE_ENTRIES_ERROR':
			return deleteEntriesError(state);
		case 'ARCHIVE_ENTRIES_PENDING':
			return archiveEntriesPending(state, action);
		case 'ARCHIVE_ENTRIES_SUCCESS':
			return archiveEntriesSuccess(state);
		case 'ARCHIVE_ENTRIES_ERROR':
			return archiveEntriesError(state);
		case 'DEARCHIVE_ENTRIES_PENDING':
			return dearchiveEntriesPending(state, action);
		case 'DEARCHIVE_ENTRIES_SUCCESS':
			return dearchiveEntriesSuccess(state);
		case 'DEARCHIVE_ENTRIES_ERROR':
			return dearchiveEntriesError(state);
		case 'CHANGE_PAGE_SIZE':
			return changePageSizeReducer(state, action);
		case 'UPDATE_BLACKLIST':
			return updateBlacklistReducer(state, action);
		case 'TOGGLE_STAR_PENDING':
			return toggleStarPending(state, action);
		case 'TOGGLE_STAR_SUCCESS':
			return toggleStarSuccess(state);
		case 'TOGGLE_STAR_ERROR':
			return toggleStarError(state);
		case 'UPDATE_LABELS':
			return updateLabelsReducer(state, action);
		case 'ATTACH_LABEL_PENDING':
			return attachLabelPending(state, action);
		case 'DETACH_LABEL_PENDING':
			return detachLabelPending(state, action);
		case 'UPDATE_NUMBERS':
			return updateNumbersReducer(state, action);

		default:
			return state;
	}
};

export interface HistoryStore {
	Provider: React.ComponentType<{ children: React.ReactNode }>;
	fetchHistory: (
		selections: Selections,
		isUserChange?: boolean
	) => Promise<{ totalCount: number; items: HistoryEntry[] } | null>;
	changeActiveAudioPlayer: (eventId: string, playerId: string, playing: boolean) => void;
	markRead: (eventIds: string[]) => Promise<void>;
	markUnread: (eventIds: string[]) => Promise<void>;
	saveNote: (eventId: string, note: string, selections: Selections) => Promise<void>;
	resendFax: (eventId: string) => Promise<void>;
	toggleEntrySelection: (eventId: string) => void;
	updateExtensions: (extensions: ExtensionState) => void;
	updateContacts: (contacts: Contact[]) => void;
	selectMultiple: (eventIds: string[]) => void;
	deleteEntries: (ids: string[], selections: Selections) => Promise<void>;
	archiveEntries: (ids: string[], selections: Selections) => Promise<void>;
	dearchiveEntries: (ids: string[], selections: Selections) => Promise<void>;
	changePageSize: (size: number, selections: Selections) => void;
	updateBlacklist: (blacklist: BlocklistEntry[]) => void;
	toggleStar: (id: string, starred: HistoryEntryStarred) => Promise<void>;
	attachLabel: (labelId: number, eventIds: string[], selections: Selections) => Promise<void>;
	detachLabel: (labelId: number, eventIds: string[], selections: Selections) => Promise<void>;
	updateLabels: (labels: Label[]) => void;
	updateNumbers: (numbers: Phonenumber[]) => void;
}

export const createStore = (domain: SipgateDomain): HistoryStore => {
	const store = configureStore({
		reducer: rootReducer(domain),
		devTools: {
			name: `HistoryDetailView State ${new Date().toLocaleString()}`,
		},
	});

	return {
		Provider: ({ children }: { children: React.ReactNode }) => (
			<Provider store={store}>{children}</Provider>
		),

		fetchHistory: fetchHistory(store),
		changeActiveAudioPlayer: changeActiveAudioPlayer(store),
		markRead: markRead(store),
		markUnread: markUnread(store),
		saveNote: saveNote(store),
		resendFax: resendFax(store),
		toggleEntrySelection: toggleEntrySelection(store),
		updateExtensions: updateExtensions(store),
		updateContacts: updateContacts(store),
		selectMultiple: selectMultiple(store),
		deleteEntries: deleteEntries(store),
		archiveEntries: archiveEntries(store),
		dearchiveEntries: dearchiveEntries(store),
		changePageSize: changePageSize(store),
		updateBlacklist: updateBlacklist(store),
		toggleStar: toggleStar(store),
		attachLabel: attachLabel(store),
		detachLabel: detachLabel(store),
		updateNumbers: updateNumbers(store),
		updateLabels: updateLabels(store),
	};
};
