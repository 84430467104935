import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import serviceUrls from '@web-apps/service-urls';
import { ReduxState } from '../../redux/types';
import { fetchTranslations } from '../../redux/slices/translations';
import { EventState, fetchEvents, selectForceEvents } from '../../redux/modules/events';
import { connect, ReduxProps } from '../../redux';
import { fetchLinks } from '../../redux/slices/links';
import { buildAuthenticateUrl } from '../../utils/authenticate';
import { LogoSpinner } from '../../components/spinner/LogoSpinner';
import * as paths from '../../routes/paths';
import { fetchUserInfo } from '../../redux/slices/userinfo';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';
import { ChangePasswordView } from './changePassword/ChangePasswordView';
import { PricingUpdateView } from './pricingUpdate/PricingUpdateView';
import { CompanySizeView } from './companySize/CompanySizeView';

const mapStateToProps = (state: ReduxState) => ({
	events: state.events,
	userinfo: state.userinfo,
	links: state.links,
});

const mapDispatchToProps = {
	fetchEvents,
	fetchLinks,
	fetchUserInfo,
	fetchTranslations,
};

type Props = RouteComponentProps &
	TranslateProps &
	ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class ForceWebView extends React.Component<Props> {
	private allowedCommand = ['CHANGEPASSWORD', 'PRICINGUPDATED', 'TEAMPERSEATPREVIEWCOMMAND'];

	public componentDidMount() {
		this.props.fetchEvents();
		this.props.fetchLinks();

		if (this.props.userinfo.fetched) {
			this.props.fetchTranslations({ locale: this.props.userinfo.data.locale });
		}
	}

	public componentDidUpdate(prevProps: Props) {
		if (
			this.props.userinfo.fetched &&
			this.props.userinfo.data.locale !== prevProps.userinfo.data?.locale
		) {
			this.props.fetchTranslations({ locale: this.props.userinfo.data.locale });
		}

		if (
			this.props.links.fetched &&
			this.props.events.fetched &&
			this.props.userinfo.fetched &&
			!this.areThereEventsToHandle(this.props.events, this.allowedCommand)
		) {
			window.location.href = buildAuthenticateUrl(
				this.props.links.items.legacyTokenAuthenticateUrl,
				this.props.userinfo.data.domain === 'sipgate.de'
					? serviceUrls.teamWebDe
					: serviceUrls.teamWebUk
			);
		} else if (
			this.props.events.fetched &&
			this.props.links.fetched &&
			// this.props.translations.fetched &&
			this.areThereEventsToHandle(this.props.events, this.allowedCommand) &&
			!paths.forceChangePasswordPath.regex.test(this.props.history.location.pathname) &&
			!paths.forcePricingUpdatePath.regex.test(this.props.history.location.pathname) &&
			!paths.forceCompanySizePath.regex.test(this.props.history.location.pathname)
		) {
			switch (selectForceEvents(this.props.events)[0].eventName) {
				case 'CHANGEPASSWORD':
					this.props.history.push(paths.forceChangePasswordPath.build());
					break;
				case 'PRICINGUPDATED':
					this.props.history.push(paths.forcePricingUpdatePath.build());
					break;
				case 'TEAMPERSEATPREVIEWCOMMAND':
					this.props.history.push(paths.forceCompanySizePath.build());
			}
		}
	}

	private areThereEventsToHandle(eventsState: EventState, allowedCommands: string[]) {
		return (
			selectForceEvents(eventsState)
				.map(e => e.eventName)
				.filter(name => allowedCommands.includes(name.toUpperCase())).length > 0
		);
	}

	public render() {
		if (this.props.events.fetching) {
			return <LogoSpinner />;
		}

		return (
			<>
				<Switch>
					<Route path={paths.forcePricingUpdatePath.path} component={PricingUpdateView} />
					<Route path={paths.forceChangePasswordPath.path} component={ChangePasswordView} />
					<Route path={paths.forceCompanySizePath.path} component={CompanySizeView} />
				</Switch>
			</>
		);
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(ForceWebView));
