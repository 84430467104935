import { useEffect } from 'react';
import { SliceData } from '../../../index';
import { useDispatch, useSelector } from '../../../utils/wrapper';
import { fetchDataUsageSettings } from './actions';
import { DataUsageSettings } from './types';
import { isFetched, selectById } from './selectors';

export const useDataUsageSettings = (containerId: string): SliceData<DataUsageSettings> => {
	const state = useSelector(s => s.dataUsage);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDataUsageSettings({ containerId }));
	}, [containerId, dispatch]);

	if (!isFetched(state.settings, containerId)) {
		return {
			fetched: false,
			data: null,
		};
	}

	const settings = selectById(state.settings, containerId);

	return {
		fetched: true,
		data: settings as DataUsageSettings,
	};
};
