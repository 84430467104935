import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconCheck_circle = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M9.615 15.175c.245.245.565.365.885.365s.64-.12.885-.365L17.28 9.28l-1.06-1.06-5.72 5.72-2.22-2.22-1.06 1.06z"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2 12c0 5.515 4.485 10 10 10s10-4.485 10-10S17.515 2 12 2 2 6.485 2 12m1.5 0c0-4.685 3.815-8.5 8.5-8.5s8.5 3.815 8.5 8.5-3.815 8.5-8.5 8.5-8.5-3.815-8.5-8.5"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconCheck_circle;
