import { createSlice } from '@reduxjs/toolkit';

import { PhoneLineBlockAnonymousItem, PhoneLineBlockAnonymousState } from './types';
import { fetchBlockAnonymous, updateBlockAnonymous } from './actions';
import { StatusItem } from '../../utils/statusEntityAdapter';
import {
	fetchItemEntityAdapter,
	phoneLineBlockAnonymousAdapter,
	updateItemEntityAdapter,
} from './adapter';

export const getInitialState = (
	items?: PhoneLineBlockAnonymousItem[],
	fetch?: StatusItem[],
	update?: StatusItem[]
): PhoneLineBlockAnonymousState => ({
	items: phoneLineBlockAnonymousAdapter.getInitialState({}, items),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetch),
	update: updateItemEntityAdapter.getInitialState({}, update),
});

const slice = createSlice({
	name: 'phoneLineBlockAnonymous',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchBlockAnonymous.pending, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.phoneLineId,
				status: 'pending',
			});
		});

		builder.addCase(fetchBlockAnonymous.fulfilled, (state, { payload, meta }) => {
			phoneLineBlockAnonymousAdapter.upsertOne(state.items, {
				id: meta.arg.phoneLineId,
				...payload,
			});
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.phoneLineId,
				status: 'succeeded',
			});
		});

		builder.addCase(updateBlockAnonymous.pending, (state, { meta }) => {
			phoneLineBlockAnonymousAdapter.upsertOne(state.items, {
				id: meta.arg.phoneLineId,
				enabled: meta.arg.enabled,
				target: meta.arg.target,
			});
			updateItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.phoneLineId,
				status: 'pending',
			});
		});

		builder.addCase(updateBlockAnonymous.fulfilled, (state, { meta }) => {
			updateItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.phoneLineId,
				status: 'succeeded',
			});
		});
	},
});

export const reducer = slice.reducer;
