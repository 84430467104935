import { createAction } from '../..';
import api from '../../../api';
import { ReduxState } from '../../types';
import { SipgateDomain } from '../../slices/userinfo';
import { ProductType } from './types';

export const fetchProducts = createAction(
	'PRODUCTS_FETCH',
	(type: ProductType, domain: SipgateDomain) => ({
		promise: () => api.getProducts(type),
		shouldFetch: (state: ReduxState) =>
			!state.products.fetchingForProductType.includes(type) &&
			!state.products.fetchedForProductType.includes(type),
		data: {
			type,
			domain,
		},
	})
);

export const forceFetchProducts = createAction(
	'PRODUCTS_FETCH',
	(type: ProductType, domain: SipgateDomain) => ({
		promise: api.getProducts(type),
		data: {
			type,
			domain,
		},
	})
);

export const fetchProductOptions = createAction(
	'PRODUCT_OPTIONS_FETCH',
	(productId: string, domain: SipgateDomain) => ({
		promise: () => api.getProductOptions(productId),
		shouldFetch: (state: ReduxState) =>
			!state.products.fetchedOptionsForProduct.includes(productId) &&
			!state.products.fetchingOptionsForProduct.includes(productId),
		data: {
			dependantProduct: productId,
			domain,
		},
	})
);

export const forceFetchProductOptions = createAction(
	'PRODUCT_OPTIONS_FETCH',
	(productId: string, domain: SipgateDomain) => ({
		promise: () => api.getProductOptions(productId),
		data: {
			dependantProduct: productId,
			domain,
		},
	})
);

const shouldFetchIsProductBookingAllowed = (state: ReduxState, productId: string) =>
	Number(productId) > 0 &&
	!state.products.fetchingIsProductBookingAllowed.includes(productId) &&
	!state.products.fetchedIsProductBookingAllowed.includes(productId);

export const fetchIsProductBookingAllowed = createAction(
	'IS_PRODUCT_BOOKING_ALLOWED_FETCH',
	(productId: string) => ({
		promise: () => api.isProductAllowed(productId),
		shouldFetch: (state: ReduxState) => shouldFetchIsProductBookingAllowed(state, productId),
		data: {
			productId,
		},
	})
);

export const forceFetchIsProductBookingAllowed = createAction(
	'IS_PRODUCT_BOOKING_ALLOWED_FETCH',
	(productId: string) => ({
		promise: () => api.isProductAllowed(productId),
		data: {
			productId,
		},
	})
);

export const fetchLegacyProduct = createAction(
	'SINGLE_PRODUCT_FETCH',
	(productId: string, domain: SipgateDomain) => ({
		promise: () => api.getProduct(productId),
		shouldFetch: (state: ReduxState) =>
			!state.products.legacyItems.find(product => product.id === productId) &&
			!state.products.fetchingLegacyItems.includes(productId),
		data: {
			productId,
			domain,
		},
	})
);
