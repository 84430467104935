import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconPlay_circle = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2 12c0 5.515 4.485 10 10 10s10-4.485 10-10S17.515 2 12 2 2 6.485 2 12m1.5 0c0-4.685 3.815-8.5 8.5-8.5s8.5 3.815 8.5 8.5-3.815 8.5-8.5 8.5-8.5-3.815-8.5-8.5m6.125 4.215c.195.115.41.17.625.17v-.005c.215 0 .43-.055.625-.17l5.425-3.135c.39-.225.625-.635.625-1.08s-.24-.86-.625-1.085l-5.425-3.135A1.251 1.251 0 0 0 9 8.86v6.27c0 .455.235.86.625 1.085M10.5 14.7V9.3l4.675 2.7z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconPlay_circle;
