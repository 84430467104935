import React from 'react';

import { UserInfo } from '../redux/slices/userinfo';

interface Props {
	userinfo: UserInfo;
}

export class LanguageAttributeUpdater extends React.PureComponent<Props> {
	public componentDidMount = () => this.setLanguageAttribute();

	public componentDidUpdate = () => this.setLanguageAttribute();

	private setLanguageAttribute() {
		document.documentElement.lang = this.props.userinfo.locale === 'en_GB' ? 'en' : 'de';
	}

	public render() {
		return null;
	}
}
