import { createEntityAdapter } from '@reduxjs/toolkit';
import { PhoneLineBlockAnonymousItem, PhoneLineBlockAnonymousState } from './types';
import { createStatusItemEntityAdapter } from '../../utils/statusEntityAdapter';

export const phoneLineBlockAnonymousAdapter = createEntityAdapter<PhoneLineBlockAnonymousItem>();

const fetchItemSelector = (state: PhoneLineBlockAnonymousState) => state.fetch;
export const fetchItemEntityAdapter = createStatusItemEntityAdapter(fetchItemSelector);

const updateItemSelector = (state: PhoneLineBlockAnonymousState) => state.update;
export const updateItemEntityAdapter = createStatusItemEntityAdapter(updateItemSelector);
