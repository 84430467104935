/* eslint-disable react/no-unused-prop-types */
export type Props = {
	/**
	 * Die Url, auf die bei Klick auf den Datensatz navigiert werden soll.
	 */
	href: string;
	/**
	 * Eine optionale Grafik, die der schnelleren Identifikation des Listeneintrags dient.
	 * Muss in geeigneten Größen für `desktop` und `mobile` übergeben werden.
	 *
	 * Wir empfehlen für ein Avatar eine Größe von 48px auf dem Desktop und 32px Mobil, sowie für Icons 40px auf dem Desktop und 32px Mobil.
	 */
	graphic?: { mobile: React.ReactNode; desktop: React.ReactNode };
	/**
	 * Füge hier ein oder mehrere `ListNavViewColumn`-Komponenten ein.
	 */
	children: JSX.Element | JSX.Element[];
};

/**
 * This components exist so the usage of <ListNavView/> has a pretty API
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ListNavViewItem = (props: Props) => null;
