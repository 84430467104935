import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconDevice_pool = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M4.75 3A2.75 2.75 0 0 0 2 5.75v.75h6.25c.855 0 1.617.39 2.121 1h4.879A2.75 2.75 0 0 1 18 10.25v.879c.61.504 1 1.266 1 2.121V19h.25A2.75 2.75 0 0 0 22 16.25V5.75A2.75 2.75 0 0 0 19.25 3zm-1.5 5C2.56 8 2 8.56 2 9.25v10.5c0 .69.56 1.25 1.25 1.25h5c.69 0 1.25-.56 1.25-1.25V9.25C9.5 8.56 8.94 8 8.25 8zm9.25 5.5v3H16v-3zM11 16.75c0 .605.43 1.11 1 1.225v1.775c0 .69.56 1.25 1.25 1.25h2c.69 0 1.25-.56 1.25-1.25v-1.775c.57-.116 1-.62 1-1.225v-3.5c0-.605-.43-1.11-1-1.225V10.25c0-.69-.56-1.25-1.25-1.25h-2c-.69 0-1.25.56-1.25 1.25v1.775c-.57.116-1 .62-1 1.225z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconDevice_pool;
