import api from '../../../api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import { OwnerType, UUID } from './types';

const shouldFetchAudioFiles = (state: ReduxState, page: number) =>
	!state.files.audiofiles.fetching === false &&
	!state.files.audiofiles.pages_fetched.includes(page);

export const fetchAudioFiles = createAsyncThunk(
	'files/fetchAudioFiles',
	async (params: { page: number; forceFetch?: boolean }) => api.getGenericAudioFiles(params.page),
	{
		condition: (params, { getState }) => {
			if (params.forceFetch === true) {
				return true;
			}
			return shouldFetchAudioFiles(getState(), params.page);
		},
	}
);

export const uploadAudioFile = createAsyncThunk(
	'files/uploadAudioFile',
	(params: { alias: string; base64Content: string; owner: string; ownerType: OwnerType }) =>
		api.uploadGenericAudioFile(params.alias, params.base64Content, params.owner, params.ownerType)
);

export const deleteAudioFile = createAsyncThunk('files/deleteAudioFile', (params: { id: UUID }) =>
	api.deleteGenericAudioFile(params.id)
);

export const renameAudioFile = createAsyncThunk(
	'files/renameAudioFile',
	(params: { id: UUID; alias: string }) => api.renameGenericAudioFile(params.id, params.alias)
);
