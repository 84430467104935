import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconESim = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M22 8v1.5h-2v1.75h2v1.5h-2v1.75h2V16h-2v1.25A2.755 2.755 0 0 1 17.25 20h-.75v2H15v-2h-2.25v2h-1.5v-2H9v2H7.5v-2h-.75A2.755 2.755 0 0 1 4 17.25V16H2v-1.5h2v-1.75H2v-1.5h2V9.5H2V8h2V6.75A2.755 2.755 0 0 1 6.75 4h.75V2H9v2h2.25V2h1.5v2H15V2h1.5v2h.75A2.755 2.755 0 0 1 20 6.75V8zm-4.75 10.5c.69 0 1.25-.56 1.25-1.25V6.75c0-.69-.56-1.25-1.25-1.25H6.75c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25zm-1.75-3h-7v-7h7zM14 10h-4v4h4z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconESim;
