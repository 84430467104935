import React, { Component } from 'react';
import { connect, ReduxProps } from '../../redux';
import { ReduxState } from '../../redux/types';

import { discardEvent, fetchEvents } from '../../redux/modules/events';

import { fetchUserInfo } from '../../redux/slices/userinfo';
import { StatusPageTour } from './StatusPageTour';
import { TranslateProps, withTranslate } from '../../helpers/withTranslations';

const mapStateToProps = (state: ReduxState) => ({
	events: state.events,
	dialogs: state.dialogs,
	userinfo: state.userinfo.fetched ? state.userinfo.data : null,
});

const mapDispatchToProps = {
	fetchEvents,
	discardEvent,
	fetchUserInfo,
};

type Props = TranslateProps & ReduxProps<typeof mapStateToProps, typeof mapDispatchToProps>;

class TourGuide extends Component<Props> {
	public componentDidMount(): void {
		this.props.fetchEvents();
		this.props.fetchUserInfo();
	}

	public render() {
		if (this.props.dialogs.dialogCount > 0 || !this.props.userinfo) {
			return <></>;
		}

		if (
			this.props.translate('STATUS_PAGE_TOUR_DESCRIPTION') !== '' &&
			this.props.translate('STATUS_PAGE_TOUR_TITLE') !== ''
		) {
			return <StatusPageTour onDone={() => {}} translate={this.props.translate} />;
		}

		return <></>;
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(TourGuide));
