import {
	ConditionalForwardingSet,
	ForwardingSet,
	ForwardingsState,
	ForwardingType,
	TimeBasedForwardingSet,
	UnconditionalForwardingSet,
} from './types';
import {
	ActiveTime,
	ForwardingStep,
	RegularForwardingDestination,
	VoicemailDestination,
} from '../../../api/types/forwardings';
import { invertActiveTimes } from './helpers';

export const selectAllForwardingSets = (fwd: ForwardingsState['items']) =>
	Object.values(fwd)
		.filter((a): a is TimeBasedForwardingSet[] => !!a)
		.flat();

export const selectForwardings = (fwd: ForwardingsState, extensionId: string) =>
	fwd.items[extensionId]?.sort((a, b) => a.priority - b.priority) || [];

export const selectSimpleForwardingSteps = (fwd: ForwardingSet) => {
	return fwd.type === 'unconditional' || fwd.type === 'holiday' ? fwd.unconditional : fwd.online;
};

export const selectAllForwardingSteps = (forwardingSet: ForwardingSet) => {
	if (forwardingSet.type === 'unconditional' || forwardingSet.type === 'holiday') {
		return forwardingSet.unconditional;
	}

	return [...forwardingSet.online, ...forwardingSet.busy, ...forwardingSet.offline];
};

export const selectForwardingStepsForType = (
	type: ForwardingType,
	forwardingSet: ForwardingSet | null
): ForwardingStep[] | null => {
	if (!forwardingSet || type === 'holiday') {
		return [];
	}
	if (type === 'unconditional') {
		if (forwardingSet.type !== 'unconditional') {
			return null;
		}

		return forwardingSet.unconditional;
	}

	if (forwardingSet.type !== 'conditional') {
		return null;
	}

	return forwardingSet[type];
};

export const areForwardingsFetchedForExtension = (fwd: ForwardingsState, extensionId: string) =>
	fwd.fetched[extensionId] || false;

export function isFullWeek(times: ActiveTime[]) {
	return invertActiveTimes(times, '').length === 0;
}

export const getAllActiveTimeframes = (forwardings: ForwardingSet[]): ActiveTime[] => {
	const allTimeFrames: ActiveTime[] = [];

	forwardings.forEach(set => {
		if (set.isUserDefined) {
			allTimeFrames.push(...set.activeTimes);
		}
	});

	return allTimeFrames;
};

export const getTimeNamesFromForwardings = (forwardings: ForwardingSet[]) => {
	const names = ['', '', ''];
	forwardings.forEach((forwarding, index) => {
		if (forwarding.isUserDefined) {
			names[index] = forwarding.name;
		} else {
			names[2] = forwarding.name;
		}
	});
	return names;
};

export const selectForwardingTimezone = (forwardings: ForwardingSet[]) => {
	const set = forwardings.find(s => s.isUserDefined);

	if (!set) {
		return null;
	}

	const activeTime = set.activeTimes[0];

	if (activeTime) {
		return activeTime.timeZone;
	}

	return null;
};

export function hasActiveForwardingSteps(forwarding: ForwardingSet) {
	return selectAllForwardingSteps(forwarding).length > 0;
}

export function areForwardingsUpdating(forwardings: ForwardingsState, phonelineId: string) {
	return !!forwardings.updating[phonelineId];
}

export function isVoicemailStep(
	forwardingStep: ForwardingStep
): forwardingStep is ForwardingStep<VoicemailDestination> {
	return forwardingStep.destination.type === 'VOICEMAIL';
}

export function isRegularForwardingStep(
	forwardingStep: ForwardingStep
): forwardingStep is ForwardingStep<RegularForwardingDestination> {
	return (
		forwardingStep.destination.type === 'USER' ||
		forwardingStep.destination.type === 'GROUP' ||
		forwardingStep.destination.type === 'VOICEMAIL' ||
		forwardingStep.destination.type === 'PHONENUMBER' ||
		forwardingStep.destination.type === 'MOBILE'
	);
}

export function canForwardingDelayBeChanged(
	forwardingSteps: ForwardingStep[],
	type: ForwardingType,
	stepIndex: number
) {
	if (type !== 'online') {
		return stepIndex > 0;
	}

	return true;
}

function isTimeBasedForwardingSet(
	forwardingSet: ForwardingSet
): forwardingSet is ConditionalForwardingSet | UnconditionalForwardingSet {
	return forwardingSet.type === 'conditional' || forwardingSet.type === 'unconditional';
}

export function getTimeBasedForwardingSets(
	forwardingSets: ForwardingSet[]
): Array<ConditionalForwardingSet | UnconditionalForwardingSet> {
	return forwardingSets.filter(isTimeBasedForwardingSet);
}
