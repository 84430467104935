import {
	CreateUserNotificationRequest,
	ReplaceUserNotificationRequest,
	userNotificationsRestApiClient as api,
} from './api';
import { ReduxState } from '../../types';
import { createAsyncThunk } from '../../utils/wrapper';
import { UserNotification } from './types';
import { isFetched, isFetching } from './selectors';

const shouldFetchUserNotifications = (state: ReduxState) =>
	!isFetching(state.userNotifications, '*') && !isFetched(state.userNotifications, '*');

export const fetchUserNotifications = createAsyncThunk<UserNotification[], boolean | undefined>(
	'userNotification/fetch',
	async (_force?) =>
		(await api.getUserNotifications()).map(item => {
			const { notificationId, ...withoutId } = item;
			return {
				id: notificationId,
				...withoutId,
			} as UserNotification;
		}),
	{
		condition: (force = false, { getState }) => force || shouldFetchUserNotifications(getState()),
	}
);

export const createUserNotification = createAsyncThunk(
	'userNotification/create',
	(createUserNotificationRequest: CreateUserNotificationRequest) =>
		api.createUserNotification(createUserNotificationRequest)
);

export const replaceUserNotification = createAsyncThunk(
	'userNotification/replace',
	(replaceUserNotificationRequest: ReplaceUserNotificationRequest) =>
		api.replaceUserNotification(replaceUserNotificationRequest)
);

export const deleteUserNotification = createAsyncThunk(
	'userNotification/delete',
	(notificationId: string) => api.deleteUserNotification(notificationId)
);
