import { createSlice } from '@reduxjs/toolkit';
import {
	createPersonalAccessToken,
	deletePersonalAccessToken,
	fetchPersonalAccessTokens,
} from './actions';
import { PersonalAccessTokenItem, PersonalAccessTokenState } from './types';
import {
	createItemSelectorAdapter,
	fetchItemEntityAdapter,
	personalAccessTokenEntityAdapter,
} from './adapter';
import { StatusItem } from '../../utils/statusEntityAdapter';
import { selectById, selectByTokenId } from './selectors';

export const getInitialState = (
	items?: PersonalAccessTokenItem[],
	fetch?: StatusItem[],
	create?: StatusItem[]
): PersonalAccessTokenState => ({
	items: personalAccessTokenEntityAdapter.getInitialState({}, items),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetch),
	create: createItemSelectorAdapter.getInitialState({}, create),
});

const slice = createSlice({
	name: 'personalAccessTokens',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchPersonalAccessTokens.pending, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg,
				status: 'pending',
			});
		});

		builder.addCase(fetchPersonalAccessTokens.fulfilled, (state, { payload: tokens, meta }) => {
			personalAccessTokenEntityAdapter.upsertOne(state.items, {
				id: meta.arg,
				tokens,
			});

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg,
				status: 'succeeded',
			});
		});

		builder.addCase(deletePersonalAccessToken.pending, (state, { meta }) => {
			const currentToken = selectByTokenId(state, meta.arg);
			if (currentToken) {
				const currentItem = selectById(state, currentToken.webuserId);
				if (currentItem) {
					personalAccessTokenEntityAdapter.upsertOne(state.items, {
						...currentItem,
						tokens: [...currentItem.tokens.filter(t => t.tokenId !== currentToken.tokenId)],
					});
				}
			}
		});

		builder.addCase(createPersonalAccessToken.pending, (state, { meta }) => {
			createItemSelectorAdapter.upsertOne(state.create, {
				id: meta.requestId,
				status: 'pending',
			});
		});

		builder.addCase(createPersonalAccessToken.rejected, (state, { meta }) => {
			createItemSelectorAdapter.upsertOne(state.create, {
				id: meta.requestId,
				status: 'failed',
			});
		});

		builder.addCase(createPersonalAccessToken.fulfilled, (state, { payload, meta }) => {
			createItemSelectorAdapter.removeOne(state.create, meta.requestId);
			createItemSelectorAdapter.addOne(state.create, {
				id: payload.tokenId,
				status: 'succeeded',
			});

			const currentItem = selectById(state, meta.arg.webuserId);

			if (currentItem) {
				personalAccessTokenEntityAdapter.upsertOne(state.items, {
					...currentItem,
					tokens: [...currentItem.tokens, { ...payload }],
				});

				return;
			}

			personalAccessTokenEntityAdapter.addOne(state.items, {
				id: meta.arg.webuserId,
				tokens: [payload],
			});
		});
	},
});

export const reducer = slice.reducer;
