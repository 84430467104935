import { Immutable } from '../..';
import {
	AppDevice,
	Device,
	DeviceState,
	ExternalDevice,
	MobileDevice,
	RegisterDevice,
	UnlinkedMobileDevice,
	UnlinkedRegisterDevice,
} from './types';
import { User } from '../users';
import { Sim } from '.';
import { MobileInfoState } from '../../slices/mobile';
import { hasRestriction, RestrictionState } from '../restrictions';

import { Translate } from '../../slices/translations';

export function selectDevice<D extends Device>(devices: D[], deviceId: string) {
	return devices.find(device => device.id === deviceId);
}

export const isRegisterDevice = (device: Device): device is RegisterDevice =>
	device.type === 'REGISTER';

export const isUnlinkedRegisterDevice = (device: Device): device is UnlinkedRegisterDevice =>
	isRegisterDevice(device) && device.owner === undefined;

export const isMobileDevice = (device: Device): device is MobileDevice => device.type === 'MOBILE';

export const isUnlinkedMobileDevice = (device: Device): device is UnlinkedMobileDevice =>
	isMobileDevice(device) && device.owner === undefined;

export const isExternalDevice = (device: Device): device is ExternalDevice => {
	if (device !== null && typeof device !== 'undefined') {
		return device.type === 'EXTERNAL';
	}
	return false;
};

export const isAppDevice = (device: Device): device is AppDevice => device.type === 'APP';

export const getAppDevices = (devices: Device[]) => devices.filter(isAppDevice);

export const getOwnDevices = (devices: Device[], userId: string) =>
	devices.filter(device => device.owner === userId && !isAppDevice(device));

export const getNotAppDevices = (devices: Device[]) =>
	devices.filter(device => !isAppDevice(device));

export const getDefaultDevice = (devices: Device[], user: User) => {
	if (!devices.some(dev => dev.id === user.defaultDevice && !isAppDevice(dev))) {
		const firstDevice = devices.filter(device => !isAppDevice(device))[0];

		if (!firstDevice) {
			return null;
		}

		return devices[0].id;
	}

	return user.defaultDevice;
};

export const getRegisterDevices = (devices: Device[]) =>
	devices.filter(device => isRegisterDevice(device)) as RegisterDevice[];

export const getUnlinkedDevices = (devices: Device[]) => devices.filter(isUnlinkedRegisterDevice);
export const getUnlinkedMobileDevices = (devices: Device[]) =>
	devices.filter(isUnlinkedMobileDevice);

export const compareDevices = (a: Immutable<Device> | Device, b: Immutable<Device> | Device) => {
	if (a.type === b.type) {
		return parseInt(a.id.slice(1), 10) - parseInt(b.id.slice(1), 10);
	}

	if (a.type === 'REGISTER') {
		return -1;
	}

	if (b.type === 'REGISTER') {
		return 1;
	}

	if (a.type === 'MOBILE') {
		return 1;
	}

	if (b.type === 'MOBILE') {
		return -1;
	}

	return 0;
};

export const sortDevices = (devices: Device[]) => {
	return [...devices].sort(compareDevices);
};

export const getFirstRegisterDevice = (devices: Device[] = []) => {
	if (!devices || !Array.isArray(devices)) {
		return null;
	}

	for (const device of devices) {
		if (isRegisterDevice(device)) {
			return device;
		}
	}

	return null;
};

export const hasMobileDeviceVoiceSims = (device: MobileDevice) =>
	device.sims.some(sim => sim.intendedUse === 'VOICE');

export const isRoutingActive = (device: Device, phonelineId: string) =>
	device.activePhonelines.includes(phonelineId);

export const isGroupRoutingActive = (device: Device, groupId: string) =>
	!!device.activeGroups?.includes(groupId);

export const getMobileDevice = (devices: Device[] = []): MobileDevice | undefined => {
	return devices
		.filter((device: Device) => device.type === 'MOBILE' && device.owner)
		.shift() as MobileDevice;
};

export const getMobileDeviceById = (devices: Device[] = [], deviceId: string) => {
	return devices.filter(isMobileDevice).find(device => device.id === deviceId);
};

export const getMobileDeviceForWebuser = (devices: Device[] = [], webuserId: string) => {
	return devices.filter(isMobileDevice).find(device => device.owner === webuserId);
};

export const selectDevicePool = (mobileInfoState: MobileInfoState, poolId: string) => {
	return mobileInfoState.data
		?.filter(state => !state.owner)
		.find(device => device.containerId === poolId);
};

export const areDevicesFetched = (deviceState: DeviceState, webuserId: string) => {
	return deviceState.fetchedForUser.includes(webuserId);
};

export function isDeviceUpdating(deviceState: DeviceState, deviceId: string) {
	return deviceState.updatingForDevice.includes(deviceId);
}

export const selectCurrentSim = (sims: Sim[], mobileId: string) => {
	return sims.find(sim => sim.id === mobileId);
};

export const selectDeviceAlias = (translate: Translate, device: Device, user: User) => {
	if (device.alias) {
		return device.alias;
	}

	if (device.type === 'MOBILE') {
		return translate('DEVICE_POOL_PROPERTY_DEFAULT_NAME', {
			userName: user.firstname,
		});
	}

	return device.alias;
};

const FREE_EXTRA_VOIP_PHONE_THRESHOLD = 2;

export const canCreateAdditionalVoipPhonesWithoutCharge = (
	registerDevices: RegisterDevice[],
	restrictions: RestrictionState
) => {
	const registers = registerDevices.map(device => device.registeredDevices);

	return (
		registers.length >= FREE_EXTRA_VOIP_PHONE_THRESHOLD &&
		!hasRestriction(restrictions.items, 'CAN_CREATE_EXTRA_VOIP_PHONE_WITHOUT_CHARGE')
	);
};
