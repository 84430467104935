import { useEffect } from 'react';
import { useAction } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	disableVoicemailNotification,
	enableVoicemailNotification,
	fetchVoicemailNotification,
} from './actions';

export const useVoicemailNotifications = (channel: string) => {
	const voicemailNotificationsState = useSelector(state => state.voicemailNotifications);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!voicemailNotificationsState.fetched.includes(channel)) {
			dispatch(fetchVoicemailNotification({ channel }));
		}
	}, [channel, dispatch, voicemailNotificationsState.fetched]);

	return {
		isEnabled: voicemailNotificationsState.channelsWithEnabledNotifications.includes(channel),
		fetched: voicemailNotificationsState.fetched.includes(channel),
		fetching: voicemailNotificationsState.fetching.includes(channel),
	};
};

export const useVoicemailNotificationActions = () => ({
	fetchNotification: useAction(fetchVoicemailNotification),
	enableNotification: useAction(enableVoicemailNotification),
	disableNotification: useAction(disableVoicemailNotification),
});
