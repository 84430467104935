import { createSlice } from '@reduxjs/toolkit';
import { DataAutomationHistoryState, isDataAutomationHistoryError } from './types';
import { fetchDataAutomationHistory } from './actions';

export const initialState: DataAutomationHistoryState = {
	items: [],
	fetchingForContainer: [],
	fetchedForContainer: [],
};

const slice = createSlice({
	name: 'dataAutomationHistory',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDataAutomationHistory.pending, (state, { meta }) => ({
			...state,
			fetchingForContainer: [
				...state.fetchingForContainer.filter(containerId => containerId !== meta.arg.containerId),
				meta.arg.containerId,
			],
		}));

		builder.addCase(fetchDataAutomationHistory.fulfilled, (state, { payload, meta }) => {
			const fetchedItem = {
				containerId: meta.arg.containerId,
				count: payload.length,
			};

			const itemsAfterUpdate = [
				...state.items.filter(item => item.containerId !== meta.arg.containerId),
				fetchedItem,
			];

			return {
				items: itemsAfterUpdate,
				fetchingForContainer: state.fetchingForContainer.filter(
					containerId => containerId !== meta.arg.containerId
				),
				fetchedForContainer: [
					...state.fetchedForContainer.filter(containerId => containerId !== meta.arg.containerId),
					meta.arg.containerId,
				],
			};
		});

		builder.addCase(fetchDataAutomationHistory.rejected, (state, { payload: error, meta }) => {
			if (
				isDataAutomationHistoryError(error) &&
				error.name === 'COULD_NOT_FIND_DATA_AUTOMATION_HISTORY'
			) {
				return { ...state };
			}

			return {
				...state,
				fetchingForContainer: state.fetchingForContainer.filter(
					containerId => containerId !== meta.arg.containerId
				),
				fetchedForContainer: [
					...state.fetchedForContainer.filter(containerId => containerId !== meta.arg.containerId),
					meta.arg.containerId,
				],
			};
		});
	},
});

export const reducer = slice.reducer;
