import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { SsoConfigurationState } from './types';
import { createSsoConfiguration, fetchSsoConfiguration, updateSsoConfiguration } from './actions';

const initialState: SsoConfigurationState = {
	item: null,
	fetched: false,
	fetching: false,
};

const slice = createSlice({
	name: 'ssoConfiguration',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addMatcher(
			isAnyOf(
				createSsoConfiguration.fulfilled,
				fetchSsoConfiguration.fulfilled,
				updateSsoConfiguration.fulfilled
			),
			(state, { payload }) => ({
				...state,
				fetched: true,
				fetching: false,
				item: payload,
			})
		);

		builder.addMatcher(
			isAnyOf(
				fetchSsoConfiguration.pending,
				createSsoConfiguration.pending,
				updateSsoConfiguration.pending
			),
			state => ({
				...state,
				fetching: true,
			})
		);

		builder.addMatcher(
			isAnyOf(
				fetchSsoConfiguration.rejected,
				createSsoConfiguration.rejected,
				updateSsoConfiguration.rejected
			),
			state => ({
				...state,
				fetching: false,
			})
		);
	},
});

export const reducer = slice.reducer;
