import React from 'react';
import { Checkbox } from '@panda/ui';

import classes from './DirectionFilter.module.scss';
import { Translate } from '../../../redux/slices/translations';
import { ChangeUrlParameter } from '../selections/types';
import { DirectionType } from '../selections/parsers';
import { FilterDropdown } from './FilterDropdown';
import { Filter } from './Filter';

interface Props {
	open?: boolean;
	translate: Translate;
	onOpenClose?: (open: boolean) => void;

	selection: DirectionType[];
	onChange: ChangeUrlParameter;
}

export class DirectionFilter extends React.PureComponent<Props> {
	private onChangedDirections = (value: DirectionType) => {
		const changed = Array.from(this.props.selection);
		if (this.hasSelected(value)) {
			changed.splice(changed.indexOf(value), 1);
			this.props.onChange({ direction: changed, offset: 0 });
		} else {
			changed.push(value);
			this.props.onChange({ direction: changed, offset: 0 });
		}
	};

	private hasSelected = (value: DirectionType) => this.props.selection.includes(value);

	private isActive() {
		return this.props.selection.length !== 0;
	}

	private renderButtonLabel() {
		if (this.hasSelected('incoming') && this.hasSelected('outgoing')) {
			return `${this.props.translate('FILTER_DIRECTION')} (2)`;
		}
		if (this.hasSelected('incoming')) {
			return this.props.translate('FILTER_DIRECTION_INCOMING');
		}
		if (this.hasSelected('outgoing')) {
			return this.props.translate('FILTER_DIRECTION_OUTGOING');
		}
		return this.props.translate('FILTER_DIRECTION');
	}

	private renderContent() {
		return (
			<ul className={classes.filterlist}>
				<li>
					<Checkbox
						checked={this.hasSelected('incoming')}
						onChange={() => this.onChangedDirections('incoming')}
					>
						{this.props.translate('FILTER_DIRECTION_INCOMING')}
					</Checkbox>
				</li>
				<li>
					<Checkbox
						checked={this.hasSelected('outgoing')}
						onChange={() => this.onChangedDirections('outgoing')}
					>
						{this.props.translate('FILTER_DIRECTION_OUTGOING')}
					</Checkbox>
				</li>
			</ul>
		);
	}

	private renderDropdown() {
		if (!this.props.onOpenClose) {
			return null;
		}

		return (
			<FilterDropdown
				buttonLabel={this.renderButtonLabel()}
				active={this.isActive()}
				onOpenClose={this.props.onOpenClose}
				open={!!this.props.open}
			>
				{this.renderContent()}
			</FilterDropdown>
		);
	}

	public render() {
		if (this.props.onOpenClose) {
			return this.renderDropdown();
		}

		return (
			<Filter title={this.props.translate('FILTER_DIRECTION')}>
				<div className={classes.twoColumns}>{this.renderContent()}</div>
			</Filter>
		);
	}
}
