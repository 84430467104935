import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconTablet = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M17.25 2H6.75A2.755 2.755 0 0 0 4 4.75v14.5A2.755 2.755 0 0 0 6.75 22h10.5A2.755 2.755 0 0 0 20 19.25V4.75A2.755 2.755 0 0 0 17.25 2m1.25 17.25c0 .69-.56 1.25-1.25 1.25H6.75c-.69 0-1.25-.56-1.25-1.25V4.75c0-.69.56-1.25 1.25-1.25H10v1h4v-1h3.25c.69 0 1.25.56 1.25 1.25z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconTablet;
