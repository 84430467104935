import { createAsyncThunk } from '../../utils/wrapper';
import api from '../../../api';
import { ApiSsoConfiguration } from '../../../api/types/ssoConfiguration';
import { SsoConfigurationCreateReduxAction, SsoConfigurationUpdateReduxAction } from './types';

export const fetchSsoConfiguration = createAsyncThunk<ApiSsoConfiguration, boolean | undefined>(
	'ssoConfiguration/fetch',
	async (_force?: boolean) => {
		return api.fetchSsoConfiguration();
	},
	{
		condition: (force = false, { getState }) => {
			const ssoConfiguration = getState().ssoConfiguration;
			return (!ssoConfiguration.fetched && !ssoConfiguration.fetching) || force;
		},
	}
);

export const createSsoConfiguration = createAsyncThunk<
	ApiSsoConfiguration,
	SsoConfigurationCreateReduxAction
>('ssoConfiguration/create', async (ssoConfiguration: SsoConfigurationCreateReduxAction) =>
	api.createSsoConfiguration(ssoConfiguration)
);

export const updateSsoConfiguration = createAsyncThunk<
	ApiSsoConfiguration,
	SsoConfigurationUpdateReduxAction
>('ssoConfiguration/update', async (ssoConfiguration: SsoConfigurationUpdateReduxAction) =>
	api.updateSsoConfiguration(ssoConfiguration)
);
