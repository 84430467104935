import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconPending = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m20.04 9.235 1.42-.485A10 10 0 0 1 22 12c0 5.515-4.485 10-10 10S2 17.515 2 12 6.485 2 12 2c2.42 0 4.705.86 6.5 2.4V2H20v3.75C20 6.44 19.44 7 18.75 7H15V5.5h2.465A8.45 8.45 0 0 0 12 3.5c-4.685 0-8.5 3.815-8.5 8.5s3.815 8.495 8.5 8.495 8.5-3.815 8.5-8.5c0-.945-.155-1.875-.46-2.76m-8.79 3.075V6.495h1.5v5.195l3.03 3.03-1.06 1.06z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconPending;
