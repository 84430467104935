import { ChannelInboxState } from './types';
import { channelInboxEntityAdapter, fetchItemEntityAdapter } from './adapters';

const selectItems = (state: ChannelInboxState) => state.items;
const channelInboxEntitySelector = channelInboxEntityAdapter.getSelectors(selectItems);
export const { selectAll, selectById } = channelInboxEntitySelector;

const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();
export const {
	isSucceeded: isFetched,
	isPending: isFetching,
	hasFailed,
	areSucceeded: selectFetchItemById,
} = fetchItemEntitySelectors;
