import serviceUrls from '@web-apps/service-urls';
import { Types } from './types';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';

interface TariffAnnouncementRestApi {
	getTariffAnnouncement(deviceId: string): Promise<Types>;
}

class TariffAnnouncementRestApiClient extends RestApiClient implements TariffAnnouncementRestApi {
	public getTariffAnnouncement(deviceId: string): Promise<Types> {
		return this.get('/v2', `/devices/${deviceId}/tariffannouncement`);
	}
}

export const tariffAnnouncementRestApiClient = new TariffAnnouncementRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
