import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconPresence = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M14 7.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0m2.909 10.98a2 2 0 0 1-1.93 2.52H4.019a2 2 0 0 1-1.93-2.52l.59-2.205a3.746 3.746 0 0 1 3.62-2.78h6.4a3.76 3.76 0 0 1 3.62 2.78zM14.5 3c-.32 0-.635.035-.935.1a5.98 5.98 0 0 1 1.935 4.4c0 1.74-.75 3.305-1.935 4.4.3.065.615.1.935.1 2.48 0 4.5-2.02 4.5-4.5s-2.02-4.5-4.5-4.5m6.82 13.275.59 2.205v-.005a2 2 0 0 1-1.93 2.52h-2.136c.595-.85.785-1.895.515-2.905l-.59-2.205a5.2 5.2 0 0 0-1.405-2.39H17.7a3.76 3.76 0 0 1 3.62 2.78"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconPresence;
