import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconMulti_user_channel = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M11 4.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0m-1.386.75H8c-.69 0-1.25.56-1.25 1.25v3h-1.5v-3A2.75 2.75 0 0 1 8 3.75h1.614a2.501 2.501 0 0 1 4.772 0H16a2.75 2.75 0 0 1 2.75 2.75v3h-1.5v-3c0-.69-.56-1.25-1.25-1.25h-1.614a2.501 2.501 0 0 1-4.772 0M4.5 14a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0M6 11a3 3 0 1 0 0 6 3 3 0 0 0 0-6m12 1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M15 14a3 3 0 1 1 6 0 3 3 0 0 1-6 0m1.75 5.5c-.69 0-1.25.558-1.25 1.245V22H14v-1.255A2.75 2.75 0 0 1 16.75 18h2.495c1.518 0 2.75 1.23 2.75 2.745V22h-1.5v-1.255c0-.685-.558-1.245-1.25-1.245zm-12 0c-.69 0-1.25.558-1.25 1.245V22H2v-1.255A2.75 2.75 0 0 1 4.75 18h2.495c1.518 0 2.75 1.23 2.75 2.745V22h-1.5v-1.255c0-.685-.558-1.245-1.25-1.245z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconMulti_user_channel;
