import * as React from 'react';
import type { DuotoneIconBaseProps } from '../../types';
const DuotoneNeoIconNumber_extension = ({
	size = 24,
	...props
}: DuotoneIconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill={props.color2}
				d="M10.206 7.294a.5.5 0 0 0 .13-.483l-.987-3.69a.5.5 0 0 0-.483-.371H4.75c-1.105 0-2.011.899-1.893 1.997.925 8.62 7.776 15.47 16.396 16.396 1.098.118 1.997-.788 1.997-1.893v-4.116a.5.5 0 0 0-.37-.483l-3.691-.986a.5.5 0 0 0-.483.129L14.4 16.1a15.1 15.1 0 0 1-6.5-6.5z"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m17.385 12.935 3.69.99h-.005c.545.15.925.645.925 1.21v4.115a2.755 2.755 0 0 1-2.75 2.75H8.995v-1.5h10.25c.69 0 1.25-.56 1.25-1.25v-3.925l-3.365-.9-2.59 2.59-.485-.25A15.9 15.9 0 0 1 7.23 9.94l-.25-.485 2.59-2.59-.9-3.365H4.865c-.36 0-.705.155-.945.425-.235.265-.34.595-.3.94A17.7 17.7 0 0 0 6.76 13H4.955A19.2 19.2 0 0 1 2.13 5.04a2.7 2.7 0 0 1 .665-2.115c.525-.59 1.28-.93 2.07-.93H8.86c.565 0 1.065.38 1.21.925l.99 3.69c.115.43-.01.895-.325 1.21l-1.91 1.91a14.4 14.4 0 0 0 5.44 5.44l1.91-1.91c.315-.315.78-.44 1.21-.325M8.25 14.5H2V16h6.25zm3.75 3H5V19h7z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default DuotoneNeoIconNumber_extension;
