import { createSelector } from '@reduxjs/toolkit';
import { DataUsageAggregationState } from './types';
import { dataAggregationAdapter, fetchItemAdapter } from './adapters';

const selectDataAggregationItems = (state: DataUsageAggregationState) => state.items;
const dataAggregationSelectors = dataAggregationAdapter.getSelectors(selectDataAggregationItems);
const fetchItemEntitySelectors = fetchItemAdapter.getSelectors();

const createDataUsageAggregationSelector = createSelector.withTypes<DataUsageAggregationState>();

export const selectById = createDataUsageAggregationSelector(
	[dataAggregationSelectors.selectById],
	dataUsageAggregationItem => {
		if (dataUsageAggregationItem) {
			return dataUsageAggregationItem.items;
		}

		return undefined;
	}
);

export const {
	isSucceeded: isFetched,
	isPending: isFetching,
	hasFailed,
	selectById: selectFetchItemById,
} = fetchItemEntitySelectors;
