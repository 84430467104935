import { Button, Checkbox, HeadingBoundary, Headline } from '@panda/ui';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import CenteredLogoSpinner from '../../components/spinner/CenteredLogoSpinner';
import PageNotFoundRedirect from '../../redirects/PageNotFoundRedirect';
import { useContacts } from '../../redux/modules/contacts/hooks';
import { usePseudo } from '../../redux/slices/pseudo/hooks';
import { useTranslate } from '../../redux/slices/translations';
import { useDialogs } from '../../routes/paths/dialogs';
import { contactsPath } from '../../routes/paths/index';
import { useInit } from '../../utils/initialize';
import { CSVMappingParams } from './CSVMappingHelpers';
import CSVMappingRow from './CSVMappingRow';
import classes from './CSVMappingView.module.scss';
import { useAccount } from '../../redux/slices/account';

const CSVMappingView = () => {
	const translate = useTranslate();
	const params = useParams<CSVMappingParams>();
	const [isShared, setIsShared] = useState(false);
	const { getPseudo } = usePseudo();
	const { filePseudo, textPseudo, type } = params;
	const [error, setError] = useState<string | undefined>(undefined);
	const [activePreviewRow, setActivePreviewRow] = useState(0);
	const { prepareCSVImport, importMapper, importFromCachedCSV, importing } = useContacts();
	const account = useAccount();
	const dialogs = useDialogs();

	const textContent = getPseudo(textPseudo);
	const fileContent = getPseudo(filePseudo);
	useEffect(() => {
		setError(undefined);
	}, [importMapper]);
	useInit(() => {
		if (fileContent) {
			switch (type) {
				case 'contacts':
					prepareCSVImport(true, fileContent.value.split(',')[1]);
					break;
				default:
					break;
			}
		}
	});

	if (!textContent || !fileContent) {
		if (type === 'contacts') {
			return <Redirect to={contactsPath.path} />;
		}
		return <PageNotFoundRedirect />;
	}

	const columns = importMapper?.header;

	const rows = importMapper?.records;

	const preview = rows ? rows[activePreviewRow] : [];

	const increaseTargetRow = () => {
		setActivePreviewRow(activePreviewRow + 1);
	};

	const decreaseTargetRow = () => {
		setActivePreviewRow(activePreviewRow - 1);
	};

	const proceed = () => {
		switch (type) {
			case 'contacts':
				if (importMapper) {
					if (importMapper.columns.every(el => el === 'SG_NOOP' || el === '')) {
						setError(translate('ERROR_NO_MAPPING'));
						return;
					}
					importFromCachedCSV(
						importMapper.cacheKey,
						importMapper.columns,
						isShared ? 'SHARED' : 'PRIVATE',
						true
					)
						.payload.promise.then(rsp => {
							if (rsp.type === 'CONTACTS_IMPORT_CACHED_CSV_SUCCESS') {
								dialogs.contactImportSuccess
									.at(contactsPath.build())
									.open({ contactsImported: `${rsp.payload.contactsImported}` });
								return;
							}
							dialogs.contactImportFailure.open();
						})
						.catch(() => {
							dialogs.contactImportFailure.open();
						});
				}
				break;
			default:
				break;
		}
	};

	const abort = () => {
		switch (type) {
			case 'contacts':
				dialogs.cancelContactImport.open();
				break;
			default:
				break;
		}
	};

	if (!importMapper?.columns || !importMapper.header || !rows) {
		return <CenteredLogoSpinner />;
	}

	return (
		<div className={classes.wrapper}>
			<main>
				<HeadingBoundary levelOverride={1}>
					<Headline>{translate('CSV_MAPPING_VIEW_HEADLINE')}</Headline>
					<p className={classes.subline}>{translate('CSV_MAPPING_VIEW_SUBLINE')}</p>
					<div className={classes.tableContainer}>
						<table>
							<thead>
								<tr>
									<th>{translate('COLUMN')}</th>
									<th>
										<span>
											<span>{translate('EXAMPLE')}</span>
											<Button
												icon="back"
												iconOnly
												onClick={decreaseTargetRow}
												disabled={activePreviewRow === 0}
												size="small"
												variant="quiet"
											/>
											<Button
												icon="next"
												iconOnly
												onClick={increaseTargetRow}
												disabled={activePreviewRow === rows.length - 1}
												size="small"
												variant="quiet"
											/>
										</span>
									</th>
									<th>{translate('OPTION')}</th>
									<th>{translate('OPTION_TYPE')}</th>
								</tr>
							</thead>
							<tbody>
								{columns!.map((col, index) => (
									<CSVMappingRow
										key={col}
										column={col}
										error={error}
										type={type}
										preview={preview[index]}
										rowIndex={index}
									/>
								))}
							</tbody>
						</table>
					</div>
				</HeadingBoundary>
			</main>
			<footer>
				<div className={classes.preview}>
					<Checkbox checked={isShared} onChange={setIsShared}>
						{account?.company
							? translate('CSV_MAPPING_VIEW_SHARE_CONTACT', account.company)
							: translate('CSV_MAPPING_VIEW_SHARE_CONTACT_NO_COMPANY')}
					</Checkbox>
				</div>
				<div className={classes.buttons}>
					<Button onClick={abort} type="button" variant="quiet" action="trigger" size="xlarge">
						{translate('ABORT')}
					</Button>
					<Button
						type="button"
						variant="loud"
						action="confirm"
						loading={importing}
						size="xlarge"
						onClick={proceed}
					>
						{translate('IMPORT')}
					</Button>
				</div>
			</footer>
		</div>
	);
};

export default CSVMappingView;
