import { createAsyncThunk } from '../../utils/wrapper';
import { DataAutomationHistoryResponse } from '../../../api/SipgateApi';
import api from '../../../api';
import {
	CouldNotFetchDataAutomationHistory,
	CouldNotFindDataAutomationHistory,
	DataAutomationHistoryError,
} from './types';
import { isApiError } from '../../../api/ApiError';

type ForceFetch = { forceFetch?: boolean };

export type FetchDataAutomationHistoryParameters = {
	containerId: string;
} & ForceFetch;

export const fetchDataAutomationHistory = createAsyncThunk<
	DataAutomationHistoryResponse[],
	FetchDataAutomationHistoryParameters,
	{
		rejectValue: DataAutomationHistoryError;
	}
>(
	'dataAutomationHistory/fetch',
	async ({ containerId }: FetchDataAutomationHistoryParameters, { rejectWithValue }) => {
		try {
			return (await api.getDataAutomationHistory(containerId)).items;
		} catch (error) {
			if (isApiError(error) && error.status === 404) {
				return rejectWithValue(CouldNotFindDataAutomationHistory(containerId));
			}
			return rejectWithValue(CouldNotFetchDataAutomationHistory());
		}
	}
);
