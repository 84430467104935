import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconOutput_device = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M13.25 21h-2.31l-4-4H4.75A2.755 2.755 0 0 1 2 14.25v-4.5A2.755 2.755 0 0 1 4.75 7h2.19l4-4h2.31c.69 0 1.25.56 1.25 1.25v15.5c0 .69-.56 1.25-1.25 1.25m-1.69-1.5H13v-15h-1.44l-4 4H4.75c-.69 0-1.25.56-1.25 1.25v4.5c0 .69.56 1.25 1.25 1.25h2.81zm5.315-3.82-.75-1.3a2.76 2.76 0 0 0 1.375-2.385c0-.98-.525-1.89-1.375-2.385l.75-1.3A4.27 4.27 0 0 1 19 11.99a4.27 4.27 0 0 1-2.125 3.68zm.75 1.3.75 1.3A7.28 7.28 0 0 0 22 12c0-2.58-1.39-4.985-3.625-6.28l-.75 1.3A5.77 5.77 0 0 1 20.5 12c0 2.045-1.1 3.955-2.875 4.98"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconOutput_device;
