import { createSlice } from '@reduxjs/toolkit';
import { fetchLocalprefix, setLocalprefix } from './actions';
import { DeviceLocalPrefixState, LocalPrefix } from './types';
import {
	deviceLocalPrefixAdapter,
	fetchItemEntityAdapter,
	updateItemEntityAdapter,
} from './adapter';
import { StatusItem } from '../../utils/statusEntityAdapter';

export const getInitialState = (
	items?: LocalPrefix[],
	fetch?: StatusItem[],
	update?: StatusItem[]
): DeviceLocalPrefixState => ({
	items: deviceLocalPrefixAdapter.getInitialState({}, items),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetch),
	update: updateItemEntityAdapter.getInitialState({}, update),
});

const slice = createSlice({
	name: 'deviceLocalPrefix',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchLocalprefix.pending, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.deviceId,
				status: 'pending',
			});
		});

		builder.addCase(fetchLocalprefix.fulfilled, (state, { payload: localPrefix, meta }) => {
			deviceLocalPrefixAdapter.upsertOne(state.items, {
				id: meta.arg.deviceId,
				value: localPrefix.value,
				active: localPrefix.active,
			});

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.deviceId,
				status: 'succeeded',
			});
		});

		builder.addCase(setLocalprefix.pending, (state, { meta }) => {
			deviceLocalPrefixAdapter.upsertOne(state.items, {
				id: meta.arg.deviceId,
				active: meta.arg.active,
				value: meta.arg.localPrefix,
			});

			updateItemEntityAdapter.upsertOne(state.update, {
				id: meta.arg.deviceId,
				status: 'pending',
			});
		});

		builder.addCase(setLocalprefix.fulfilled, (state, { meta }) => {
			updateItemEntityAdapter.upsertOne(state.update, {
				id: meta.arg.deviceId,
				status: 'succeeded',
			});
		});
	},
});

export const reducer = slice.reducer;
