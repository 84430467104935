import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconPlus_circle = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2 12C2 6.485 6.485 2 12 2s10 4.485 10 10-4.485 10-10 10S2 17.515 2 12m1.5 0c0 4.685 3.815 8.5 8.5 8.5s8.5-3.815 8.5-8.5-3.815-8.5-8.5-8.5S3.5 7.315 3.5 12m7.75-.75V7.5h1.5v3.75h3.75v1.5h-3.75v3.75h-1.5v-3.75H7.5v-1.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconPlus_circle;
