import { createSelector } from '@reduxjs/toolkit';
import {
	DeviceIcon,
	DeviceIconType,
	DevicePropertiesState,
	DeviceProperty,
	isDeviceIconType,
} from './types';
import { devicePropertyEntityAdapter, fetchItemEntityAdapter } from './adapters';

const selectItems = (state: DevicePropertiesState) => state.items;
const devicePropertyEntitySelector = devicePropertyEntityAdapter.getSelectors(selectItems);
export const { selectAll, selectIds, selectById } = devicePropertyEntitySelector;

const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();
export const {
	isSucceeded: isFetched,
	isPending: isFetching,
	areSucceeded: areFetched,
} = fetchItemEntitySelectors;

const createTypedSelector = createSelector.withTypes<DevicePropertiesState>();

const selectByIds = createTypedSelector(
	[selectAll, (state: DevicePropertiesState, ids: string[]) => ids],
	(entities, ids) => entities.filter(entity => ids.includes(entity.id.toString()))
);

const makeKeySelectorById = (key: string) =>
	createTypedSelector(
		[selectById, (_: DevicePropertiesState, deviceId: string) => deviceId],
		entity => {
			if (!entity) {
				return [];
			}
			return entity.properties.filter((property: Partial<DeviceProperty>) => property.key === key);
		}
	);

const makeKeySelectorByIds = (key: string) => {
	return createTypedSelector(
		[selectByIds, (_: DevicePropertiesState, ids: string[]) => ids],
		entities =>
			entities.flatMap(entity => {
				return {
					id: entity.id,
					properties: entity.properties.filter(
						(property: Partial<DeviceProperty>) => property.key === key
					),
				};
			})
	);
};

export const selectDeviceIcon = createTypedSelector([makeKeySelectorById('DEVICE_ICON')], items => {
	if (items.length === 0) {
		return null;
	}

	const property = items.pop();
	return isDeviceIconType(property!.value) ? property!.value : null;
});

export const selectDeviceIcons = createTypedSelector([makeKeySelectorByIds('DEVICE_ICON')], items =>
	items.map(
		i =>
			({
				deviceId: i.id,
				icon: i.properties.length !== 0 ? (i.properties[0].value as DeviceIconType) : 'MOBILE',
			}) as DeviceIcon
	)
);
