import { Button, ContextMenu, MenuItem } from '@panda/ui';
import React from 'react';

import { Translate } from '../../../redux/slices/translations';
import classes from './Actions.module.scss';

interface Props {
	translate: Translate;
	showArchiveButton: boolean;
	showDearchiveButton: boolean;
	showMarkReadButton: boolean;
	showMarkUnreadButton: boolean;

	onDelete: () => void;
	onArchive: () => void;
	onDearchive: () => void;
	onEditLabels: () => void;
	onMarkRead: () => void;
	onMarkUnread: () => void;
}

interface State {
	deleteCheckVisible: boolean;
}

export class Actions extends React.PureComponent<Props, State> {
	public state: State = {
		deleteCheckVisible: false,
	};

	private onDelete = () => {
		this.setState({ deleteCheckVisible: true });
	};

	private onDeleteConfirm = () => {
		this.props.onDelete();
	};

	private onAbort = () => {
		this.setState({ deleteCheckVisible: false });
	};

	private getActions() {
		const menuItems = [];

		if (this.props.showArchiveButton) {
			menuItems.push(
				<MenuItem onClick={this.props.onArchive} key="archive">
					{this.props.translate('ARCHIVE_MULTIPLE')}
				</MenuItem>
			);
		}

		if (this.props.showDearchiveButton) {
			menuItems.push(
				<MenuItem onClick={this.props.onDearchive} key="dearchive">
					{this.props.translate('DEARCHIVE_MULTIPLE')}
				</MenuItem>
			);
		}

		if (this.props.showMarkReadButton) {
			menuItems.push(
				<MenuItem onClick={this.props.onMarkRead} key="mark_qread">
					{this.props.translate('MARK_READ_MULTIPLE')}
				</MenuItem>
			);
		}

		if (this.props.showMarkUnreadButton) {
			menuItems.push(
				<MenuItem onClick={this.props.onMarkUnread} key="mark_unread">
					{this.props.translate('MARK_UNREAD_MULTIPLE')}
				</MenuItem>
			);
		}

		menuItems.push(
			<MenuItem onClick={this.props.onEditLabels} key="edit_labels">
				{this.props.translate('EVENTLIST_ACTION_EDIT_LABELS')}
			</MenuItem>
		);

		return menuItems;
	}

	private renderDearchiveButton() {
		if (this.props.showDearchiveButton) {
			return (
				<Button onClick={this.props.onDearchive}>
					{this.props.translate('DEARCHIVE_MULTIPLE')}
				</Button>
			);
		}

		return null;
	}

	private renderArchiveButton() {
		if (this.props.showArchiveButton) {
			return (
				<Button onClick={this.props.onArchive}>{this.props.translate('ARCHIVE_MULTIPLE')}</Button>
			);
		}

		return null;
	}

	private renderMarkReadButton() {
		if (this.props.showMarkReadButton) {
			return (
				<Button onClick={this.props.onMarkRead}>
					{this.props.translate('MARK_READ_MULTIPLE')}
				</Button>
			);
		}

		return null;
	}

	private renderMarkUnreadButton() {
		if (this.props.showMarkUnreadButton) {
			return (
				<Button onClick={this.props.onMarkUnread}>
					{this.props.translate('MARK_UNREAD_MULTIPLE')}
				</Button>
			);
		}

		return null;
	}

	private renderEditLabels() {
		return (
			<Button onClick={this.props.onEditLabels}>
				{this.props.translate('EVENTLIST_ACTION_EDIT_LABELS')}
			</Button>
		);
	}

	private renderActions() {
		return (
			<>
				<div className={classes.mobileMenu}>
					<Button critical icon="delete" onClick={this.onDelete} />{' '}
					<ContextMenu variant="normal" size="medium">
						{this.getActions()}
					</ContextMenu>
				</div>
				<div className={classes.desktopMenu}>
					{this.renderDearchiveButton()}
					{this.renderArchiveButton()}
					{this.renderMarkReadButton()}
					{this.renderMarkUnreadButton()}
					{this.renderEditLabels()}
					<Button critical icon="delete" onClick={this.onDelete} />{' '}
				</div>
			</>
		);
	}

	private renderDeleteCheck() {
		return (
			<>
				<Button icon="delete" action="confirm" critical onClick={this.onDeleteConfirm}>
					{this.props.translate('CONFIRM_DELETE_MULTIPLE')}
				</Button>

				<Button variant="quiet" onClick={this.onAbort}>
					{this.props.translate('ABORT_DELETE_MULTIPLE')}
				</Button>
			</>
		);
	}

	public render() {
		return (
			<div className={classes.actions}>
				{this.state.deleteCheckVisible ? this.renderDeleteCheck() : this.renderActions()}
			</div>
		);
	}
}
