import { createAsyncThunk } from '../../utils/wrapper';
import { ApiUserInfo } from '../../../api/types/userinfo';
import api from '../../../api';

export const fetchUserInfo = createAsyncThunk<ApiUserInfo, void>(
	'userInfo/fetch',
	async () => {
		return api.getUserInfo();
	},
	{
		condition: (_arg: void, { getState }) => {
			const userInfoState = getState().userinfo;
			return !userInfoState.fetched && !userInfoState.fetching;
		},
	}
);
