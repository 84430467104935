import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconConference_room = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M3 3.25C3 2.56 3.56 2 4.25 2h15.5c.69 0 1.25.56 1.25 1.25v6c0 .69-.56 1.25-1.25 1.25H4.25C3.56 10.5 3 9.94 3 9.25zm1.5.25V9h15V3.5zM4.75 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M10 13.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m5.25 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-1.75 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m5.75 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-8.5 4v-1.5h2.5V22h1.5v-1.75c0-.69-.56-1.25-1.25-1.25h-3c-.69 0-1.25.56-1.25 1.25V22zM18 20.5V22h-1.5v-1.75c0-.69.56-1.25 1.25-1.25h3c.69 0 1.25.56 1.25 1.25V22h-1.5v-1.5zM3.5 22v-1.5H6V22h1.5v-1.75c0-.69-.56-1.25-1.25-1.25h-3C2.56 19 2 19.56 2 20.25V22z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconConference_room;
