import React, { createRef } from 'react';
import { DropdownWithOpener } from './DropdownWithOpener';

import classes from './DropdownMenu.module.scss';
import { ariaId } from '../../utils/a11y/aria-id';

interface Props {
	left?: boolean;
	opener:
		| ((open: boolean) => React.ReactElement<{
				ref: React.Ref<HTMLElement>;
				onClick: React.MouseEventHandler;
				id: string;
		  }>)
		| React.ReactElement<{
				ref: React.Ref<HTMLElement>;
				onClick: React.MouseEventHandler;
				id: string;
		  }>;

	children: (
		| React.ReactElement<{
				onClick: React.MouseEventHandler;
				disabled?: boolean;
		  }>
		| undefined
		| null
	)[];
	preventBackgroundInteraction?: boolean;
}

interface State {
	open: boolean;
}

/**
 * A fully state-managed dropdown-menu.
 * To be used with MenuItem.
 */
export class DropdownMenu extends React.PureComponent<Props, State> {
	public state = {
		open: false,
	};

	private openerId = ariaId();

	private ref = createRef<HTMLUListElement>();

	private onOpen = (focus: 'first' | 'last') => {
		this.setState({ open: true }, () => {
			if (focus === 'first') {
				this.focusFirst();
			} else {
				this.focusLast();
			}
		});
	};

	private onClose = () => {
		this.setState({ open: false });
	};

	private onKeyDown = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case 'Tab':
				this.onClose();
				// We explicitly want propagation so the next thing
				// before or after our opener is focussed.
				return;
			case 'Escape':
				this.onClose();
				break;
			case 'ArrowUp':
				this.focusPrevious();
				break;
			case 'ArrowDown':
				this.focusNext();
				break;
			case 'Home':
				this.focusFirst();
				break;
			case 'End':
				this.focusLast();
				break;

			default:
				return;
		}

		e.preventDefault();
		e.stopPropagation();
	};

	private getMenuItems() {
		if (!this.ref.current) {
			return [];
		}

		return Array.from(this.ref.current.querySelectorAll('[role="menuitem"]')).filter(
			(e): e is HTMLElement => e instanceof HTMLElement
		);
	}

	private focusFirst() {
		const element = this.getMenuItems()[0];

		if (!element) {
			return;
		}

		element.focus();
	}

	private focusLast() {
		const elements = this.getMenuItems();
		const element = elements[elements.length - 1];

		if (!element) {
			return;
		}

		element.focus();
	}

	private focusNext() {
		const elements = this.getMenuItems();
		const focussedIndex = elements.findIndex(e => document.activeElement === e);

		if (focussedIndex === -1) {
			return;
		}

		if (focussedIndex + 1 < elements.length) {
			elements[focussedIndex + 1].focus();
			return;
		}

		elements[0].focus();
	}

	private focusPrevious() {
		const elements = this.getMenuItems();
		const focussedIndex = elements.findIndex(e => document.activeElement === e);

		if (focussedIndex === -1) {
			return;
		}

		if (focussedIndex === 0) {
			elements[elements.length - 1].focus();
			return;
		}

		elements[focussedIndex - 1].focus();
	}

	public render() {
		return (
			<DropdownWithOpener
				openerId={this.openerId}
				open={this.state.open}
				horizontalOpeningPosition={this.props.left ? 'Left' : 'Right'}
				verticalOpeningPosition="Bottom"
				opener={this.props.opener}
				onOpen={this.onOpen}
				onClose={this.onClose}
				preventBackgroundInteraction={this.props.preventBackgroundInteraction}
			>
				<ul
					role="menu"
					className={classes.menu}
					aria-labelledby={this.openerId}
					ref={this.ref}
					onKeyDown={this.onKeyDown}
				>
					{this.props.children.map(child => {
						if (!child) {
							return child;
						}

						return React.cloneElement(child, {
							onClick: (e: React.MouseEvent) => {
								const returnValue = child.props.onClick ? child.props.onClick(e) : undefined;

								if (e.button === 0) {
									this.onClose();
								}

								return returnValue;
							},
						});
					})}
				</ul>
			</DropdownWithOpener>
		);
	}
}
