import React from 'react';
import { Headline } from '@panda/ui';
import classes from './CompanySizeView.module.scss';
import { useTranslate } from '../../../redux/slices/translations';
import SipgateBlackLogo from './img/sipgate.svg';
import api from '../../../api';
import { useEventsAction } from '../../../redux/modules/events/hooks/useEvents';

export const CompanySizeView = () => {
	const translate = useTranslate();
	const { forceFetchEvents } = useEventsAction();
	const setCompanySize = (size: 'TEN' | 'ONE_HUNDRED' | 'ONE_THOUSAND') => {
		api.setCompanySize(size).finally(() => {
			forceFetchEvents();
			window.location.reload();
		});
	};

	return (
		<div className={classes.wrapper}>
			<img className={classes.logo} src={SipgateBlackLogo} alt={translate('SIPGATE_LOGO_ALT')} />
			<div className={classes.center}>
				<Headline className={classes.title}>
					{translate.markdown.block('COMPANY_SIZE_VIEW_TITLE')}
				</Headline>
				<p className={classes.description}>
					{translate.markdown.block('COMPANY_SIZE_VIEW_DESCRIPTION')}
				</p>
				<div className={classes.tileWrapper}>
					<button className={classes.tile} type="button" onClick={() => setCompanySize('TEN')}>
						<p>{translate.markdown.block('COMPANY_SIZE_VIEW_SIZE_10')}</p>
					</button>
					<button
						className={classes.tile}
						type="button"
						onClick={() => setCompanySize('ONE_HUNDRED')}
					>
						<p>{translate.markdown.block('COMPANY_SIZE_VIEW_SIZE_100')}</p>
					</button>
					<button
						className={classes.tile}
						type="button"
						onClick={() => setCompanySize('ONE_THOUSAND')}
					>
						<p>{translate.markdown.block('COMPANY_SIZE_VIEW_SIZE_1000')}</p>
					</button>
				</div>
			</div>
		</div>
	);
};
