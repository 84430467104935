import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconHourglass = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M18 7.815V3.5h2V2H4v1.5h2v4.315c0 .98.525 1.89 1.375 2.38L10.5 12l-3.125 1.805A2.75 2.75 0 0 0 6 16.185V20.5H4V22h16v-1.5h-2v-4.315c0-.98-.525-1.89-1.375-2.38L13.5 12l3.125-1.805A2.75 2.75 0 0 0 18 7.815m-2.125 7.29c.385.225.625.64.625 1.085v4.315h-9V16.19c0-.445.24-.86.625-1.085L12 12.87zm0-6.205L12 11.135 8.125 8.9A1.26 1.26 0 0 1 7.5 7.815V3.5h9v4.315c0 .445-.24.86-.625 1.085"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconHourglass;
