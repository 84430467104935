import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconLaptop = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M21 16.5V5.75A2.755 2.755 0 0 0 18.25 3H5.75A2.755 2.755 0 0 0 3 5.75V16.5H2v1.75A2.755 2.755 0 0 0 4.75 21h14.5A2.755 2.755 0 0 0 22 18.25V16.5zM4.5 5.75c0-.69.56-1.25 1.25-1.25h12.5c.69 0 1.25.56 1.25 1.25V16.5h-15zm16 12.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25V18h17z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconLaptop;
