import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconEventList = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M18.78 4.005H5.22L2 13.125v4.13a2.755 2.755 0 0 0 2.75 2.75h14.5a2.755 2.755 0 0 0 2.75-2.75v-4.13zm-4.045 8.5a2.75 2.75 0 0 1-2.735 2.5 2.75 2.75 0 0 1-2.735-2.5H3.81l2.47-7h11.44l2.47 7z"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconEventList;
