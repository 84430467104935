import { createSelector } from '@reduxjs/toolkit';
import { DataUsageState } from './types';
import { dataUsageAdapter, fetchItemEntityAdapter } from './adapters';

const selectDataUsageItems = (state: DataUsageState) => state.items;
const dataUsageSelectors = dataUsageAdapter.getSelectors(selectDataUsageItems);
const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();

export const { selectAll } = dataUsageSelectors;

const createDataUsageSelector = createSelector.withTypes<DataUsageState>();

export const selectById = createDataUsageSelector(
	[dataUsageSelectors.selectById],
	dataUsageItem => {
		if (dataUsageItem) {
			return dataUsageItem.items;
		}

		return undefined;
	}
);

export const {
	isSucceeded: isFetched,
	isPending: isFetching,
	hasFailed,
	selectById: selectFetchItemById,
} = fetchItemEntitySelectors;
