import React from 'react';
import classnames from 'classnames';
import { NeoIcon } from '@web-apps/neo-icons';
import { useTableBodyContext } from './contexts/tableBodyContext';
import { useTableContext } from './contexts/tableContext';
import { TableRowContext } from './contexts/tableRowContext';

type Props = {
	control?: React.ReactElement;
	children: React.ReactNode[];
};

const styles = {
	tr: (isSmallView: boolean) =>
		classnames(
			'block',
			'border-b',
			'border-neo-color-global-border-neutral-moderate-default',
			'last:border-none',
			isSmallView ? ['flex', 'flex-wrap'] : 'table-row'
		),

	controlCell: (isSmallView: boolean, isActiveRow: boolean) =>
		classnames(
			'w-auto',
			'align-middle',
			'py-4',
			'pr-8',
			isSmallView && ['-order-1', 'flex-none', 'self-center'],
			!isSmallView && ['table-cell', 'text-right'],
			isSmallView && isActiveRow && 'block',
			isSmallView && !isActiveRow && 'hidden'
		),

	control: () => classnames('flex', 'justify-end', 'gap-4'),
	collapsableHeader: (isSmallView: boolean, isActiveRow: boolean) =>
		classnames(
			'hover:bg-neo-color-global-background-neutral-soft-hover',
			'transition',
			'border-neo-color-global-border-neutral-moderate-default',
			'cursor-pointer',
			'flex',
			'items-center',
			'font-brand',
			'font-normal',
			'text-base/20',
			'group',
			'justify-between',
			'order-first',
			'pl-4',
			'pr-8',
			'py-8',
			'text-left',
			'w-full',
			!isSmallView && 'hidden',
			isActiveRow && 'border-b'
		),
	iconContainer: (isActiveRow: boolean) =>
		classnames(
			'duration-150',
			'ease-in-out',
			'fill-current',
			'flex',
			'h-24',
			'text-neo-color-global-content-neutral-moderate',
			'transition',
			'w-24',
			'p-0',
			'bg-transparent',
			'rounded',
			'focus-visible:ring-focus-inset',
			isActiveRow && ['scale-y-flip']
		),
};

export const TableRow = ({ children, control }: Props): JSX.Element => {
	const { isActiveRow, rowIndex } = useTableBodyContext();
	const { isSmallView, setActiveRow, activeRow, headerTitles } = useTableContext();

	return (
		<tr className={styles.tr(isSmallView)}>
			{React.Children.map(children, (child, index) => {
				const isFirstColumn = index === 0;
				if (!React.isValidElement(child)) {
					return child;
				}

				const dataCell = (
					<TableRowContext.Provider
						value={{
							headerTitle: headerTitles[index],
							isFirstColumn,
						}}
					>
						{child}
					</TableRowContext.Provider>
				);

				if (!isFirstColumn) {
					return dataCell;
				}

				return (
					<>
						<th
							scope="row"
							onClick={e => {
								e.preventDefault();
								setActiveRow(activeRow === rowIndex ? -1 : rowIndex);
							}}
							className={styles.collapsableHeader(isSmallView, isActiveRow)}
						>
							{child.props.children}

							<button type="button" className={styles.iconContainer(isActiveRow)}>
								<NeoIcon name="Expand" variant="solid" />
							</button>
						</th>

						{dataCell}
					</>
				);
			})}

			{control ? (
				<td className={styles.controlCell(isSmallView, isActiveRow)}>
					<div className={styles.control()}>{control}</div>
				</td>
			) : null}
		</tr>
	);
};
