import { createSlice } from '@reduxjs/toolkit';
import { ProductsTacsState } from '.';
import { fetchProductTacs } from './actions';

const initialState: ProductsTacsState = {
	fetchingForProducts: [],
	fetchedForProducts: [],
	items: {},
};

const slice = createSlice({
	name: 'productTacs',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchProductTacs.pending, (state, { meta }) => ({
			...state,
			fetchingForProducts: [
				...state.fetchingForProducts.filter(productId => productId !== meta.arg),
				meta.arg,
			],
		}));

		builder.addCase(fetchProductTacs.fulfilled, (state, { payload: tacs, meta }) => ({
			fetchingForProducts: [
				...state.fetchingForProducts.filter(productId => productId !== meta.arg),
			],
			fetchedForProducts: [
				...state.fetchedForProducts.filter(productId => productId !== meta.arg),
				meta.arg,
			],
			items: { ...state.items, [meta.arg]: tacs },
		}));
	},
});

export const reducer = slice.reducer;
