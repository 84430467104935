/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import classnames from 'classnames';
import { HeadingBoundary, Headline, SetIcon } from '@panda/ui';
import classes from './JoyrideTooltip.module.scss';

export const JoyrideTooltip = (props: TooltipRenderProps) => {
	const {
		backProps,
		closeProps,
		continuous,
		index,
		primaryProps,
		skipProps,
		step,
		tooltipProps,
		size,
	} = props;

	return (
		<div className={classes.tooltip} {...tooltipProps}>
			<div className={classes.header}>
				{step.title && (
					<HeadingBoundary>
						<Headline className={classes.headline}>{step.title}</Headline>{' '}
					</HeadingBoundary>
				)}
				<button
					type="button"
					className={classnames(classes.button, classes.closeButton)}
					{...closeProps}
				>
					{/* TODO@Panda das x erscheint nicht mittig */}
					<SetIcon icon="close" size="24" touchSize="24" />
				</button>
			</div>

			<div>{step.content}</div>
			<div className={classes.footer}>
				{step.showSkipButton && size > 1 && (
					<button
						type="button"
						className={classnames(classes.button, classes.skipButton)}
						{...skipProps}
					>
						{skipProps.title}
					</button>
				)}

				<div className={classes.actions}>
					{index > 0 && (
						<button
							type="button"
							className={classnames(classes.button, classes.backButton)}
							{...backProps}
						>
							{backProps.title}
						</button>
					)}
					{continuous && (
						<button
							type="button"
							className={classnames(classes.button, classes.primaryButton)}
							{...primaryProps}
						>
							{step.showProgress
								? `${primaryProps.title} (${index + 1}/${size})`
								: primaryProps.title}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
