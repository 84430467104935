import serviceUrls from '@web-apps/service-urls';
import { ApiDataUsageAggregationItem } from './types';
import { RestApiClient } from '../../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../../api/client/HttpClient';
import { ListResponse } from '../../../../../api/SipgateApi';

interface DataUsageAggregationApi {
	getDataUsageAggregation(
		containerId: string,
		months: string[]
	): Promise<ListResponse<ApiDataUsageAggregationItem>>;
}

class DataUsageAggregationRestApiClient extends RestApiClient implements DataUsageAggregationApi {
	public getDataUsageAggregation = (
		containerId: string,
		months: string[]
	): Promise<ListResponse<ApiDataUsageAggregationItem>> =>
		this.get(
			'/v3',
			`/devices/${containerId}/contingents/usage/aggregation?${months
				.map(month => `months=${month}`)
				.join('&')}`
		);
}

export const dataUsageAggregationRestApiClient = new DataUsageAggregationRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
