import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconGroup = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M13 6c0 2.205 1.796 4 4 4 2.206 0 4-1.795 4-4s-1.794-4-4-4c-2.204 0-4 1.795-4 4m1.5 0a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1-5 0M3 11.5c0 2.205 1.795 4 4 4s4-1.795 4-4-1.795-4-4-4-4 1.795-4 4m1.5 0a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1-5 0m6.216 9.945-.54-2.02A1.25 1.25 0 0 0 8.97 18.5H5.04c-.565 0-1.06.38-1.205.925l-.54 2.02-1.45-.39.54-2.02a2.755 2.755 0 0 1 2.655-2.04h3.93a2.75 2.75 0 0 1 2.655 2.04l.54 2.015-1.45.39zm9.46-7.52.54 2.02v-.005l1.45-.39-.54-2.015a2.75 2.75 0 0 0-2.655-2.04h-3.93c-1.24 0-2.335.84-2.655 2.04l-.54 2.02 1.45.39.54-2.02c.145-.545.64-.925 1.205-.925h3.93c.565 0 1.06.38 1.205.925"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconGroup;
