import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconInvoice = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M4 2h16v20H4zm1.5 1.5v17h13v-17zm11 3.5h-9V5.5h9zm-9 3h9V8.5h-9zm4 8.5h-4V17h4zm-4-5.5h4v-1.5h-4z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconInvoice;
