import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconEnterprise = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M17.5 11V3h-11v5H3v13h18V11zm-11 8.5h-2v-10h2zm9.5 0H8v-15h8zm3.5 0h-2v-7h2zM11 6H9.5v2.5H11zm2 0h1.5v2.5H13zm-2 4H9.5v2.5H11zm2 0h1.5v2.5H13zm-2 4H9.5v2.5H11zm2 0h1.5v2.5H13z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconEnterprise;
