import { useEffect } from 'react';

import { useAction } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import {
	assignAudioFileToIvr,
	assignPhoneNumbersToIvr,
	createIvr,
	deleteIVR,
	deleteIvrKeyMapping,
	fetchIvrs,
	renameIVR,
	setIvrFallbackMapping,
	setIvrKeyMapping,
} from './actions';

export const useIvrs = () => {
	const ivrsState = useSelector(state => state.ivrs);

	const dispatch = useDispatch();
	useEffect(() => {
		if (!ivrsState.fetched && !ivrsState.fetching) {
			dispatch(fetchIvrs({}));
		}
	}, [dispatch, ivrsState.fetched, ivrsState.fetching]);

	return {
		items: ivrsState.items,
		fetched: ivrsState.fetched,
		fetching: ivrsState.fetching,
	};
};

export const useIvr = (id: string) => {
	const ivrs = useIvrs();

	return {
		ivr: ivrs.items.find(item => item.id === id),
		fetched: ivrs.fetched,
		fetching: ivrs.fetching,
	};
};

export const useIvrActions = () => ({
	createIvr: useAction(createIvr),
	assignPhoneNumbersToIvr: useAction(assignPhoneNumbersToIvr),
	assignAudioFileToIvr: useAction(assignAudioFileToIvr),
	renameIVR: useAction(renameIVR),
	deleteIVR: useAction(deleteIVR),
	setIvrKeyMapping: useAction(setIvrKeyMapping),
	deleteIvrKeyMapping: useAction(deleteIvrKeyMapping),
	setIvrFallbackMapping: useAction(setIvrFallbackMapping),
});
