import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { MicrosoftTeamsState } from './types';
import { createFqdns, fetchFqdns } from './actions';

const initialState: MicrosoftTeamsState = {
	fqdns: [],
	fetched: false,
	fetching: false,
};

const slice = createSlice({
	name: 'microsoftTeams',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addMatcher(isAnyOf(createFqdns.pending, fetchFqdns.pending), state => ({
			...state,
			fetching: true,
		}));

		builder.addMatcher(
			isAnyOf(createFqdns.fulfilled, fetchFqdns.fulfilled),
			(_state, { payload }) => ({
				fqdns: payload,
				fetched: true,
				fetching: false,
			})
		);
	},
});

export const reducer = slice.reducer;
