import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconLocale_phone_number = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M11.04 21.345c.24.285.59.45.96.45s.72-.165.96-.45l5.185-6.225A8.02 8.02 0 0 0 20 10c0-4.41-3.59-8-8-8s-8 3.59-8 8c0 1.87.66 3.685 1.855 5.12zM5.5 10c0-3.585 2.915-6.5 6.5-6.5s6.5 2.915 6.5 6.5a6.5 6.5 0 0 1-1.505 4.16L12 20.155 7.005 14.16A6.52 6.52 0 0 1 5.5 10m3 0c0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5S8.5 8.07 8.5 10m1.5 0a2 2 0 1 1 3.999-.001A2 2 0 0 1 10 10"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconLocale_phone_number;
