/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import React from 'react';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { usePopper } from 'react-popper';
import { NeoIcon } from '@web-apps/neo-icons';
import { useDisabled } from '../../contexts/disabledContext';
import { Description } from '../visualComponents/Description';
import { ErrorDescription } from '../visualComponents/ErrorDescription';
import { Label } from '../visualComponents/Label';
import { VisualInput } from '../visualComponents/VisualInput';

import classes from './Combobox.module.css';
import { usePandaContext } from '../../contexts/pandaContext';

export type Props<T> = {
	/**
	 * Die Prop `name` entspricht dem HTML-`name`-Attribut.
	 */
	name?: string;
	/**
	 * Die `inputValue` entspricht der Value des Textfeldes.
	 */
	inputValue: string;
	/**
	 * Die Prop `onInputChange` bezieht sich auf die Eingabe in das Textfeld.
	 * Du kannst hier den eingegebenen String entgegennehmen.
	 */
	onInputChange: React.ChangeEventHandler<HTMLInputElement>;
	/**
	 * Die Prop `onOptionSelect` bezieht sich auf die Auswahl des Dropdowns (Vorschlagsliste).
	 * Sollten die `options` ein einfaches String-Array sein, kannst du hier die gleiche
	 * Funktion wie bei `onInputChange` übergeben.
	 */
	onOptionSelect: (option: T) => void;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	error?: string;
} & AdditionalProps<T>;

export type ManagedOption<OptionType> = {
	input: string;
	option?: OptionType;
};

type ManagedProps<T> = {
	/**
	 * Die Prop `managedField` kann genutzt werden, um die renderProps der
	 * [`ManagedForm`-Komponente](https://github.com/sipgate/web-apps/blob/main/shared/forms/Readme.md)
	 * entgegenzunehmen.
	 *
	 */
	managedField: {
		name: string;
		value: ManagedOption<T>;
		setValue: (selectedOption: ManagedOption<T>) => void;
		onBlur: React.FocusEventHandler<HTMLInputElement>;
	} & ({ valid: true; error: null } | { valid: false; error: string });
} & AdditionalProps<T>;

type AdditionalProps<OptionType> = {
	/**
	 * Das `label` befindet sich über dem eigentlichen Eingabefeld und
	 * beschreibt es mit 1-2 Wörtern.
	 */
	label: string;
	/**
	 * Der `placeholder` ist sichtbar, wenn das Eingabefeld leer ist.
	 */
	placeholder: string;
	/**
	 * Die `description` kann benutzt werden, um in einer zweiten Zeile
	 * unter dem `label` noch eine kurze Beschreibung hinzuzufügen.
	 */
	description?: string;
	disabled?: boolean;
	readOnly?: boolean;
	autoFocus?: boolean;
	optional?: boolean;
	options: OptionType[];
	/**
	 * Die Prop `mapOptionToString` wird gebraucht, damit bei der Auswahl aus der Vorschlagsliste Screenreadern
	 * ein vorlesbarer Wert übergeben wird. Sollten deine `options` aus einem String-Array bestehen, kannst du
	 * hier `option => option` übergeben, z. B. bei komplexeren Objekten `option => option.label`.
	 */
	mapOptionToString: (option: OptionType) => string;
	/**
	 * Die Prop `mapOptionToKey` wird gebraucht, damit React die Komponente performant rerendern kann. Sollten
	 * deine `options` aus einem String-Array bestehen, kannst du hier `option => option` übergeben,
	 * z. B. bei komplexeren Objekten `option => option.id`.
	 */
	mapOptionToKey: (option: OptionType) => string | number;
	children: (option: OptionType) => React.ReactNode;
	type?: 'email' | 'tel' | 'text' | 'url';
};

type Condition = 'open' | 'closed';

const styles = {
	div: classnames('relative', 'w-full', 'flex', 'flex-wrap'),
	inputContainer: classnames('relative', 'w-full'),
	optional: classnames(
		'mb-4',
		'mx-2',
		'font-brand',
		'font-light',
		'text-xs/14',
		'text-neo-color-global-content-neutral-moderate',
		'float-right',
		'select-none'
	),
	icon: classnames(
		'absolute',
		'right-12',
		'inset-y-0',
		'h-40',
		'self-center',
		'text-neo-color-global-content-critical-moderate'
	),
	optionList: (condition: Condition) =>
		classnames(
			classes.transitionProperties,
			classes.maxHeight,
			'absolute',
			'z-800',
			'flex',
			'flex-col',
			'items-center',
			'w-full',
			'overflow-x-hidden',
			'overflow-y-scroll',
			'list-none',
			'my-0',
			'px-0',
			'py-4',
			'shadow',
			'bg-neo-color-global-surface-menu',
			'border',
			'border-neo-color-global-border-primary-soft-default',
			'rounded-sm',
			'focus:outline-none',
			'duration-150',
			'ease-in-out',
			condition === 'open' && ['visible'],
			condition === 'closed' && ['invisible']
		),
	option: (listItemCondition: 'default' | 'disabled' | 'highlighted') =>
		classnames(
			'p-8',
			'pl-10',
			'duration-150',
			'ease-in-out',
			'focus:outline-none',
			'focus-visible:outline-none',
			'font-brand',
			'font-normal',
			'text-base/24',
			'select-none',
			'text-left',
			'transition',
			'w-full',
			'flex',
			'shrink-0',
			'whitespace-nowrap',
			listItemCondition === 'default' && [
				'bg-neo-color-global-background-static-transparent',
				'text-neo-color-global-content-neutral-intense',
				'cursor-pointer',
			],
			listItemCondition === 'highlighted' && [
				'bg-neo-color-global-background-primary-soft-hover',
				'text-neo-color-global-content-neutral-intense',
				'cursor-pointer',
				'active:bg-neo-color-global-background-primary-soft-active',
				'active:text-neo-color-global-content-neutral-intense',
			],
			listItemCondition === 'disabled' && [
				'bg-neo-color-global-background-neutral-intense-disabled',
				'cursor-not-allowed',
				'text-neo-color-global-content-neutral-disabled',
			]
		),
	optionLabel: classnames('grow', 'truncate', 'block'),
	overflow: classnames('text-center', 'p-8', 'text-neo-color-global-content-neutral-moderate'),
};

const Combobox = <OptionType,>({
	name,
	error,
	onBlur,
	onInputChange,
	onOptionSelect,
	inputValue,
	autoFocus = false,
	children,
	description,
	disabled: disabledProp,
	readOnly,
	optional,
	mapOptionToString,
	mapOptionToKey,
	options,
	placeholder,
	label,
	type = 'text',
}: Props<OptionType>): JSX.Element => {
	const { languageKeys } = usePandaContext();
	const disabled = useDisabled(disabledProp);
	const inputRef = React.useRef<HTMLInputElement>(null);

	const popperEl = React.useRef(null);
	const popper = usePopper(inputRef.current, popperEl.current, {
		placement: 'bottom',
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 4],
				},
			},
		],
	});

	React.useEffect(() => {
		if (inputRef.current && autoFocus) {
			inputRef.current.focus();
		}
	}, [autoFocus]);

	React.useLayoutEffect(() => {
		// Workaround to get linter to shut up.
		const update = popper.update;

		if (update) {
			update();
		}
	}, [popper.update, options]);

	const onSelectedItemChange = (changes: UseComboboxStateChange<OptionType>) => {
		if (changes.selectedItem !== undefined && changes.selectedItem !== null) {
			onOptionSelect(changes.selectedItem);
		}
	};

	const maxCount = 100;
	const truncated = options.length - maxCount;

	const truncatedOptions = options.slice(0, maxCount);

	const combobox = useCombobox({
		inputValue,
		onSelectedItemChange,
		items: truncatedOptions,
		itemToString: option => (option ? mapOptionToString(option) : ''),
	});

	const listCondition = combobox.isOpen && options.length > 0 ? 'open' : 'closed';

	const focusInput = () => {
		inputRef.current?.focus?.();
	};

	const getCondition = () => {
		if (disabled || readOnly) {
			return 'disabled';
		}

		if (error) {
			return 'error';
		}

		return 'default';
	};

	return (
		<div className={styles.div}>
			<Label {...combobox.getLabelProps()}>{label}</Label>

			{optional ? <span className={styles.optional}>optional</span> : null}

			{description ? <Description onClick={focusInput}>{description}</Description> : null}

			<div className={styles.inputContainer}>
				{error ? <NeoIcon name="Form_error" variant="solid" className={styles.icon} /> : null}

				<VisualInput
					{...combobox.getInputProps({
						placeholder,
						disabled,
						readOnly,
						onBlur,
						type,
						ref: inputRef,
						name,
						onChange: onInputChange,
					})}
					condition={getCondition()}
				/>
			</div>
			<ul
				{...combobox.getMenuProps({
					className: styles.optionList(listCondition),
					style: popper.styles.popper,
					ref: popperEl,
				})}
			>
				{truncatedOptions.map((option, index) => (
					<li
						{...combobox.getItemProps({
							item: option,
							key: mapOptionToKey(option),
							index,
							className: styles.option(
								combobox.highlightedIndex === index ? 'highlighted' : 'default'
							),
						})}
					>
						<span className={styles.optionLabel}>{children(option)}</span>
					</li>
				))}

				{truncated > 0 ? (
					<li className={styles.overflow}>
						{languageKeys.PANDA_COMBOBOX_OVERFLOW(truncated.toLocaleString())}
					</li>
				) : null}
			</ul>

			{error ? <ErrorDescription onClick={focusInput}>{error}</ErrorDescription> : null}
		</div>
	);
};

const ManagedCombobox = <OptionType,>({
	managedField: { value, setValue, error, name, onBlur },
	...otherProps
}: ManagedProps<OptionType>) => (
	<Combobox
		{...otherProps}
		name={name}
		onBlur={onBlur}
		inputValue={value.input}
		onInputChange={event => setValue({ input: event.currentTarget.value })}
		onOptionSelect={selectedOption =>
			setValue({
				input: otherProps.mapOptionToString(selectedOption),
				option: selectedOption,
			})
		}
		error={error === null ? undefined : error}
	/>
);

export { Combobox, ManagedCombobox };
