import { createSlice } from '@reduxjs/toolkit';
import { DataUsageAggregationItem } from './types';
import { dataAggregationAdapter, fetchItemAdapter } from './adapters';
import { fetchDataUsageAggregation } from './actions';
import { StatusItem } from '../../../utils/statusEntityAdapter';

export const getInitialState = (items?: DataUsageAggregationItem[], fetch?: StatusItem[]) => ({
	items: dataAggregationAdapter.getInitialState({}, items),
	fetch: fetchItemAdapter.getInitialState({}, fetch),
});

const slice = createSlice({
	name: 'dataUsageAggregation',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDataUsageAggregation.pending, (state, { meta }) => {
			fetchItemAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'pending',
			});
		});

		builder.addCase(
			fetchDataUsageAggregation.fulfilled,
			(state, { meta, payload: dataAggregationItem }) => {
				fetchItemAdapter.upsertOne(state.fetch, {
					id: meta.arg.containerId,
					status: 'succeeded',
				});
				dataAggregationAdapter.upsertOne(state.items, dataAggregationItem);
			}
		);
	},
});

export const reducer = slice.reducer;
