import React from 'react';
import classnames from 'classnames';
import { localizeNumber } from '@web-apps/phonenumbers-utils';
import angleDownIcon from '../../../media/icons/icon-slim_arrow_down-light-line-default-16.svg';
import classes from './C2DButtonDropdown.module.scss';
import { Contact, ContactNumberType } from '../../../redux/modules/contacts/types';
import { Translate } from '../../../redux/slices/translations';
import { DropdownMenu } from '../../../components/dropdowns/DropdownMenu';
import { DropdownMenuItem } from '../../../components/dropdowns/DropdownMenuItem';
import { SipgateDomain } from '../../../redux/slices/userinfo';

interface Props {
	number: string;
	contact: Contact;
	domain: SipgateDomain;
	translate: Translate;
	isReturningCall?: boolean;
	onClick2Dial: (number: string) => void;
}

export class C2DButtonDropdown extends React.PureComponent<Props> {
	private translateNumberType(type: ContactNumberType) {
		switch (type) {
			case 'cell':
				return this.props.translate('EVENTLIST_TYPE_CELL');
			case 'home':
				return this.props.translate('EVENTLIST_TYPE_HOME');
			case 'other':
				return this.props.translate('EVENTLIST_TYPE_OTHER');
			case 'pager':
				return this.props.translate('EVENTLIST_TYPE_PAGER');
			case 'work':
				return this.props.translate('EVENTLIST_TYPE_WORK');
			case 'directdial':
				return this.props.translate('EVENTLIST_TYPE_DIRECTDIAL');
			default:
				return this.props.translate('EVENTLIST_TYPE_UNKNOWN');
		}
	}

	private onClick2Dial = (number: string) => {
		this.props.onClick2Dial(number);
	};

	public render() {
		return (
			<div className={classes.group}>
				<button
					type="button"
					className={classnames(classes.button, classes.call)}
					onClick={() => this.onClick2Dial(this.props.number)}
					title={this.props.contact.name}
				>
					{this.props.translate(
						this.props.isReturningCall ? 'CALL_EVENT_C2D_BUTTON_CALLBACK' : 'CALL_EVENT_C2D_BUTTON'
					)}
				</button>

				<DropdownMenu
					opener={
						<button type="button" className={classnames(classes.button, classes.arrow)}>
							<img
								alt={this.props.translate('ALT_TAG_CHOOSE_NUMBER')}
								src={angleDownIcon}
								className={classes.icon}
							/>
						</button>
					}
					preventBackgroundInteraction
				>
					{this.props.contact.numbers.map(number => (
						<DropdownMenuItem
							type="button"
							key={number.number}
							onClick={() => this.onClick2Dial(number.number)}
						>
							{`${this.translateNumberType(number.type)} ${
								number.fax ? `${this.props.translate('EVENTLIST_TYPE_FAX')} ` : ''
							} (${localizeNumber(number.number, this.props.domain)})`}
						</DropdownMenuItem>
					))}
				</DropdownMenu>
			</div>
		);
	}
}
