import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconCall = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M19.275 21.885q-.157.001-.315-.02A19.17 19.17 0 0 1 2.135 5.04c-.09-.76.15-1.53.665-2.11C3.325 2.34 4.08 2 4.87 2h3.995c.565 0 1.06.38 1.21.925l.99 3.69c.115.43-.01.895-.325 1.21l-1.91 1.91a14.4 14.4 0 0 0 5.44 5.44l1.91-1.91c.315-.315.775-.44 1.21-.325l3.69.99c.545.145.925.645.925 1.21v3.995c0 .79-.34 1.545-.93 2.07a2.7 2.7 0 0 1-1.795.685zM4.87 3.5c-.36 0-.705.155-.945.425-.235.265-.34.595-.3.94a17.664 17.664 0 0 0 15.51 15.51c.34.04.675-.065.94-.3.27-.24.425-.585.425-.945v-3.805l-3.365-.9-2.59 2.59-.485-.25A15.9 15.9 0 0 1 7.235 9.94l-.25-.485 2.59-2.59-.9-3.365z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconCall;
