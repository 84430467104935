import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconEventList = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M18.78 4H5.22L2 13.12v4.13A2.755 2.755 0 0 0 4.75 20h14.5A2.755 2.755 0 0 0 22 17.25v-4.13zM6.28 5.5h11.44l2.47 7H14.5l-.19.46A2.495 2.495 0 0 1 12 14.5a2.495 2.495 0 0 1-2.31-1.54l-.19-.46H3.81zm12.97 13H4.75c-.69 0-1.25-.56-1.25-1.25V14h5.035c.71 1.23 2.02 2 3.465 2s2.755-.77 3.465-2H20.5v3.25c0 .69-.56 1.25-1.25 1.25"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconEventList;
