import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconArrowLeftUp = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10.135 5.075a1.25 1.25 0 0 0-1.765 0L3.475 9.97l1.06 1.06L8.5 7.066v8.184A2.75 2.75 0 0 0 11.25 18H20v-1.5h-8.75c-.69 0-1.25-.56-1.25-1.25V7.06l3.97 3.97 1.06-1.06z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconArrowLeftUp;
