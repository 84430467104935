import { createSlice } from '@reduxjs/toolkit';
import { DataAutomation, DataAutomationState, isDataAutomationError } from '.';
import { deleteDataAutomation, fetchDataAutomation, setDataAutomation } from './actions';
import { StatusItem } from '../../utils/statusEntityAdapter';
import { dataAutomationAdapter, fetchItemEntityAdapter } from './adapter';

export const getInitialState = (
	items?: DataAutomation[],
	fetch?: StatusItem[]
): DataAutomationState => ({
	items: dataAutomationAdapter.getInitialState({}, items),
	fetch: fetchItemEntityAdapter.getInitialState({}, fetch),
});

const slice = createSlice({
	name: 'dataAutomation',
	initialState: getInitialState(),
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchDataAutomation.pending, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'pending',
			});
		});

		builder.addCase(fetchDataAutomation.fulfilled, (state, { payload, meta }) => {
			dataAutomationAdapter.removeOne(state.items, meta.arg.containerId);

			if (payload.productId) {
				dataAutomationAdapter.upsertOne(state.items, {
					id: meta.arg.containerId,
					productId: payload.productId,
					limit: payload.limit,
				});
			}

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'succeeded',
			});
		});

		builder.addCase(fetchDataAutomation.rejected, (state, { payload: error, meta }) => {
			if (isDataAutomationError(error) && error.name === 'COULD_NOT_FIND_DATA_AUTOMATION') {
				fetchItemEntityAdapter.upsertOne(state.fetch, {
					id: meta.arg.containerId,
					status: 'succeeded',
				});
				return;
			}

			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'failed',
				error,
			});
		});

		builder.addCase(deleteDataAutomation.pending, (state, { meta }) => {
			dataAutomationAdapter.removeOne(state.items, meta.arg);
			fetchItemEntityAdapter.removeOne(state.fetch, meta.arg);
		});

		builder.addCase(setDataAutomation.pending, (state, { meta }) => {
			dataAutomationAdapter.upsertOne(state.items, {
				id: meta.arg.containerId,
				productId: meta.arg.productId,
				limit: meta.arg.limit,
			});
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'pending',
			});
		});

		builder.addCase(setDataAutomation.fulfilled, (state, { meta }) => {
			fetchItemEntityAdapter.upsertOne(state.fetch, {
				id: meta.arg.containerId,
				status: 'succeeded',
			});
		});
	},
});

export const reducer = slice.reducer;
