import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconCheck = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M8.75 18.29c-.32 0-.64-.12-.885-.365l-4.645-4.65 1.06-1.06 4.47 4.47 10.97-10.97 1.06 1.06L9.635 17.92a1.25 1.25 0 0 1-.885.365z"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconCheck;
