import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconRinging_order_longest_idle = ({
	size = 24,
	...props
}: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="m7.47 17.47-1.72 1.72V2.75h-1.5v16.44l-1.72-1.72-1.06 1.06 2.646 2.647a1.25 1.25 0 0 0 1.768 0L8.53 18.53zm3.03-4.72a5.25 5.25 0 1 1 10.5 0 5.25 5.25 0 0 1-10.5 0M15 6.041a6.751 6.751 0 1 0 6.024 2.496L22.28 7.28l-1.06-1.06-1.257 1.256A6.7 6.7 0 0 0 16.5 6.041V5h1.75V3.5h-5V5H15zm0 7.709v-4.5h1.5v4.5z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconRinging_order_longest_idle;
