import { IconNames } from '@web-apps/neo-icons';
import { NeoLanguageKeys } from '../contexts/pandaContext';

export const iconMapping: Record<
	string,
	{ languageKey: keyof NeoLanguageKeys; NeoIcon: IconNames }
> = {
	add: {
		languageKey: 'PANDA_ICON_ADD',
		NeoIcon: 'Add',
	},
	back: {
		languageKey: 'PANDA_ICON_BACK',
		NeoIcon: 'Back',
	},
	close: {
		languageKey: 'PANDA_ICON_CLOSE',
		NeoIcon: 'Close',
	},
	copy: {
		languageKey: 'PANDA_ICON_COPY',
		NeoIcon: 'Copy',
	},
	delete: {
		languageKey: 'PANDA_ICON_DELETE',
		NeoIcon: 'Delete',
	},
	edit: {
		languageKey: 'PANDA_ICON_EDIT',
		NeoIcon: 'Edit',
	},
	export: {
		languageKey: 'PANDA_ICON_EXPORT',
		NeoIcon: 'Download',
	},
	info: {
		languageKey: 'PANDA_ICON_INFO',
		NeoIcon: 'Info',
	},
	next: {
		languageKey: 'PANDA_ICON_NEXT',
		NeoIcon: 'Next',
	},
	'show-password': {
		languageKey: 'PANDA_ICON_SHOW_PASSWORD',
		NeoIcon: 'Show',
	},
	'hide-password': {
		languageKey: 'PANDA_ICON_HIDE_PASSWORD',
		NeoIcon: 'Hide',
	},
	up: {
		languageKey: 'PANDA_ICON_UP',
		NeoIcon: 'Up',
	},
	down: {
		languageKey: 'PANDA_ICON_DOWN',
		NeoIcon: 'Down',
	},
	'renew-invoice': {
		languageKey: 'PANDA_ICON_RENEW_INVOICE',
		NeoIcon: 'Refresh',
	},
} as const;

export const getNeoIconLabel = (lks: NeoLanguageKeys, icon: keyof typeof iconMapping) =>
	lks[iconMapping[icon].languageKey] as keyof typeof iconMapping;
