import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconMobile_network = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M17.305 13.55a7.5 7.5 0 0 0 0-10.605l1.06-1.06c3.513 3.513 3.513 9.212 0 12.725zM6.695 2.95a7.5 7.5 0 0 0 0 10.605l-1.06 1.06c-3.513-3.513-3.513-9.212 0-12.725zm2.475 8.13a4.003 4.003 0 0 1 0-5.66L8.11 4.36a5.503 5.503 0 0 0 0 7.78zm5.66 0a4.003 4.003 0 0 0 0-5.66l1.06-1.06a5.503 5.503 0 0 1 0 7.78zm-3.58-1.986V7.5h1.5v1.594L18.376 22H16.74l-.872-2H8.132l-.872 2H5.624zm3.092 7.406.872 2H8.786l.872-2zm-.654-1.5L12 11.127 10.312 15zM12 9.25"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconMobile_network;
