import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconPbx = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M18.75 2H5.25C4.56 2 4 2.56 4 3.25V10c0 .69.56 1.25 1.25 1.25h13.5c.69 0 1.25-.56 1.25-1.25V3.25C20 2.56 19.44 2 18.75 2M11 7.25H7v-1.5h4zm5 .25c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m1.37 7.38.47-1.75a.51.51 0 0 1 .495-.38h2.06c.65 0 1.18.56 1.105 1.205a10.315 10.315 0 0 1-9.04 9.04c-.645.075-1.205-.455-1.205-1.105v-2.06c0-.23.155-.435.38-.495l1.75-.47a.51.51 0 0 1 .495.135l1.19 1.19a8.4 8.4 0 0 0 3.625-3.625l-1.19-1.19a.52.52 0 0 1-.135-.495m-6 3.49a1.51 1.51 0 0 0-1.12 1.46v2.065q.001.03.005.059l.005.051H5.25c-.69 0-1.25-.56-1.25-1.25v-6.75c0-.69.56-1.25 1.25-1.25h11.665l-.016.04q-.017.04-.029.08l-.47 1.75c-.14.52.01 1.08.39 1.46l.65.65a7.5 7.5 0 0 1-2.21 2.21l-.65-.65a1.49 1.49 0 0 0-1.07-.445q-.195-.001-.39.05zM11 16.5H7V18h4z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconPbx;
