import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconSimCard = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M6.75 3.5c-.69 0-1.25.56-1.25 1.25v14.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25V8.06L13.94 3.5zM4 4.75A2.75 2.75 0 0 1 6.75 2h7.81L20 7.44v11.81A2.75 2.75 0 0 1 17.25 22H6.75A2.75 2.75 0 0 1 4 19.25zm3 5c0-.69.56-1.25 1.25-1.25h7.5c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25h-7.5C7.56 19 7 18.44 7 17.75zm1.5.25v1.5h2.75V10zm4.25 0v1.5h2.75V10zM8.5 17.5V13h7v1.5H10v3zm7-1.5h-4v1.5h4z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconSimCard;
