import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconMs_teams = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M13 8.64v-.805h.005A1.836 1.836 0 0 0 11.17 6H9.725a2 2 0 0 1-.015-.25A2.915 2.915 0 1 1 13 8.64m6.19.235a2.185 2.185 0 1 0 0-4.37 2.185 2.185 0 0 0 0 4.37M11.165 7h-8.33A.836.836 0 0 0 2 7.835v8.335c0 .46.375.835.835.835h8.335c.46 0 .835-.375.835-.835V7.835A.836.836 0 0 0 11.17 7zm-1.68 3.11h-1.87v5.01H6.39v-5.01H4.51V8.88h4.975zm7.205-.61H13v6.665c0 1.01-.82 1.835-1.835 1.835h-3.26q.02.06.037.118.02.068.043.132c.09.215.19.425.31.625a4.997 4.997 0 0 0 9.325-2.5v-5.94c0-.52-.42-.94-.94-.94zm1.68 0h2.69l-.01-.005c.52 0 .94.42.94.94v4.65q.001.324-.065.64a3.12 3.12 0 0 1-3.58 2.44 5.9 5.9 0 0 0 .275-1.79v-5.94c0-.335-.09-.65-.25-.935"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconMs_teams;
