import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconZoom_reset = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M3 6.124V2.5H1.5v5.25C1.5 8.44 2.06 9 2.75 9H8V7.5H3.998A6.502 6.502 0 0 1 16.5 10a6.5 6.5 0 0 1-11.838 3.71l-1.231.857A7.99 7.99 0 0 0 10 18c1.94 0 3.717-.69 5.102-1.838l5.618 5.618 1.06-1.06-5.618-5.618A8 8 0 1 0 3 6.125"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconZoom_reset;
