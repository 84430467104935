import { EntityId } from '@reduxjs/toolkit';
import { ErrorBase } from '../../utils/statusEntityAdapter/types';
import { ChannelHistoryState } from './reducer';

const errorNames = ['COULD_NOT_FETCH_HISTORY'];

type ChannelHistoryErrorNames = (typeof errorNames)[number];

export type ChannelHistoryError = ErrorBase<ChannelHistoryErrorNames> & { channelId: string };

export const CouldNotFetchHistory = (channelId: string): ChannelHistoryError => ({
	name: 'COULD_NOT_FETCH_HISTORY',
	message: 'Could not fetch history',
	channelId,
});

export const isChannelHistoryError = (error: unknown): error is ChannelHistoryError =>
	typeof error === 'object' && error !== null && 'name' in error && 'message' in error;

export type CallStatus = 'Accepted' | 'Missed';

export type ChannelHistoryItem = {
	acdName: string;
	callCenterNumber: string;
	callerNumber: string;
	callStatus: CallStatus;
	datetime: string;
	waitingTime: string;
	talkTime: string;
	followUpTime: string;
	missedReason: string;
	acceptedAgentName: string;
};

export type ChannelHistory = {
	id: EntityId;
	history: ChannelHistoryItem[];
};

export function isChannelHistoryState(obj: unknown): obj is ChannelHistoryState {
	if (typeof obj !== 'object' || obj === null) return false;

	const typedObj = obj as ChannelHistoryState;

	return (
		typeof typedObj.revision === 'string' &&
		typeof typedObj.fetch === 'object' &&
		typeof typedObj.items === 'object'
	);
}
