import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconPlug_and_play = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M18.75 21H13v-1.5c0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5V21H4.25C3.56 21 3 20.44 3 19.75V14h1.5c.825 0 1.5-.675 1.5-1.5S5.325 11 4.5 11H3V5.25C3 4.56 3.56 4 4.25 4H8.5c0-1.655 1.345-3 3-3s3 1.345 3 3h4.25c.69 0 1.25.56 1.25 1.25V9.5c1.655 0 3 1.345 3 3s-1.345 3-3 3v4.25c0 .69-.56 1.25-1.25 1.25m-4.25-1.5h4V14H20c.825 0 1.5-.675 1.5-1.5S20.825 11 20 11h-1.5V5.5H13V4c0-.825-.675-1.5-1.5-1.5S10 3.175 10 4v1.5H4.5v4c1.655 0 3 1.345 3 3s-1.345 3-3 3v4h4c0-1.655 1.345-3 3-3s3 1.345 3 3"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconPlug_and_play;
