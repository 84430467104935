import api from '../../../api';
import { createAsyncThunk } from '../../utils/wrapper';

export const fetchFqdns = createAsyncThunk<string[]>(
	'microsoftTeams/fetchFqdns',
	async () => (await api.getMicrosoftTeamsFqdn()).items
);

export const createFqdns = createAsyncThunk<string[]>(
	'microsoftTeams/createFqdns',
	async () => (await api.createMicrosoftTeamsFqdn()).items
);
