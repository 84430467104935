import * as React from 'react';
import type { DuotoneIconBaseProps } from '../../types';
const DuotoneNeoIconSheep = ({ size = 24, ...props }: DuotoneIconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="#808080"
				d="m22.63 16.08-1.545-4.38c.57-.66.915-1.51.915-2.445 0-1.42-.805-2.705-2.045-3.335A3.97 3.97 0 0 0 16 2.505c-.475 0-.94.085-1.38.245a5.05 5.05 0 0 0-5.245 0 3.9 3.9 0 0 0-1.38-.245A3.97 3.97 0 0 0 4.04 5.92a3.76 3.76 0 0 0-2.045 3.335c0 .935.345 1.79.915 2.445l-1.545 4.38c-.155.445-.055.935.27 1.275.24.255.565.39.905.39q.181.001.355-.05l3.015-.885.37 1.55A4.735 4.735 0 0 0 10.9 22h.345v-1.94l-1.28-1.28 1.06-1.06.97.97.97-.97 1.06 1.06-1.28 1.28V22h.345a4.74 4.74 0 0 0 4.62-3.64l.37-1.55 3.015.885a1.242 1.242 0 0 0 1.26-.34c.325-.34.43-.83.27-1.275zm-19.685.04 1.225-3.47c.255.12.525.215.81.275l.585 2.43-2.615.77zM9 15.005c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m1.36-4.55-.38-.6-.62.345c-.93.52-2.125.805-3.365.805S9.56 10.72 8.63 10.2l-.62-.345-.38.6a2.23 2.23 0 0 1-1.89 1.05c-1.24 0-2.25-1.01-2.25-2.25 0-.95.605-1.805 1.505-2.12l.49-.17.01-.52a2.48 2.48 0 0 1 2.495-2.44c.39 0 .77.09 1.12.27l.415.21.37-.28a3.53 3.53 0 0 1 4.185 0l.37.28.415-.21q.534-.27 1.12-.27c1.37 0 2.465 1.07 2.495 2.44l.01.52.49.17c.9.315 1.505 1.17 1.505 2.12 0 1.24-1.01 2.25-2.25 2.25-.765 0-1.47-.395-1.89-1.05zm2.075 4.9.585-2.43c.285-.06.555-.155.81-.275l1.225 3.47-2.615-.77z"
			/>
			<path
				fill="currentColor"
				d="M9 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2M16 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
			/>
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M12 2a4.96 4.96 0 0 0-2.621.749A4 4 0 0 0 8 2.5a3.996 3.996 0 0 0-3.953 3.415A3.746 3.746 0 0 0 2.912 11.7L1.368 16.08a1.25 1.25 0 0 0 1.531 1.615l3.014-.886.372 1.55A4.75 4.75 0 0 0 10.904 22h2.193a4.75 4.75 0 0 0 4.618-3.642l.373-1.55 3.013.886a1.25 1.25 0 0 0 1.531-1.615L21.087 11.7a3.74 3.74 0 0 0-1.134-5.786A3.996 3.996 0 0 0 16 2.5c-.487 0-.951.09-1.379.249A4.96 4.96 0 0 0 12 2m7.832 10.65q-.385.18-.812.27l-.582 2.427 2.617.77zm-2.354.27a3.73 3.73 0 0 1-1.964-1.126c-1.02.45-2.228.706-3.514.706s-2.493-.255-3.512-.705a3.7 3.7 0 0 1-1.966 1.125l1.221 5.088a3.25 3.25 0 0 0 3.16 2.492h.347v-.44l-1.28-1.28 1.06-1.06.97.97.97-.97 1.06 1.06-1.28 1.28v.44h.347a3.25 3.25 0 0 0 3.16-2.492zm-12.498 0a3.7 3.7 0 0 1-.812-.27l-1.223 3.467 2.617-.77zm4.929-8.719A3.48 3.48 0 0 1 12 3.5c.784 0 1.503.262 2.091.701l.37.277.414-.207C15.22 4.099 15.6 4 16 4a2.497 2.497 0 0 1 2.495 2.442l.012.52.49.17A2.24 2.24 0 0 1 20.5 9.25c0 1.24-1.01 2.25-2.25 2.25-.79 0-1.485-.418-1.887-1.052l-.38-.598-.618.345C14.483 10.687 13.31 11 12 11s-2.483-.313-3.365-.805l-.62-.346-.38.6A2.22 2.22 0 0 1 5.75 11.5c-1.24 0-2.25-1.01-2.25-2.25 0-.975.628-1.81 1.504-2.118l.489-.172.012-.518A2.497 2.497 0 0 1 8 4c.4 0 .78.099 1.125.27l.413.208z"
				clipRule="evenodd"
			/>
		</svg>,
		elementProps
	);
};
export default DuotoneNeoIconSheep;
