import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { NeoIcon } from '@web-apps/neo-icons';
import { DisabledContext, isDisabled } from '../../contexts/disabledContext';
import { BaseLink } from './BaseLink';

type GeneralProps = {
	children: string | ReactNode;
	/** Der disabled State kann auch über den Context gesteuert werden. [Info zu Disabled Context](../?path=/docs/utilities-disabledcontext--docs) */
	disabled?: boolean;
	/** Der Pfad zum Download-Ziel. */
	fileName?: string;
	/** target='blank' sollte nur in Ausnahmen verwendet werden. */
	target?: 'blank';
	url: string;
	/** Diese Funktion wird aufgerufen, wenn die Datei gedownloaded wird und soll genutzt werden, um den Download an sich zu tracken. Dazu nehmen wir in der Regel mixpanel. Sie darf auf keinen Fall dazu genutzt werden, eine Seitennavigation vorzunehmen. Dazu dient das "url"-prop. */
	tracking?: () => void;
};

type AsButtonProps = {
	button: true;
	/** Die Größen "small" und "xlarge" können nur in der variant="loud" gesetzt werden. */
	size?: 'small' | 'medium' | 'large' | 'xlarge';
	width?: 'content' | 'max' | 'max-on-touch-device';
	variant?: 'normal' | 'loud';
	iconOnly?: boolean;
};

type LinkProps = {
	button?: false;
	size?: never;
	width?: never;
	variant?: 'silent' | 'quiet' | 'normal';
	iconOnly?: never;
};

type Props = GeneralProps & (AsButtonProps | LinkProps);

const styles = {
	icon: (
		size: 'small' | 'medium' | 'large' | 'xlarge',
		disabled: boolean,
		button: boolean,
		iconOnly: boolean
	) =>
		classnames(
			'self-center',
			disabled && 'text-neo-color-global-content-primary-intense-disabled',
			button &&
				!iconOnly && [
					size === 'small' && 'ml-6',
					size === 'medium' && 'ml-8',
					size === 'large' && 'ml-10',
					size === 'xlarge' && 'ml-12',
				],
			!button && ['ml-eighth-text'],
			!button && '-mb-[0.14em]'
		),
	text: classnames('whitespace-normal'),
};

const DownloadLink = ({
	button = false,
	children,
	disabled,
	fileName,
	size = 'medium',
	width = 'content',
	url,
	variant = 'normal',
	iconOnly = false,
	target,
	tracking,
}: Props): JSX.Element => {
	const disabledContextValue = React.useContext(DisabledContext);

	const getIconSize = () => {
		switch (size) {
			case 'small':
				return 12;
			case 'medium':
				return 16;
			case 'large':
			case 'xlarge':
				return 24;
		}
	};

	return (
		<BaseLink
			button={button}
			disabled={disabled}
			size={size}
			width={width}
			type="download"
			fileName={fileName}
			variant={variant}
			iconOnly={iconOnly}
			url={url}
			target={target}
			tracking={tracking}
		>
			{!iconOnly && <span className={styles.text}>{children}</span>}
			{!iconOnly && '\uFEFF'}
			{button ? (
				<NeoIcon
					name="Download"
					variant="line"
					size={getIconSize()}
					touchSize={24}
					className={styles.icon(
						size,
						isDisabled(disabled, disabledContextValue),
						button,
						iconOnly
					)}
				/>
			) : (
				<NeoIcon
					name="Download"
					variant="line"
					size="inline"
					className={styles.icon(
						size,
						isDisabled(disabled, disabledContextValue),
						button,
						iconOnly
					)}
				/>
			)}
		</BaseLink>
	);
};

export { DownloadLink };
