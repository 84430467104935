import serviceUrls from '@web-apps/service-urls';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';
import { DataAutomationResponse } from './types';

interface DataAutomationRestApi {
	getDataAutomation(containerId: string): Promise<DataAutomationResponse>;

	setDataAutomation(containerId: string, productId: number, limit?: number): Promise<void>;

	deleteDataAutomation(containerId: string): Promise<void>;
}

class DataAutomationRestApiClient extends RestApiClient implements DataAutomationRestApi {
	public getDataAutomation = (containerId: string) =>
		this.get('/v3', `/devices/${containerId}/contingents/automatic`);

	public setDataAutomation = (containerId: string, productId: number, limit?: number) =>
		this.put('/v3', `/devices/${containerId}/contingents/automatic`, {
			productId,
			limit,
		});

	public deleteDataAutomation = (containerId: string) =>
		this.delete('/v3', `/devices/${containerId}/contingents/automatic`);
}

export const dataAutomationRestApiClient = new DataAutomationRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
