import { createSlice } from '@reduxjs/toolkit';
import { PseudoState } from '.';
import { createPseudo, deletePseudo } from './actions';

const initialState: PseudoState = {
	items: [],
};

const slice = createSlice({
	name: 'pseudo',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createPseudo, (state, { payload }) => ({
			items: [...state.items, payload],
		}));
		builder.addCase(deletePseudo, (state, { payload }) => ({
			items: state.items.filter(item => item.key !== payload.key),
		}));
	},
});

export const reducer = slice.reducer;
