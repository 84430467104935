import { EntityId, EntityState } from '@reduxjs/toolkit';

type FailedStatusItem = {
	id: EntityId;
	status: 'failed';
	timestamp?: string;
	error?: ErrorBase<string>;
};

export type StatusItem =
	| FailedStatusItem
	| {
			id: EntityId;
			status: Exclude<OperationState, 'failed'>;
			timestamp?: string;
	  };

export const hasError = (item: StatusItem): item is FailedStatusItem =>
	item.status === 'failed' && item.error !== undefined;

export type StatusState = EntityState<StatusItem, EntityId>;

export type OperationState = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface ErrorBase<T extends string> {
	name: T;
	message: string;
}

// Generic Type Guard
export function isErrorBase<T extends string>(
	error: unknown,
	validNames: readonly T[]
): error is ErrorBase<T> {
	return (
		typeof error === 'object' &&
		error !== null &&
		'name' in error &&
		'message' in error &&
		validNames.includes((error as ErrorBase<T>).name)
	);
}
