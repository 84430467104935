import * as React from 'react';
import type { IconBaseProps } from '../../types';
const SolidNeoIconSheep = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="m22.63 16.08-1.545-4.38c.57-.66.915-1.51.915-2.445 0-1.42-.805-2.705-2.045-3.335A3.97 3.97 0 0 0 16 2.505c-.475 0-.94.085-1.38.245a5.05 5.05 0 0 0-5.245 0 3.9 3.9 0 0 0-1.38-.245A3.97 3.97 0 0 0 4.04 5.92a3.76 3.76 0 0 0-2.045 3.335c0 .935.345 1.79.915 2.445l-1.545 4.38c-.155.445-.055.935.27 1.275.24.255.565.39.905.39q.181.001.355-.05l3.015-.885.37 1.55A4.735 4.735 0 0 0 10.9 22h.345v-1.94l-1.28-1.28 1.06-1.06.97.97.97-.97 1.06 1.06-1.28 1.28V22h.345a4.74 4.74 0 0 0 4.62-3.64l.37-1.55 3.015.885a1.242 1.242 0 0 0 1.26-.34c.325-.34.43-.83.27-1.275zm-19.685.04 1.225-3.47c.255.12.525.215.81.275l.585 2.43-2.615.77zM9 15.005c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1m1.36-4.55-.38-.6-.62.345c-.93.52-2.125.805-3.365.805S9.56 10.72 8.63 10.2l-.62-.345-.38.6a2.23 2.23 0 0 1-1.89 1.05c-1.24 0-2.25-1.01-2.25-2.25 0-.95.605-1.805 1.505-2.12l.49-.17.01-.52a2.48 2.48 0 0 1 2.495-2.44c.39 0 .77.09 1.12.27l.415.21.37-.28a3.53 3.53 0 0 1 4.185 0l.37.28.415-.21q.534-.27 1.12-.27c1.37 0 2.465 1.07 2.495 2.44l.01.52.49.17c.9.315 1.505 1.17 1.505 2.12 0 1.24-1.01 2.25-2.25 2.25-.765 0-1.47-.395-1.89-1.05zm2.075 4.9.585-2.43c.285-.06.555-.155.81-.275l1.225 3.47-2.615-.77z"
			/>
		</svg>,
		elementProps
	);
};
export default SolidNeoIconSheep;
