import { useEffect } from 'react';
import { SliceData, useAction } from '../..';
import { useDispatch, useSelector } from '../../utils/wrapper';
import { hasFailed, isFetched, selectById, selectFetchItemById } from './selectors';
import { ChannelInboxesOfWebuser } from './types';
import { fetchChannelInbox, setChannelInbox } from './actions';

export const useChannelInbox = (
	webuserId: string,
	isNeoPbx: boolean
): SliceData<ChannelInboxesOfWebuser | null> => {
	const dispatch = useDispatch();
	const state = useSelector(s => s.channelInbox);

	useEffect(() => {
		if (isNeoPbx) {
			dispatch(fetchChannelInbox({ webuserId }));
		}
	}, [dispatch, webuserId, isNeoPbx]);

	if (hasFailed(state, webuserId) || selectFetchItemById(state, [webuserId]) === undefined) {
		return {
			fetched: true,
			data: null,
		};
	}

	if (!isFetched(state, webuserId)) {
		return {
			fetched: false,
			data: null,
		};
	}

	return {
		fetched: true,
		data: selectById(state, webuserId) || null,
	};
};

export const useChannelInboxActions = () => ({
	setChannelInbox: useAction(setChannelInbox),
});
