import * as React from 'react';
import type { IconBaseProps } from '../../types';
const LineNeoIconDown = ({ size = 24, ...props }: IconBaseProps): React.ReactElement => {
	const convertedSize = size === 'inline' ? '1em' : `${size / 16}rem`;
	const elementProps = {
		style: {
			minWidth: convertedSize,
			width: convertedSize,
			minHeight: convertedSize,
			height: convertedSize,
		},
		viewBox: '0 0 24 24',
		...props,
	};
	return React.cloneElement(
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
			<path
				fill="currentColor"
				d="M12 16.54c-.32 0-.64-.12-.885-.365L3.97 9.03l1.06-1.06L12 14.94l6.97-6.97 1.06 1.06-7.145 7.145a1.25 1.25 0 0 1-.885.365"
			/>
		</svg>,
		elementProps
	);
};
export default LineNeoIconDown;
