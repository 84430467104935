import { Moment } from 'moment/moment';
import { HttpClient } from './HttpClient';

type Version = '/v2' | '/v3';

export class RestApiClient {
	// eslint-disable-next-line no-empty-function
	public constructor(protected readonly http: HttpClient) {}

	protected get(
		version: Version,
		path: string,
		options: {
			mimeType?: string;
			unauthenticated?: boolean;
		} = {}
	) {
		return this.http.get(version + path, options);
	}

	protected delete(version: Version, path: string, data?: unknown) {
		return this.http.delete(version + path, data);
	}

	protected post(version: Version, path: string, data?: unknown) {
		return this.http.post(version + path, data);
	}

	protected put(version: Version, path: string, data?: unknown) {
		return this.http.put(version + path, data);
	}

	protected patch(version: Version, path: string, data?: unknown) {
		return this.http.patch(version + path, data);
	}

	protected getDataDStartAndEndDateParameterString = (
		startDate?: Moment | null,
		endDate?: Moment | null
	) => {
		const formattedDates = this.formatStartAndEndDateForDataD(startDate, endDate);
		let url = '';

		if (typeof formattedDates.from !== 'undefined') {
			url += `&from=${encodeURIComponent(formattedDates.from)}`;
		}
		if (typeof formattedDates.to !== 'undefined') {
			url += `&to=${encodeURIComponent(formattedDates.to)}`;
		}

		return url;
	};

	protected formatStartAndEndDateForDataD = (from?: Moment | null, to?: Moment | null) => {
		let beginningOfStartDay;
		let endOfEndDay;

		if (from && typeof from !== 'undefined') {
			beginningOfStartDay = from
				.clone()
				/* datepicker defaults to noon (12:00) */
				.hours(0)
				.format('YYYY-MM-DDTHH:mm:ssZ');

			if (to && typeof to !== 'undefined') {
				endOfEndDay = to
					.clone()
					/* datepicker defaults to noon (12:00) */
					.hours(23)
					.minutes(59)
					.seconds(59)
					.format('YYYY-MM-DDTHH:mm:ssZ');
			} else {
				/* User picked a single day so we use the end of that day */
				endOfEndDay = from
					.clone()
					/* datepicker defaults to noon (12:00) */
					.hours(0)
					.add(1, 'day')
					.format('YYYY-MM-DDTHH:mm:ssZ');
			}
		}

		return {
			from: beginningOfStartDay,
			to: endOfEndDay,
		};
	};
}
