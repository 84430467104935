import { createAsyncThunk } from '../../../utils/wrapper';
import { dataUsageAggregationRestApiClient as api } from './api';
import { isFetched, isFetching } from './selectors';
import { DataUsageAggregationState } from './types';

type WithForce = {
	force?: boolean;
};

type FetchDataUsageAggregationActionParameter = {
	containerId: string;
	months: string[];
	force?: boolean;
} & WithForce;

const shouldFetchDataAggregation = (state: DataUsageAggregationState, deviceId: string) =>
	!isFetched(state, deviceId) && !isFetching(state, deviceId);

export const fetchDataUsageAggregation = createAsyncThunk(
	'dataUsageAggregation/fetch',
	async ({ containerId, months }: FetchDataUsageAggregationActionParameter) => {
		const { items } = await api.getDataUsageAggregation(containerId, months);

		return {
			id: containerId,
			items: items.sort((a, b) => b.month.localeCompare(a.month)),
		};
	},
	{
		condition: (
			{ force = false, containerId }: FetchDataUsageAggregationActionParameter,
			{ getState }
		) => force || shouldFetchDataAggregation(getState().dataUsage.aggregation, containerId),
	}
);
