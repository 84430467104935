import serviceUrls from '@web-apps/service-urls';
import { PhonelineBlockAnonymous, PhonelineBlockAnonymousTargetType } from './types';
import { RestApiClient } from '../../../../api/client/RestApiClient';
import { HttpClient } from '../../../../api/client/HttpClient';

interface PhoneLineBlockAnonymousRestApi {
	getPhonelineBlockAnonymous(userId: string, phonelineId: string): Promise<PhonelineBlockAnonymous>;

	setPhonelineBlockAnonymous(
		userId: string,
		phonelineId: string,
		enabled: boolean,
		target: PhonelineBlockAnonymousTargetType
	): Promise<void>;
}

class PhoneLineBlockAnonymousRestApiClient
	extends RestApiClient
	implements PhoneLineBlockAnonymousRestApi
{
	public getPhonelineBlockAnonymous = (userId: string, phonelineId: string) =>
		this.get('/v2', `/${userId}/phonelines/${phonelineId}/blockanonymous`);

	public setPhonelineBlockAnonymous = (
		userId: string,
		phonelineId: string,
		enabled: boolean,
		target: string
	) =>
		this.put('/v2', `/${userId}/phonelines/${phonelineId}/blockanonymous`, {
			enabled,
			target,
		});
}

export const phoneLineBlockAnonymousRestApiClient = new PhoneLineBlockAnonymousRestApiClient(
	new HttpClient({
		baseUrl: serviceUrls.restApi,
	})
);
