import { createSelector } from '@reduxjs/toolkit';
import { CallStatus, ChannelHistoryItem } from './types';
import { crunchNumbers } from './utils';
import { OperationState } from '../../utils/statusEntityAdapter';
import { ChannelHistoryState } from './reducer';
import { channelHistoryEntityAdapter, fetchItemEntityAdapter } from './adapter';

export const aggregateByHour = (
	history: ChannelHistoryItem[],
	callStatus: CallStatus
): Record<string, number> => {
	return crunchNumbers(history, callStatus);
};

const selectChannelHistoryState = (state: ChannelHistoryState) => state.items;

const channelHistoryEntitySelectors =
	channelHistoryEntityAdapter.getSelectors(selectChannelHistoryState);
const fetchItemEntitySelectors = fetchItemEntityAdapter.getSelectors();

const creatTypedSelector = createSelector.withTypes<ChannelHistoryState>();

export const selectByStatus = creatTypedSelector(
	[
		channelHistoryEntitySelectors.selectAll,
		fetchItemEntitySelectors.selectAll,
		(_, status: OperationState) => status,
	],
	(items, statusItems, status) => {
		const ids = statusItems.filter(i => i.status === status).map(i => i.id);
		return items.filter(i => ids.includes(i.id));
	}
);

export const areFetched = fetchItemEntitySelectors.areSucceeded;
export const isStale = fetchItemEntitySelectors.isStale;
export const isFetching = fetchItemEntitySelectors.isPending;
export const isFetched = fetchItemEntitySelectors.isSucceeded;
